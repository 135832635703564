import type { FC, ReactNode } from 'react';
import React from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  Text,
  TextSize,
  Tooltip,
  TooltipBackgroundColor,
} from '@writercolab/ui-atoms';

import isEmpty from 'lodash/isEmpty';
import { NavLink } from 'react-router';

import { openContactSalesPage } from '../../../utils/navigationUtils';

import styles from './styles.module.css';

interface MenuItemLockConfig {
  title: string;
  description: string;
  cta: boolean;
}

export interface SecondaryMenuConfigBase {
  name?: string;
  icon?: ReactNode;
  trailingIcon?: ReactNode;
  isHidden?: boolean;
  isDisabled?: boolean;
  locked?: boolean;
  lockConfig?: MenuItemLockConfig;
  reload?: boolean;
}

// Utility type enforcing that either `link` or `navLink` is required
export type SecondaryMenuConfig =
  | (SecondaryMenuConfigBase & { link: string; navLink?: never })
  | (SecondaryMenuConfigBase & { navLink: string; link?: never });

export interface MenuItem {
  section: string;
  list: Record<string, SecondaryMenuConfig>;
}

interface ISecondaryMenuProps {
  className?: string;
  data: MenuItem[];
}

const LockTooltip = ({ lockConfig }) => (
  <div className={styles.lockContainer}>
    <div className={styles.lockArt} />
    {lockConfig.title && (
      <div className={styles.lockHeading}>
        <Text variant={TextSize.XXXL} bold className={styles.lockHeadingText}>
          {lockConfig.title}
        </Text>
      </div>
    )}
    {lockConfig.description && (
      <div className={styles.lockBody}>
        <Text variant={TextSize.L} className={styles.lockBodyText}>
          {lockConfig.description}
        </Text>
      </div>
    )}
    {lockConfig.cta && (
      <div className={styles.lockCta}>
        <Button type={ButtonTypes.PRIMARY} onClick={openContactSalesPage}>
          Contact us
        </Button>
      </div>
    )}
  </div>
);

/**
 * Generates a list of menu items based on the provided configuration.
 *
 * @param {Record<string, SecondaryMenuConfig>} config - The configuration object containing menu item details.
 * @returns {JSX.Element[] | null[]} - An array of JSX elements representing the menu items, or null for hidden items.
 *
 * @throws {Error} - Throws an error if neither `link` nor `navLink` is provided in the configuration.
 *
 * Note: Either `link` or `navLink` must be provided in the `SecondaryMenuConfig` for each menu item.
 */
const generateMenuList = (config: Record<string, SecondaryMenuConfig>): (JSX.Element | null)[] => {
  return Object.keys(config).map(item => {
    const { icon, name, trailingIcon, locked, lockConfig, isDisabled, link, navLink, isHidden, reload } = config[item];
    const enableLock = locked && lockConfig;
    const _linkDetails = (
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2">
          <div>{icon}</div>
          <div>{name}</div>
        </div>
        <div>
          <div>{trailingIcon}</div>
          {enableLock && (
            <div className={styles.lockIcon}>
              <Icon name={IconVariant.LOCK_LINE} />
            </div>
          )}
        </div>
      </div>
    );

    const className = cx(styles.iconLink, {
      [styles.disabledLink]: isDisabled,
      [styles.lockedLink]: locked,
    });

    let LinkComponent: ReactNode;

    if (isDisabled || locked) {
      LinkComponent = <span className={className}>{_linkDetails}</span>;
    } else if (link) {
      LinkComponent = (
        <a href={link} className={className}>
          {_linkDetails}
        </a>
      );
    } else if (navLink) {
      LinkComponent = (
        <NavLink to={navLink} className={className} reloadDocument={reload}>
          {_linkDetails}
        </NavLink>
      );
    } else {
      throw new Error('Either link or navLink must be provided');
    }

    return isHidden ? null : (
      <li key={item}>
        <Tooltip
          title={<LockTooltip lockConfig={lockConfig} />}
          placement="right-start"
          disabled={!locked}
          tooltipBackgroundColor={TooltipBackgroundColor.WHITE}
          tooltipWidth="265px"
          tooltipWrapperClassname={styles.lockTooltipContainer}
        >
          <div>{LinkComponent}</div>
        </Tooltip>
      </li>
    );
  });
};

interface NavigationLinksProps {
  list: Record<string, SecondaryMenuConfig>;
  title: string;
}

const NavigationLinks = ({ list, title }: NavigationLinksProps) => (
  <div className={styles.navigationLinksContainer}>
    {title && (
      <Text extraSmallCaps bold variant={TextSize.XS} className={styles.navigationLinksContainerItem}>
        {title}
      </Text>
    )}
    <ul className={styles.menuList}>{generateMenuList(list)}</ul>
  </div>
);

export const SecondaryMenuSidebar: FC<ISecondaryMenuProps> = ({ className, data, ...props }) => (
  <div className={cx(className, styles.secondaryMenuWrapper)} {...props}>
    <nav>
      {data.map(({ section, list }, index) => {
        if (isEmpty(list)) {
          return null;
        }

        return <NavigationLinks key={`${index}-${section}`} list={list} title={section} />;
      })}
    </nav>
  </div>
);

export default SecondaryMenuSidebar;
