import { observable, runInAction } from 'mobx';
import qs from 'qs';

import { PaginatedModel } from '@writercolab/mobx';
import { RequestServiceInitialize } from '@writercolab/network';

import {
  TOAuthAppsConnectorResponse,
  TOAuthAppsCreateConnectorRequest,
  TOAuthAppsQuery,
  TOAuthAppsResponse,
  TOAuthAppsSortField,
  TOAuthAppsSortOrder,
  TOAuthAppsTypes,
  TOAuthAppsUpdateConnectorRequest,
  TOrganizationSettingsRequestBody,
  TPaginatedOAuthApps,
  TPaginatedOAuthAppsArgs,
  TPaginatedOAuthAppsExtraArgs,
} from '@web/types';

import { TAppState } from '../state/types';

interface OauthAppsApiModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number | undefined;
  teamId: TAppState['teamId'];
}

const DEFAULT_PAGE_SIZE = 50;
export class OAuthAppsApiModel {
  private $isLoading = observable.box(false);

  public pagination: PaginatedModel<
    TPaginatedOAuthApps,
    TOAuthAppsResponse,
    TPaginatedOAuthAppsArgs,
    TPaginatedOAuthAppsExtraArgs
  >;

  constructor(private opts: OauthAppsApiModelOptions) {
    const urlParams = new URLSearchParams(document.location.search);
    this.pagination = new PaginatedModel<
      TPaginatedOAuthApps,
      TOAuthAppsResponse,
      TPaginatedOAuthAppsArgs,
      TPaginatedOAuthAppsExtraArgs
    >({
      argsExtra: {
        search: urlParams?.get('search') ?? undefined,
        sortField: (urlParams?.get('sortField') as TOAuthAppsSortField) ?? 'createdAt',
        sortOrder: (urlParams?.get('sortOrder') as TOAuthAppsSortOrder) ?? 'desc',
        types: (urlParams?.getAll('types') as TOAuthAppsTypes[]) ?? undefined,
      },
      argsDefault: {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      extractMeta: obj => {
        const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

        if (offset >= obj.totalCount) {
          return this.pagination.args;
        }

        return {
          offset,
          limit: DEFAULT_PAGE_SIZE,
        };
      },
      extract: obj => obj.result ?? [],
      load: async ({ args, extra }) => {
        if (!this.opts.organizationId) {
          throw new Error('OrganizationId is not defined');
        }

        const query: TOAuthAppsQuery = {
          ...args,
          ...extra,
        };

        runInAction(() => {
          this.$isLoading.set(true);
        });

        const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/connector', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
            },
            query,
          },
          querySerializer: query => qs.stringify(query, { arrayFormat: 'repeat' }),
        });

        runInAction(() => {
          this.$isLoading.set(false);
        });

        return data as TPaginatedOAuthApps;
      },
    });
  }

  createConnector = async (body: TOAuthAppsCreateConnectorRequest) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });

    await this.opts.request.post('/api/media/organization/{organizationId}/connector', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
      },
      body,
    });

    runInAction(() => {
      this.$isLoading.set(false);
    });
  };

  getConnectorById = async (connectorId: string) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    if (!connectorId) {
      throw new Error('ConnectorId is not defined');
    }

    const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/connector/{connectorId}', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
          connectorId,
        },
      },
    });

    return data as TOAuthAppsConnectorResponse;
  };

  getOrganizationSettings = async () => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    const { data } = await this.opts.request.get('/api/organization/v2/{organizationId}/settings', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
      },
    });

    return data;
  };

  updateOrganizationSettings = async (body: TOrganizationSettingsRequestBody) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    await this.opts.request.put('/api/organization/v2/{organizationId}/settings', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
      },
      body,
    });
  };

  deleteConnector = async (connectorId?: string) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    if (!connectorId) {
      throw new Error('ConnectorId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });

    const { data } = await this.opts.request.delete(
      '/api/media/organization/{organizationId}/connector/{connectorId}',
      {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            connectorId,
          },
        },
      },
    );

    runInAction(() => {
      this.$isLoading.set(false);
    });

    return data;
  };

  editConnector = async (connectorId: string, body: TOAuthAppsUpdateConnectorRequest) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    if (!connectorId) {
      throw new Error('ConnectorId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });

    await this.opts.request.put('/api/media/organization/{organizationId}/connector/{connectorId}', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
          connectorId,
        },
      },
      body,
    });
    runInAction(() => {
      this.$isLoading.set(false);
    });
  };

  getConnectorTypes = async () => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/connector/type', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
      },
    });

    return data;
  };

  get data(): TOAuthAppsResponse[] | undefined {
    return this.pagination.value;
  }

  get isLoading() {
    return this.$isLoading.get();
  }

  refresh = async () => {
    await this.pagination.reload();
    await this.pagination.promise;
  };
}
