import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import {
  BillingProduct,
  RemovePersonActionType,
  UserTeamRole,
  isUserInvited,
  wordPluralize,
} from '@writercolab/common-utils';
import { Button, IconButton, SimpleTooltip } from '@writercolab/fe.wds';
import {
  Dropdown,
  DropdownPlacement,
  ItemsTypes,
  LabelledTags,
  Modal,
  SearchBar,
  UserNotificationBanner,
  useCustomSnackbar,
  useQueueWorkerNotifications,
} from '@writercolab/ui-atoms';
import { FilterIndicator } from '@writercolab/ui-molecules';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';
import { cn } from '@writercolab/utils';

import { UpdateBillingGroup } from 'components/molecules/UpdateBillingGroup';

import { snackbarMessages } from '@web/component-library';
import { MAX_TEAM_SIZE, type IAddPeopleOption } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { ArrowDownToLine, CirclePlus } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { DROPDOWN_DOWNLOAD_OPTIONS, usePeopleContext } from '../../../context/peopleContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { ROUTE } from '../../../services/config/routes';
import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import { goToBilling } from '../../../utils/navigationUtils';
import { calcMaxTeamSize } from '../../../utils/teamUtils';
import { InviteTeammates } from '../../molecules/InviteTeammates/InviteTeammates';
import UIModelInviteTeammates from '../../molecules/InviteTeammates/UIModelInviteTeammates';
import PageTitle from '../../molecules/PageTitle';
import PeopleList from '../../molecules/PeopleList';
import type { IModifiedUser } from '../../molecules/PeopleList/PeopleListRow';
import TeamStatsSection from '../../molecules/TeamStatsSection';
import DeletePersonAdvanced from '../../organisms/DeletePersonAdvanced/DeletePersonAdvanced';
import { ImportItemType } from '../../organisms/ImporterPopup/types';
import {
  InviteTeammatesForm,
  InviteTeammatesFormModelUi,
  TInviteTeammatesEvents,
} from '../../organisms/InviteTeammatesForm';
import Error404Page from '../Error404Page';
import { headerTooltipText } from '../termsAndSnippetsShared/common';
import { SendInviteModal } from './modals';
import DeletePersonModal from './modals/DeletePersonModal';
import RemovePersonModal from './modals/RemovePersonModal';

import styles from './styles.module.css';

interface IPeoplePageProps {
  isDisabled?: boolean;
}

const TooltipText = {
  disabledAddButton: 'To invite a user, ask an org admin',
  disabledSSAddButton: 'Only org admins can invite people',
};

export const PeoplePage: React.FC<IPeoplePageProps> = observer(({ isDisabled }) => {
  const navigate = useNavigate();
  const {
    appState,
    appModel: { permissionsModel, assistantSubscription, analyticsService },
  } = useAppState();
  const { organization, userProfile } = appState;
  const { isTeam, isEnterprise, isMultiTeam, limits, isStarter } = assistantSubscription;
  const { closeSnackbar, enqueueLoadingSnackbar } = useCustomSnackbar();
  const {
    people,
    orgId,
    teamId,
    isLoading,
    infiniteRefSentry,
    handleSendInvite,
    currentTeam,
    isTeamUsersPage,
    organizationAdmins,
    filtersCount,
    handleSearch,
    onAfterInviteTeamMate,
    handleSeatsNumberChange,
    searchValue,
    handleMakeAdmin,
    handleRemovePerson,
    handleResendInvite,
    handleAddUserToTeam,
    handlePeopleSortingChange,
    handleRemovePersonFromTeam,
    handleDownloadPeoplesList,
    handleRejectPendingUser,
    handleApprovePendingUser,
    handleUserFilterChange,
    handleBillingGroupFilterChange,
    handleResetFilters,
    fetchPeopleList,
    billingGroups,
    isCurrentTeamLoading,
    userFilterVal,
    billingGroupFilterVal,
    editBillingGroup,
  } = usePeopleContext();
  usePageTitle(isTeamUsersPage ? 'Teammates' : 'People');

  const [isSendInviteOpen, setIsSendInviteOpen] = useState(false);
  const [isDeletePersonOpen, setIsDeletePersonOpen] = useState(false);
  const [isRemoveTeammateOpen, setIsRemoveTeammateOpen] = useState(false);
  const [isUpdateBillingGroupOpen, setIsUpdateBillingGroupOpen] = useState(false);
  const [person, setActivePerson] = useState<IModifiedUser | null>(null);
  const billingGroupOptions = billingGroups.map(group => ({ label: group.name, value: group.id }));
  const navigateToBilling = () => navigate(ROUTE.toBilling(orgId));
  const teamSizeLimit = calcMaxTeamSize(MAX_TEAM_SIZE, limits?.user?.limit);

  const teamSizeLimitReached = useMemo(() => {
    return !isEnterprise && (limits?.user?.value || 0) >= (limits?.user?.limit || 0);
  }, [isEnterprise, limits?.user?.limit, limits?.user?.value]);

  const seatsRemainingCount = useMemo(() => {
    const limit = limits?.user?.limit ?? 0;
    const value = limits?.user?.value ?? 0;

    return Math.max(limit - value, 0);
  }, [limits?.user?.limit, limits?.user?.value]);

  const handleEditBillingGroup = useCallback(
    (billingGroupId: number) => {
      if (!person) {
        return;
      }

      editBillingGroup({
        billingGroupId,
        userIds: [person.id],
      });

      setIsUpdateBillingGroupOpen(false);
    },
    [editBillingGroup, person],
  );

  const inviteTeammates = async (invitees: IAddPeopleOption[]) => {
    handleAddUserToTeam({
      orgId,
      teamId,
      role: UserTeamRole.MEMBER,
      invitees,
    });
    fetchPeopleList();
    setIsSendInviteOpen(false);
  };

  const uiModelInviteTeammatesModel = useMemo(
    () =>
      new UIModelInviteTeammates({
        request: requestService.api,
        onSubmit: inviteTeammates,
        teamId,
        orgId,
        teamSizeLimitReached,
        teamSizeLimit: limits?.user?.limit || 0,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId, teamId, limits?.user?.limit, teamSizeLimitReached, people],
  );

  const inviteTeammatesFormModelUi = useMemo(
    () =>
      new InviteTeammatesFormModelUi({
        request: requestService.api,
        organizationId: () => Number(orgId),
        teamId: () => Number(teamId),
        maxSeatsCount: () => assistantSubscription.limits?.user?.limit,
        currentSeatsCount: () => assistantSubscription.limits?.user?.value,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSendInviteOpen,
      assistantSubscription.limits?.user?.limit,
      assistantSubscription.limits?.user?.value,
      orgId,
      teamId,
    ],
  );

  useQueueWorkerNotifications(inviteTeammatesFormModelUi.notificationQueue);

  inviteTeammatesFormModelUi.eventsQueue.read(({ type }) => {
    let snackKey: string | number = '';

    switch (type) {
      case TInviteTeammatesEvents.enum.invitesSentSuccessfully:
        onAfterInviteTeamMate();
        break;
      case TInviteTeammatesEvents.enum.startSendingInvites:
        snackKey = enqueueLoadingSnackbar(snackbarMessages.loader.invitingTeamMember);
        break;
      case TInviteTeammatesEvents.enum.invitesSentError:
      case TInviteTeammatesEvents.enum.finishSendingInvites:
        closeSnackbar(snackKey);
        setIsSendInviteOpen(false);
        break;
      default:
        break;
    }
  });

  const access = {
    isTeamPage: isTeamUsersPage,
    isPeoplePage: !isTeamUsersPage,
    showViewOnlyTag: permissionsModel?.isTeamMember,
    addPeopleDisabled: permissionsModel?.isTeamMember || teamSizeLimitReached,
    hideMoreOptions: permissionsModel?.isTeamMember,
    hideInvitePendingDropdown: permissionsModel?.isTeamMember,
    hidePendingApprovalDropdown: !permissionsModel?.isOrganizationAdmin,
    teamSizeLimitReached,
  };

  const handleClearInput = () => {
    handleSearch('');
  };

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  const actions = {
    make_admin: handleMakeAdmin,
    // delete user from the org
    delete: (person: IModifiedUser) => {
      setIsDeletePersonOpen(!isDeletePersonOpen);
      setActivePerson(person);
    },
    // remove user from the team
    remove: (person: IModifiedUser) => {
      setIsRemoveTeammateOpen(!isRemoveTeammateOpen);
      setActivePerson(person);
    },
    resend: handleResendInvite,
    approve: handleApprovePendingUser,
    reject: handleRejectPendingUser,
    update_billing_group: (person: IModifiedUser) => {
      setIsUpdateBillingGroupOpen(!isUpdateBillingGroupOpen);
      setActivePerson(person);
    },
  };

  const onBillingLockAction = () => {
    if (teamId) {
      analyticsService.track(AnalyticsActivity.clickPurchaseTeamNow, {
        clicked_from: 'team_people',
      });
    }

    organization?.id && goToBilling(navigate, organization?.id, BillingProduct.STARTER);
  };

  useEffect(() => {
    analyticsService.track(isTeamUsersPage ? AnalyticsActivity.teammatesViewed : AnalyticsActivity.peopleViewed, {});
  }, [analyticsService, isTeamUsersPage]);

  const handleSingleTeamPerson = useCallback(
    (user: IModifiedUser) => {
      if (userProfile) {
        handleRemovePerson(user, RemovePersonActionType.ASSIGN_ALL_DOCS, userProfile);
      }
    },
    [handleRemovePerson, userProfile],
  );

  const handleCloseBillingGroupModel = () => {
    setIsUpdateBillingGroupOpen(false);
  };

  const navigateToPeoplePage = () => {
    navigate(ROUTE.toPeople(orgId));
  };

  const inviteButtonTooltipText = useMemo(() => {
    let inviteButtonTooltipText: string | React.ReactNode = !isMultiTeam
      ? TooltipText.disabledSSAddButton
      : TooltipText.disabledAddButton;

    if (teamSizeLimitReached) {
      inviteButtonTooltipText = (
        <div className="text-white text-xs">
          You don't have any available seats on your
          <br /> plan. Purchase more to invite people.
        </div>
      );
    }

    return inviteButtonTooltipText;
  }, [isMultiTeam, teamSizeLimitReached]);

  const inviteModalStates = useMemo(
    () => ({
      isInvitePeopleModal: isEnterprise && (!isTeamUsersPage || !isMultiTeam),
      isInviteEnterpriseTeammateModal: isTeamUsersPage && isEnterprise && !!currentTeam?.id,
      isInviteStarterTeammateModal: (isStarter || isTeam) && !!currentTeam?.id,
    }),
    [isTeamUsersPage, isMultiTeam, isEnterprise, isStarter, currentTeam],
  );

  const teammatesCountBannerVisible = useMemo(() => {
    if (!permissionsModel || !teamId) {
      return false;
    }

    return !isEnterprise && permissionsModel.isTeamAdminOf(teamId) && seatsRemainingCount === 0;
  }, [isEnterprise, permissionsModel, seatsRemainingCount, teamId]);

  if (isDisabled) {
    return <Error404Page />;
  }

  if (!userProfile) {
    return null;
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.peopleList}>
        <div className={styles.peopleHeadline}>
          <div className={styles.title}>
            <PageTitle title={isTeamUsersPage ? 'Teammates' : 'People'} teamName={currentTeam?.name || ''} />
            {access.showViewOnlyTag && (
              <SimpleTooltip
                className={styles.indicatorContainer}
                trigger={
                  <div>
                    <LabelledTags bgColor="var(--classic-grey-1)">VIEW ONLY</LabelledTags>
                  </div>
                }
              >
                {headerTooltipText.viewOnlyTeamMember(ImportItemType.TEAMMATES)}
              </SimpleTooltip>
            )}
          </div>
          {permissionsModel?.isOrganizationAdmin && (
            <Dropdown
              options={DROPDOWN_DOWNLOAD_OPTIONS}
              onPrimaryOptionClickAction={handleDownloadPeoplesList}
              placement={DropdownPlacement.BOTTOM_RIGHT}
              itemsType={ItemsTypes.ACTION}
              testId="people-download-dropdown"
              trigger={
                <SimpleTooltip
                  trigger={
                    <IconButton variant="gray" className={styles.downloadButton} aria-label="Download people list">
                      <ArrowDownToLine />
                    </IconButton>
                  }
                >
                  Download
                </SimpleTooltip>
              }
            />
          )}
          {!!filtersCount && (
            <div className={styles.indicatorContainer}>
              <FilterIndicator filtersAmount={filtersCount} onClose={handleResetFilters} testId="people-filter-count" />
            </div>
          )}
          <SearchBar
            id="search"
            value={searchValue}
            placeholder="Search"
            onChange={handleSearchInput}
            handleClearInput={handleClearInput}
            className={styles.searchBar}
          />
          <SimpleTooltip
            placement="bottom"
            trigger={
              <div>
                <Button
                  variant="primary"
                  className={styles.invitePeopleButton}
                  size="md"
                  leftIcon={<CirclePlus />}
                  onClick={() => setIsSendInviteOpen(!isSendInviteOpen)}
                  loading={isCurrentTeamLoading}
                  disabled={access.addPeopleDisabled || isCurrentTeamLoading}
                >
                  {isEnterprise && isMultiTeam ? 'Add people' : 'Invite people'}
                </Button>
              </div>
            }
            disabled={!access.addPeopleDisabled}
          >
            {inviteButtonTooltipText}
          </SimpleTooltip>
        </div>
        {currentTeam && <TeamStatsSection team={currentTeam} adminTitle="Team admins" compact />}
        {teammatesCountBannerVisible && (
          <UserNotificationBanner
            className={styles.userNotification}
            notification={
              <div className="text-base">
                You have <span className={styles.seatsCount}>{seatsRemainingCount}</span>{' '}
                {wordPluralize(seatsRemainingCount, 'seat')} remaining on your plan. Go to the{' '}
                <Button
                  className={cn(styles.linkButton, 'text-base underline text-black')}
                  variant="link"
                  onClick={navigateToBilling}
                >
                  billing page
                </Button>{' '}
                to purchase more seats.
              </div>
            }
          />
        )}
        <div className={styles.listWrapper}>
          <PeopleList
            list={people}
            actions={actions}
            isLoading={isLoading}
            handleSortingChange={handlePeopleSortingChange}
            hideMoreOptions={access.hideMoreOptions}
            isTeamUsersPage={isTeamUsersPage}
            isSelfServe={!isMultiTeam}
            hideInvitePendingDropdown={access.hideInvitePendingDropdown}
            hidePendingApprovalDropdown={access.hidePendingApprovalDropdown}
            handleUserStatusFilterChange={handleUserFilterChange}
            handleBillingGroupFilterChange={handleBillingGroupFilterChange}
            infiniteRefSentry={infiniteRefSentry}
            handleClearFilters={handleResetFilters}
            hideBillingGroups={!isEnterprise || (isTeamUsersPage && isMultiTeam)}
            billingGroups={billingGroups}
            userFilterVal={userFilterVal}
            billingGroupFilterVal={billingGroupFilterVal}
          />
        </div>
      </div>

      {inviteModalStates.isInviteEnterpriseTeammateModal && (
        <Modal
          open={isSendInviteOpen}
          modalTitleClassName={styles.modalTitle}
          handleClose={() => setIsSendInviteOpen(false)}
          title={`Add people to ${currentTeam?.name} team`}
          style={{ width: '460px' }}
        >
          <InviteTeammates
            model={uiModelInviteTeammatesModel}
            onNavigateToPeoplePageClick={navigateToPeoplePage}
            showWarningsForTeamUser={!permissionsModel?.isOrganizationAdmin}
            showPreventAddUserWarning={isTeamUsersPage}
          />
        </Modal>
      )}

      {inviteModalStates.isInvitePeopleModal && (
        <SendInviteModal
          isOpen={isSendInviteOpen}
          changeModalState={() => setIsSendInviteOpen(!isSendInviteOpen)}
          onSubmit={handleSendInvite}
          orgId={orgId}
          onSeatsChange={handleSeatsNumberChange}
          teamSizeLimit={teamSizeLimit}
          billingGroupOptions={billingGroupOptions}
        />
      )}

      {inviteModalStates.isInviteStarterTeammateModal && (
        <Modal
          open={isSendInviteOpen}
          className={styles.teammateInviteModal}
          modalTitleClassName={styles.modalTitle}
          handleClose={() => setIsSendInviteOpen(false)}
        >
          <InviteTeammatesForm
            model={inviteTeammatesFormModelUi}
            onBillingPageClick={navigateToBilling}
            onCancel={() => setIsSendInviteOpen(false)}
          />
        </Modal>
      )}

      <RemovePersonModal
        person={person}
        orgName={organization?.name || ''}
        isOpen={isRemoveTeammateOpen}
        onClose={() => setIsRemoveTeammateOpen(false)}
        isEnterpriseAccount={isEnterprise}
        isMultiTeamAccount={isMultiTeam}
        modalText={{
          title: `${isEnterprise && !isMultiTeam ? 'Delete' : 'Remove'} user from team`,
          description: personName => `Are you sure you want to remove ${personName} from ${currentTeam?.name}?`,
        }}
        submitButtonText={`Yes, ${isEnterprise && !isMultiTeam ? 'delete' : 'remove'}`}
        onSubmit={handleRemovePersonFromTeam}
      />

      {((access.isTeamPage && !isEnterprise && !isMultiTeam) ||
        (access.isPeoplePage && isMultiTeam) ||
        (access.isTeamPage && isMultiTeam) ||
        (!isMultiTeam && access.isTeamPage)) &&
      person &&
      !isUserInvited(person) ? (
        <DeletePersonAdvanced
          isOpen={isDeletePersonOpen}
          onSubmit={handleRemovePerson}
          teammate={person}
          userProfile={userProfile}
          organizationAdmins={organizationAdmins}
          onClose={() => setIsDeletePersonOpen(false)}
        />
      ) : (
        <DeletePersonModal
          userProfile={userProfile}
          isOpen={isDeletePersonOpen}
          onClose={() => setIsDeletePersonOpen(false)}
          onSubmit={handleSingleTeamPerson}
          person={person}
          orgName={organization?.name || ''}
          modalText={{
            title: 'Delete user from organization',
            description: (personName, orgName) => `Are you sure you want to delete ${personName} from ${orgName}?`,
          }}
        />
      )}
      {!isTeam && !isEnterprise && <BillingLock type={BillingLockType.TEAMS} onActionCallback={onBillingLockAction} />}

      <Modal open={isUpdateBillingGroupOpen} handleClose={handleCloseBillingGroupModel} style={{ width: '445px' }}>
        <UpdateBillingGroup
          billingGroupOptions={billingGroupOptions}
          onSubmit={handleEditBillingGroup}
          defaultBillingGroupId={person?.billingGroup?.id}
        />
      </Modal>
    </div>
  );
});

export default PeoplePage;

PeoplePage.displayName = 'PeoplePage';
