import type { ReactNode, Reducer } from 'react';
import type React from 'react';
import { useEffect, useMemo, useReducer, useState } from 'react';

import type { IBillingPlan, IPaymentMethod, IPaymentMethodDetails } from '@writercolab/common-utils';
import {
  BillingInformationFormElement,
  BillingProduct,
  BillingStatus,
  CheckoutPopupType,
  StripeErrorCode,
  creditCardCvcErrorCodes,
  creditCardErrorCodes,
  creditCardExpiryErrorCodes,
  setupIntentErrorCodes,
} from '@writercolab/common-utils';
import { Button, ButtonTypes, LinkText, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import type { PaymentMethod, Stripe, StripeError } from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';

import { useBillingContext } from '../../../../context/billingContext';
import { useAppState } from '../../../../state';
import { openContactSalesPage } from '../../../../utils/navigationUtils';
import type { IBillingTotalWidgetState } from '../BillingTotalWidget';
import BillingTotalWidget, { billingCalculationMapper } from '../BillingTotalWidget';
import CreditCardHolder from '../CreditCardHolder';
import { CheckoutCoupon } from './CheckoutCoupon';
import PaymentMethodWrapper from './PaymentMethod/PaymentMethodWrapper';

import styles from './styles.module.css';

interface CheckoutElementsProps {
  stripePromise: Promise<Stripe | null>;
  trigger: (onClick) => ReactNode;
  onCancel?: () => void;
  paymentMethod: IPaymentMethod | null;
  onEditPaymentMethod?: () => void;
}

type TCheckoutState = {
  isCardNumberElementFocused: boolean;
  isCardExpiryElementFocused: boolean;
  isCardCvcElementFocused: boolean;
  isCardNumberElementInvalid: boolean;
  isCardExpiryElementInvalid: boolean;
  isCardCvcElementInvalid: boolean;
  isZipCodeAvailable: boolean;
  billingInformationItemInvalid: any;
  billingInformationError: StripeError | null;
  billingInformationValid: boolean | null;
  billingDetails: IPaymentMethodDetails;
  paymentMethod: PaymentMethod | null;
  isStatesAvailable: boolean;
  billingPlan: IBillingPlan | null;
  isLoading: boolean;
};

type TCheckoutAction = {
  type: CheckoutActionType;
  payload?: { [key: string]: any };
};

enum CheckoutActionType {
  CARD_NUMBER_INPUT_FOCUS = 'CARD_NUMBER_INPUT_FOCUS',
  CARD_NUMBER_INPUT_INVALID = 'CARD_NUMBER_INPUT_INVALID',
  BILLING_PROVIDER_ERROR = 'BILLING_PROVIDER_ERROR',
  BILLING_PROVIDER_INFO = 'BILLING_PROVIDER_INFO',
  BILLING_INFORMATION_ITEM_INVALID = 'BILLING_INFORMATION_ITEM_INVALID',
  SET_COUNTRY = 'SET_COUNTRY',
  SET_STATE = 'SET_STATE',
  SET_VALIDITY = 'SET_VALIDITY',
  SET_BILLING_PLAN = 'SET_BILLING_PLAN',
  SET_LOADING = 'SET_LOADING',
}

const ErrorMessage = ({ error }) => (
  <div className={styles.inlineErrorMessage}>
    <Text variant={TextSize.S}>{error.message}</Text>
  </div>
);

const CancelPlanHolder = ({ onSubmit }) => (
  <div className={styles.cancelPlanContainer}>
    <Text variant={TextSize.XXL} bold className={styles.cancelPlanTitle}>
      Cancel plan
    </Text>
    <Text variant={TextSize.S}>
      Is something not working out for you?
      <br />
      <LinkText onClick={openContactSalesPage}>Contact us</LinkText> and let's see if we can help.
    </Text>
    <Button type={ButtonTypes.LINK} size={SizeTypes.SMALL} className={styles.cancelButton} onClick={onSubmit}>
      I definitely want to cancel
    </Button>
  </div>
);

const PaymentDetailsHolder = ({
  organizationId,
  paymentMethod,
  onEditPaymentMethod,
  state,
  stripePromise,
  isPlanPastDue,
}) => {
  const [isEditMode, setIsEditMode] = useState(true);

  return (
    <div className={styles.containerCreditCardHolder}>
      {isPlanPastDue ? (
        <PaymentMethodWrapper
          paymentMethod={paymentMethod}
          stripePromise={stripePromise}
          isEditButtonShown={isEditMode}
          onEditButtonClick={() => setIsEditMode(false)}
          onCancel={() => setIsEditMode(true)}
          isPlanPastDue={isPlanPastDue}
          organizationId={organizationId}
        />
      ) : (
        <>
          {state.billingInformationError && <ErrorMessage error={state.billingInformationError} />}
          <CreditCardHolder
            lastDigits={paymentMethod.lastDigits}
            brandName={paymentMethod.cardBrand}
            onEditClick={onEditPaymentMethod}
            className={styles.containerCreditCardItem}
          />
        </>
      )}
    </div>
  );
};

const CreditCardForm = ({ organizationId, paymentMethod, stripePromise, onSubmitSuccess, submitTrigger, onCancel }) => (
  <>
    <PaymentMethodWrapper
      organizationId={organizationId}
      paymentMethod={paymentMethod}
      stripePromise={stripePromise}
      onSubmitSuccess={onSubmitSuccess}
      submitTrigger={submitTrigger}
      cancelTrigger={
        <Button type={ButtonTypes.TRANSPARENT} className={styles.checkoutFormButtonsCancel}>
          Cancel
        </Button>
      }
      onCancel={onCancel}
      inlineTriggers
    />
  </>
);

const reducer = (state: TCheckoutState, action: TCheckoutAction) => ({ ...state, ...action.payload });

const checkoutInitialState: TCheckoutState = {
  billingInformationError: null,
  billingInformationValid: null,
  isCardCvcElementFocused: false,
  isLoading: false,
  isCardCvcElementInvalid: false,
  isCardExpiryElementFocused: false,
  isCardExpiryElementInvalid: false,
  isCardNumberElementFocused: false,
  isCardNumberElementInvalid: false,
  billingInformationItemInvalid: {
    [BillingInformationFormElement.NAME]: false,
    [BillingInformationFormElement.CITY]: false,
    [BillingInformationFormElement.ADDRESS_LINE1]: false,
    [BillingInformationFormElement.POSTAL_CODE]: false,
  },
  isStatesAvailable: true,
  isZipCodeAvailable: true,
  paymentMethod: null,
  billingDetails: {},
  billingPlan: null,
};

export const CheckoutElements: React.FC<CheckoutElementsProps> = observer(
  ({ trigger, paymentMethod, onEditPaymentMethod, stripePromise, onCancel }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { appModel } = useAppState();
    const {
      billingContext,
      onApplyCoupon,
      onRemoveCoupon,
      onSubmitBillingInformation,
      onSubmitBillingInformationError,
      onSubmitBillingInformationComplete,
      onCheckoutPlanSwitch,
      onCancelPlanClick,
    } = useBillingContext();

    const subscription = appModel.assistantSubscription.$subscription.value;

    const [state, dispatch] = useReducer<Reducer<TCheckoutState, TCheckoutAction>>(reducer, checkoutInitialState);
    const _userCount = billingContext.usage?.user?.value || 1;
    const _userSeatLimit = billingContext.usage?.user?.limit || 0;
    const _areUsersMoreThanPlanSeats = _userCount && _userCount > _userSeatLimit;

    const access = {
      isPlanPastDue: subscription?.status === BillingStatus.PAST_DUE,
      isPlanCancelled: subscription?.status === BillingStatus.CANCELED,
    };

    const plan = {
      isStarter: billingContext.checkout.planSelected?.productName === BillingProduct.STARTER,
      isTeam: billingContext.checkout.planSelected?.productName === BillingProduct.TEAM,
    };

    const _seatsToShowInBillingTotalWidget =
      _areUsersMoreThanPlanSeats || access.isPlanCancelled ? _userCount : _userSeatLimit;

    const billingCalc = useMemo(
      () => ({
        isLoading: !billingContext.checkout.planCalculation,
        rows: billingCalculationMapper(
          billingContext.checkout.planCalculation,
          billingContext.checkout.submitButtonDisabled,
          !!subscription?.scheduledToCancelAt,
          billingContext.checkout.isPlanUnchanged,
          access.isPlanPastDue,
          billingContext.checkout.planSelected?.interval,
          plan.isStarter,
        ),
        paymentAt: new Date(billingContext.checkout.planCalculation?.paymentAt || ''),
        showSeatSelector: plan.isTeam || plan.isStarter,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [billingContext.checkout.planCalculation, billingContext.checkout.planSelected],
    );
    const showCancelPlanBox =
      billingContext.checkout.popupType === CheckoutPopupType.MANAGE_PLAN &&
      !subscription?.canceledAt &&
      !subscription?.scheduledToCancelAt;

    useEffect(() => {
      if (!(stripe && elements)) {
        return;
      }

      if (billingContext.checkout.confirmationIntent) {
        const { clientSecret, paymentMethodId } = billingContext.checkout.confirmationIntent;
        stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethodId }).then(result => {
          if (!result.error) {
            onSubmitBillingInformationComplete();
          } else {
            // cover very specific Stripe credit card error message
            if (result.error.code === StripeErrorCode.INCOMPATIBLE_PAYMENT_METHOD) {
              // eslint-disable-next-line no-param-reassign
              result.error.message = "There's a problem with your card. Update it or add a different card.";
            }

            dispatch({
              type: CheckoutActionType.BILLING_PROVIDER_ERROR,
              payload: { billingInformationError: result.error },
            });
            onSubmitBillingInformationError(result.error);
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingContext.checkout.confirmationIntent, elements, stripe]);

    useEffect(() => {
      if (state.billingInformationError && state.billingInformationError.code) {
        const stripeErrorCode = state.billingInformationError.code as StripeErrorCode;

        if (creditCardErrorCodes.includes(stripeErrorCode)) {
          dispatch({
            type: CheckoutActionType.CARD_NUMBER_INPUT_INVALID,
            payload: { isCardNumberElementInvalid: true },
          });
        } else if (creditCardExpiryErrorCodes.includes(stripeErrorCode)) {
          dispatch({
            type: CheckoutActionType.CARD_NUMBER_INPUT_INVALID,
            payload: { isCardExpiryElementInvalid: true },
          });
        } else if (creditCardCvcErrorCodes.includes(stripeErrorCode)) {
          dispatch({
            type: CheckoutActionType.CARD_NUMBER_INPUT_INVALID,
            payload: { isCardCvcElementInvalid: true },
          });
        } else if (setupIntentErrorCodes.includes(stripeErrorCode)) {
          dispatch({
            type: CheckoutActionType.CARD_NUMBER_INPUT_INVALID,
            payload: {
              isCardNumberElementInvalid: true,
              isCardCvcElementInvalid: true,
              isCardExpiryElementInvalid: true,
            },
          });
        }
      }
    }, [state.billingInformationError]);

    useEffect(() => {
      if (billingContext.checkout.planSelected) {
        dispatch({
          type: CheckoutActionType.SET_BILLING_PLAN,
          payload: {
            billingPlan: billingContext.checkout.planSelected,
          },
        });
      }
    }, [billingContext.checkout.planSelected]);

    const handleSubmit = async stripePaymentMethod => {
      onSubmitBillingInformation(stripePaymentMethod.id);
    };
    const handleChangeBillingData = (state: IBillingTotalWidgetState) => {
      onCheckoutPlanSwitch(state.planDuration, state.seats);
    };

    return (
      <div className={styles.container}>
        {state.billingPlan && (
          <BillingTotalWidget
            billingDate={billingCalc.paymentAt}
            onChange={handleChangeBillingData}
            seats={_seatsToShowInBillingTotalWidget}
            planDuration={state.billingPlan.interval}
            rows={billingCalc.rows}
            type={billingContext.checkout.popupType}
            isLoading={billingCalc.isLoading}
            disableSeatsSelector={!billingCalc.showSeatSelector}
            userCount={billingContext.usage?.user?.value}
            isCancelled={!!(billingContext.checkout.isPlanUnchanged && subscription?.scheduledToCancelAt)}
            disabled={access.isPlanPastDue}
            billingDatePrefix={!billingContext.checkout.submitButtonDisabled ? 'Start date' : undefined}
            teamSizeLimit={billingContext.teamSizeLimit.max}
            isTeamSizeLimitReached={billingContext.teamSizeLimit.reached}
          />
        )}

        <div className={styles.containerCoupon}>
          <CheckoutCoupon
            onApply={onApplyCoupon}
            onRemove={onRemoveCoupon}
            couponCode={billingContext.checkout.coupon?.code}
            isValid={billingContext.checkout.coupon?.valid}
            isLoading={billingContext.checkout.isCouponLoading}
          />
        </div>
        <div>
          {paymentMethod ? (
            <>
              <div className={styles.containerTitle}>
                <Text variant={TextSize.XXL} bold>
                  Payment information
                </Text>
              </div>
              <PaymentDetailsHolder
                organizationId={appModel.organizationId}
                paymentMethod={paymentMethod}
                onEditPaymentMethod={onEditPaymentMethod}
                state={state}
                stripePromise={stripePromise}
                isPlanPastDue={access.isPlanPastDue}
              />
              {showCancelPlanBox && <CancelPlanHolder onSubmit={onCancelPlanClick} />}
              <div className={styles.containerTrigger}>{trigger(handleSubmit)}</div>
              <div className={styles.containerCancelTrigger}>
                <Button
                  type={ButtonTypes.TRANSPARENT}
                  size={SizeTypes.SMALL}
                  className={styles.checkoutFormButtonsCancel}
                  onClick={onCancel}
                >
                  <Text variant={TextSize.L}>Cancel</Text>
                </Button>
              </div>
            </>
          ) : (
            <CreditCardForm
              organizationId={appModel.organizationId}
              stripePromise={stripePromise}
              paymentMethod={paymentMethod}
              onSubmitSuccess={handleSubmit}
              submitTrigger={trigger}
              onCancel={onCancel}
            />
          )}
        </div>
      </div>
    );
  },
);

export default CheckoutElements;
