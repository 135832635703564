import React from 'react';

import { wordPluralize } from '@writercolab/common-utils';
import { SimpleTooltip } from '@writercolab/fe.wds';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingColor,
  HeadingVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  UserNotificationBanner,
  useQueueWorkerNotifications,
} from '@writercolab/ui-atoms';
import { ThreeDotsLoader } from '@writercolab/ui-molecules';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';

import { AddVoiceModal } from 'components/organisms/AddVoiceModal';
import SectionHeading from 'components/organisms/SectionHeading';
import { ViewVoiceModal } from 'components/organisms/ViewVoiceModal';
import { VoiceAnalyzerModal } from 'components/organisms/VoiceAnalyzerModal';
import { VoiceConstructorModal } from 'components/organisms/VoiceConstructorModal';
import { VoiceTable } from 'components/organisms/VoiceTable';

import { AnalyticsActivity } from 'constants/analytics';
import { usePageTitle } from 'hooks/usePageTitle';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import { useAppState } from 'state';

import { openContactSalesPage } from '../../../utils/navigationUtils';
import type { VoicePageModel } from './VoicePageModel.ui';

import styles from './VoicePage.module.css';

interface IVoicePageProps extends BaseComponentsProps {
  model: VoicePageModel;
  isAdmin: boolean;
  isFeatureLocked: boolean;
  isAssistantSubscriptionActive?: boolean;
  teamName?: string;
  onBillingLockClick?: () => void;
  onDescriptionLinkClick?: () => void;
  checkIsVoiceEditable?: (id?: number) => boolean;
}

export const VoicePage = observer<IVoicePageProps>(
  ({
    model,
    isAdmin,
    isFeatureLocked,
    isAssistantSubscriptionActive,
    teamName,
    onBillingLockClick,
    onDescriptionLinkClick,
    checkIsVoiceEditable,
  }) => {
    const { appModel } = useAppState();
    usePageTitle(model.aiStudioMode ? 'AI Studio' : 'Voice');
    useQueueWorkerNotifications(model.notificationQueue);

    const { limit, exceeded: voiceLimitReached } = model.voiceLimits;

    return (
      <div className={styles.container}>
        {!isFeatureLocked && (
          <SectionHeading
            descriptionContainerClassName={styles.headingDescription}
            heading="Voice"
            subHeading={teamName}
            headingVariant={HeadingVariant.H2}
            descriptionTextVariant={TextSize.XL}
            subHeadingUppercase
            headingUppercase
            description={
              model.aiStudioMode && onDescriptionLinkClick ? (
                <>
                  Set up AI Studio voice profiles to use in text generation agents.
                  <br />
                  {isAssistantSubscriptionActive && (
                    <>
                      To access team voices, head over to the{' '}
                      <span onClick={onDescriptionLinkClick} className={styles.descriptionLink}>
                        main app
                      </span>
                      .
                    </>
                  )}
                </>
              ) : (
                <>
                  Set up a voice profile that sounds more like you.
                  <br />
                  Apply voices when you generate content with agents. You can also add voices to rewrite.
                </>
              )
            }
            cta={
              !isEmpty(model.voiceList) && (
                <SimpleTooltip
                  className={styles.addVoiceTooltip}
                  placement="bottom"
                  disabled={isAdmin && !voiceLimitReached}
                  trigger={
                    <Button
                      type={ButtonTypes.BLACK}
                      disabled={!isAdmin || voiceLimitReached}
                      content="Add a voice"
                      className={styles.addVoiceButton}
                      onClick={() => {
                        appModel.analyticsService.track(
                          model.aiStudioMode
                            ? AnalyticsActivity.aiStudioVoiceAddClicked
                            : AnalyticsActivity.voiceAddClicked,
                          {},
                        );
                        model.openAddVoiceModal();
                      }}
                    />
                  }
                >
                  {isAdmin ? (
                    `Your org has reached the ${limit} ${wordPluralize(limit, 'voice')} limit on your plan.`
                  ) : (
                    <>
                      {model.aiStudioMode
                        ? 'Only individual builder and full access users can add and manage voices'
                        : 'Only team admins can configure and edit voices'}
                    </>
                  )}
                </SimpleTooltip>
              )
            }
          />
        )}

        {isFeatureLocked && onBillingLockClick ? (
          <BillingLock
            type={BillingLockType.VOICE}
            className={styles.billingLockContainer}
            onActionCallback={onBillingLockClick}
            banner={<div className={styles.banner}></div>}
          />
        ) : (
          <>
            {model.isLoading ? (
              <ThreeDotsLoader className={styles.loader} />
            ) : (
              <>
                {isEmpty(model.voiceList) ? (
                  <>
                    <div className={styles.descriptionContainer}>
                      <Heading
                        className={styles.descriptionTitle}
                        variant={HeadingVariant.H2}
                        color={HeadingColor.BLACK}
                      >
                        Get Writer sounding more like you
                      </Heading>
                      <Text className={styles.descriptionText} variant={TextSize.XL} color={TextColor.GREY}>
                        Set up your default voice profile to generate content in your own voice
                      </Text>
                      <Tooltip
                        disabled={isAdmin}
                        placement="top"
                        title={<div className={styles.addVoiceTooltip}>Only org admins can configure voices</div>}
                      >
                        <Button
                          disabled={!isAdmin}
                          type={ButtonTypes.BLACK}
                          size={SizeTypes.LARGE}
                          content="Tune your default voice"
                          onClick={() => model.openAddVoiceModal()}
                        />
                      </Tooltip>
                    </div>
                    <div className={styles.banner}></div>
                  </>
                ) : (
                  <>
                    {voiceLimitReached && (
                      <UserNotificationBanner
                        type="info"
                        className={styles.limitReachedBanner}
                        notification={
                          <>
                            You've reached the {limit} {wordPluralize(limit, 'voice')} limit on your plan.
                            <br />
                            To create more voices, contact us to upgrade.
                          </>
                        }
                        action={{
                          title: 'Contact sales',
                          onClick: () => {
                            openContactSalesPage();
                          },
                        }}
                      />
                    )}

                    <VoiceTable
                      voices={model.voiceList}
                      viewOnly={!isAdmin}
                      aiStudioMode={model.aiStudioMode}
                      onClickShowInRewrite={model.showVoiceInRewrite}
                      onClickView={model.viewVoice}
                      onClickEdit={model.editVoice}
                      onClickCalibrate={model.calibrateVoice}
                      onClickSetAsDefault={model.setVoiceAsDefault}
                      onClickDelete={model.deleteVoice}
                      checkIsVoiceEditable={checkIsVoiceEditable}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}

        <AddVoiceModal
          open={model.isAddVoiceModalOpen}
          onClickAnalyze={() => {
            appModel.analyticsService.track(
              model.aiStudioMode
                ? AnalyticsActivity.aiStudioVoiceCalibrationSelected
                : AnalyticsActivity.voiceCalibrationSelected,
              {
                method: 'analyze',
              },
            );
            model.closeAddVoiceModal();
            model.openAnalyzer();
          }}
          onClickManuallySelect={() => {
            appModel.analyticsService.track(
              model.aiStudioMode
                ? AnalyticsActivity.aiStudioVoiceCalibrationSelected
                : AnalyticsActivity.voiceCalibrationSelected,
              {
                method: 'manual',
              },
            );
            model.closeAddVoiceModal();
            model.openConstructor();
          }}
          onClickClose={model.closeAddVoiceModal}
        />
        <VoiceAnalyzerModal
          open={model.isAnalyzerOpen}
          model={model.voiceAnalyzer}
          onClickClose={model.closeAnalyzer}
        />
        <VoiceConstructorModal
          open={model.isConstructorOpen}
          model={model.voiceConstructor}
          onClickClose={model.closeConstructor}
        />
        <ViewVoiceModal
          open={model.isViewerOpen}
          name={model.voiceAnalyzer.selectedVoice?.name || ''}
          description={model.voiceAnalyzer.selectedVoice?.description || ''}
          overview={model.voiceAnalyzer.selectedVoice?.overview || ''}
          profile={model.voiceAnalyzer.selectedVoice?.profile || ''}
          onClickClose={model.closeViewer}
        />
      </div>
    );
  },
);
