import React from 'react';

import cx from 'classnames';

import { numberWithCommas, wordPluralize } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingColor,
  HeadingVariant,
  Icon,
  IconVariant,
  Input,
  Modal,
  SizeTypes,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import { observer } from 'mobx-react-lite';

import {
  VOICE_ANALYZER_DESCRIPTION_LIMIT,
  VOICE_ANALYZER_INPUT_LIMIT,
  VOICE_ANALYZER_NAME_LIMIT,
  VoiceAnalyzerModalUIModel,
} from './VoiceAnalyzerModalModel.ui';

import styles from './VoiceAnalyzerModal.module.css';

export interface IVoiceAnalyzerModalProps {
  open: boolean;
  model: VoiceAnalyzerModalUIModel;
  onClickClose: () => void;
}

export const VoiceAnalyzerModal = observer<IVoiceAnalyzerModalProps>(({ open, model, onClickClose }) => (
  <Modal
    open={open}
    hideCloseButton
    className={styles.container}
    multiModalContainerClassName={styles.outerContainer}
    disableBackdropClick
    handleClose={onClickClose}
  >
    <>
      <div className={styles.closeButton} onClick={onClickClose}>
        <Icon name={IconVariant.CLOSE_WHITE} height={18} width={18} />
      </div>
      <div className={styles.header}>
        <Icon className={styles.icon} name={IconVariant.VOICE_TUNE} width={40} height={40} />

        {model.isEditingEnabled ? (
          <Tooltip title={model.saveDisabledMessage} disabled={!model.saveDisabledMessage}>
            <Button
              className={styles.nextButton}
              type={ButtonTypes.PRIMARY}
              disabled={!!model.saveDisabledMessage}
              content="Save voice"
              onClick={() => {
                if (model.selectedVoice) {
                  model.updateVoice();
                } else {
                  model.addVoice();
                }

                onClickClose();
              }}
            />
          </Tooltip>
        ) : (
          <Button
            className={styles.nextButton}
            type={ButtonTypes.PRIMARY}
            disabled={
              !model.output &&
              (model.isLoading ||
                model.inputWordsCount < VOICE_ANALYZER_INPUT_LIMIT.MIN ||
                model.inputWordsCount > VOICE_ANALYZER_INPUT_LIMIT.MAX)
            }
            content="Next"
            onClick={() => {
              if (model.output) {
                model.setIsEditingEnabled(true);
              } else {
                model.analyzeVoice();
              }
            }}
          />
        )}

        {model.output && (
          <Button
            className={styles.goBackButton}
            type={ButtonTypes.BORDERED}
            icon={<Icon name={IconVariant.ARROW_LEFT} width={18} height={18} />}
            content={model.isCalibrationEnabled ? 'Recalibrate' : 'Go back'}
            onClick={() => {
              if (model.isEditingEnabled) {
                model.setIsEditingEnabled(false);
              } else {
                model.setOutput(null);
              }
            }}
          />
        )}

        <div className={styles.headerContainer}>
          <Heading className={styles.heading} variant={HeadingVariant.H2} color={HeadingColor.BLACK} upperCase>
            Tune your voice
          </Heading>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            Customize your voice by analyzing example copy
          </Text>
        </div>
      </div>

      {model.output || model.isEditingEnabled ? (
        <div className={styles.content}>
          {model.output && (
            <div
              className={cx(styles.outputContainer, {
                [styles.outputContainerNarrow]: model.isEditingEnabled,
              })}
            >
              <Text className={styles.subtitle} variant={TextSize.XL} color={TextColor.GREY} bold>
                All right! Here's what we found.
              </Text>

              <Text className={styles.outputLabel} variant={TextSize.S} color={TextColor.GREY} medium upperCase>
                Overview
              </Text>
              <Text className={styles.outputOverview} variant={TextSize.XL} color={TextColor.GREY} bold>
                {model.output.overview}
              </Text>

              <Text className={styles.outputLabel} variant={TextSize.S} color={TextColor.GREY} medium upperCase>
                Detailed profile
              </Text>
              <Text
                className={cx(styles.outputProfile, {
                  [styles.outputProfileExtended]: model.isEditingEnabled,
                })}
                variant={TextSize.XL}
                color={TextColor.GREY}
              >
                {model.output.profile}
              </Text>

              {!model.isCalibrationEnabled && !model.isEditingEnabled && (
                <div className={styles.controls}>
                  <Button
                    className={styles.reAnalyzeButton}
                    type={ButtonTypes.BORDERED}
                    size={SizeTypes.MEDIUM}
                    content="Re-analyze"
                    onClick={() => {
                      model.setIsEditingEnabled(false);
                      model.analyzeVoice();
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {model.isEditingEnabled && (
            <div
              className={cx(styles.editContainer, {
                [styles.editContainerNarrow]: model.output,
              })}
            >
              <Heading className={styles.heading} variant={HeadingVariant.H3} color={HeadingColor.BLACK}>
                Final touches
              </Heading>

              <Text className={styles.nameLabel} variant={TextSize.XL} color={TextColor.GREY} bold>
                Voice name*
              </Text>
              <Input
                className={styles.name}
                placeholder="e.g., External communications"
                value={model.name}
                inputProps={{ maxLength: VOICE_ANALYZER_NAME_LIMIT.MAX }}
                onChange={e => model.setName(e.target.value)}
              />
              <Text className={styles.counter} variant={TextSize.XS} color={TextColor.GREY3} medium upperCase>
                {model.name.length}/{VOICE_ANALYZER_NAME_LIMIT.MAX} {wordPluralize(model.name.length, 'char')}
              </Text>

              <Text className={styles.descriptionLabel} variant={TextSize.XL} color={TextColor.GREY} bold>
                When to use
              </Text>
              <textarea
                data-qordoba-ignore
                className={styles.description}
                placeholder="Where should writers use this voice? We'll show this description in the voice selection dropdown.&#10;&#10;e.g., customer newsletters, sales emails"
                value={model.description}
                maxLength={VOICE_ANALYZER_DESCRIPTION_LIMIT.MAX}
                onChange={e => model.setDescription(e.target.value)}
              />
              <Text className={styles.tuningCounter} variant={TextSize.XS} color={TextColor.GREY3} medium upperCase>
                {model.description.length}/{VOICE_ANALYZER_DESCRIPTION_LIMIT.MAX}{' '}
                {wordPluralize(model.description.length, 'char')}
              </Text>

              <div className={styles.controls}>
                {model.isCalibrationEnabled && !model.selectedVoice && (
                  <Button
                    className={styles.goBackButton}
                    type={ButtonTypes.BORDERED}
                    size={SizeTypes.MEDIUM}
                    icon={<Icon name={IconVariant.ARROW_LEFT} width={18} height={18} />}
                    content="Go back"
                    onClick={() => model.setIsEditingEnabled(false)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.inputContainer}>
          <Text className={styles.subtitle} variant={TextSize.XL} color={TextColor.GREY} bold>
            Add copy that represents your voice and tone
          </Text>
          <Text className={styles.description} variant={TextSize.XL} color={TextColor.GREY}>
            Use something substantial, such as an article or several emails.
            <br />
            We recommend adding between {VOICE_ANALYZER_INPUT_LIMIT.MIN}-{VOICE_ANALYZER_INPUT_LIMIT.MAX} words.
          </Text>

          {model.isLoading ? (
            <div className={styles.loader}>
              <div className={styles.progressBarContainer}>
                <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.progressBar} />
              </div>
              Analyzing your copy
            </div>
          ) : (
            <textarea
              id={styles.textarea}
              data-qordoba-ignore
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              placeholder="Paste your example text here"
              name={styles.textarea}
              value={model.input}
              className={cx(styles.textarea, {
                [styles.textareaAlerted]:
                  model.isInputTouched && model.inputWordsCount < VOICE_ANALYZER_INPUT_LIMIT.MIN,
                [styles.textareaBlocked]:
                  model.isInputTouched && model.inputWordsCount > VOICE_ANALYZER_INPUT_LIMIT.MAX,
              })}
              onChange={e => model.setInput(e.target.value)}
            />
          )}

          <Text className={styles.wordsCounter} variant={TextSize.S} color={TextColor.GREY}>
            <span
              className={cx(styles.words, {
                [styles.alert]: model.inputWordsCount > VOICE_ANALYZER_INPUT_LIMIT.MAX,
              })}
            >
              {model.inputWordsCount}/{VOICE_ANALYZER_INPUT_LIMIT.MAX} {wordPluralize(model.inputWordsCount, 'word')}
            </span>

            {model.inputWordsCount < VOICE_ANALYZER_INPUT_LIMIT.MIN &&
              `Add at least ${numberWithCommas(VOICE_ANALYZER_INPUT_LIMIT.MIN)} words for best results`}

            {model.inputWordsCount > VOICE_ANALYZER_INPUT_LIMIT.MAX &&
              `You can add a maximum of ${numberWithCommas(VOICE_ANALYZER_INPUT_LIMIT.MAX)} words`}
          </Text>
        </div>
      )}
    </>
  </Modal>
));
