import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  LinkText,
  Slideout,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import JotformFrame from '../JotformFrame';

import styles from './KnowledgeGraphIntroductionBanner.module.css';

const WAITING_LIST_FORM_ID = '231586787672170';

interface IKnowledgeGraphIntroductionBannerProps extends BaseComponentsProps {
  waitingListInitialEmail?: string;
  onLearnMoreClick?: () => void;
}

export const KnowledgeGraphIntroductionBanner: React.FC<IKnowledgeGraphIntroductionBannerProps> = ({
  className,
  waitingListInitialEmail,
  onLearnMoreClick,
}) => {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const [waitListFormEmail, setWaitListFormEmail] = useState('');
  const onJoinWaitlistClick = () => setShowWaitListForm(true);

  useEffect(() => {
    waitingListInitialEmail && setWaitListFormEmail(waitingListInitialEmail);
  }, [waitingListInitialEmail]);

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.containerContentHeadingWrapper}>
        <div className={styles.containerContentHeading}>
          <div className={styles.containerContentTitleWrapper}>
            <Icon name={IconVariant.LOCK_LINE} width={18} height={18} className={styles.containerContentLockIcon} />
            <Heading variant={HeadingVariant.H3} className={styles.containerContentHeadingText} upperCase>
              beta feature
            </Heading>
          </div>
          <Button
            type={ButtonTypes.PRIMARY}
            className={styles.containerWaitlistButton}
            onClick={onJoinWaitlistClick}
            icon={<Icon name={IconVariant.PREMIUM} className={styles.containerWaitlistButtonIcon} />}
            content="Join the waitlist"
          />
        </div>
        <div className={styles.containerContentHeadingSeparator}></div>
      </div>
      <div className={styles.containerDescriptionWrapper}>
        <Heading variant={HeadingVariant.H3} className={styles.containerDescriptionHeading}>
          Harness your company’s
          <br /> knowledge
        </Heading>
        <div
          className={cx(styles.containerContentHeadingSeparator, styles.containerContentHeadingSeparatorShort)}
        ></div>
        <div className={styles.containerDescriptionHeadingDescriptionWrapper}>
          <Text variant={TextSize.L} color={TextColor.GREY}>
            Connect your company’s data via Knowledge Graph to improve your agent outputs.
          </Text>
        </div>
        <div className={styles.containerDescriptionHeadingCtaWrapper}>
          <LinkText className={styles.containerDescriptionHeadingCta} onClick={onLearnMoreClick}>
            <Text variant={TextSize.L} color={TextColor.GREY} bold>
              Learn more.
            </Text>
          </LinkText>
        </div>
      </div>
      <div className={styles.bottomBanner} />
      <Slideout
        className={styles.slideoutContentContainer}
        isOpen={showWaitListForm}
        onClose={() => setShowWaitListForm(false)}
      >
        <JotformFrame
          fromId={WAITING_LIST_FORM_ID}
          className={styles.slideoutContent}
          frameFormAttributes={{ email: waitListFormEmail }}
        />
      </Slideout>
    </div>
  );
};

export default KnowledgeGraphIntroductionBanner;
