export const MAX_FILE_SIZE = 40000000;
export const SIMULTANEOUS_FILE_UPLOAD_LIMIT = 10;
export const DEFAULT_CONTEXT_WINDOW = 20000;

export const CHAT_FILE_EMBED_CLASS_PREFIX = 'chat-file-format';
export const CHAT_VARIABLE_EMBED_CLASS_PREFIX = 'chat-variable-format';
export const CHAT_FILE_EMBED = 'chat-file-embed';
export const CHAT_VARIABLE_EMBED = 'chat-variable-embed';

export const ALLOWED_TEXT_FILE_EXTENSIONS = ['txt', 'doc', 'docx', 'pdf'];
export const ALLOWED_SPREADSHEET_FILE_EXTENSIONS = ['csv', 'xls', 'xlsx'];
export const ALLOWED_PRESENTATION_FILE_EXTENSIONS = ['ppt', 'pptx'];
export const ALLOWED_SUBTITLES_FILE_EXTENSIONS = ['srt'];

export const ALL_ALLOWED_FILE_EXTENSIONS = [
  ...ALLOWED_TEXT_FILE_EXTENSIONS,
  ...ALLOWED_SPREADSHEET_FILE_EXTENSIONS,
  ...ALLOWED_PRESENTATION_FILE_EXTENSIONS,
  ...ALLOWED_SUBTITLES_FILE_EXTENSIONS,
];

export const PROBLEMATIC_FILE_EXTENSIONS = ['srt'];

const ALLOWED_TEXT_FILE_TYPES = [
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];
const ALLOWED_SPREADSHEET_FILE_TYPES = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const ALLOWED_PRESENTATION_FILE_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
const ALLOWED_SUBTITLES_FILE_TYPES = ['application/srt'];

export const ALL_ALLOWED_FILE_TYPES = [
  ...ALLOWED_TEXT_FILE_TYPES,
  ...ALLOWED_SPREADSHEET_FILE_TYPES,
  ...ALLOWED_PRESENTATION_FILE_TYPES,
  ...ALLOWED_SUBTITLES_FILE_TYPES,
];

export const CHAT_APP_DEFAULT_WELCOME_MESSAGE =
  '**Welcome to Ask Writer**\n\nUse this chat session as your personal workshop. Start by asking a question or making a request below.\n\nYou can also add sources to use as context.\n\n**If you add a source, try the following:**\n\n- Ask a question about your sources\n\n- Summarize your sources\n\n- Generate content from your sources\n\n- Rewrite the content in your sources';

export const limitMessage = wordLimit => `
<p><b>You’ve reached your limit of ${wordLimit} words generated this month.</b></p>
<p>Contact sales to upgrade to a larger plan.</p>
`;

export const DISABLED_SOURCES_TOOLTIP =
  "Since you're in Knowledge Graph mode, your sources are disabled. To go back to chatting with your sources toggle off your Knowledge Graph.";

export const CHAT_MODE_MESSAGE = `I'm now back in chat mode! You can return to generating content and chatting with any sources you've added to this chat.`;
export const KNOWLEDGE_GRAPH_MODE_MESSAGE = `I'm now connected to your team's Knowledge Graph.\n\nWhat would you like to know? You can:\n\n- Ask a general question (e.g., a "how to", define a term, etc.)\n\n- Search for specific facts and data\n\n- Request a summary of a topic`;

export const KG_BANNER_RESERVED_HEIGHT = 52;
export const NOTIFICATION_BAR_RESERVED_HEIGHT = 50;
export const TRIAL_BANNER_RESERVED_HEIGHT = 48;

export const ERROR_MESSAGES = {
  sourcesUploadSuccess: 'Source has been successfully uploaded',
  sourcesUploadFail: 'Error while uploading {{source}}',
  sourcesUploadCrawlFail: 'Error while crawling {{url}}',
  sourcesDeleteSuccess: 'Source has been successfully deleted',
  sourcesDeleteFail: 'Error while deleting source',
  sourcesGetFail: 'Error while loading sources',
  sourcesDownloadFail: 'Error while downloading source',
  sessionsGetFail: 'Error while getting user sessions',
  sessionsDeleteSuccess: 'Session has been deleted',
  sessionsDeleteFail: 'Error while deleting session',
  sessionsCreateFail: 'Error while creating session',
  sessionsRenameFail: 'Error while renaming session',
  sessionChangeGuard: "Hang on! We're still processing your last request. Wait a moment and try again.",
  sessionSourcesAssociateFail: 'Error while adding session files',
  sessionSourcesDisassociateFail: 'Error while deleting session source',
  sessionSourcesDisassociateSuccess: 'Your source was successfully removed',
  chatFailFetch: 'Error while fetching session messages',
  chatFailClear: 'Error while clearing session messages',
  chatFailPost: 'Error while sending the message',
  chatSuccessFlag: "Got your flag! We'll review this output.",
  chatSuccessUnflag: "We've removed your flag!",
  simultaneousFileUploadLimitViolation:
    "File uploads are limited to {{simultaneousFileUploadLimit}} at a time, so we've only uploaded the first {{simultaneousFileUploadLimit}} files you added.",
  fileSizeViolationMessage: '{{filename}} is too large. Max file size is {{maxFileSize}}MB.',
  fileTypeViolationMessage: "The file type for {{filename}} isn't supported. Try a different file.",
  selectKnowledgeGraphs:
    'Select a Knowledge Graph first from the "Enable Knowledge Graph" toggle to start asking questions',
  chatMessageCopySuccess: 'Copied!',
  atLeastOneMode: 'Agent needs at least one mode enabled',
};
