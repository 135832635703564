import qs from 'qs';

import {
  IUserProfile,
  IUserSalesProfile,
  Request,
  RequestQueryParams,
  emailAddressCleanup,
} from '@writercolab/common-utils';
import { components } from '@writercolab/network';
import { MfaChallengeType } from '@writercolab/types';

import {
  ICreateOrganizationResponse,
  IDiscoverOrganizationsResponse,
  IMfaSettings,
  IOrganizationAdminsResponse,
  IOriginalUser,
  ISendInviteRequest,
} from '@web/types';

import { TAppState } from '../../state/types';
import { getLogger } from '../../utils/logger';
import { ListResponse } from './_types';

const LOG = getLogger('userRequest');

export const getUserProfile = async (): Promise<IUserProfile> => {
  const { data } = await Request.getAxiosInstance().get<IUserProfile>('/user/v2/profile');

  return data;
};

export const getUserSalesProfile = async (): Promise<IUserSalesProfile> => {
  const { data } = await Request.getAxiosInstance().get<IUserSalesProfile>('/user/v2/profile/sales');

  return data;
};

export const findUserInOrganization = async (
  orgId: number | undefined,
  params?: RequestQueryParams,
): Promise<ListResponse<IOriginalUser>> => {
  const { data } = await Request.getAxiosInstance().get<ListResponse<IOriginalUser>>(`/user/v2/organization/${orgId}`, {
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const findBillingGroups = async (
  orgId: number | undefined,
): Promise<components['schemas']['com_qordoba_user_dto_BillingGroupResponse'][]> => {
  const { data } = await Request.getAxiosInstance().get<
    components['schemas']['model_PaginatedResult_user_dto_BillingGroupWithCount']
  >(`user/v2/organization/${orgId}/billing-group`);

  if (!data.result) {
    return [];
  }

  const res = data.result?.map(billingGroup => ({
    id: billingGroup.id,
    name: billingGroup.name,
  }));

  return res as unknown as components['schemas']['com_qordoba_user_dto_BillingGroupResponse'][];
};

export const updateillingGroup = async ({
  orgId,
  billingGroupId,
  userIds,
}: {
  orgId: number | undefined;
  billingGroupId?: null | number;
  userIds?: null | number[];
}): Promise<Record<string, unknown>> => {
  const data = await Request.getAxiosInstance().put<
    components['schemas']['user_dto_BulkUpdateUserBillingGroupRequest'],
    Record<string, unknown>
  >(`user/v2/organization/${orgId}/billing-group/bulk`, {
    billingGroupId,
    userIds,
  });

  return data;
};

export const getOrganizationAdmins = async (orgId: number | undefined): Promise<IUserProfile[]> => {
  const {
    data: { users },
  } = await Request.getAxiosInstance().get<IOrganizationAdminsResponse>(`/user/v2/organization/${orgId}/admins`);

  return users;
};

export const findUserInTeam = async (
  orgId: number | undefined,
  teamId: TAppState['teamId'],
  params?: RequestQueryParams,
): Promise<ListResponse<IOriginalUser>> => {
  const { data } = await Request.getAxiosInstance().get<ListResponse<IOriginalUser>>(
    `/user/v2/organization/${orgId}/team/${teamId}`,
    {
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  );

  const flags = {};

  // Todo: added temporary filter until the fix is implemented from BE
  const filteredResult = data.result.filter(({ user }) => {
    if (flags[user.id]) return false;

    flags[user.id] = true;

    return true;
  });

  if (filteredResult.length < data.result.length) {
    LOG.warn('Getting duplicated results from API.');
  }

  return { ...data, result: filteredResult };
};

export const makeOrganizationAdmin = async (orgId: number | undefined, params: { role: string; userId: number }) => {
  const { data } = await Request.getAxiosInstance().put(`/user/v2/organization/${orgId}/role`, {
    ...params,
  });

  return data;
};

export const makeTeamAdmin = async (
  orgId: number | undefined,
  teamId: TAppState['teamId'],
  params: { role: string; userId: number },
) => {
  const { data } = await Request.getAxiosInstance().put(`/user/v2/organization/${orgId}/team/${teamId}/role`, {
    ...params,
  });

  return data;
};

export const resendInviteLink = async (orgId: number | undefined, userId: IUserProfile['id']) => {
  const { data } = await Request.getAxiosInstance().get(`/user/v2/organization/${orgId}/invitation/${userId}/resend`);

  return data;
};

export const getMfaSettings = async (): Promise<MfaChallengeType[]> => {
  const { data } = await Request.getAxiosInstance().get<IMfaSettings>(`/auth/user/mfa`);

  return data.methods;
};

export const saveMfaSettings = async (password: string, methods: MfaChallengeType[]): Promise<void> => {
  await Request.getAxiosInstance().put(`/auth/user/mfa`, { password, methods });
};

export const deleteMfaSettings = async (password: string): Promise<void> => {
  await Request.getAxiosInstance().delete<{ password: string }>(`/auth/user/mfa/delete`, {
    data: {
      password,
    },
  });
};

export const savePassword = async (currentPassword: string, password: string, passwordConfirmation: string) => {
  const { data } = await Request.getAxiosInstance().put('/user/v2/password/update', {
    currentPassword,
    password,
    passwordConfirmation,
  });

  return data;
};

export const resetPassword = async (email: string) => {
  const { data } = await Request.getAxiosInstance().put('/user/v2/password/recover', { email });

  return data;
};

export const saveProfile = async (firstName: string, lastName: string, timeZone: string) => {
  const { data } = await Request.getAxiosInstance().put('/user/v2/profile', { firstName, lastName, timeZone });

  return data;
};

export const saveEmail = async (email: string, password: string, recaptchaToken: string) => {
  const { data } = await Request.getAxiosInstance().post('/user/v2/email', { email, password, recaptchaToken });

  return data;
};

export const savePhone = async (phone: string | null, password: string, recaptchaToken: string) => {
  const { data } = await Request.getAxiosInstance().post('/user/v2/phone', { phone, password, recaptchaToken });

  return data;
};

export const sendEmailConfirmationCode = async (email: string, code: string) => {
  const emailParam = emailAddressCleanup(email);
  const { data } = await Request.getAxiosInstance().post('/user/v2/email/confirm', { email: emailParam, code });

  return data;
};

export const sendEmailUpdateConfirmationCode = async (email: string, code: string) => {
  const emailParam = emailAddressCleanup(email);
  const { data } = await Request.getAxiosInstance().post('/user/v2/email/update/confirm', { email: emailParam, code });

  return data;
};

export const uploadProfilePicture = async (file: FormData) => {
  const { data } = await Request.getAxiosInstance().post('/user/v2/profile_photo', file, {
    headers: { 'Content-Type': undefined },
  });

  return data;
};

export const createUserPassword = async () => {
  const { data } = await Request.getAxiosInstance().post('/user/v2/password/create/mail', {});

  return data;
};

export const cropPicture = async (photoUrl: string) => {
  const { data } = await Request.getAxiosInstance().post('/user/v2/photo/crop', { photoUrl });

  return data;
};

export const discoverOrganizations = async (): Promise<IDiscoverOrganizationsResponse> => {
  const { data } = await Request.getAxiosInstance().get<IDiscoverOrganizationsResponse>(
    '/user/v2/organization/discover',
  );

  return data;
};

export const requestJoinOrganization = async (organizationId: number | string): Promise<void> => {
  await Request.getAxiosInstance().post(`/user/v2/organization/join/${organizationId}`);
};

export const createOrganization = async (name: string): Promise<number> => {
  const { data } = await Request.getAxiosInstance().post<ICreateOrganizationResponse>('/user/v2/organization/create', {
    name,
    registrationPlan: 'starter_monthly',
  });

  return data.value;
};

export const approveUserOrganizationByInviter = async (
  organizationId: number | undefined,
  userId: number | string,
): Promise<void> => {
  await Request.getAxiosInstance().post(`/user/v2/${userId}/organization/${organizationId}/approve`);
};

export const denyUserOrganizationByInviter = async (
  organizationId: number | undefined,
  userId: number | string,
): Promise<void> => {
  await Request.getAxiosInstance().post(`/user/v2/${userId}/organization/${organizationId}/deny`);
};

export const inviteUser = async ({
  organizationId,
  role,
  invitees,
  billingGroupId,
  teamIds,
  params,
}: ISendInviteRequest): Promise<components['schemas']['com_qordoba_user_model_InviteUserResponse']> => {
  const { data } = await Request.getAxiosInstance().post<
    components['schemas']['com_qordoba_user_model_InviteUserResponse']
  >(
    `/user/v2/organization/${organizationId}/team/invite`,
    {
      billingGroupId,
      invitees,
      role,
      teamIds,
    },
    {
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  );

  return data;
};
