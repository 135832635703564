import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import type { IBillingPlan } from '@writercolab/common-utils';
import {
  BillingAction,
  BillingProduct,
  BillingStatus,
  CheckoutPopupType,
  NUMBER_OF_DAYS_BEFORE_ACCOUNT_DOWNGRADE,
  SUPPORT_EMAIL,
  SubscriptionFlagType,
  addDaysToDate,
} from '@writercolab/common-utils';
import {
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  LinkText,
  Modal,
  Text,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import { AnalyticsActivity } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router';

import contactSales from '../../../assets/backgrounds/contactSales.png';
import { useBillingContext } from '../../../context/billingContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppState } from '../../../state';
import { launchSupportEmail, openContactSalesPage } from '../../../utils/navigationUtils';
import BillingContact from './BillingContact';
import BillingContactPopup from './BillingContactPopup';
import BillingPlans from './BillingPlans';
import CancelTrialPopup from './CancelTrialPopup';
import Cancellation from './Cancellation';
import CheckoutPopup from './CheckoutPopup';
import CreditCardHolder from './CreditCardHolder';
import HistoryTable from './HistoryTable';
import PaymentMethodPopup from './PaymentMethodPopup';
import TrialPopup from './TrialPopup';
import YourBillingPlanWidget from './YourBillingPlanWidget';

import styles from './styles.module.css';
import { TrialEndedBanner } from '../../molecules/TrialEndedBanner';
import { useAppSessionContext } from '../../../state/sessionContext';

interface IBillingPageProps {}

const ContactInfoTooltip = () => (
  <Tooltip
    title="We’ll send payment receipts and other billing emails to this address."
    placement="right"
    tooltipWidth={150}
  >
    <span>
      <Icon name={IconVariant.INFO_OUTLINED} />
    </span>
  </Tooltip>
);

export const BillingPage: React.FC<IBillingPageProps> = observer(() => {
  usePageTitle('Billing');
  const location = useLocation();
  const navigate = useNavigate();
  const {
    billingContext,
    onChangeBillingInterval,
    onUpdateSubscription,
    onStartTrial,
    onToggleTrialPopup,
    onToggleCheckoutPopup,
    onEditPaymentMethod,
    onManageUsersClick,
    onManageBillingGroupsClick,
    onTogglePaymentInfoPopup,
    stripePromise,
    onEditPaymentMethodClick,
    onManagePlanClick,
    onToggleCancellationPopup,
    onCancellationNextStep,
    onApplyForFreeMonth,
    onApplyForWeeksFree,
    onUpdateCancellationComments,
    onUpdateSurveyQuestion,
    onToggleBillingContactPopup,
    onSaveBillingContact,
    onContactSalesClick,
    onActivateFreePlan,
    onCancelTrial,
    onCancelTrialConfirmed,
    onToggleCancelTrialPopup,
  } = useBillingContext();
  const {
    appState,
    appModel: { organizationId, analyticsService, assistantSubscription },
  } = useAppState();
  const { onOrganizationChange } = useAppSessionContext();

  const { isLoading, popupType, comments, surveyQuestions } = billingContext.cancellation;
  const subscription = assistantSubscription.$subscription.value;

  const {
    usage,
    billingInterval,
    plans,
    allBillingPlans,
    paymentMethod,
    checkout,
    trial,
    cancelTrial,
    billingInfo,
    invoicesList,
    paymentDate,
    billingContact,
    isSubmitting,
  } = billingContext;

  const [invoicesLimit, setInvoicesLimit] = useState(8);
  const hasLessThanTwoUsers = (usage?.user?.value || 2) <= 1;
  const isTeamTrialExtension = subscription?.flags?.find(
    flag => flag.flag === SubscriptionFlagType.TRIAL_EXTENSION && flag.active,
  );

  const access = {
    isPlanPastDue: assistantSubscription.isExpired,
    isTrial: assistantSubscription.isTrial,
    isFreePlan: assistantSubscription.isFree,
    isTeamPlan: assistantSubscription.isTeam,
    isStarterPlan: assistantSubscription.isStarter,
    isTrialCancelledTeam: assistantSubscription.isTeam && !isEmpty(isTeamTrialExtension),
    renewByDate: subscription
      ? addDaysToDate(subscription.startPeriodDate || '', NUMBER_OF_DAYS_BEFORE_ACCOUNT_DOWNGRADE)
      : '',
    isCowriteShown:
      assistantSubscription.isTeam || assistantSubscription.isEnterprise || assistantSubscription.isStarter,
  };

  const trialEndedPopupVisible = useMemo(() => {
    if (assistantSubscription.isFree && assistantSubscription.isCancelled) {
      return true;
    }

    if (!access.isStarterPlan) {
      return false;
    }

    // checking for the grace payment period right after ended trial
    if (assistantSubscription.isExpired && subscription?.startPeriodDate === subscription?.trialEnd) {
      return true;
    }

    return assistantSubscription.isTrialExpired;
  }, [access.isStarterPlan, assistantSubscription.isTrialExpired, assistantSubscription.isExpired, subscription]);

  const isAbleToBeCancelled = access.isTrial && (access.isTeamPlan || access.isStarterPlan);

  const onViewAllInvoicesClicked = () => setInvoicesLimit(invoicesList?.length || 0);
  const onStopCancellation = useCallback(() => onToggleCancellationPopup(null), [onToggleCancellationPopup]);

  useEffect(() => {
    analyticsService.track(AnalyticsActivity.billingViewed, {});
  }, [analyticsService]);

  const onUpgradeToStarter = () => {
    const planToActivate = allBillingPlans?.find(v => v.productName === BillingProduct.STARTER);

    if (planToActivate) {
      onUpdateSubscription(planToActivate, CheckoutPopupType.CHECKOUT);
    }
  };

  useEffect(() => {
    if (!allBillingPlans || !subscription?.price) return;

    const _triggerUpdateParamName = 'triggerUpdateTo';
    const params = new URLSearchParams(location.search.slice(1));

    if (!params.get(_triggerUpdateParamName)) {
      return;
    }

    const _cleanUpTriggerUpdateParam = () => {
      params.delete(_triggerUpdateParamName);
      navigate(
        {
          search: params.toString(),
        },
        { replace: true },
      );
    };

    const getPlanToActivate = (productName: BillingProduct) =>
      allBillingPlans.find(plan => plan.interval === subscription.price?.interval && plan.productName === productName);

    const triggerUpdateParamValue = params.get(_triggerUpdateParamName) as BillingProduct;

    switch (triggerUpdateParamValue) {
      case BillingProduct.FREE:
        onActivateFreePlan();
        _cleanUpTriggerUpdateParam();
        break;
      case BillingProduct.PRO: {
        const planToActivate = getPlanToActivate(BillingProduct.PRO);

        if (planToActivate) {
          onUpdateSubscription(planToActivate, CheckoutPopupType.CHECKOUT);
          _cleanUpTriggerUpdateParam();
        }

        break;
      }
      case BillingProduct.TEAM:
        if (
          [BillingStatus.TRIALING, BillingStatus.CANCELED].includes(subscription.status as BillingStatus) ||
          subscription.productName === BillingProduct.FREE
        ) {
          const planToActivate = getPlanToActivate(BillingProduct.TEAM);
          onUpdateSubscription(planToActivate, CheckoutPopupType.CHECKOUT);
        } else {
          onToggleTrialPopup(true, BillingProduct.TEAM);
        }

        _cleanUpTriggerUpdateParam();
        break;
      case BillingProduct.STARTER:
        {
          const planToActivate = getPlanToActivate(BillingProduct.STARTER);

          if (planToActivate) {
            onUpdateSubscription(planToActivate, CheckoutPopupType.CHECKOUT);
          }
        }

        _cleanUpTriggerUpdateParam();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans, allBillingPlans, subscription, location]);

  const pageContent = () => {
    const _onChangeBillingPlan = (plan: IBillingPlan, action: BillingAction) => {
      const _popupType = action === BillingAction.BUY ? CheckoutPopupType.CHECKOUT : CheckoutPopupType.MANAGE_PLAN;
      const activity =
        plan.productName === BillingProduct.PRO
          ? AnalyticsActivity.clickPurchaseProNow
          : AnalyticsActivity.clickPurchaseTeamNow;

      onUpdateSubscription(plan, _popupType, activity, 'all_plans_widget');
    };

    const cancelSubscription = () => {
      subscription?.productName && onCancelTrial(subscription.productName as BillingProduct);
    };

    if (assistantSubscription.isEnterprise) {
      return (
        <div className={styles.enterpriseContainer}>
          <Heading variant={HeadingVariant.H3}>Questions?</Heading>
          <Text variant={TextSize.L} className={styles.enterpriseContact}>
            contact <LinkText onClick={launchSupportEmail}>{SUPPORT_EMAIL}</LinkText>{' '}
          </Text>
          <div className={styles.enterpriseLogo}>
            <img src={contactSales} alt="contact sales images" width={180} />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={cx({
            [styles.sectionContainerBottomSpace]: access.isFreePlan,
          })}
        >
          <div className={cx(styles.section, styles.lessTopSpace, styles.billingPlans)}>
            {subscription && plans && allBillingPlans && (
              <BillingPlans
                billingInterval={billingInterval}
                onChangeBillingInterval={onChangeBillingInterval}
                onChangeBillingPlan={_onChangeBillingPlan}
                currentPlan={subscription?.price as IBillingPlan}
                status={subscription?.status as BillingStatus}
                free={plans.free}
                pro={plans.pro}
                team={plans.team}
                starter={plans.starter}
                isTrialExpired={assistantSubscription?.isTrialExpired}
                isTeamDisabled={access.isPlanPastDue}
                onContactSalesClick={onContactSalesClick}
              />
            )}
          </div>
          {paymentMethod && (
            <div className={styles.section}>
              <Heading variant={HeadingVariant.H4}>Payment information</Heading>
              <div className={styles.sectionPaymentMethod}>
                <CreditCardHolder
                  lastDigits={paymentMethod.lastDigits}
                  onEditClick={onEditPaymentMethodClick}
                  brandName={paymentMethod.cardBrand}
                />
              </div>
            </div>
          )}

          {!access.isFreePlan && billingContact.contact && (
            <div className={cx(styles.section)}>
              <div className={styles.sectionFlex}>
                <Heading variant={HeadingVariant.H4} className={styles.sectionFlexHeading}>
                  Billing contact
                </Heading>
                <div className={styles.iconInfo}>
                  <ContactInfoTooltip />
                </div>
              </div>
              <div className={styles.sectionBillingAddress}>
                <BillingContact
                  contactEmail={billingContact.contact}
                  onEditClick={() => onToggleBillingContactPopup(true)}
                />
              </div>
            </div>
          )}

          {!access.isFreePlan && invoicesList && (
            <div className={styles.section}>
              <Heading variant={HeadingVariant.H4}>Billing history</Heading>

              <div className={styles.billingHistory}>
                <HistoryTable list={invoicesList} limit={invoicesLimit} onViewAllClick={onViewAllInvoicesClicked} />
              </div>
            </div>
          )}

          {isAbleToBeCancelled && (
            <div className={styles.cancelSubscriptionWrapper}>
              <Heading className={styles.marginBottom} variant={HeadingVariant.H4}>
                Cancel trial
              </Heading>
              <Text variant={TextSize.S}>
                Is something not working out for you? <LinkText onClick={launchSupportEmail}>Contact us</LinkText> and
                let's see if we can help. Otherwise, click here:{' '}
              </Text>
              <Text variant={TextSize.S} className={styles.hyperLinks} onClick={cancelSubscription}>
                I want to cancel my trial
              </Text>{' '}
            </div>
          )}

          <TrialPopup
            open={trial.popupVisible}
            isSubmitting={isSubmitting}
            type={trial.type}
            onStartTrial={onStartTrial}
            onCancel={() => onToggleTrialPopup(false)}
          />
          <Cancellation
            popupType={popupType}
            orgId={organizationId!.toString()}
            isTeam={assistantSubscription.isTeam}
            isStarter={assistantSubscription.isStarter}
            isTrialCancelledTeam={access.isTrialCancelledTeam}
            onCancellationNextStepCb={onCancellationNextStep}
            onStopCancellationCb={onStopCancellation}
            onApplyForMonthFreeCb={onApplyForFreeMonth}
            onApplyForWeeksFreeCb={onApplyForWeeksFree}
            onCommentsChangeCb={onUpdateCancellationComments}
            onChangeQuestionStateCb={onUpdateSurveyQuestion}
            isLoading={isLoading}
            comments={comments}
            questions={surveyQuestions}
            paymentDate={paymentDate}
          />
          <PaymentMethodPopup
            orgId={organizationId}
            stripePromise={stripePromise}
            open={billingInfo.isPopupVisible}
            handleClose={() => onTogglePaymentInfoPopup(false)}
            paymentMethod={paymentMethod}
            onSubmitSuccess={onEditPaymentMethod}
            isPlanPastDue={access.isPlanPastDue}
          />
          <BillingContactPopup
            open={billingContact.isPopupVisible}
            onCancel={() => onToggleBillingContactPopup(false)}
            onSubmit={onSaveBillingContact}
            contact={billingContact.contact}
            isSubmitting={billingContact.isSubmitting}
          />
          <CancelTrialPopup
            hasLessThanTwoUsers={hasLessThanTwoUsers}
            open={cancelTrial.popupVisible}
            type={cancelTrial.type}
            isSubmitting={isSubmitting}
            onCancelTrial={onCancelTrialConfirmed}
            onCancel={() => onToggleCancelTrialPopup(false)}
            isDowngrade={!assistantSubscription.isStarter}
          />
        </div>
      );
    }
  };

  const onSwitchOrganizationsClick = (organizationId: string) => {
    onOrganizationChange(organizationId);
  };

  return (
    <div className={styles.mainBillingContainer}>
      <Heading className={styles.pageHeader} variant={HeadingVariant.H3} style={{ width: 'auto' }}>
        Plans and billing
      </Heading>

      <div className={cx(styles.section, styles.lessTopSpace)}>
        <YourBillingPlanWidget
          billingProduct={(subscription?.productName as BillingProduct) || BillingProduct.FREE}
          billingStatus={(subscription?.status as BillingStatus) || BillingStatus.ACTIVE}
          wChecked={usage?.contentCheck.value || 0}
          wIncluded={usage?.contentCheck.limit || 0}
          coWriteChecked={usage?.coWrite?.value || 0}
          coWriteIncluded={usage?.coWrite?.limit || 0}
          paymentDate={paymentDate}
          cancelAt={subscription?.scheduledToCancelAt || subscription?.canceledAt}
          renewByDate={access.renewByDate}
          isCowriteShown={access.isCowriteShown}
          numOfUsers={usage?.user?.value}
          totalNumOfSeats={usage?.user?.limit}
          onManageUsersCallback={onManageUsersClick}
          onManageBillingGroupsCallback={onManageBillingGroupsClick}
          onManagePlanCallback={onManagePlanClick}
          onActivateFreeCallback={onActivateFreePlan}
          onUpgradeNowCallback={access.isPlanPastDue ? onEditPaymentMethodClick : onUpdateSubscription}
          isTrialExpired={assistantSubscription?.isTrialExpired}
          isLoading={!(subscription && usage && paymentDate)}
          isFreeSubscription={assistantSubscription.isFree}
          isEventTakeawaysEnabled={assistantSubscription.access?.eventTakeaways}
          transcriptsLimit={assistantSubscription.limits?.transcript?.limit || 0}
          transcriptsValue={assistantSubscription.limits?.transcript?.value || 0}
          transcriptsResetDate={assistantSubscription.limits?.transcript?.blockedUntil}
          analytics={analyticsService}
        />
        {pageContent()}
      </div>

      <CheckoutPopup
        open={checkout.isPopupVisible}
        onCancel={() => onToggleCheckoutPopup(false, CheckoutPopupType.CHECKOUT)}
        onEditPaymentMethod={onEditPaymentMethodClick}
        type={checkout.popupType}
        paymentMethod={paymentMethod}
        isSubmitting={checkout.isSubmitting}
        paymentDate={paymentDate}
      />
      <Modal open={trialEndedPopupVisible} style={{ width: '519px' }} hideCloseButton>
        <TrialEndedBanner
          onUpgradeClick={onUpgradeToStarter}
          onContactSalesClick={openContactSalesPage}
          organizations={appState.organizations}
          onSwitchOrganizationsClick={onSwitchOrganizationsClick}
          organizationSelected={organizationId}
        />
      </Modal>
    </div>
  );
});

export default BillingPage;
