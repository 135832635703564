import { action, computed, makeObservable, observable, runInAction, untracked } from 'mobx';

import { copyToClipboard, openNewTab } from '@writercolab/common-utils';
import { PromisedModel, ReactiveQueue, ReactiveWatcher } from '@writercolab/mobx';
import type { AiAssistantSubscriptionModel, AiStudioAccountModel, TRetentionConfig } from '@writercolab/models';
import { AppVoiceApiModel, KnowledgeFilesApiModel, MediaFilesApiModel, ModalsManager, ApplicationGenerationLimitsModel } from '@writercolab/models';
import type { RequestServiceInitialize, components } from '@writercolab/network';
import type { TConsoleApplication, TNotificationQueueItem, TRewritePromptResponse } from '@writercolab/types';
import { NotificationQueueItemType, STATIC_VOICE_OPTIONS } from '@writercolab/types';
import { GenerationApplicationUIModel } from '@writercolab/ui-generation-apps';
import { Enum, isDocumentIsValid } from '@writercolab/utils';

import type { TAppInput, TConsoleGenerationApplication, TTemplateDraft, TVoice } from '@web/types';
import { EApplicationType } from '@web/types';
import { DATA_RETENTION_SUPPORT_PAGE_URL } from 'utils/dataRetentionUtils';
import { processLlmOutputBeforeInsert } from 'utils/textUtils';

import type { TAppLocalStorage } from '../../../models/localStorage';
import { extractBackendResponseErrorMessage } from '../../../utils/backendErrorUtils';
import { coWriteQuotaExceeded } from '../../../utils/coWriteUtils';
import { isBadRequest } from '../../../utils/requestUtils';

interface IEditorApplicationUIModelProps {
  request: RequestServiceInitialize['api'];
  storage: TAppLocalStorage;
  subscriptionModel: () => AiAssistantSubscriptionModel;
  aiStudioAccountModel: () => AiStudioAccountModel;
  teamId: () => number | undefined;
  organizationId: () => number | undefined;
  documentId: () => string | undefined;
  applicationId: () => string | undefined;
  voices: () => TVoice[];
  retentionPreferences?: () => TRetentionConfig | undefined | null;
  organizationName?: string;
  rewritePrompts?: TRewritePromptResponse[];
}

export const EditorApplicationModalType = new Enum('rateDraft', 'expandedDraft');

export class EditorApplicationUIModel {
  isGeneratingContent = false;
  notificationQueue = new ReactiveQueue<TNotificationQueueItem>();
  appVoiceApiModel: AppVoiceApiModel;
  knowledgeFileApiModel: KnowledgeFilesApiModel;
  mediaFilesApiModel: MediaFilesApiModel;
  applicationGenerationLimitsModel: ApplicationGenerationLimitsModel;

  private forcedQuotaExceeded = observable.box(false);
  private createdDrafts = observable.array<TTemplateDraft>([]);
  private deletedDrafts = observable.array<TTemplateDraft>([]);

  modalsManager: ModalsManager<typeof EditorApplicationModalType.type, TTemplateDraft> = new ModalsManager<
    typeof EditorApplicationModalType.type,
    TTemplateDraft
  >();

  $drafts = new PromisedModel<TTemplateDraft[] | undefined>({
    name: '$drafts',
    load: async () => {
      const { organizationId, teamId, documentId } = this;

      if (!organizationId || !teamId || !documentId || !isDocumentIsValid(documentId)) {
        return undefined;
      }

      const { data } = await this.opts.request.get(
        '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}',
        {
          params: {
            path: {
              organizationId,
              teamId,
              documentId,
            },
          },
        },
      );

      return data;
    },
  });

  $application = new PromisedModel({
    name: '$application',
    load: async () => {
      const { organizationId, teamId, applicationId: applicationIdOrIdAlias } = this;

      if (!organizationId || !teamId || !applicationIdOrIdAlias) {
        return undefined;
      }

      try {
        const { data } = await this.opts.request.get(
          '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed/{applicationIdOrIdAlias}',
          {
            params: {
              path: {
                organizationId,
                teamId,
                applicationIdOrIdAlias,
              },
            },
          },
        );

        return data;
      } catch {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message:
            'Agent is no longer available. If you believe this is in error, please contact your team administrator.',
        });
      }

      return undefined;
    },
  });

  previewAutoSaver = new ReactiveWatcher(
    () => this.applicationPreviewModel?.values,
    values => {
      if (values) {
        this.opts.storage.editorApplicationPreviewValues.update({ [this.cachingKey]: _ => values });
      }
    },
    1000,
  );

  constructor(private opts: IEditorApplicationUIModelProps) {
    this.appVoiceApiModel = new AppVoiceApiModel({
      request: this.opts.request,
      organizationId: () => this.opts.organizationId(),
      teamId: () => this.opts.teamId(),
      getVoiceIds: () => this.applicationData.voiceSettings?.voiceIds ?? undefined,
      isVoiceEnabled: () => this.opts.subscriptionModel().isModelReady && !!this.opts.subscriptionModel().access?.voice,
    });

    this.knowledgeFileApiModel = new KnowledgeFilesApiModel({
      request: this.opts.request,
      organizationId: Number(this.opts.organizationId()),
      teamId: this.opts.teamId(),
      getApplicationId: () => this.$application.value?.id,
    });

    this.mediaFilesApiModel = new MediaFilesApiModel({
      request: this.opts.request,
      organizationId: Number(this.opts.organizationId()),
      teamId: this.opts.teamId(),
      limit: 50,
    });

    this.applicationGenerationLimitsModel = new ApplicationGenerationLimitsModel({
      aiStudioAccountModel: this.opts.aiStudioAccountModel(),
      assistantSubscriptionModel: this.opts.subscriptionModel(),
      getApplicationDeployment: () => this.$application.value?.writer,
    });

    makeObservable(this, {
      isGeneratingContent: observable,
      generationLimits: computed,
      drafts: computed,
      applicationPreviewModel: computed,
      applicationId: computed,
      organizationId: computed,
      teamId: computed,
      documentId: computed,
      cachingKey: computed,
      applicationName: computed,

      onDeleteDraft: action,
      generateDraft: action,
    });
  }

  get generationLimits() {
    return {
      isGenerationDisabled: this.applicationGenerationLimitsModel.isGenerationDisabled,
      generationDisabledMessage: this.applicationGenerationLimitsModel.generationDisabledMessage,
      coWriteLimits: this.applicationGenerationLimitsModel.generationLimits,
    };
  }

  get applicationId() {
    return this.opts.applicationId();
  }

  get applicationAccess() {
    return this.$application.value?.writer.access;
  }

  get organizationId() {
    return this.opts.organizationId();
  }

  get teamId() {
    return this.opts.teamId();
  }

  get documentId() {
    return this.opts.documentId();
  }

  get applicationData() {
    return this.$application?.value?.data as TConsoleGenerationApplication['applicationVersionData']['data'];
  }

  get applicationName() {
    return this.$application?.value?.name;
  }

  get cachingKey() {
    return `${this.opts.documentId()}-${this.applicationId}`;
  }

  get applicationPreviewModel() {
    if (
      this.$application.value?.type === EApplicationType.enum.generation &&
      this.applicationId &&
      this.organizationId
    ) {
      const values = untracked(() => this.opts.storage.editorApplicationPreviewValues.get()?.[this.cachingKey] || []);

      return new GenerationApplicationUIModel({
        inputs: this.applicationData.inputs as TAppInput[],
        values,
        voiceSettings: this.applicationData.voiceSettings ?? undefined,
        rewritePrompts: this.opts.rewritePrompts,
        rewriteSettings: this.applicationData.rewriteSettings ?? undefined,
        teamVoices: this.opts.voices(),
        orgVoices: this.appVoiceApiModel?.orgVoiceList || [],
        getRetentionPreferences: this.opts.retentionPreferences,
        organizationName: this.opts.organizationName,
        onDataRetentionLearnMore: () => openNewTab(DATA_RETENTION_SUPPORT_PAGE_URL),
        params: {
          knowledgeFilesApi: this.knowledgeFileApiModel,
          mediaFilesApi: this.mediaFilesApiModel,
          request: this.opts.request,
          applicationId: this.applicationId,
          teamId: this.teamId,
          organizationId: this.organizationId,
          notificationsQueue: this.notificationQueue,
        },
      });
    }

    return undefined;
  }

  get drafts(): TTemplateDraft[] {
    return [...this.createdDrafts, ...(this.$drafts.value || [])]
      .filter(draft => !this.deletedDrafts.find(deletedDraft => deletedDraft.id === draft.id))
      .sort((a, b) => {
        const dateA = new Date(a.creationTime).getTime();
        const dateB = new Date(b.creationTime).getTime();

        return dateB - dateA;
      });
  }

  onDeleteDraft = async (draft: TTemplateDraft) => {
    this.deletedDrafts.push(draft);

    const { id } = draft;

    if (!id) {
      return;
    }

    const { organizationId, teamId } = this;

    if (!organizationId || !teamId) {
      return;
    }

    try {
      await this.opts.request.delete('/api/template/organization/{organizationId}/team/{teamId}/draft', {
        params: {
          path: {
            organizationId,
            teamId,
          },
          query: {
            draftIds: [id],
          },
        },
      });
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Unable to delete draft',
      });

      this.deletedDrafts.remove(draft);
    }
  };

  onCopyDraft = async (draft: TTemplateDraft) => {
    const res = copyToClipboard({
      html: processLlmOutputBeforeInsert(draft.body),
      text: draft.body,
    });

    if (!res) {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Failed to copy link to clipboard',
      });
    } else {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Link copied to clipboard',
      });
    }
  };

  onRateDraft = async (feedback: string, rate: number) => {
    const draft = this.modalsManager.getModalState(EditorApplicationModalType.enum.rateDraft);

    if (!draft || !draft.id) {
      return;
    }

    const { id } = draft;

    const { organizationId, teamId, applicationId: applicationIdOrIdAlias, documentId } = this;

    if (!organizationId || !teamId || !applicationIdOrIdAlias || !documentId) {
      return;
    }

    try {
      await this.opts.request.post(
        '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}/draft/{draftId}/feedback',
        {
          params: {
            path: {
              organizationId,
              teamId,
              documentId: Number(documentId),
              draftId: id,
            },
          },
          body: {
            feedback,
            rate,
          },
        },
      );
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Unable to rate draft',
      });
    } finally {
      this.modalsManager.hideModal(EditorApplicationModalType.enum.rateDraft);
    }
  };

  generateDraft = async (
    inputs: components['schemas']['template_model_MagicRequestInput'][],
    voiceId?: string,
    reWritePromptId?: string,
  ): Promise<TTemplateDraft | undefined> => {
    const { organizationId, teamId, applicationId: applicationIdOrIdAlias, documentId } = this;

    if (!organizationId || !teamId || !applicationIdOrIdAlias || !documentId) {
      return undefined;
    }

    try {
      this.isGeneratingContent = true;
      const draft = await this.opts.request
        .post(
          '/api/template/organization/{organizationId}/team/{teamId}/application/{applicationIdOrIdAlias}/generate',
          {
            params: {
              path: {
                organizationId,
                teamId,
                applicationIdOrIdAlias,
              },
            },
            body: {
              documentId,
              inputs,
              voiceId: voiceId === STATIC_VOICE_OPTIONS.DEFAULT ? null : voiceId,
              reWritePromptId,
            },
          },
        )
        .then(res => res.data);

      this.createdDrafts.push(draft);

      return draft;
    } catch (e) {
      if (coWriteQuotaExceeded(e)) {
        runInAction(() => {
          this.forcedQuotaExceeded.set(true);
        });
      } else if (isBadRequest(e)) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: extractBackendResponseErrorMessage(e),
        });
      } else {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Unable to generate content',
        });
      }
    } finally {
      runInAction(() => {
        this.isGeneratingContent = false;
      });
      this.applicationGenerationLimitsModel.reloadGenerationLimits();
    }

    return undefined;
  };
}
