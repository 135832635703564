import { action, computed, makeObservable, observable } from 'mobx';

import { FormModel } from '@writercolab/mobx';
import { IconVariant } from '@writercolab/ui-atoms';

import type {
  TOAuthAppsConnectorResponse,
  TOAuthAppsCreateConnectorRequest,
  TOAuthAppsTypes,
  TOAuthAppsUpdateConnectorRequest,
} from '@web/types';
import { OAuthAppsTypes } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';

import type { OAuthAppsApiModel } from '../../../models/oauthApps.api';
import type { TOAuthAppsModalManager } from '../../pages/OAuthAppsPage/OAuthAppsPageModel.ui';

type IConnectorsConfig = {
  [K in typeof OAuthAppsTypes.type]: IConnectorData;
};

export interface IConnectorData {
  type: typeof OAuthAppsTypes.type;
  name: string;
  description: string;
  icon: IconVariant;
  setupGuideUrl?: string;
  settingUpUrl?: string;
}

export const GDriveFormModel = (input?: TOAuthAppsConnectorResponse) =>
  FormModel.build(({ field }) => ({
    name: field({
      init: () => input?.name ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    developerKey: field({
      init: () => input?.credential?.developerKey ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    clientId: field({
      init: () => input?.credential?.clientId ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    clientSecret: field({
      init: () => input?.credential?.clientSecret ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
  }));

export const SharepointFormModel = (input?: TOAuthAppsConnectorResponse) =>
  FormModel.build(({ field }) => ({
    name: field({
      init: () => input?.name ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    clientId: field({
      init: () => input?.credential?.clientId ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    clientSecret: field({
      init: () => input?.credential?.clientSecret ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    tenantId: field({
      init: () => input?.credential?.tenantId ?? '',
      autotouched: true,
    }),
  }));

export const NotionFormModel = (input?: TOAuthAppsConnectorResponse) =>
  FormModel.build(({ field }) => ({
    name: field({
      init: () => input?.name ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    clientId: field({
      init: () => input?.credential?.clientId ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    clientSecret: field({
      init: () => input?.credential?.clientSecret ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
  }));

export const ConfluenceFormModel = (input?: TOAuthAppsConnectorResponse) =>
  FormModel.build(({ field }) => ({
    name: field({
      init: () => input?.name ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    clientId: field({
      init: () => input?.credential?.clientId ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
    clientSecret: field({
      init: () => input?.credential?.clientSecret ?? '',
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'This field is required';
        }

        return undefined;
      },
    }),
  }));

export type TConnectorReturnModel =
  | ReturnType<typeof GDriveFormModel>
  | ReturnType<typeof SharepointFormModel>
  | ReturnType<typeof NotionFormModel>
  | ReturnType<typeof ConfluenceFormModel>;

type IConnectorsFormModel = {
  [K in typeof OAuthAppsTypes.type]: (input?: TOAuthAppsConnectorResponse) => TConnectorReturnModel;
};

export const CONNECTOR_CONFIG: IConnectorsConfig = {
  gdrive: {
    type: OAuthAppsTypes.enum.gdrive,
    name: 'Google Drive',
    description: 'Create an OAuth application in Google Drive<br/>and add your application details here',
    icon: IconVariant.GDRIVE,
    setupGuideUrl: 'https://support.writer.com/article/256-configuring-authentication-values-for-google-drive',
    settingUpUrl: 'https://support.writer.com/article/251-setting-up-knowledge-graph-data-connectors',
  },
  sharepoint: {
    type: OAuthAppsTypes.enum.sharepoint,
    name: 'SharePoint',
    description: 'Create an OAuth application in SharePoint<br/>and add your application details here',
    icon: IconVariant.SHAREPOINT,
    setupGuideUrl: 'https://support.writer.com/article/255-configuring-authentication-values-for-sharepoint',
    settingUpUrl: 'https://support.writer.com/article/251-setting-up-knowledge-graph-data-connectors',
  },
  notion: {
    type: OAuthAppsTypes.enum.notion,
    name: 'Notion',
    description: 'Create an OAuth application in Notion<br/>and add your application details here',
    icon: IconVariant.NOTION,
    setupGuideUrl: 'https://support.writer.com/article/257-configuring-authentication-values-for-notion',
    settingUpUrl: 'https://support.writer.com/article/251-setting-up-knowledge-graph-data-connectors',
  },
  confluence: {
    type: OAuthAppsTypes.enum.confluence,
    name: 'Confluence',
    description: 'Create an OAuth application in Confluence<br/>and add your application details here',
    icon: IconVariant.CONFLUENCE,
    setupGuideUrl: 'https://support.writer.com/article/265-configuring-authentication-values-for-confluence',
    settingUpUrl: 'https://support.writer.com/article/251-setting-up-knowledge-graph-data-connectors',
  },
};

export const CONNECTOR_FORM_MODEL: IConnectorsFormModel = {
  gdrive: GDriveFormModel,
  sharepoint: SharepointFormModel,
  notion: NotionFormModel,
  confluence: ConfluenceFormModel,
};

interface OAuthAppsViewerUIModelOpts {
  modalsManager: TOAuthAppsModalManager;
  handleCreate: (body: TOAuthAppsCreateConnectorRequest) => Promise<void>;
  handleEdit: (body: TOAuthAppsUpdateConnectorRequest) => Promise<void>;
  getSelectedConnector: () => TOAuthAppsConnectorResponse | undefined;
  getSelectedConnectorLoading: () => boolean;
  getIsConfluenceConnectorEnabled: () => boolean;
  getConnectorTypes: () => { type: TOAuthAppsTypes; enabled: boolean }[] | undefined;
  isAdvancedConnectorsEnabled: () => boolean;
  api: Pick<OAuthAppsApiModel, 'isLoading'>;
  analyticsService: IWebAppAnalyticsTrack;
}

export class OAuthAppsViewerUIModel {
  private $connectorType = observable.box<TOAuthAppsTypes | undefined>(undefined);
  modalsManager: TOAuthAppsModalManager;
  getSelectedConnector: () => TOAuthAppsConnectorResponse | undefined;
  getSelectedConnectorLoading: () => boolean;
  api: Pick<OAuthAppsApiModel, 'isLoading'>;

  constructor(private opts: OAuthAppsViewerUIModelOpts) {
    this.api = opts.api;
    this.modalsManager = opts.modalsManager;
    this.getSelectedConnector = opts.getSelectedConnector;
    this.getSelectedConnectorLoading = opts.getSelectedConnectorLoading;

    makeObservable(this, {
      setConnectorType: action,
      connectors: computed,
      connectorType: computed,
      connectorData: computed,
      connectorForm: computed,
      isShowCreateStep: computed,
      connectorTypes: computed,
      isAdvancedConnectorsEnabled: computed,
    });
  }

  get connectors() {
    return Object.values(CONNECTOR_CONFIG).filter(connector => {
      if (connector.type === OAuthAppsTypes.enum.confluence) {
        return this.opts.getIsConfluenceConnectorEnabled();
      }

      return true;
    });
  }

  get connectorType() {
    const connector = this.getSelectedConnector();

    if (connector) return connector?.type;

    return this.$connectorType.get();
  }

  setConnectorType = (connectorType: TOAuthAppsTypes | undefined) => {
    this.$connectorType.set(connectorType);
  };

  get connectorData() {
    if (!this.connectorType) return null;

    return CONNECTOR_CONFIG[this.connectorType];
  }

  get connectorForm() {
    if (!this.connectorType) return null;

    return CONNECTOR_FORM_MODEL[this.connectorType](this.getSelectedConnector());
  }

  get isShowCreateStep() {
    return !this.connectorData && !this.connectorForm && !this.getSelectedConnectorLoading();
  }

  get connectorTypes() {
    return this.opts.getConnectorTypes();
  }

  get isAdvancedConnectorsEnabled() {
    return this.opts.isAdvancedConnectorsEnabled();
  }

  isConnectorBlocked = (connectorType: TOAuthAppsTypes): boolean => {
    const connector = this.connectorTypes?.find(connector => connector.type === connectorType);

    if (connector) {
      return !connector.enabled;
    }

    return !this.isAdvancedConnectorsEnabled;
  };

  onCancel = () => {
    this.modalsManager.hideModal();
    this.setConnectorType(undefined);
  };

  onSubmit = async () => {
    if (!this.connectorType) return;

    if (!this.connectorForm) return;

    await OAuthAppsTypes.match(
      this.connectorType,
      {
        gdrive: async () => {
          const form = this.connectorForm as ReturnType<typeof GDriveFormModel>;
          const selectedConnector = this.getSelectedConnector();
          const credentials = selectedConnector?.credential;

          if (selectedConnector) {
            const body: TOAuthAppsUpdateConnectorRequest = {
              type: OAuthAppsTypes.enum.gdrive,
              name: form.value.name,
              credential: {
                clientId: form.value?.clientId,
                maskedClientSecret:
                  form.value?.clientSecret === credentials?.clientSecret ? undefined : form.value?.clientSecret,
                maskedDeveloperKey:
                  form.value?.developerKey === credentials?.developerKey ? undefined : form.value?.developerKey,
              },
            };
            await this.opts.handleEdit(body);
          } else {
            const body: TOAuthAppsCreateConnectorRequest = {
              type: OAuthAppsTypes.enum.gdrive,
              name: form.value.name,
              credential: {
                clientId: form.value?.clientId,
                clientSecret: form.value?.clientSecret,
                developerKey: form.value?.developerKey,
              },
            };
            await this.opts.handleCreate(body);
          }
        },
        sharepoint: async () => {
          const form = this.connectorForm as ReturnType<typeof SharepointFormModel>;
          const selectedConnector = this.getSelectedConnector();
          const credentials = selectedConnector?.credential;

          if (selectedConnector) {
            const body: TOAuthAppsUpdateConnectorRequest = {
              type: OAuthAppsTypes.enum.sharepoint,
              name: form.value.name,
              credential: {
                clientId: form.value?.clientId,
                maskedClientSecret:
                  form.value?.clientSecret === credentials?.clientSecret ? undefined : form.value?.clientSecret,
                tenantId: form.value?.tenantId || undefined,
              },
            };
            await this.opts.handleEdit(body);
          } else {
            const body: TOAuthAppsCreateConnectorRequest = {
              type: OAuthAppsTypes.enum.sharepoint,
              name: form.value.name,
              credential: {
                clientId: form.value?.clientId,
                clientSecret: form.value?.clientSecret,
                tenantId: form.value?.tenantId || undefined,
              },
            };
            await this.opts.handleCreate(body);
          }
        },
        notion: async () => {
          const form = this.connectorForm as ReturnType<typeof NotionFormModel>;
          const selectedConnector = this.getSelectedConnector();
          const credentials = selectedConnector?.credential;

          if (selectedConnector) {
            const body: TOAuthAppsUpdateConnectorRequest = {
              type: OAuthAppsTypes.enum.notion,
              name: form.value.name,
              credential: {
                clientId: form.value?.clientId,
                maskedClientSecret:
                  form.value?.clientSecret === credentials?.clientSecret ? undefined : form.value?.clientSecret,
              },
            };
            await this.opts.handleEdit(body);
          } else {
            const body: TOAuthAppsCreateConnectorRequest = {
              type: OAuthAppsTypes.enum.notion,
              name: form.value.name,
              credential: {
                clientId: form.value?.clientId,
                clientSecret: form.value?.clientSecret,
              },
            };
            await this.opts.handleCreate(body);
          }
        },
        confluence: async () => {
          const form = this.connectorForm as ReturnType<typeof ConfluenceFormModel>;
          const selectedConnector = this.getSelectedConnector();
          const credentials = selectedConnector?.credential;

          if (selectedConnector) {
            const body: TOAuthAppsUpdateConnectorRequest = {
              type: OAuthAppsTypes.enum.confluence,
              name: form.value.name,
              credential: {
                clientId: form.value?.clientId,
                maskedClientSecret:
                  form.value?.clientSecret === credentials?.clientSecret ? undefined : form.value?.clientSecret,
              },
            };
            await this.opts.handleEdit(body);
          } else {
            const body: TOAuthAppsCreateConnectorRequest = {
              type: OAuthAppsTypes.enum.confluence,
              name: form.value.name,
              credential: {
                clientId: form.value?.clientId,
                clientSecret: form.value?.clientSecret,
              },
            };
            await this.opts.handleCreate(body);
          }
        },
      },
      null,
    );

    this.opts.analyticsService.track(AnalyticsActivity.oAuthAppsAddedApp, {
      connector_type: this.connectorType,
    });

    this.setConnectorType(undefined);
  };
}
