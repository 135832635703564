import { useMemo } from 'react';

import cx from 'classnames';

import { SimpleTooltip } from '@writercolab/fe.wds';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingColor,
  HeadingVariant,
  Icon,
  IconVariant,
  Modal,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  TooltipAlignment,
  UserNotificationBanner,
} from '@writercolab/ui-atoms';
import { ThreeDotsLoader } from '@writercolab/ui-molecules';

import oauthAppsEmptyBg from 'assets/backgrounds/emptyOAuthApps.png';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { Lock } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { ROUTE } from 'services/config/routes';
import { getConnectorsSectionLabel } from 'utils/knowledgeGraphUtils';
import { openContactSalesPage } from 'utils/navigationUtils';

import type { KnowledgeGraphConnectorUIModel } from './KnowledgeGraphConnectorModel.ui';

import styles from './KnowledgeGraphConnector.module.css';

const AddGraphTooltipContent = ({
  isBlockedByBalance,
  isBlockedByUsageLimits,
  isBlockedBySubscriptionPlan,
}: {
  isBlockedByBalance?: boolean;
  isBlockedByUsageLimits?: boolean;
  isBlockedBySubscriptionPlan?: boolean;
}) => {
  const blockedMessage = useMemo(() => {
    if (isBlockedByBalance) {
      return 'Disabled due to insufficient credits, contact your org admin to resume service.';
    }

    if (isBlockedByUsageLimits) {
      return 'Disabled due to exceeding usage limits, contact your org admin to resume service.';
    }

    if (isBlockedBySubscriptionPlan) {
      return 'This is an enterprise feature';
    }

    return 'Only team admins can create and manage Knowledge Graph';
  }, [isBlockedByBalance, isBlockedByUsageLimits, isBlockedBySubscriptionPlan]);

  return (
    <Text variant={TextSize.M} color={TextColor.WHITE}>
      {blockedMessage}
    </Text>
  );
};

export interface IKnowledgeGraphConnectorSelector {
  model: KnowledgeGraphConnectorUIModel;
  viewOnly?: boolean;
  isOrgAdmin?: boolean;
  isBlockedByBalance?: boolean;
  isBlockedByUsageLimits?: boolean;
  isAdvancedConnectorsEnabled: boolean;
}

export const KnowledgeGraphConnectorSelector = observer<IKnowledgeGraphConnectorSelector>(
  ({ model, viewOnly, isBlockedByBalance, isBlockedByUsageLimits, isOrgAdmin, isAdvancedConnectorsEnabled }) => {
    const navigate = useNavigate();
    const handleConnectorClick = (id: string, type: string) => {
      if (!viewOnly) {
        model.setConnectorId(id);
        model.setConnectorType(type);
        model.openAuthModal();
        model.openOAuthPopup();
      }
    };

    return (
      <div className={styles.container}>
        <Heading variant={HeadingVariant.H2} color={HeadingColor.GREY} upperCase>
          Data Connectors
        </Heading>
        <Text className={styles.description} variant={TextSize.XL} color={TextColor.GREY}>
          You can set up a connector for any data source that has an OAuth app configured.
          <br />
          Each graph can be connected to one data connector.
        </Text>
        <div className={styles.tabs}>
          {model.isAssistantSubscriptionActive && (
            <Tooltip
              disabled={!model.isWriterOauthAppsEnabled}
              title={
                isOrgAdmin
                  ? 'Self-managed apps have been disabled under admin settings. To manage this, go to Admin > OAuth apps.'
                  : 'Your organization has disabled self-managed OAuath apps. To get an OAuth app set up, contact an org admin.'
              }
              align={TooltipAlignment.LEFT}
              tooltipWidth={240}
            >
              <Text
                className={cx(styles.tab, { [styles.selected]: !model.isWriterOauthAppsEnabled })}
                variant={TextSize.XL}
                color={TextColor.GREY}
              >
                Self-managed OAuth apps
              </Text>
            </Tooltip>
          )}

          <Tooltip
            disabled={model.isWriterOauthAppsEnabled}
            title={
              isOrgAdmin
                ? 'Writer-managed apps have been disabled under admin settings. To manage this, go to Admin > OAuth apps.'
                : 'Writer-managed apps have been disabled by your org admin. To get an OAuth app set up, contact an org admin.'
            }
            align={TooltipAlignment.LEFT}
            tooltipWidth={255}
          >
            <Text
              className={cx(styles.tab, { [styles.selected]: model.isWriterOauthAppsEnabled })}
              variant={TextSize.XL}
              color={TextColor.GREY}
            >
              Writer-managed OAuth apps
            </Text>
          </Tooltip>
        </div>

        <div className={cx(styles.connectors, { [styles.disabled]: model.isOrgSettingsLoading })}>
          {model.isWriterOauthAppsEnabled ? (
            <>
              {model.writerConnectors.map(connector => {
                const icon = IconVariant[connector.type.toUpperCase()];
                const isConnectorDisabled = !connector.enabled;

                const isDisabledButton = isBlockedByBalance || isConnectorDisabled || viewOnly;
                const isEnabledTooltip =
                  (isBlockedByBalance || isBlockedByUsageLimits || isConnectorDisabled) && !viewOnly;

                return (
                  <SimpleTooltip
                    key={connector.id}
                    placement="top"
                    disabled={!isEnabledTooltip}
                    trigger={
                      <Button
                        className={cx(styles.connectorSimplified, {
                          [styles.disabled]: isDisabledButton,
                        })}
                        key={connector.id}
                        content={
                          <>
                            {connector.name}
                            {isConnectorDisabled && <Lock width={12} height={12} className={styles.lockIcon} />}
                          </>
                        }
                        type={ButtonTypes.TRANSPARENT_HOVER}
                        size={SizeTypes.XXXL}
                        icon={<Icon name={icon ? IconVariant[icon] : IconVariant.ALERT} width={28} height={28} />}
                        onClick={() => handleConnectorClick(connector.id, connector.type)}
                        disabled={isDisabledButton}
                      />
                    }
                  >
                    <AddGraphTooltipContent
                      isBlockedByBalance={isBlockedByBalance}
                      isBlockedByUsageLimits={isBlockedByUsageLimits}
                      isBlockedBySubscriptionPlan={isConnectorDisabled}
                    />
                  </SimpleTooltip>
                );
              })}

              {!isAdvancedConnectorsEnabled && (
                <UserNotificationBanner
                  type="info"
                  className={styles.limitedConnectorsBanner}
                  notification={<>To access advanced data connectors, upgrade your plan.</>}
                  action={{
                    title: 'Contact sales',
                    onClick: () => {
                      openContactSalesPage();
                    },
                  }}
                />
              )}
            </>
          ) : (
            <>
              {isEmpty(model.connectorsGroups) && (
                <div className={styles.connectorsEmptyState}>
                  <Text variant={TextSize.XL} color={TextColor.GREY} bold>
                    Your org hasn’t set up an OAuth app yet
                  </Text>
                  {viewOnly ? (
                    <Text variant={TextSize.XL} color={TextColor.GREY} className={styles.connectorsEmptyDescription}>
                      To set up an app, contact an org admin
                    </Text>
                  ) : (
                    <Button
                      type={ButtonTypes.PRIMARY}
                      size={SizeTypes.LARGE}
                      onClick={() => {
                        navigate(ROUTE.toAdminOAuthApps(model.orgId));
                      }}
                    >
                      Set up a new app
                    </Button>
                  )}
                  <Text variant={TextSize.XL} color={TextColor.GREY}>
                    Or, try an Writer-managed app. <span>Learn more.</span>
                  </Text>

                  <div className={styles.connectorsEmptyBg}>
                    <img src={oauthAppsEmptyBg} alt="Set up a new app" />
                  </div>
                </div>
              )}

              {model.connectorsGroups.map(group => (
                <>
                  <Heading
                    variant={HeadingVariant.H3}
                    color={HeadingColor.GREY}
                    className={styles.connectorsSectionHeading}
                  >
                    {getConnectorsSectionLabel(group)}
                  </Heading>

                  {model.connectors
                    .filter(connector => connector.type === group)
                    .map(connector => {
                      const icon = IconVariant[connector.type.toUpperCase()];

                      return (
                        <Tooltip
                          key={connector.id}
                          placement="top"
                          align={TooltipAlignment.LEFT}
                          title={
                            <AddGraphTooltipContent
                              isBlockedByBalance={isBlockedByBalance}
                              isBlockedByUsageLimits={isBlockedByUsageLimits}
                            />
                          }
                          tooltipWidth={275}
                          disabled={(!isBlockedByBalance || isBlockedByUsageLimits) && !viewOnly}
                        >
                          <div
                            className={cx(styles.connector, { [styles.disabled]: isBlockedByBalance || viewOnly })}
                            onClick={() => handleConnectorClick(connector.id, connector.type)}
                          >
                            <Icon name={icon ? IconVariant[icon] : IconVariant.ALERT} width={30} height={30} />
                            <div className={styles.connectorDescription}>
                              <span className={styles.connectorDescriptionName}>{connector.name}</span>
                              <span>
                                Added by {connector.createdBy?.firstName} {connector.createdBy?.lastName}
                              </span>
                              <span>{format(new Date(connector.createdAt), `MMM d, yyyy h:mmaaa`)}</span>
                            </div>
                          </div>
                        </Tooltip>
                      );
                    })}
                </>
              ))}
            </>
          )}
        </div>

        <Modal
          className={styles.authModal}
          open={model.isAuthModalOpen}
          title={`Connect to ${model.connectorName}`}
          modalTitleClassName={styles.title}
          handleClose={model.closeAuthModal}
          disableBackdropClick
        >
          <div className={styles.content}>
            <ThreeDotsLoader className={styles.loader} />
            <Text className={styles.description} variant={TextSize.XL} color={TextColor.GREY}>
              Authenticating with <b>{model.connectorName}</b>
            </Text>
          </div>
        </Modal>
      </div>
    );
  },
);
