import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import qs from 'qs';

import {
  ISubscription,
  IUserProfile,
  RemovePersonActionType,
  RequestQueryParams,
  SearchQueryParam,
  SortFieldTypes,
  SortOrders,
  TApplicationUserType,
  TUserStatusFilter,
  TermsSortingTypes,
  UserTeamRole,
  addUserToTeam,
  downloadBlobAsFilename,
  getCurrentSubscription,
  getTeams,
  increaseSubscriptionSeatsAmount,
  removeUserFromOrganization,
  removeUserFromTeam,
  retry,
} from '@writercolab/common-utils';
import { TSubscriptionLimitType } from '@writercolab/models';
import type { components } from '@writercolab/network';
import { Icon, IconVariant, useCustomSnackbar } from '@writercolab/ui-atoms';
import { stringsUniq } from '@writercolab/utils';

import type { IModifiedUser } from '../components/molecules/PeopleList/PeopleListRow';

import { loadStripe } from '@stripe/stripe-js';
import { snackbarMessages } from '@web/component-library';
import type { IAddTeammateForm, IMappedTeamDetails, IOriginalUser, ISendInviteRequest } from '@web/types';
import { OrgJoinNewUserStatus, OrgJoinQueryParams } from '@web/types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams, useSearchParams } from 'react-router';

import { DEFAULT_PEOPLE_FETCH_LIMIT } from '../services/config/constants';
import {
  downloadPeoplesListAsCsv,
  downloadPeoplesListAsXlsx,
  downloadTeammatesListAsCsv,
  downloadTeammatesListAsXlsx,
} from '../services/request/people';
import {
  approveUserOrganizationByInviter,
  denyUserOrganizationByInviter,
  findBillingGroups,
  findUserInOrganization,
  findUserInTeam,
  getOrganizationAdmins,
  inviteUser,
  makeOrganizationAdmin,
  makeTeamAdmin,
  resendInviteLink,
  updateillingGroup,
} from '../services/request/user';
import { useAppState } from '../state';
import { extractBackendResponseErrorMessage } from '../utils/backendErrorUtils';
import { getLogger } from '../utils/logger';
import { isUserActionQueryParamValid } from '../utils/queryParamUtils';
import { teamDetailsMapper } from '../utils/teamUtils';

const LOG = getLogger('peopleContext');

const { VITE_STRIPE_KEY } = import.meta.env;

enum DOWNLOAD_FILE_TYPE {
  CSV = 'csv',
  XLSX = 'xlsx',
}

export const DROPDOWN_DOWNLOAD_OPTIONS = [
  {
    name: 'Download as csv',
    id: DOWNLOAD_FILE_TYPE.CSV,
    icon: <Icon name={IconVariant.SPREADSHEET} width={18} height={18} />,
  },
  {
    name: 'Download as xlsx',
    id: DOWNLOAD_FILE_TYPE.XLSX,
    icon: <Icon name={IconVariant.SPREADSHEET} width={18} height={18} />,
  },
];

interface IPeopleContextProps {
  children?: ReactNode;
  isSelfServe?: boolean;
  isEnterprise?: boolean;
  isOrganizationAdmin?: boolean;
}

interface StripeClientSecretKey {
  client_secret?: string;
  clientSecret?: string;
}

interface IPeopleContext {
  people: IOriginalUser[];
  organizationAdmins: IUserProfile[];
  isLoading?: boolean;
  orgId?: number;
  teamId?: number;
  infiniteRefSentry?: any;
  handleSearch: (value: string) => void;
  searchValue: string;
  handleMakeAdmin: (user: IModifiedUser) => void;
  handleRemovePerson: (user: IModifiedUser, actionType: RemovePersonActionType, admin: IUserProfile) => void;
  handleRemovePersonFromTeam: (user: IModifiedUser) => void;
  handleResendInvite: (userId: number) => void;
  handleSendInvite: (form: ISendInviteRequest) => Promise<void>;
  handleAddUserToTeam: (form: IAddTeammateForm) => void;
  isTeamUsersPage: boolean;
  currentTeam?: IMappedTeamDetails;
  handleSeatsNumberChange: (seatsNumber: number) => Promise<any>;
  teamsCount: number;
  filtersCount: number;
  handlePeopleSortingChange: (sortType: TermsSortingTypes) => void;
  peopleSortField?: SortFieldTypes;
  peopleSortOrder?: SortOrders;
  handleDownloadPeoplesList: (filetype: string) => void;
  handleApprovePendingUser: (pendingUser: IUserProfile) => void;
  handleRejectPendingUser: (rejectedUser: IUserProfile) => void;
  handleResetFilters: () => void;
  fetchPeopleList: (isAddToList?: boolean, afterFetchCallback?: () => void) => void;
  editBillingGroup: (data: { billingGroupId: number; userIds: number[] }) => void;
  handleUserFilterChange: (filterValue: UserStatusFilterIdentifier) => void;
  handleBillingGroupFilterChange?: (filterId: number) => void;
  billingGroups: components['schemas']['com_qordoba_user_dto_BillingGroupResponse'][];
  userFilterVal: string[];
  billingGroupFilterVal: string[];
  onAfterInviteTeamMate: () => void;
  isCurrentTeamLoading: boolean;
}

export enum peopleFilterType {
  USER_STATUS = 'userStatus',
}

export enum UserStatusFilterIdentifier {
  ACTIVE = 'active',
  INVITE_PENDING = 'invite_pending',
  APPROVAL_PENDING = 'approval_pending',
}

const validUserStatuses: UserStatusFilterIdentifier[] = [
  UserStatusFilterIdentifier.ACTIVE,
  UserStatusFilterIdentifier.INVITE_PENDING,
  UserStatusFilterIdentifier.APPROVAL_PENDING,
];

const PeopleContext = createContext<IPeopleContext>({} as IPeopleContext);

enum FilterType {
  STATUS = 'status',
  SEARCH = 'search',
  BILLING_GROUP = 'billingGroupIds',
}

const mapStatusQueryParamToRequestParam = (status: UserStatusFilterIdentifier): typeof TUserStatusFilter.type => {
  const statusMapping: Record<UserStatusFilterIdentifier, typeof TUserStatusFilter.type> = {
    [UserStatusFilterIdentifier.ACTIVE]: TUserStatusFilter.enum.active,
    [UserStatusFilterIdentifier.APPROVAL_PENDING]: TUserStatusFilter.enum.approval_pending,
    [UserStatusFilterIdentifier.INVITE_PENDING]: TUserStatusFilter.enum.invite_pending,
  };

  return statusMapping[status];
};

const PeopleContextProvider: React.FC<IPeopleContextProps> = observer(({ isOrganizationAdmin, children }) => {
  const { orgId, teamId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { appState, loadSubscription, loadApplicationStats, appModel } = useAppState();
  const currentOrgId: number = +orgId!;
  const currentTeamId: number = +teamId!;
  const isTeamUsersPage: boolean = !!currentTeamId;
  const [people, setPeople] = useState<IOriginalUser[]>([]);
  const [organizationAdmins, setOrganizationAdmins] = useState<IUserProfile[]>([]);
  const [search, setSearch] = useState<string>(searchParams.get('search') || '');
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
  const [currentTeam, setCurrentTeam] = useState<IMappedTeamDetails>();
  const [teamsCount, setTeamsCount] = useState(0);
  const [filtersCount, setFiltersCount] = useState(0);
  const [isCurrentTeamLoading, setIsCurrentTeamLoading] = useState(false);
  const [billingGroups, setBillingGroups] = useState<
    components['schemas']['com_qordoba_user_dto_BillingGroupResponse'][]
  >([]);
  const {
    closeSnackbar,
    enqueueBasicSnackbar,
    enqueueDeleteSnackbar,
    enqueueErrorSnackbar,
    enqueueLoadingSnackbar,
    enqueueSuccessSnackbar,
  } = useCustomSnackbar();

  const { isModelReady, isFree, isMultiTeam } = appModel.assistantSubscription;
  const subscription = appModel.assistantSubscription.$subscription.value;

  // fetch data on mount and when search params change
  useEffect(() => {
    fetchPeopleList(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const userId = searchParams.get(OrgJoinQueryParams.USER_ID) || '';
    const userAction = searchParams.get(OrgJoinQueryParams.ACTION) || '';
    const canProcessUserAction = isUserActionQueryParamValid(userAction) && !isEmpty(userId);

    if (canProcessUserAction && !isLoading) {
      handleNewUserAction(userId, userAction as OrgJoinNewUserStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const resetPage = useCallback(() => {
    setOffset(0);
    setPeople([]);
  }, []);

  const updateSearchParams = useCallback(
    (params: { [key: string]: string | string[] | number | undefined }) => {
      // Get the current URL search parameters
      const currentParams = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });

      // Merge the new parameters with the existing ones
      const mergedParams = { ...currentParams, ...params };

      // Update the URL search parameters with the merged parameters
      const res = qs.stringify(mergedParams, { arrayFormat: 'repeat' });
      setSearchParams(res);
    },
    [searchParams, setSearchParams],
  );

  const handleUserFilterChange = useCallback(
    (filterValue: UserStatusFilterIdentifier) => {
      const userStatusValues = searchParams.getAll(peopleFilterType.USER_STATUS);

      if (!userStatusValues.includes(filterValue)) {
        userStatusValues.push(filterValue);
      } else {
        userStatusValues.splice(userStatusValues.indexOf(filterValue), 1);
      }

      updateSearchParams({
        [peopleFilterType.USER_STATUS]: userStatusValues,
      });
    },
    [searchParams, updateSearchParams],
  );

  const handleBillingGroupFilterChange = useCallback(
    (filterValue: number) => {
      const billingGroupIds = searchParams.getAll(FilterType.BILLING_GROUP);

      if (!billingGroupIds.includes(String(filterValue))) {
        billingGroupIds.push(String(filterValue));
      } else {
        billingGroupIds.splice(billingGroupIds.indexOf(String(filterValue)), 1);
      }

      updateSearchParams({
        billingGroupIds,
      });
    },
    [searchParams, updateSearchParams],
  );

  const afterNewUserAction = useCallback(() => {
    updateSearchParams({
      userId: '',
      action: '',
    });

    resetPage();
  }, [resetPage, updateSearchParams]);

  const handleApprovePaymentRequest = useCallback(
    async (paymentIntent: StripeClientSecretKey) => {
      const clientSecret = paymentIntent.clientSecret!;
      const stripeInstance = await loadStripe(VITE_STRIPE_KEY!);
      const confirmationResult = await stripeInstance?.confirmCardPayment(clientSecret);

      if (confirmationResult?.error) {
        enqueueErrorSnackbar(snackbarMessages.billing.paymentConfirmationError);
        throw new Error(snackbarMessages.billing.paymentConfirmationError);
      } else if (confirmationResult?.paymentIntent) {
        enqueueBasicSnackbar(snackbarMessages.billing.paymentConfirmed);
      }
    },
    [enqueueBasicSnackbar, enqueueErrorSnackbar],
  );

  const handlePeopleSortingChange = useCallback(
    (sortType: TermsSortingTypes) => {
      if (sortType === TermsSortingTypes.LAST_SEEN_ASC) {
        updateSearchParams({
          sortField: SortFieldTypes.LAST_SEEN,
          sortOrder: SortOrders.ASC,
        });
      } else if (sortType === TermsSortingTypes.LAST_SEEN_DESC) {
        updateSearchParams({
          sortField: SortFieldTypes.LAST_SEEN,
          sortOrder: SortOrders.DESC,
        });
      }

      resetPage();
    },
    [resetPage, updateSearchParams],
  );

  const fetchBillingGroups = useCallback(async () => {
    try {
      const billingGroups = await findBillingGroups(currentOrgId);

      setBillingGroups(billingGroups);
    } catch (err) {
      LOG.error(err);
      setBillingGroups([]);
    }
  }, [currentOrgId]);

  const fetchPeopleList = useCallback(
    async (isAddToList?: boolean, afterFetchCallback?: () => void) => {
      setIsLoading(true);

      const fetchFn = isTeamUsersPage
        ? (params: RequestQueryParams) => findUserInTeam(currentOrgId, currentTeamId, params)
        : (params: RequestQueryParams) =>
            findUserInOrganization(currentOrgId, { userType: TApplicationUserType.enum.individual, ...params });

      const currentOffset = isAddToList ? offset : 0;
      const _fetchUsersRequestParams: RequestQueryParams = {
        search,
        userType: TApplicationUserType.enum.individual,
        offset: currentOffset,
        limit: DEFAULT_PEOPLE_FETCH_LIMIT,
      };

      try {
        const sortField = searchParams.get(SearchQueryParam.sortField);
        const sortOrder = searchParams.get(SearchQueryParam.sortOrder);
        const userStatusFilter = searchParams.getAll(peopleFilterType.USER_STATUS) as (typeof TUserStatusFilter.type)[];
        const userId = searchParams.get(OrgJoinQueryParams.USER_ID) || '';
        const billingGroupIds = searchParams.getAll(FilterType.BILLING_GROUP);
        const searchFilter = searchParams.get(FilterType.SEARCH);
        const filters: FilterType[] = [];

        if (userId.length) {
          _fetchUsersRequestParams.search = userId;
        }

        if (userStatusFilter) {
          _fetchUsersRequestParams.userStatus = isEmpty(userStatusFilter) ? undefined : userStatusFilter;

          if (_fetchUsersRequestParams.userStatus) {
            filters.push(FilterType.STATUS);
          }
        }

        if (searchFilter) {
          _fetchUsersRequestParams.search = searchFilter;
          filters.push(FilterType.SEARCH);
        }

        if (billingGroupIds.length) {
          // @ts-expect-error: billingGroupIds is not a property of RequestQueryParams, but it is being assigned here
          _fetchUsersRequestParams.billingGroupIds = billingGroupIds;
          filters.push(FilterType.BILLING_GROUP);
        }

        setFiltersCount(uniq(filters).length);

        if (
          sortField &&
          sortOrder &&
          Object.values(SortFieldTypes).includes(sortField as SortFieldTypes) &&
          Object.values(SortOrders).includes(sortOrder as SortOrders)
        ) {
          _fetchUsersRequestParams.sortField = sortField as SortFieldTypes;
          _fetchUsersRequestParams.sortOrder = sortOrder as SortOrders;
        }

        const { result, totalCount } = await fetchFn(_fetchUsersRequestParams);

        setIsLoading(false);
        setPeople(currentOffset || isAddToList ? [...people, ...result] : result);
        setOffset(isAddToList ? currentOffset + DEFAULT_PEOPLE_FETCH_LIMIT : DEFAULT_PEOPLE_FETCH_LIMIT);
        setTotalCount(totalCount);
      } catch (e) {
        LOG.error(e);
      } finally {
        afterFetchCallback?.();
      }
    },
    [currentOrgId, currentTeamId, offset, people, search, searchParams, isTeamUsersPage],
  );

  const handleLoadMore = useCallback(() => {
    if (!isLoading && offset < totalCount) {
      fetchPeopleList(true);
    }
  }, [fetchPeopleList, isLoading, offset, totalCount]);

  const handleResetFilters = useCallback(() => {
    setSearchParams('');
    setSearch('');
    resetPage();
  }, [resetPage, setSearchParams]);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: totalCount > people.length,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 50px 0px',
  });

  // we should create just one insctance of debounced function
  // because it's not a good idea to create a new instance of debounced function on every render
  const debouncedUpdateSearchParams = useRef(
    debounce((value: string) => {
      updateSearchParams({ search: value });
    }, 500),
  ).current;

  const handleSearch = (value: string) => {
    setSearch(value);
    debouncedUpdateSearchParams(value);
  };

  const handleMakeAdmin = async (user: IModifiedUser) => {
    const _request =
      isTeamUsersPage && isMultiTeam
        ? params => makeTeamAdmin(currentOrgId, currentTeamId, params)
        : params => makeOrganizationAdmin(currentOrgId, params);
    const _loader = enqueueLoadingSnackbar(snackbarMessages.loader.roleUpdating);

    const _newRole =
      (!isTeamUsersPage && user.role === UserTeamRole.ORG_ADMIN) ||
      (isTeamUsersPage && user.role.includes(UserTeamRole.ADMIN))
        ? UserTeamRole.MEMBER
        : UserTeamRole.ADMIN;

    const _formattedData = {
      role: _newRole,
      userId: user.id,
    };

    try {
      await _request(_formattedData);

      const _newRoleToShow = !isTeamUsersPage && _newRole === UserTeamRole.ADMIN ? UserTeamRole.ORG_ADMIN : _newRole;
      setPeople(people.map(data => (data.user.id === user.id ? { ...data, role: _newRoleToShow } : data)));
      enqueueBasicSnackbar(snackbarMessages.user.roleUpdateSuccess(user, isTeamUsersPage, _newRole, isMultiTeam));
      await renewTeamStat();
    } catch (err) {
      enqueueErrorSnackbar(snackbarMessages.user.roleUpdateError(err as Error));
    } finally {
      closeSnackbar(_loader);
    }
  };

  const handleRemovePerson = async (user: IModifiedUser, actionType: RemovePersonActionType, admin: IUserProfile) => {
    const requestRemoveUser =
      isTeamUsersPage && isMultiTeam
        ? () => removeUserFromTeam(currentOrgId, currentTeamId, [user.id])
        : () => removeUserFromOrganization(currentOrgId, user.id, actionType, admin.id);
    const _loader = enqueueLoadingSnackbar(snackbarMessages.loader.removePersonUpdating);

    try {
      await requestRemoveUser();
      enqueueDeleteSnackbar(snackbarMessages.user.removeTeammate);
      setPeople(people.filter(data => data.user.id !== user.id));
      loadSubscription();
      await loadApplicationStats(TSubscriptionLimitType.enum.user);
      await renewTeamStat();
    } catch (err) {
      enqueueErrorSnackbar(snackbarMessages.user.removePersonError(err as Error));
    } finally {
      closeSnackbar(_loader);
    }
  };

  const handleRemovePersonFromTeam = async (user: IModifiedUser) => {
    const _loader = enqueueLoadingSnackbar(snackbarMessages.loader.removePersonUpdating);

    try {
      await removeUserFromTeam(currentOrgId, currentTeamId, [user.id]);
      await renewTeamStat();
      setPeople(people.filter(data => data.user.id !== user.id));
      loadSubscription();
      await loadApplicationStats(TSubscriptionLimitType.enum.user);
      await renewTeamStat();
    } catch (err) {
      enqueueErrorSnackbar(snackbarMessages.user.removePersonError(err as Error));
    } finally {
      closeSnackbar(_loader);
    }
  };

  const onAfterInviteTeamMate = async () => {
    await fetchPeopleList(false, () => closeSnackbar('loading'));
    loadSubscription();
    await loadApplicationStats(TSubscriptionLimitType.enum.user);
    await renewTeamStat();
  };

  const handleSendInvite = async ({ organizationId, teamIds, role, invitees, billingGroupId }: ISendInviteRequest) => {
    const loader = enqueueLoadingSnackbar(snackbarMessages.loader.invitingTeamMember);
    try {
      const inviteesList = invitees ? stringsUniq(invitees) : [];
      const { invitedUsers, existedUsersCount, invitedUsersCount } = await inviteUser({
        organizationId,
        teamIds,
        role,
        invitees: inviteesList,
        billingGroupId,
      });

      if (existedUsersCount) {
        enqueueErrorSnackbar(snackbarMessages.user.inviteDuplicates(existedUsersCount), {
          key: `existedUsersCount${existedUsersCount}`,
        });
      }

      if (invitedUsersCount) {
        enqueueBasicSnackbar(snackbarMessages.user.inviteSuccess(invitedUsers?.map(u => u.email) as []), {
          key: `invitedUsersCount${invitedUsersCount}`,
        });
      }

      onAfterInviteTeamMate();
    } catch (_err) {
      // TODO: make cast to Backend error
      const err = _err as any;

      if (err?.response?.data?.errors[0]?.description) {
        enqueueErrorSnackbar(snackbarMessages.user.inviteError(err.response.data.errors[0].description));

        return;
      }

      enqueueErrorSnackbar(snackbarMessages.user.inviteError(err.message));
    } finally {
      closeSnackbar(loader);
    }
  };

  const handleAddUserToTeam: IPeopleContext['handleAddUserToTeam'] = async ({ orgId, teamId, role, invitees }) => {
    const _mapOfUserIds = invitees.map(d => d.id);
    const _mapOfNamesOfUsers = invitees.map(d => d.name || d.subText);

    try {
      await addUserToTeam(Number(orgId), Number(teamId), role as any, _mapOfUserIds);
      const _newUsers = invitees.map(d => ({ user: { id: d.id, fullName: d.name, email: d.subText }, role })) as any; // Todo: update type
      setPeople([..._newUsers, ...people]);
      await renewTeamStat();
      enqueueBasicSnackbar(snackbarMessages.user.inviteSuccess(_mapOfNamesOfUsers));
    } catch (err) {
      const message = extractBackendResponseErrorMessage(err);
      enqueueErrorSnackbar(snackbarMessages.user.inviteError(message));
      LOG.error(err);
    }
  };

  const handleResendInvite = async (userId: number) => {
    try {
      await resendInviteLink(currentOrgId, userId);
      const _userObj = people.find(({ user }) => user.id === userId);
      enqueueBasicSnackbar(snackbarMessages.user.resendInviteSuccess(_userObj?.user.email as string));
    } catch (err) {
      LOG.error(err instanceof Error ? err.message : err);
    }
  };

  const handleSeatsNumberChange = useCallback(
    async (seatsNumber: number): Promise<boolean> => {
      let actionSuccess = false;

      try {
        if (subscription?.price?.key) {
          const increaseRes = await increaseSubscriptionSeatsAmount(
            `${appState.organizationId}`,
            subscription?.price?.key,
            seatsNumber,
          );

          if (increaseRes.paymentIntent && increaseRes.paymentRequired) {
            await handleApprovePaymentRequest(increaseRes.paymentIntent);
            await retry<ISubscription>(
              () =>
                new Promise((resolve, reject) => {
                  getCurrentSubscription(`${appState.organizationId}`)
                    .then(updatedSubscription => {
                      if (updatedSubscription.quantity === seatsNumber) {
                        resolve(updatedSubscription);
                      } else {
                        reject();
                      }
                    })
                    .catch(reject);
                }),
              3000,
              3,
            );
          }

          actionSuccess = true;
        }
      } catch (e) {
        LOG.error(e);
      }

      return actionSuccess;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.organizationId, subscription?.price?.key],
  );

  const handleDownloadPeoplesList = useCallback(
    async (filetype: string) => {
      if (!orgId) {
        LOG.debug('Organization id is not defined');

        return;
      }

      try {
        const userStatusFilterQueryParam = searchParams.getAll(peopleFilterType.USER_STATUS);
        const userStatusesValid = userStatusFilterQueryParam.filter((status): status is UserStatusFilterIdentifier =>
          validUserStatuses.includes(status as UserStatusFilterIdentifier),
        );
        const userStatus = userStatusesValid.map(mapStatusQueryParamToRequestParam);

        if (isTeamUsersPage) {
          if (!teamId) {
            LOG.debug('Team id is not defined');

            return;
          }

          if (filetype === DOWNLOAD_FILE_TYPE.CSV) {
            const blob = await downloadTeammatesListAsCsv(orgId, teamId, {
              userStatus,
            });
            downloadBlobAsFilename(blob, `Writer-${appState.team?.name}-users.csv`);
          } else {
            const blob = await downloadTeammatesListAsXlsx(orgId, teamId, {
              userStatus,
            });
            downloadBlobAsFilename(blob, `Writer-${appState.team?.name}-users.xlsx`);
          }
        } else if (filetype === DOWNLOAD_FILE_TYPE.CSV) {
          const blob = await downloadPeoplesListAsCsv(orgId, {
            billingGroupIds: searchParams.getAll(FilterType.BILLING_GROUP),
            userStatus,
          });
          downloadBlobAsFilename(blob, 'Writer-users.csv');
        } else {
          const blob = await downloadPeoplesListAsXlsx(orgId, {
            billingGroupIds: searchParams.getAll(FilterType.BILLING_GROUP),
            userStatus,
          });
          downloadBlobAsFilename(blob, 'Writer-users.xlsx');
        }
      } catch (err) {
        LOG.error(err instanceof Error ? err.message : err);
      }
    },
    [appState.team?.name, isTeamUsersPage, orgId, searchParams, teamId],
  );

  const enqueuePendingUserActionSnackbar = useCallback(
    (err: any) => {
      const errorDescription = err?.response?.data?.errors[0]?.description;

      if (errorDescription.includes('approved')) {
        enqueueBasicSnackbar(snackbarMessages.pendingUserAction.approvedAlready);
      } else if (errorDescription.includes('rejected')) {
        enqueueBasicSnackbar(snackbarMessages.pendingUserAction.rejectedAlready);
      } else {
        enqueueErrorSnackbar(snackbarMessages.error.standard);
      }
    },
    [enqueueBasicSnackbar, enqueueErrorSnackbar],
  );

  const handleApprovePendingUser = useCallback(
    async (pendingUser: IUserProfile) => {
      try {
        if (!isOrganizationAdmin) {
          enqueueErrorSnackbar(snackbarMessages.error.lackOfPermissions);

          return;
        }

        setIsChangeStatusLoading(true);
        await approveUserOrganizationByInviter(appState.organizationId, pendingUser.id);
        enqueueSuccessSnackbar(snackbarMessages.user.userSignupApproved(pendingUser.email ?? ''));
        await fetchPeopleList(false, () => closeSnackbar('loading'));
      } catch (err) {
        enqueuePendingUserActionSnackbar(err);
        LOG.error(err);
      } finally {
        setIsChangeStatusLoading(false);
      }
    },
    [
      appState.organizationId,
      closeSnackbar,
      enqueueErrorSnackbar,
      enqueuePendingUserActionSnackbar,
      enqueueSuccessSnackbar,
      fetchPeopleList,
      isOrganizationAdmin,
    ],
  );

  const handleRejectPendingUser = useCallback(
    async (rejectedUser: IUserProfile) => {
      try {
        if (!isOrganizationAdmin) {
          enqueueErrorSnackbar(snackbarMessages.error.lackOfPermissions);

          return;
        }

        setIsChangeStatusLoading(true);
        await denyUserOrganizationByInviter(appState.organizationId, rejectedUser.id);
        enqueueBasicSnackbar(snackbarMessages.user.userSignupRejected(rejectedUser.email ?? ''));
        await fetchPeopleList(false, () => closeSnackbar('loading'));
      } catch (err: any) {
        enqueuePendingUserActionSnackbar(err);
        LOG.error(err);
      } finally {
        setIsChangeStatusLoading(false);
      }
    },
    [
      appState.organizationId,
      closeSnackbar,
      enqueueBasicSnackbar,
      enqueueErrorSnackbar,
      enqueuePendingUserActionSnackbar,
      fetchPeopleList,
      isOrganizationAdmin,
    ],
  );

  const renewTeamStat = useCallback(async () => {
    try {
      setIsCurrentTeamLoading(true);
      const teams = await getTeams(currentOrgId);
      const currentTeam = teams.find(team => team.id === currentTeamId);
      setTeamsCount(teams.length);

      if (currentTeam) {
        const mappedCurrentTeam = teamDetailsMapper(currentTeam);
        setCurrentTeam(mappedCurrentTeam);
      }
    } catch (err: any) {
      LOG.error(err.message);
    } finally {
      setIsCurrentTeamLoading(false);
      setIsLoading(false);
    }
  }, [currentOrgId, currentTeamId]);

  const editBillingGroup = async ({
    billingGroupId,
    userIds,
  }: {
    billingGroupId: number;
    userIds?: number[] | null;
  }) => {
    const person = people.find(person => person.user.id === userIds?.[0]);
    const billingGroup = billingGroups.find(group => group.id === billingGroupId);

    try {
      await updateillingGroup({
        orgId: currentOrgId,
        billingGroupId,
        userIds,
      });

      const successText = billingGroup
        ? `${person?.user.email} has been assigned to ${billingGroup?.name}`
        : `${person?.user.email} has been removed from billing group ${person?.billingGroup?.name}`;
      enqueueSuccessSnackbar(successText);

      await fetchPeopleList(false, () => closeSnackbar('loading'));
      loadSubscription();
      await loadApplicationStats(TSubscriptionLimitType.enum.user);
      await renewTeamStat();
    } catch (err) {
      const errorText = billingGroup
        ? `Error assigning ${person?.user.email} to ${billingGroup?.name}`
        : `Error removing ${person?.user.email} from billing group ${person?.billingGroup?.name}`;
      enqueueErrorSnackbar(errorText);
      LOG.error(err);
    }
  };

  const handleNewUserAction = useCallback(
    async (userId: string, userAction: OrgJoinNewUserStatus) => {
      const isActionValid = isUserActionQueryParamValid(userAction);

      if (isChangeStatusLoading || !isActionValid) {
        return;
      }

      const { totalCount, result } = await findUserInOrganization(currentOrgId, { [SearchQueryParam.search]: userId });

      // In case people list is empty it means that user has been rejected by someone else
      if (totalCount) {
        const person = result.find(person => person.user.id === +userId);

        if (!person) {
          return;
        }

        // in case person has status approvedByInviter it means that user has been approved by someone else
        if (person?.approvedByInviter) {
          enqueueBasicSnackbar(snackbarMessages.pendingUserAction.approvedAlready);
          afterNewUserAction();

          return;
        }

        try {
          if (userAction === OrgJoinNewUserStatus.APPROVE) {
            // @ts-expect-error TS doesn't know that person is not undefined
            await handleApprovePendingUser(person.user);
          } else if (userAction === OrgJoinNewUserStatus.DENY) {
            // @ts-expect-error TS doesn't know that person is not undefined
            await handleRejectPendingUser(person.user);
          }
        } catch (error) {
          LOG.error(error);
        } finally {
          afterNewUserAction();
        }
      } else {
        enqueueBasicSnackbar(snackbarMessages.pendingUserAction.rejectedAlready);
        afterNewUserAction();
      }
    },
    [
      afterNewUserAction,
      currentOrgId,
      enqueueBasicSnackbar,
      handleApprovePendingUser,
      handleRejectPendingUser,
      isChangeStatusLoading,
    ],
  );

  const fetchOrganizationAdmins = useCallback(async () => {
    if (!isModelReady || isFree) {
      return;
    }

    try {
      const organizationAdmins = await getOrganizationAdmins(currentOrgId);
      setOrganizationAdmins(organizationAdmins);
      await renewTeamStat();
    } catch (err) {
      LOG.error(err);
    }
  }, [isModelReady, isFree, currentOrgId, renewTeamStat]);

  useEffect(() => {
    fetchOrganizationAdmins();
  }, [fetchOrganizationAdmins]);

  useEffect(() => {
    if (!isModelReady || isFree) {
      return;
    }

    resetPage();
    fetchPeopleList(false, () => closeSnackbar('loading'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId, isModelReady, isFree]);

  // get billing groups
  useEffect(() => {
    fetchBillingGroups();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PeopleContext.Provider
      value={{
        people,
        organizationAdmins,
        orgId: currentOrgId,
        teamId: currentTeamId,
        isTeamUsersPage,
        currentTeam,
        isLoading,
        isCurrentTeamLoading,
        handleSendInvite,
        handleAddUserToTeam,
        handleSearch,
        searchValue: search,
        handleMakeAdmin,
        handleRemovePerson,
        handleRemovePersonFromTeam,
        handleResendInvite,
        handleSeatsNumberChange,
        infiniteRefSentry: sentryRef,
        teamsCount,
        handlePeopleSortingChange,
        peopleSortField: searchParams.get(SearchQueryParam.sortField) as SortFieldTypes,
        peopleSortOrder: searchParams.get(SearchQueryParam.sortOrder) as SortOrders,
        handleDownloadPeoplesList,
        handleApprovePendingUser,
        handleRejectPendingUser,
        handleUserFilterChange,
        handleBillingGroupFilterChange,
        handleResetFilters,
        filtersCount,
        fetchPeopleList,
        billingGroups,
        onAfterInviteTeamMate,
        editBillingGroup,
        billingGroupFilterVal: searchParams.getAll(FilterType.BILLING_GROUP),
        userFilterVal: searchParams.getAll(peopleFilterType.USER_STATUS),
      }}
    >
      {children}
    </PeopleContext.Provider>
  );
});

export function usePeopleContext() {
  const context = useContext(PeopleContext);

  if (!context) {
    throw new Error('usePeopleContext must be used within the PeopleContextProvider');
  }

  return context;
}

export default PeopleContextProvider;
