import { AnalyticsService } from '@writercolab/analytics';
import { TOrgTeamUserActivityParams } from '@writercolab/types';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';

import { AnalyticsActivity, TWebAnalyticsParams } from 'constants/analytics';
import { openContactSalesPage } from 'utils/navigationUtils';

import styles from './BillingLockState.module.css';

export const BillingLockState = ({
  analyticsService,
}: {
  analyticsService: AnalyticsService<TWebAnalyticsParams, TOrgTeamUserActivityParams>;
}) => {
  const handleContactSalesClick = () => {
    analyticsService.track(AnalyticsActivity.clickedContactSales, {
      clicked_from: 'reporting_page',
    });

    openContactSalesPage();
  };

  return (
    <div className={styles.lockWrapper}>
      <BillingLock type={BillingLockType.REPORTING} onActionCallback={handleContactSalesClick} />
    </div>
  );
};
