import aiStudioBanner from '@writercolab/assets/static/backgrounds/aiStudio.png';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Modal,
  Text,
  TextSize,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';

import { getDiscountFromURL as getDiscount } from '@web/utils';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { TAppModals } from 'models/app';
import { useNavigate, useSearchParams } from 'react-router';
import { ROUTE } from 'services/config/routes';
import { useAppState } from 'state';

import styles from './styles.module.css';

export const JoinAiStudioAppModal: React.FC<{ organizationId?: number }> = observer(({ organizationId }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    appState: { userProfile },
    appModel: {
      modalsManager,
      optInIntoAiStudio,
      refreshPermissions,
      isOptingIntoAiStudio,
      analyticsService,
      assistantSubscription,
    },
  } = useAppState();

  const { enqueueErrorSnackbar } = useCustomSnackbar();

  const onTryAiStudio = async () => {
    try {
      if (!userProfile) {
        return;
      }

      await optInIntoAiStudio();
      await refreshPermissions();
      modalsManager.hideModal(TAppModals.enum.aiStudioOptIn);

      analyticsService.track(AnalyticsActivity.aiStudioOrgAdminOptedIntoAIStudio, {
        plan_name: String(assistantSubscription.productName),
      });
      navigate(ROUTE.toAiStudioHome(`${organizationId}`));
    } catch {
      enqueueErrorSnackbar('Failed to opt-in into AI Studio');
    }
  };

  return (
    <Modal
      open={modalsManager.isModalVisible(TAppModals.enum.aiStudioOptIn)}
      style={{ width: '720px' }}
      handleClose={() => modalsManager.hideModal(TAppModals.enum.aiStudioOptIn)}
    >
      <div className={styles.aiStudioContainer}>
        <Heading variant={HeadingVariant.H3} medium>
          Introducing Writer AI Studio: the fastest way to build AI agents
        </Heading>
        <img className={styles.aiStudioBanner} src={aiStudioBanner} alt="ai studio banner" />
        <Text className={styles.aiStudioText} variant={TextSize.XL}>
          We&apos;re excited to introduce Writer AI Studio, a suite of new development tools that enable anyone —
          regardless of their technical skills — to quickly build AI agents and workflows.
        </Text>
        <Text variant={TextSize.XL}>
          Start building today with <b>{getDiscount(params, userProfile?.createdAt)} in free credits</b>.
        </Text>
        <div className={styles.aiStudioButtons}>
          <Button
            type={ButtonTypes.BORDERED}
            onClick={() => modalsManager.hideModal(TAppModals.enum.aiStudioOptIn)}
            content="Return to Writer app"
          />

          <Button
            type={ButtonTypes.PRIMARY}
            onClick={onTryAiStudio}
            isLoading={isOptingIntoAiStudio.value}
            content="Try AI Studio"
          />
        </div>
      </div>
    </Modal>
  );
});
