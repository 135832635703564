import type React from 'react';

import { Button } from '@writercolab/fe.wds';

import { cn } from '@web/utils';
import { ArrowRight } from 'lucide-react';

interface IIconLinkProps {
  className?: string;
  onClick?: () => void;
  text: string | React.ReactNode;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  ariaLabel?: string;
}

export const IconLink: React.FC<IIconLinkProps> = ({
  className,
  onClick,
  text,
  icon = <ArrowRight />,
  iconPosition = 'right',
  ariaLabel,
}) => {
  return (
    <Button
      className={cn('text-sm text-gray-500 !px-0', className)}
      color="secondary"
      variant="link"
      rightIcon={iconPosition === 'right' ? icon : undefined}
      leftIcon={iconPosition === 'left' ? icon : undefined}
      onClick={onClick}
      aria-label={ariaLabel ?? (typeof text === 'string' ? text : undefined)}
    >
      {text}
    </Button>
  );
};
