import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import type { TOrgTeamUserActivityParams } from '@writercolab/common-utils';
import {
  BillingProduct,
  SUGGESTION_LINK_MAX_LENGTH,
  convertToEllipsis,
  isElementInViewport,
  openNewTab,
  preventEventDefault,
  stopEventPropagation,
  wordPluralize,
} from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  DotLoader,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SearchBar,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router';

import { useMagicLinksContext } from '../../../context/magicLinksContext';
import { ROUTE } from '../../../services/config/routes';
import CustomLinkUrl from './parts/CustomLinkUrl';
import EmptyLink from './parts/EmptyLink';
import { LinkSuggestionContent } from './parts/LinkSuggestionContent';

import styles from './styles.module.css';

interface IMagicLinksProps {
  amount: number;
  selectedText: string;
  isAdmin: boolean;
  doSearch: boolean;
  isMagicLinksMode: boolean;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  onClose: () => void;
  onTextUpdate: (text: string) => void;
  updateAmountOfDomains: (amount: number) => void;
  onLoading: (isLoading: boolean) => void;
  resetMagicLinksData: () => void;
}

const MagicLinksSuggestions = ({
  amount,
  selectedText,
  analyticsService,
  onLoading,
  onClose,
  isAdmin,
  onTextUpdate,
  doSearch,
  isMagicLinksMode,
  resetMagicLinksData,
  updateAmountOfDomains,
}: IMagicLinksProps) => {
  const suggestionsListRef: React.MutableRefObject<any> = useRef([]);
  const searchRef: React.MutableRefObject<any> = useRef(null);
  const { orgId, teamId } = useParams<Record<string, string>>();

  const {
    state,
    isTeamAdmin,
    subscriptionisLoaded,
    updateParams,
    clearLinks,
    onFetchSuggestions,
    updateCustomUrl,
    onFetchMagicLinksDomains,
    onLinkSelected,
    featureEnabled,
  } = useMagicLinksContext();
  const { isLoading, magicLinksList, params, customUrl, amountOfMagicDomains, isDomainsLoading } = state;

  // This is zero state management
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [searchInput, setSearchInput] = useState(params.text);
  const [showCustomUrl, setShowCustomUrl] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('Apply URL');

  const access = {
    isBlocked: !featureEnabled && subscriptionisLoaded && !isDomainsLoading,
    isWithoutDomains: amountOfMagicDomains === 0,
    hasMagicLinks: !isEmpty(magicLinksList.result),
    emptyLinksLoaded: !isLoading && !isEmpty(params.text) && isEmpty(magicLinksList.result),
  };

  const onLinkApply = (url: string) => {
    if (!isEmpty(selectedText)) {
      onLinkSelected(url);
      setFirstLoaded(false);
      clearLinks();

      const analyticsType = AnalyticsActivity.magicLinksClickedSuggestions;

      analyticsService.track(analyticsType, {
        clicked_from: 'magic-links',
      });
    }
  };

  const onAddDomain = () => {
    navigateToSuggestionsMagicLinks();
  };

  // eslint-disable-next-line no-return-assign, no-param-reassign
  const containerDivRef = (el: HTMLDivElement | null, index: number) => (suggestionsListRef.current[index] = el);

  const handleScroll = () => {
    const element = suggestionsListRef.current[magicLinksList.result.length - 2];
    const isCappedOffset = params.offset + params.limit > 100;
    let canLoad = false;

    // If 3rd last element is in the view - fetch suggestions
    if (element && isElementInViewport(element)) {
      canLoad = true;
    }

    // If element was at least once in the view means trigger fetch and reset the flag
    if (canLoad && !isCappedOffset) {
      canLoad = false;
      updateParams({ ...params, offset: params.offset + params.limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // This search is updating searchValue and send it to amgic links inside editor
  const handleSearch = (searchText: string) => {
    setSearchInput(searchText);
    delayedQuery(searchText);
    clearLinks();
  };

  const handleBillingClick = useCallback(() => {
    const analyticsType = AnalyticsActivity.magicLinksContactSales;

    analyticsService.track(analyticsType, {
      clicked_from: 'magic-links',
    });

    openNewTab(ROUTE.toBilling(orgId, BillingProduct.STARTER));
  }, [analyticsService, orgId]);

  const navigateToSuggestionsMagicLinks = () => {
    openNewTab(window.location.origin + ROUTE.toSuggestionsMagicLinks(`${orgId}`, `${teamId}`));
  };

  const onCogClicked = () => {
    navigateToSuggestionsMagicLinks();
  };

  const handleLink = (e: React.MouseEvent, url: string) => {
    stopEventPropagation(e);
    preventEventDefault(e);
    openNewTab(url);
  };

  const onSupportClicked = () => {
    openNewTab('https://support.writer.com/article/196-get-started-with-magic-links');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedScrollQuery = useCallback(
    debounce(() => {
      handleScroll();
    }, 50),
    [magicLinksList],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((value: string) => {
      onTextUpdate(value);
    }, 500),
    [],
  );

  const onCustomLinkApply = (customUrl: string) => {
    const analyticsType = AnalyticsActivity.magicLinksAddedCustomURL;

    analyticsService.track(analyticsType, {
      clicked_from: 'magic-links',
    });

    onLinkApply(customUrl);
    updateCustomUrl('');
  };

  const onCloseHandler = () => {
    updateCustomUrl('');
    onClose();
  };

  useEffect(() => {
    onLoading(isLoading);
  }, [onLoading, isLoading]);

  useEffect(() => {
    const hasLeftLinks = magicLinksList.totalCount > magicLinksList.result.length;

    if (hasLeftLinks) {
      onFetchSuggestions(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.offset]);

  // This is actual fetching handler
  useEffect(() => {
    setFirstLoaded(false);

    if (!isEmpty(params.text)) {
      setShowCustomUrl(false);
      onFetchSuggestions();
      setFirstLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.text]);

  useEffect(() => {
    clearLinks();
    setSearchInput(selectedText);

    // When magicLinksIcon clicked menas do search
    if (doSearch) {
      updateParams({ ...params, offset: 1, text: selectedText.toLowerCase() });
      const analyticsType = AnalyticsActivity.magicLinksSearchedKeyword;
      analyticsService.track(analyticsType, {
        clicked_from: 'magic-links',
      });
    }

    // If no result found means set to zero stand
    if (amount === 0) {
      setFirstLoaded(false);
    }

    searchRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedText, doSearch]);

  useEffect(() => {
    setShowCustomUrl(firstLoaded && access.emptyLinksLoaded);
  }, [firstLoaded, access.emptyLinksLoaded]);

  useEffect(() => {
    onFetchMagicLinksDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureEnabled]);

  useEffect(() => {
    setFirstLoaded(false);
    clearLinks();
    updateCustomUrl('');
    resetMagicLinksData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMagicLinksMode]);

  useEffect(() => {
    updateAmountOfDomains(amountOfMagicDomains);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountOfMagicDomains]);

  if (!subscriptionisLoaded || isDomainsLoading) {
    return (
      <div className={cx(styles.wrapper)}>
        <DotLoader className={styles.loader} />
      </div>
    );
  }

  if (access.isBlocked) {
    return (
      <div className={cx(styles.wrapper, styles.blocked)}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <Heading variant={HeadingVariant.H2}>Magic links</Heading>
          </div>
          <Tooltip title="Close" placement="top">
            <Button
              className={cx(styles.closeButton, styles.other)}
              size={SizeTypes.SMALL}
              type={ButtonTypes.GRAY}
              onClick={onCloseHandler}
            >
              <Icon name={IconVariant.CLOSE} />
            </Button>
          </Tooltip>
        </div>
        <div className={styles.premium}>
          <div>
            <Icon name={IconVariant.LOCK_LINE} width={16} height={16} />
            <Text variant={TextSize.XXXXL} caps>
              Starter feature
            </Text>
          </div>
          <Button
            className={styles.entepriseButton}
            type={ButtonTypes.PRIMARY}
            icon={<Icon name={IconVariant.PREMIUM} className={styles.button} />}
            content="Upgrade now"
            onClick={handleBillingClick}
          />
        </div>
        <div className={cx(styles.noDomains, styles.blocked)}>
          <Text variant={TextSize.XL} color={TextColor.BLACK} bold>
            Get automatic links for your content
          </Text>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            With magic links, Writer will match keywords in your doc to suggested links from your preferred domains.
          </Text>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            Available on Team and Enterprise plans.
          </Text>
        </div>

        <div className={styles.blockedBackground} onClick={() => openNewTab('https://writer.com/pricing')} />
      </div>
    );
  }

  if (access.isWithoutDomains) {
    return (
      <div className={cx(styles.wrapper, styles.noDomains)}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <Heading variant={HeadingVariant.H2}>Magic links</Heading>
          </div>

          <Tooltip title="Close" placement="top">
            <Button
              className={cx(styles.closeButton, styles.other)}
              size={SizeTypes.SMALL}
              type={ButtonTypes.GRAY}
              onClick={onCloseHandler}
            >
              <Icon name={IconVariant.CLOSE} />
            </Button>
          </Tooltip>
        </div>

        <div className={styles.noDomainsBackground} />

        <div className={styles.noDomains}>
          <Text variant={TextSize.XL} color={TextColor.BLACK} bold>
            Get automatic links for your content
          </Text>

          <Text variant={TextSize.XL} color={TextColor.GREY}>
            Writer will crawl selected domains and suggest previously published content to link your new content to.
          </Text>

          <Text variant={TextSize.XL} color={TextColor.GREY}>
            {isTeamAdmin ? 'To get started, add a domain in Setup.' : 'Contact your Writer admin to get set up.'}
          </Text>
        </div>

        {isTeamAdmin && (
          <Button
            className={styles.addDomainButton}
            size={SizeTypes.SMALL}
            type={ButtonTypes.PRIMARY}
            onClick={onAddDomain}
            content="Add domain"
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.wrapper} onScroll={delayedScrollQuery}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <Heading variant={HeadingVariant.H2}>Magic links</Heading>
          <span className={styles.supportIcon} onClick={onSupportClicked}>
            <Icon name={IconVariant.LIFEBUOY} />
          </span>
        </div>

        <div className={styles.buttonHeaderGroup}>
          {isAdmin && (
            <Tooltip title="Settings" placement="top">
              <Button
                className={styles.cogButton}
                size={SizeTypes.SMALL}
                type={ButtonTypes.GRAY}
                onClick={onCogClicked}
              >
                <Icon name={IconVariant.SETTINGS} />
              </Button>
            </Tooltip>
          )}

          <Tooltip title="Close" placement="top">
            <Button
              className={styles.closeButton}
              size={SizeTypes.SMALL}
              type={ButtonTypes.GRAY}
              onClick={onCloseHandler}
            >
              <Icon name={IconVariant.CLOSE} />
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className={styles.content}>
        <Heading variant={HeadingVariant.H3} bold>
          Get started
        </Heading>

        <Text variant={TextSize.XL}>
          <span className={styles.highlight}>Highlight</span> keywords where you’d like to add links. Then, select
          <span className={styles.linkButton}>
            <Icon name={IconVariant.LINK} width={18} height={18} />
            Find links
          </span>{' '}
          <br />
          <div className={styles.manually}>Or, manually search below.</div>
        </Text>

        <div className={styles.searchContainer}>
          <SearchBar
            ref={searchRef}
            hideClear
            value={searchInput}
            placeholder="Search for a keyword"
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleSearch(e.target.value)}
          />

          <Text variant={TextSize.XL} color={TextColor.GREY2} className={styles.foundText}>
            {amount >= 0 ? `Found ${amount} ${wordPluralize(amount, 'time')} in your doc` : ' '}
          </Text>
        </div>

        <div className={styles.linksContainer}>
          <Heading
            className={cx(styles.header, { [styles.isLoading]: isLoading || !firstLoaded })}
            variant={HeadingVariant.H6}
          >
            <Icon name={IconVariant.LINK} className={styles.linksContainerIcon} />
            Suggested links
          </Heading>

          {!isLoading && firstLoaded && amount > 0 && (
            <div className={styles.subtitle}>
              <Text variant={TextSize.XL} className={styles.subtitleDescriptionText}>
                Hyperlink your keyword with
                <br /> one of the options below
              </Text>
              <Button
                className={styles.addButton}
                size={SizeTypes.SMALL}
                type={ButtonTypes.GRAY}
                onClick={() => setShowCustomUrl(!showCustomUrl)}
              >
                <Text variant={TextSize.S} className={styles.subtitleButtonText} extraSmallCaps>
                  {showCustomUrl ? 'Hide' : 'Add'} custom url
                </Text>
              </Button>
            </div>
          )}

          {showCustomUrl && (
            <CustomLinkUrl
              customUrl={customUrl}
              updateCustomUrl={updateCustomUrl}
              onLinkApply={() => onCustomLinkApply(customUrl)}
            />
          )}

          {access.hasMagicLinks &&
            magicLinksList.result.map((suggestion, i) => (
              <div
                key={i}
                className={cx(styles.linkWrapper, styles.blackLink)}
                ref={el => containerDivRef(el, i)}
                onClick={() => onLinkApply(suggestion.link)}
              >
                <Icon name={IconVariant.ARROW_RIGHT_ALT} width={25} height={25} className={styles.linkWrapperArrow} />
                <Tooltip title={tooltipTitle} placement="top" tooltipWidth={86}>
                  <div className={styles.link}>
                    <Text variant={TextSize.XL} medium className={styles.title}>
                      {suggestion.title}
                    </Text>
                    <LinkSuggestionContent linkSuggestion={suggestion} />
                    <Text
                      variant={TextSize.XS}
                      className={styles.url}
                      onClick={(event: React.MouseEvent) => handleLink(event, suggestion.link)}
                      onMouseEnter={() => setTooltipTitle('Go to URL')}
                      onMouseLeave={() => setTooltipTitle('Apply URL')}
                    >
                      {convertToEllipsis(suggestion.link, SUGGESTION_LINK_MAX_LENGTH)}
                    </Text>

                    {/* <ul className={styles.bullets}>
                      <div className={styles.listItem}>
                        <span className={styles.listMark}>&#8226;</span>
                        <Text variant={TextSize.XS}>
                          Found in <span className={styles.bold}>{suggestion.pages.length} other pages</span>
                        </Text>
                      </div>
                    </ul> */}
                  </div>
                </Tooltip>
              </div>
            ))}

          {firstLoaded && access.emptyLinksLoaded && (
            <div className={styles.linkWrapper}>
              <Icon name={IconVariant.ARROW_RIGHT_ALT} width={20} height={20} />

              <div className={cx(styles.link, styles.empty, styles.noResult)}>
                <Text variant={TextSize.XL}>Shucks, we didn’t find any URLs for this keyword</Text>
              </div>
            </div>
          )}

          {(!firstLoaded || isLoading) && (
            <>
              {amount === 0 ? (
                <div className={cx(styles.link, styles.empty, styles.noResult, styles.zeroAmount)}>
                  <Text>
                    <span>Your keyword wasn't found in this doc.</span> <br />{' '}
                    <span>Try searching for something else.</span>
                  </Text>
                </div>
              ) : (
                <EmptyLink isLoading={isLoading} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MagicLinksSuggestions;
