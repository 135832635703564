import React from 'react';

import cx from 'classnames';

import {
  Button,
  Heading,
  BaseSelect,
  SelectTrigger,
  SelectGroup,
  SelectContent,
  SelectValue,
  SelectItem,
} from '@writercolab/fe.wds';

import trialEnded from '../../../assets/backgrounds/trial/trialEnded.svg';

import styles from './TrialEndedBanner.module.css';
import type { IOrganization } from '@writercolab/common-utils';

interface ITrialEndedBannerProps {
  className?: string;
  onContactSalesClick?: () => void;
  onUpgradeClick?: () => void;
  organizations?: IOrganization[];
  organizationSelected?: number;
  onSwitchOrganizationsClick?: (orgId: string) => void;
}

export const TrialEndedBanner: React.FC<ITrialEndedBannerProps> = ({
  className,
  onContactSalesClick,
  onUpgradeClick,
  organizations,
  onSwitchOrganizationsClick,
  organizationSelected,
}) => {
  const organization = organizations?.find(organization => organization.id === organizationSelected);
  const showOrgSelector = organizations && organizations.length > 1;

  return (
    <div className={cx(className, styles.container)}>
      <div className={styles.headingContainer}>
        <Heading className={cx('text-center', 'text-gray-600')} size="h2">
          Your 14-day trial has ended
        </Heading>
      </div>
      <div className={styles.bannerContainer}>
        <img src={trialEnded} alt="trial banner" width={455} />
      </div>
      <div className={cx('text-center', 'text-sm', 'text-[14px]')}>
        To keep using Writer, upgrade to <span className="font-[600]">Starter plan</span> today
      </div>
      <div className={cx(styles.actionContainer, 'text-center')}>
        <Button onClick={onUpgradeClick} className="font-[500]">
          Upgrade now
        </Button>
      </div>
      {onContactSalesClick && (
        <div className={cx('text-center', 'text-[12px]')}>
          Questions?{' '}
          <Button onClick={onContactSalesClick} variant="link" className={'text-[12px]'}>
            Contact sales
          </Button>
        </div>
      )}
      {showOrgSelector && (
        <div className={styles.organizationSwitcher}>
          <div className={styles.organizationSwitcherTitle}>Change org</div>
          <div className={styles.organizationSwitcherDropdown}>
            <BaseSelect value={String(organization?.id)} onValueChange={onSwitchOrganizationsClick}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={organization?.name} />
              </SelectTrigger>
              <SelectContent className={styles.selectContent}>
                <SelectGroup>
                  {organizations?.map(organization => (
                    <SelectItem key={organization.id} value={`${organization.id}`} className={styles.selectOption}>
                      {organization.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </BaseSelect>
          </div>
        </div>
      )}
    </div>
  );
};
