import type { components } from '@writercolab/network';
import { IconsConsoleResearchAssistant } from '@writercolab/assets';
import type {
  TAppDropdownInput,
  TAppFileUploadInput,
  TAppInput,
  TAppInputOptions,
  TApplicationBrief,
  TApplicationBriefPaginatedResponse,
  TApplicationBriefResponse,
  TApplicationExpand,
  TApplicationExportResponse,
  TApplicationInputType,
  TApplicationStatus,
  TApplicationStatuses,
  TApplicationType,
  TAppMediaUploadInput,
  TAppTextInput,
  TBaseApplicationType,
  TConsoleApplication,
  TConsoleApplicationVersion,
  TConsoleApplicationVersionData,
  TConsoleChatApplication,
  TConsoleChatApplicationData,
  TConsoleGenerationApplication,
  TConsoleGenerationApplicationData,
  TConsoleResearchApplication,
  TDevApplicationType,
  TPaginatedConsoleExtraArgs,
} from '@writercolab/types';
import {
  EApplicationType,
  EDevApplicationType,
  TApplicationSortField,
  TApplicationSortOrder,
  TApplicationState,
  TConsoleApplicationInputType,
} from '@writercolab/types';
import { IconVariant } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

export const APPLICATION_NAME_MAX_LENGTH = 35;
export const FILTER_USER_EMAIL_MAX_LENGTH = 20;
export const SUPPORTED_CONSOLE_APPLICATIONS_IMPORT_EXT = ['json'];

export type TConsoleGenerationPrompt = components['schemas']['template_model_ApplicationData_ApplicationPrompt'];
export type TConsoleOutputFormatting = components['schemas']['template_model_Output'];
export type TConsoleLlmSettings = components['schemas']['template_model_ModelSettings'];

export const TConsoleApplicationAccessType = new Enum('public', 'private-organization', 'private-team');
export const TConsoleApplicationState = new Enum('deployed', 'draft');
export const TConsoleAppTableAction = new Enum(
  'edit',
  'duplicate',
  'share',
  'undeploy',
  'delete',
  'protect',
  'view',
  'draftNewVersion',
  'editMetadata',
  'editDraft',
  'export',
  'playground',
);

export interface TPaginatedConsoleArgs {
  limit: number;
  offset: number;
}

export interface TPaginatedConsoleQuery extends TPaginatedConsoleArgs, TPaginatedConsoleExtraArgs {}

export const noCodeApps = {
  generation: {
    name: 'Text generation',
    description: 'Build an agent that collects specific user inputs and generates text',
    icon: IconVariant.CONSOLE_TEXT_GENERATION,
    preview: false,
  },
  chat: {
    name: 'Chat',
    description: 'Construct an agent with a specific persona or skills, and connect it to your data sources',
    icon: IconVariant.CONSOLE_CHAT,
    preview: false,
  },
  research: {
    name: 'Research assistant',
    description: 'Create an agent to conduct tailored research using agents',
    icon: <IconsConsoleResearchAssistant />,
    preview: false,
  },
};

export const devApps = {
  agent: {
    name: 'Agent editor',
    description: 'Build a generative AI data agent with Writer’s low code, Python framework',
    icon: IconVariant.FRAMEWORK_APP,
    preview: false,
  },
  framework: {
    name: 'Framework',
    description: 'Build a generative AI data agent with Writer’s low-code, Python framework',
    icon: IconVariant.FRAMEWORK_APP,
    preview: false,
  },
  api: {
    name: 'API',
    description: 'Generate keys and build your agent with calls to Writer’s API',
    icon: IconVariant.API_APP,
    preview: false,
  },
  db: {
    name: 'Chat with database',
    description: '',
    icon: IconVariant.CHAT_WITH_DB,
    preview: true,
  },
};

export const isResearchApplication = (application?: TConsoleApplication): application is TConsoleResearchApplication =>
  application?.type === EApplicationType.enum.research;

export const isChatApplication = (application?: TConsoleApplication): application is TConsoleChatApplication =>
  application?.type === EApplicationType.enum.chat;

export const isGenerationApplication = (
  application?: TConsoleApplication,
): application is TConsoleGenerationApplication => application?.type === EApplicationType.enum.generation;

export const TConsoleApplicationEmbedLayout = new Enum('centered', 'widget');

export const ProtectedOptions = new Enum('true', 'false');

export const inputTypesDropdownSource = [
  {
    id: TConsoleApplicationInputType.enum.text,
    name: 'Text input',
  },
  {
    id: TConsoleApplicationInputType.enum.dropdown,
    name: 'Dropdown',
  },
  {
    id: TConsoleApplicationInputType.enum.file,
    name: 'File upload',
  },
  {
    id: TConsoleApplicationInputType.enum.media,
    name: 'Image input',
  },
];

export const ApplicationTypeToEmbedType = EApplicationType.hash({
  chat: 'chat',
  generation: 'text-generation',
  framework: 'framework',
  research: 'research-assistant',
});

export type TConsoleApplicationData = components['schemas']['template_model_ApplicationData'];
export type TConsoleApplicationMetaData = components['schemas']['template_model_ApplicationVersionUpdate'];
export type TFrameworkApplication = components['schemas']['template_model_ApplicationData_FrameworkApplicationData'];
export type TApplicationVersionCreate = components['schemas']['template_model_ApplicationVersionCreate'];
export type TExportApplicationResponse = components['schemas']['template_dto_ExportApplicationResponse'];

export {
  TConsoleApplicationInputType,
  EApplicationType,
  EDevApplicationType,
  TApplicationState,
  TApplicationSortField,
  TApplicationSortOrder,
};

export type {
  TAppInputOptions,
  TApplicationInputType,
  TAppInput,
  TAppTextInput,
  TAppDropdownInput,
  TAppFileUploadInput,
  TAppMediaUploadInput,
  TConsoleChatApplicationData,
  TConsoleGenerationApplicationData,
  TConsoleGenerationApplication,
  TConsoleChatApplication,
  TConsoleApplication,
  TConsoleApplicationVersion,
  TConsoleApplicationVersionData,
  TApplicationBriefPaginatedResponse,
  TApplicationStatus,
  TApplicationBrief,
  TApplicationBriefResponse,
  TApplicationExpand,
  TApplicationExportResponse,
  TBaseApplicationType,
  TDevApplicationType,
  TPaginatedConsoleExtraArgs,
  TApplicationType,
  TApplicationStatuses,
};

export const TAppColorStatus = new Enum('green', 'gray');

export const appStatusTextMap = TAppColorStatus.hash({
  [TAppColorStatus.enum.gray]: 'Draft',
  [TAppColorStatus.enum.green]: 'Deployed',
});

export const appTypeColorTagMap = EApplicationType.hash({
  chat: '#FFE3FA',
  generation: '#E4E9FF',
  framework: '#B5EEEE',
  research: '#FFD9D9',
});
