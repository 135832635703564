import type React from 'react';
import { useRef } from 'react';

import cx from 'classnames';

import { openNewTab } from '@writercolab/dom';
import {
  DotLoader,
  Heading,
  HeadingVariant,
  IconButton,
  IconVariant,
  Modal,
  Text,
  TextSize,
  Tooltip,
  useQueueWorkerNotifications,
} from '@writercolab/ui-atoms';
import { GenerationApplication } from '@writercolab/ui-generation-apps';

import { DraftFeedback } from '@web/component-library';
import type { TBaseApplicationType, TTemplateDraft } from '@web/types';
import { EApplicationType } from '@web/types';
import { useDraftsContext } from 'context/draftsContext';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import { ROUTE } from 'services/config/routes';
import { useAppState } from 'state';

import { AnalyticsActivity } from '../../../constants/analytics';
import { getLogger } from '../../../utils/logger';
import TemplateQuotaBanner, { QUOTA_BANNER_TYPE } from '../../molecules/TemplateQuotaBanner/TemplateQuotaBanner';
import EditDraftPanel from '../../organisms/EditDraftPanel';
import { DraftCard } from '../../organisms/Template/DraftCard';
import type { EditorApplicationUIModel } from './EditorApplicationModel.ui';
import { EditorApplicationModalType } from './EditorApplicationModel.ui';

import styles from './EditorApplication.module.css';

export interface EditorApplicationProps {
  className?: string;
  model: EditorApplicationUIModel;
  isTeamAdmin: boolean;
  onPromptLibraryClick: (() => void) | null;
  onSupportClick?: () => void;
  onContactSales?: () => void;
  onAllTemplatesClick?: () => void;
  onCloseClick?: () => void;
  onDraftCreated?: (draftAdded: TTemplateDraft, draftsList: TTemplateDraft[]) => void;
  onBeforeDraftCreate?: () => Promise<void>;
}

const SUPPORTED_APPS: TBaseApplicationType[] = [EApplicationType.enum.generation];

const LOG = getLogger('EditorApplication');
export const EditorApplication: React.FC<EditorApplicationProps> = observer(
  ({
    className,
    model,
    isTeamAdmin,
    onPromptLibraryClick,
    onSupportClick,
    onContactSales,
    onAllTemplatesClick,
    onCloseClick,
    onDraftCreated,
    onBeforeDraftCreate,
  }) => {
    const {
      appModel: { organizationId, teamId, featureFlags, analyticsService },
    } = useAppState();
    const { updateDraftId } = useDraftsContext();
    const draftsHeaderRef = useRef(null);

    useQueueWorkerNotifications(model.notificationQueue);
    model.previewAutoSaver.use();

    const { isGenerationDisabled, generationDisabledMessage, coWriteLimits } = model.generationLimits;

    if (!model.$application.value) {
      return (
        <div className={styles.loading}>
          <DotLoader />
        </div>
      );
    }

    if (model.$application.value && !SUPPORTED_APPS.includes(model.$application.value.type)) {
      return (
        <div className={styles.loading}>
          <Text>App is not supported yet</Text>
        </div>
      );
    }

    const onGenerateDraft = async () => {
      try {
        if (model.applicationPreviewModel?.isValid) {
          analyticsService.track(AnalyticsActivity.appGenerated, {
            app_id: model.applicationId ?? '',
            app_name: model.applicationName ?? '',
            app_type: model.applicationData.type ?? '',
            built_by_writer: model.applicationAccess === 'public',
          });

          await onBeforeDraftCreate?.();

          const voiceId = model.applicationPreviewModel.voiceDropdownModel.isVisible
            ? model.applicationPreviewModel.voiceDropdownModel.value
            : undefined;

          const reWritePromptId = model.applicationPreviewModel.rewriteDropdownModel.isVisible
            ? model.applicationPreviewModel.rewriteDropdownModel.value
            : undefined;

          const draft = await model.generateDraft(model.applicationPreviewModel.values, voiceId, reWritePromptId);

          draft && onDraftCreated?.(draft, model.drafts);
        }
      } catch (error) {
        LOG.error('Error generating draft', error);
      }
    };

    const onGoToDraft = (draftId?: number | null) => {
      if (!draftId || !organizationId || !teamId) return null;

      const isMyWorkPage = featureFlags.get('waMyWorkPage', false);

      if (isMyWorkPage) {
        openNewTab(ROUTE.toTeamOutputsWithOutputId(organizationId, teamId, `outputId=${draftId}`));
      } else {
        openNewTab(ROUTE.toTeamDraftsWithDraftId(organizationId, teamId, `openId=${draftId}`));
      }

      return;
    };

    return (
      <div className={cx(styles.container, className)}>
        <div className={styles.heading}>
          <div className={styles.actionsContainer}>
            {onPromptLibraryClick ? (
              <>
                <Text className={styles.hintText} variant={TextSize.XS} smallCaps>
                  Writer Prompt Library
                </Text>
                <Tooltip title="Prompt library" placement="bottom">
                  <IconButton
                    className={styles.iconButton}
                    icon={IconVariant.LIFEBUOY}
                    width={28}
                    height={28}
                    iconHeight={24}
                    iconWidth={24}
                    onClick={onPromptLibraryClick}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                <Text variant={TextSize.XS} smallCaps>
                  Help
                </Text>
                <Tooltip title="Go to Learning Center" placement="bottom">
                  <IconButton
                    className={styles.iconButton}
                    icon={IconVariant.LIFEBUOY}
                    width={28}
                    height={28}
                    iconHeight={24}
                    iconWidth={24}
                    onClick={onSupportClick}
                  />
                </Tooltip>
              </>
            )}
            <Tooltip title="Agent library" placement="bottom">
              <IconButton
                className={styles.iconButton}
                icon={IconVariant.ALL_SETTINGS}
                width={28}
                height={28}
                onClick={onAllTemplatesClick}
              />
            </Tooltip>
            {onCloseClick && (
              <Tooltip title="Close" placement="bottom">
                <IconButton
                  className={styles.iconButton}
                  icon={IconVariant.CLOSE}
                  width={28}
                  height={28}
                  iconHeight={24}
                  iconWidth={24}
                  onClick={onCloseClick}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <Heading variant={HeadingVariant.H2} medium>
          {model.$application.value?.name || ''}
        </Heading>
        {isGenerationDisabled && (
          <TemplateQuotaBanner
            className={styles.quotaBanner}
            wordsLimit={coWriteLimits?.limit || 0}
            type={QUOTA_BANNER_TYPE.TEMPLATE}
            onContactSales={() => onContactSales?.()}
          />
        )}
        {model.applicationPreviewModel && model.$application.value?.type === EApplicationType.enum.generation && (
          <GenerationApplication
            model={model.applicationPreviewModel}
            guideUrl={model.$application.value?.guideUrl}
            description={model.$application.value?.shortDescription}
            isLoading={model.isGeneratingContent}
            canSetupNewVoices={isTeamAdmin}
            isSubmitDisabled={
              (model.applicationPreviewModel.voiceDropdownModel.isVisible &&
                !model.applicationPreviewModel.voiceDropdownModel.value) ||
              isGenerationDisabled
            }
            submitDisabledMessage={generationDisabledMessage}
            onGenerateClick={onGenerateDraft}
          />
        )}

        {!isEmpty(model.drafts) && (
          <footer className={styles.draftsSection} ref={draftsHeaderRef}>
            <Heading variant={HeadingVariant.H2}>
              Outputs <span className={styles.draftsLength}>{model.drafts.length}</span>
            </Heading>

            <div className={styles.draftsList}>
              {model.drafts?.map(draft => {
                const { id } = draft;

                return (
                  <DraftCard
                    key={`template-draft-${id}`}
                    draft={draft}
                    onDraftRate={() => model.modalsManager.showModal(EditorApplicationModalType.enum.rateDraft, draft)}
                    onDraftDelete={() => model.onDeleteDraft(draft)}
                    onDraftExpand={() => {
                      draft.id && updateDraftId(draft.id);
                      model.modalsManager.showModal(EditorApplicationModalType.enum.expandedDraft);
                    }}
                    onGoToDraft={() => onGoToDraft(draft.id)}
                    onDraftCopy={() => model.onCopyDraft(draft)}
                  />
                );
              })}
            </div>
          </footer>
        )}

        <Modal
          open={model.modalsManager.isModalVisible(EditorApplicationModalType.enum.rateDraft)}
          className={styles.modalContainer}
          handleClose={() => model.modalsManager.hideModal()}
          style={{ padding: 0 }}
        >
          <DraftFeedback
            onClose={() => model.modalsManager.hideModal()}
            onSubmit={input => model.onRateDraft(input.feedback, input.rate)}
          />
        </Modal>

        <Modal
          open={model.modalsManager.isModalVisible(EditorApplicationModalType.enum.expandedDraft)}
          handleClose={() => model.modalsManager.hideModal()}
          className={styles.isDraftPreviews}
        >
          <EditDraftPanel isPreviewMode />
        </Modal>
      </div>
    );
  },
);
