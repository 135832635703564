import { LinkWithLabel } from '../../molecules/LinkWithLabel';
import { useMemo } from 'react';
import type { BaseComponentsProps } from '@writercolab/common-utils';
import styles from './LinkToStyleguide.module.css';

export interface ILinkToStyleguideProps extends BaseComponentsProps {
  link: string;
  adminPanelLink: string;
  isLive?: boolean;
  isAdminMode?: boolean;
}

const LinkToStyleguide: React.FC<ILinkToStyleguideProps> = ({ link, adminPanelLink, isLive, isAdminMode }) => {
  const { styleguideText, styleguideLink, showOfflineStatus } = useMemo(() => {
    let _text = '';
    let _showOfflineStatus = false;
    const openEditor = !!isAdminMode;

    const styleguideLink = openEditor
      ? adminPanelLink
      : link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `https://${link}`;

    if (isLive && link.length > 0) {
      _text = link;
    } else if (link.length) {
      _text = 'Keep working';
      _showOfflineStatus = true;
    } else {
      _text = 'Get started';
    }

    return {
      styleguideText: _text,
      styleguideLink,
      showOfflineStatus: _showOfflineStatus,
    };
  }, [link, adminPanelLink, isLive, isAdminMode]);

  return (
    <div className={styles.linkToStyleguideContainer}>
      <LinkWithLabel
        link={styleguideLink}
        linkText={styleguideText}
        label={<>Style guide {showOfflineStatus && <span className={styles.offlineStatus}>&#8226;</span>}</>}
      />
    </div>
  );
};

export default LinkToStyleguide;
