import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { when } from 'mobx';

import {
  BillingProduct,
  ContentEditorPageMode,
  ContentEditorQueryParams,
  IssuesPipeline,
  SortFieldTypes,
  SortOrders,
  getWordsCount,
  openNewTab,
} from '@writercolab/common-utils';
import { calculateChecksumOverDelta } from '@writercolab/quill-delta-utils';
import { AppLibrary } from '@writercolab/ui-app-library';
import { ButtonTypes, useQueueWorkerNotifications } from '@writercolab/ui-atoms';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';
import { CREATE_NEW_DOC_ID, EMPTY_EDITOR_DOC_ID, SETUP_ROUTES, isDocumentIsValid } from '@writercolab/utils';

import {
  ASK_WRITER_APP_ID,
  BLOG_BUILDER_APP_ID,
  DocumentInfoPanelButtonStateID,
  EApplicationType,
  IDocumentInfoPanelButtonState,
  RECAPS_APP_ID,
  TTemplateDraft,
} from '@web/types';
import { AnalyticsActivity, MODE_NAME_MAP } from 'constants/analytics';
import { useDocumentsContext } from 'context/documentsContext';
import head from 'lodash/head';
import { observer } from 'mobx-react-lite';
import type { Params } from 'react-router';
import { useLocation, useNavigate, useParams } from 'react-router';
import { processLlmOutputBeforeInsert } from 'utils/textUtils';

import documentClaimsDetection from '../../../assets/backgrounds/documentClaimsDetection.png';
import { PromptLibraryEventSources } from '../../../constants/PromptLibrary';
import DocumentHighlightsContextProvider from '../../../context/documentHighlightsContext';
import DraftsContextProvider from '../../../context/draftsContext';
import GeneratorSeoBlogContextProvider, {
  getBlogNextState,
  setBlogNextState,
} from '../../../context/generatorSeoBlogContext';
import MagicLinksContextProvider from '../../../context/magicLinksContext';
import RecapsContextProvider from '../../../context/recapsContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import useQuery from '../../../hooks/useQuery';
import useStartTeamTrialPopup from '../../../hooks/useStartTeamTrialPopup';
import { appLocalStorage } from '../../../models/localStorage';
import { ROUTE } from '../../../services/config/routes';
import { getTeamDrafts } from '../../../services/request/drafts';
import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import { TActionType } from '../../../state/types';
import { isBlogBuilderDraft, isRecapsDraft } from '../../../utils/draftsUtils';
import config from '../../../utils/dynamicConfig';
import { getLogger } from '../../../utils/logger';
import {
  goToBilling,
  goToBillingNewTab,
  navigateToQueryParam,
  openContactSalesPage,
} from '../../../utils/navigationUtils';
import { getCoWriteNextState, saveCoWriteNextState } from '../../../utils/templateUtils';
import { ChromeExtWindow } from '../../molecules/ChromeExtWindow';
import { EditorLeftSidebar } from '../../molecules/EditorLeftSidebar';
import { FullScreenModal } from '../../molecules/FullScreenModal';
import { DocumentHighlights } from '../../organisms/DocumentHighlights';
import { ErrorContainer } from '../../organisms/DocumentHighlights/parts/ErrorContainer';
import { GeneratorSeoBlog } from '../../organisms/GeneratorSeoBlog';
import MagicLinksSuggestions from '../../organisms/MagicLinksSuggestions';
import { PromptLibraryModal } from '../../organisms/PromptLibraryModal';
import RecapsPanel from '../../organisms/RecapsPanel/RecapsPanel';
import type { IContentEditorRef } from '../../templates/ContentEditor';
import { ContentEditor } from '../../templates/ContentEditor';
import { EditorApplication } from '../../templates/EditorApplication';
import { Start30DayTeamTrialPopup } from '../BillingPage/Start30DayTeamTrialPopup';
import type { ContentEditorPageModel } from './ContentEditorPageModel';

import styles from './styles.module.css';

const LOG = getLogger('ContentEditorPage');

interface IContentEditorPageProps {
  openLearningCenter: () => void;
  model: ContentEditorPageModel;
}

interface QueryParamTypes extends Params {
  orgId: string;
  teamId: string;
  docId: string;
}

const ContentEditorPage: React.FC<IContentEditorPageProps> = ({ openLearningCenter, model }) => {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const { addNewDoc } = useDocumentsContext();
  const contentEditorRef = useRef<IContentEditorRef>(null);
  const { orgId, teamId, docId } = useParams() as QueryParamTypes;
  const updateQueryParam = navigateToQueryParam(navigate);
  const { appState, dispatchAppState, loadSubscriptionLimits, appModel } = useAppState();
  const [currentDocumentHash, setCurrentDocumentHash] = useState<string | null>(null);
  const [currentDocumentWordsCount, setCurrentDocumentWordsCount] = useState<number | null>(null);
  const [highlightsLoading, setHighlightsLoading] = useState(false);
  const [magicLinksLoading, setMagicLinksLoading] = useState(false);
  const [isPromptModalOpen, setPromptModalState] = useState(false);
  const [currentTemplateId, setCurrentTemplateId] = useState<string | null>(null);
  const [magicLinksAmount, setMagicLinksAmount] = useState(-1);
  const [magicLinksText, setMagicLinksText] = useState('');
  const [magicLinksDoSearch, setMagicLinksDoSearch] = useState(false);
  const [isSearchFromInput, setIsSearchFromInput] = useState(false);
  const [claimDetectionLoading, setClaimDetectionLoading] = useState(false);
  const [isFullSizeMode, setIsFullSizeMode] = useState(+docId === EMPTY_EDITOR_DOC_ID);
  const appValuesHash = useRef('');
  const dynamicDocCreated = useRef(false);
  const isCurrentModeParamsExists = !!query.get(ContentEditorQueryParams.mode);
  const isCurrentTemplateParamExists = !!query.get(ContentEditorQueryParams.currentTemplateId);
  const { isPopupOpen, onCancelPopup, onStartTrial } = useStartTeamTrialPopup(orgId!);
  const { permissionsModel, dataRetentionModel, appLibraryModel, analyticsService } = appModel;
  const subscriptionModel = appModel.assistantSubscription;
  const isEmptyDoc = +docId === EMPTY_EDITOR_DOC_ID;

  usePageTitle(model.editorApplicationModel?.applicationName || 'Content Editor');

  const appRoot = config.APP_ROOT;

  const baseConfig = {
    organizationId: orgId,
    workspaceId: teamId,
    personaId: '94',
    documentId: docId,
    appRoot,
  };

  const [documentInfoPanelCoWriteButtonState, setDocumentInfoPanelCoWriteButtonState] =
    useState<IDocumentInfoPanelButtonState>({
      id: DocumentInfoPanelButtonStateID.EXPLORE_LIBRARY,
      type: ButtonTypes.GRAY,
      content: 'Explore templates',
    });

  const showLeftSidebarPanel =
    ![CREATE_NEW_DOC_ID].includes(+docId) &&
    (model.isCoWriteMode ||
      model.isBlogMode ||
      model.isHighlightsMode ||
      model.isLockedClaimsMode ||
      model.isEventTakeawaysMode);
  const showDimmer = showLeftSidebarPanel && model.isBlogMode && !isEmptyDoc && !isFullSizeMode;

  const { organizationId, workspaceId, personaId, documentId } = baseConfig;

  const onUpgradeSubscriptionClick = useCallback(
    () => goToBilling(navigate, appState.organizationId, BillingProduct.STARTER),

    [navigate, appState.organizationId],
  );

  useQueueWorkerNotifications(appLibraryModel?.notificationQueue);
  useQueueWorkerNotifications(model.contentEditorModel.notificationQueue);

  useEffect(() => {
    if (+docId !== EMPTY_EDITOR_DOC_ID) {
      analyticsService.track(AnalyticsActivity.openDocument, {});
    }

    if (orgId && teamId && docId) {
      const currentQueryParamMode = query.get(ContentEditorQueryParams.mode) as ContentEditorPageMode;
      const templateId = query.get(ContentEditorQueryParams.currentTemplateId) ?? undefined;
      calculateInfoPanelCoWriteButton();

      if (
        [ContentEditorPageMode.CO_WRITE, ContentEditorPageMode.BLOG, ContentEditorPageMode.EVENT_TAKEAWAYS].includes(
          currentQueryParamMode,
        )
      ) {
        calculateInfoPanelCoWriteButton();
        saveCoWriteNextState(orgId, teamId, docId, {
          mode: currentQueryParamMode,
          templateId,
        });
      }

      if (currentQueryParamMode === ContentEditorPageMode.CO_WRITE && templateId) {
        model.setApplication(templateId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId, model]);

  useEffect(() => {
    dispatchAppState({ type: TActionType.DocumentId, payload: +docId });
  }, [dispatchAppState, docId]);

  useEffect(() => {
    if (!model.claimsLocked) {
      model.sidebarModel.sidebarState.setEnforcePipeline(
        model.isClaimsDetectionMode ? IssuesPipeline.CLAIM : undefined,
      );
    }
  }, [model.isClaimsDetectionMode, model.claimsLocked, model.sidebarModel.sidebarState]);

  const onUseThisDraft = (draft: TTemplateDraft, replaceText?: boolean) => {
    setIsFullSizeMode(false);

    appLocalStorage.lastDraft.set({
      title: draft.title || '',
      body: processLlmOutputBeforeInsert(draft.body),
      replace: replaceText,
    });
  };

  const onUseRecapsDraft = (draft: TTemplateDraft) => {
    contentEditorRef.current?.replaceEditorContentIfEmpty(draft.body, draft.title || '');
  };

  useEffect(() => {
    return when(
      () => !model.sidebarModel.sidebarState.isLoading && !!contentEditorRef.current?.isEditorLoaded(),
      () => {
        const storedDocumentDraft = appLocalStorage.lastDraft.get();
        const storedChatMessageDocument = appLocalStorage.chatMessageDocumentBootstrapContent.get();

        if (storedDocumentDraft) {
          contentEditorRef.current?.addContentToEditor(storedDocumentDraft.body, storedDocumentDraft?.replace === true);
          storedDocumentDraft.title && contentEditorRef.current?.updateEditorTitle(storedDocumentDraft.title);
          appLocalStorage.lastDraft.remove();
        } else if (storedChatMessageDocument && storedChatMessageDocument !== 'null') {
          contentEditorRef.current?.addContentToEditor(storedChatMessageDocument, true);
          appLocalStorage.chatMessageDocumentBootstrapContent.set('');
        }
      },
      {
        onError: err => LOG.error('Error waiting for sidebar loading', err),
        timeout: 0,
      },
    );
  }, [contentEditorRef, model.sidebarModel.sidebarState.isLoading]);

  const onRefreshScore = useCallback(async () => {
    if (!isDocumentIsValid(documentId)) {
      return;
    }

    await requestService.api.put(
      '/api/content/organization/{organizationId}/workspace/{workspaceId}/persona/{personaId}/document/{documentId}/content/refresh',
      {
        params: {
          path: {
            documentId,
            organizationId: +organizationId,
            personaId: +personaId,
            workspaceId: +workspaceId,
          },
        },
      },
    );
  }, [documentId, organizationId, personaId, workspaceId]);

  const onRefreshClaim = useCallback(async () => {
    const currentPipeline = model.sidebarModel.pipeline;

    setClaimDetectionLoading(true);
    try {
      await model.sidebarModel.setPipeline(IssuesPipeline.FULL);
      await onRefreshScore();
    } finally {
      setClaimDetectionLoading(false);

      if (currentPipeline) {
        await model.sidebarModel.setPipeline(currentPipeline);
      }
    }
  }, [model.sidebarModel, onRefreshScore]);

  const onHighlightsLoading = (isLoading: boolean) => setHighlightsLoading(isLoading);
  const onMagicLinksLoading = (isLoading: boolean) => setMagicLinksLoading(isLoading);
  const onDraftCreated = (draftAdded: TTemplateDraft) => {
    setIsFullSizeMode(false);

    setTimeout(() => {
      if (contentEditorRef.current) {
        if (contentEditorRef.current.isEditorContentEmpty()) {
          contentEditorRef.current.addContentToEditor(draftAdded.body, true);

          if (draftAdded.title) {
            contentEditorRef.current.updateEditorTitle(draftAdded.title);
          }
        }
      }
    }, 200);
  };

  const navigateToNewDocument = async () => {
    dynamicDocCreated.current = true;

    const currentDoc = await addNewDoc();
    navigate(ROUTE.toEditorPage(orgId, teamId, currentDoc?.id, location.search));

    return currentDoc;
  };

  const waitPromise = (timeout: number = 200) => {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, timeout);
    });
  };

  const onBeforeAppGeneration = async () => {
    if (isEmptyDoc) {
      const newDoc = await navigateToNewDocument();

      const oldCachingKey = model.editorApplicationModel.cachingKey;
      const newCachingKey = `${newDoc?.id}-${model.applicationId}`;

      const inputs = appLocalStorage.editorApplicationPreviewValues.get()?.[oldCachingKey] || [];
      appLocalStorage.editorApplicationPreviewValues.update({
        [oldCachingKey]: _ => [],
      });
      appLocalStorage.editorApplicationPreviewValues.update({
        [newCachingKey]: _ => inputs,
      });

      // for mobx models to recalculate the state
      await waitPromise();
    }

    return Promise.resolve();
  };

  const onBeforeRecapsGeneration = async () => {
    setIsFullSizeMode(false);

    if (isEmptyDoc) {
      await navigateToNewDocument();
      await waitPromise(1000);
    }
  };

  const onUpdateBlogTitle = async () => {
    if (isEmptyDoc && !dynamicDocCreated.current) {
      const newDoc = await navigateToNewDocument();

      const state = getBlogNextState(orgId, teamId, docId);
      setBlogNextState({
        orgId,
        teamId,
        docId,
        state: {
          blogTitle: '',
          blogCta: '',
          blogSeoKeywords: '',
          outlineGenerated: [],
          keyPointsGenerated: [],
        },
      });
      setBlogNextState({ orgId, teamId, docId: `${newDoc?.id}`, state });

      await waitPromise();
    }
  };

  useEffect(() => {
    if (isEmptyDoc && !dynamicDocCreated.current) {
      const newHash = JSON.stringify(model.editorApplicationModel?.applicationPreviewModel?.values);
      const prev = appValuesHash.current;

      if (appValuesHash.current !== newHash) {
        appValuesHash.current = newHash;

        if (prev && newHash) {
          navigateToNewDocument().then(() => {
            const oldCachingKey = model.editorApplicationModel.cachingKey;
            appLocalStorage.editorApplicationPreviewValues.update({
              [oldCachingKey]: _ => [],
            });
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEmptyDoc,
    model.applicationId,
    model.editorApplicationModel?.applicationPreviewModel?.values,
    model.editorApplicationModel.cachingKey,
  ]);

  const switchCurrentMode = useCallback(
    (mode: ContentEditorPageMode, skipDocumentCreation = false) => {
      const newQuery = updateQueryParam(ContentEditorQueryParams.mode, mode);
      model.setCurrentMode(mode);

      switch (mode) {
        case ContentEditorPageMode.BLOG:
        case ContentEditorPageMode.EVENT_TAKEAWAYS:
        case ContentEditorPageMode.CO_WRITE:
        case ContentEditorPageMode.PURE_EDITOR:
          if (isEmptyDoc && !skipDocumentCreation) {
            navigate(ROUTE.toEditorPage(orgId, teamId, CREATE_NEW_DOC_ID, `?${newQuery.toString()}`));
          }

          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [docId, orgId, teamId, model],
  );

  const _onOpenSidebar = useCallback(() => {
    const currentQueryParamMode = query.get(ContentEditorQueryParams.mode);
    const currentQueryParamTemplateId = query.get(ContentEditorQueryParams.currentTemplateId);

    if (currentQueryParamMode && currentQueryParamTemplateId) {
      setDocumentInfoPanelCoWriteButtonState({
        id: DocumentInfoPanelButtonStateID.OPEN_TEMPLATE,
        type: ButtonTypes.GRAY,
        content: 'Reopen template',
      });
    }

    switchToPureEditor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, orgId, teamId, docId]);

  const switchToPureEditor = useCallback(() => {
    updateQueryParam(ContentEditorQueryParams.mode);
    updateQueryParam(ContentEditorQueryParams.currentTemplateId);
    switchCurrentMode(ContentEditorPageMode.PURE_EDITOR);
  }, [switchCurrentMode, updateQueryParam]);

  const switchToCoWriteNextState = useCallback(() => {
    if (!orgId || !teamId || !docId) {
      return;
    }

    const nextCoWriteState = getCoWriteNextState(orgId, teamId, docId);
    const currentQueryParamMode = query.get(ContentEditorQueryParams.mode);
    const currentQueryParamTemplateId = query.get(ContentEditorQueryParams.currentTemplateId);

    if (
      !nextCoWriteState ||
      (currentQueryParamMode && currentQueryParamTemplateId) ||
      currentQueryParamMode === ContentEditorPageMode.EVENT_TAKEAWAYS
    ) {
      switchToLibrary();

      return;
    }

    const nextCoWriteMode = nextCoWriteState.mode as ContentEditorPageMode;

    if (
      [ContentEditorPageMode.PURE_EDITOR, ContentEditorPageMode.BLOG, ContentEditorPageMode.HIGHLIGHTS].includes(
        nextCoWriteMode,
      )
    ) {
      switchCurrentMode(nextCoWriteMode);
    } else if (nextCoWriteMode === ContentEditorPageMode.EVENT_TAKEAWAYS) {
      setDocumentInfoPanelCoWriteButtonState({
        id: DocumentInfoPanelButtonStateID.EXPLORE_LIBRARY,
        type: ButtonTypes.GRAY,
        content: 'Explore templates',
      });

      switchCurrentMode(nextCoWriteMode);
    } else if (nextCoWriteMode === ContentEditorPageMode.CO_WRITE && nextCoWriteState.templateId) {
      model.setApplication(nextCoWriteState.templateId);
      setDocumentInfoPanelCoWriteButtonState({
        id: DocumentInfoPanelButtonStateID.EXPLORE_LIBRARY,
        type: ButtonTypes.GRAY,
        content: 'Explore templates',
      });

      updateQueryParam(ContentEditorQueryParams.currentTemplateId, nextCoWriteState.templateId);
      switchCurrentMode(nextCoWriteMode);
    } else {
      switchToLibrary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, teamId, docId, query, switchCurrentMode, updateQueryParam]);

  const switchToLibrary = useCallback(() => {
    analyticsService.track(AnalyticsActivity.appLibraryAllAppsShortcutClicked, { opened_from: 'editor' });
    switchCurrentMode(ContentEditorPageMode.LIBRARY);
  }, [analyticsService, switchCurrentMode]);

  const switchToHighlights = useCallback(() => {
    if (currentDocumentHash) {
      const currentQueryParamMode = query.get(ContentEditorQueryParams.mode);
      const currentQueryParamTemplateId = query.get(ContentEditorQueryParams.currentTemplateId);

      if (model.isCoWriteMode && currentQueryParamMode && currentQueryParamTemplateId) {
        setDocumentInfoPanelCoWriteButtonState({
          id: DocumentInfoPanelButtonStateID.OPEN_TEMPLATE,
          type: ButtonTypes.GRAY,
          content: 'Reopen template',
        });

        saveCoWriteNextState(orgId, teamId, docId, {
          mode: ContentEditorPageMode.CO_WRITE,
          templateId: currentQueryParamTemplateId,
        });
      }

      if (model.isHighlightsMode) {
        switchToPureEditor();
      } else {
        updateQueryParam(ContentEditorQueryParams.currentTemplateId);
        switchCurrentMode(ContentEditorPageMode.HIGHLIGHTS);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, model.currentMode, switchToPureEditor, switchCurrentMode, currentDocumentHash, orgId, teamId, docId]);

  const onGenerationAppSelected = (applicationId: string) => {
    if (applicationId === BLOG_BUILDER_APP_ID) {
      switchCurrentMode(ContentEditorPageMode.BLOG, true);
    } else if (applicationId === RECAPS_APP_ID) {
      switchCurrentMode(ContentEditorPageMode.EVENT_TAKEAWAYS, true);
    } else {
      setCurrentTemplateId(applicationId);
      model.setApplication(applicationId);
      updateQueryParam(ContentEditorQueryParams.currentTemplateId, applicationId);
      switchCurrentMode(ContentEditorPageMode.CO_WRITE, true);
    }
  };

  const onChatSelected = (chatId: string) => {
    if (chatId === ASK_WRITER_APP_ID) {
      navigate(ROUTE.toAskWriter(organizationId, teamId!));
    } else {
      navigate(ROUTE.toChatApp(organizationId, teamId, chatId));
    }
  };

  const onResearchAssistantSelected = (chatId: string) => {
    navigate(ROUTE.toResearchApp(organizationId, teamId, chatId));
  };

  const onFrameworkAppSelected = (applicationId: string) => {
    navigate(ROUTE.toFrameworkAppPage(organizationId, teamId, applicationId));
  };

  const onApplicationSelected = useCallback(
    app => {
      EApplicationType.match(
        app.type,
        {
          [EApplicationType.enum.framework]: () => onFrameworkAppSelected(app.id),
          [EApplicationType.enum.chat]: () => onChatSelected(app.id),
          [EApplicationType.enum.generation]: () => onGenerationAppSelected(app.id),
          [EApplicationType.enum.research]: () => onResearchAssistantSelected(app.id),
        },
        null,
      );
      analyticsService.track(AnalyticsActivity.appOpened, {
        app_id: app.id,
        origin: 'home',
        source: 'app_library',
        app_name: app.name,
        app_type: app?.type ?? '',
        built_by_writer: app?.access === 'public',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationId, teamId, switchCurrentMode],
  );

  const onOpenAppLibraryUrl = (url?: string | null) => {
    if (url) {
      openNewTab(url);
    }
  };

  const onCloseAppLibraryModal = () => {
    appModel.appLibraryModel?.reset();

    if (parseInt(docId, 10) === EMPTY_EDITOR_DOC_ID) {
      navigate(ROUTE.toHome(orgId, teamId));

      return;
    }

    const nextCoWriteState = getCoWriteNextState(orgId, teamId, docId);
    onCloseCoWriteLibrary();

    if (subscriptionModel.limits?.coWrite?.exceeded) {
      switchToPureEditor();
    } else if (nextCoWriteState?.mode === ContentEditorPageMode.CO_WRITE && !model.coWriteLocked) {
      switchCurrentMode(ContentEditorPageMode.CO_WRITE);
      updateQueryParam(ContentEditorQueryParams.currentTemplateId, nextCoWriteState.templateId);
    } else if (nextCoWriteState?.mode === ContentEditorPageMode.BLOG && !model.seoBlogBuilderLocked) {
      switchCurrentMode(ContentEditorPageMode.BLOG);
    } else if (nextCoWriteState?.mode === ContentEditorPageMode.EVENT_TAKEAWAYS && !model.eventTakeawaysLocked) {
      switchCurrentMode(ContentEditorPageMode.EVENT_TAKEAWAYS);
    } else if (model.isStartWithLibraryMode) {
      navigate(ROUTE.toHome(orgId, teamId));
    } else {
      switchToPureEditor();
    }
  };

  const onLinkSelected = useCallback((link: string) => {
    contentEditorRef.current?.addMagicLinkUrl(link);
  }, []);

  const onTextUpdate = (text: string) => {
    setIsSearchFromInput(true);
    contentEditorRef.current?.updateSearchedLinksText(text);
  };

  const updateAmountOfDomains = (amount: number) => {
    setIsSearchFromInput(true);
    contentEditorRef.current?.updateAmountOfDomains(amount);
  };

  const onCloseMagicLinks = () => {
    resetMagicLinksData();
    switchToPureEditor();
  };

  const resetMagicLinksData = () => {
    setMagicLinksAmount(-1);
    setMagicLinksText('');
  };

  const calculateInfoPanelCoWriteButton = useCallback(() => {
    const nextCoWriteState = getCoWriteNextState(orgId, teamId, docId);
    const currentQueryParamMode = query.get(ContentEditorQueryParams.mode);
    const currentQueryParamTemplateId = query.get(ContentEditorQueryParams.currentTemplateId);

    if (nextCoWriteState) {
      if (
        currentQueryParamMode &&
        currentQueryParamTemplateId &&
        nextCoWriteState.templateId &&
        nextCoWriteState.templateId === currentQueryParamTemplateId &&
        documentInfoPanelCoWriteButtonState.id === DocumentInfoPanelButtonStateID.OPEN_TEMPLATE
      ) {
        setDocumentInfoPanelCoWriteButtonState(prevState => ({
          ...prevState,
          id: DocumentInfoPanelButtonStateID.EXPLORE_LIBRARY,
          type: ButtonTypes.GRAY,
          content: 'Explore agents',
        }));
        saveCoWriteNextState(orgId, teamId, docId, {
          mode: ContentEditorPageMode.LIBRARY,
        });
      } else if (nextCoWriteState.mode === ContentEditorPageMode.BLOG) {
        setDocumentInfoPanelCoWriteButtonState(prevState => ({
          ...prevState,
          id: DocumentInfoPanelButtonStateID.OPEN_TEMPLATE,
          type: ButtonTypes.GRAY,
          content: 'Reopen agent',
        }));
      } else if (nextCoWriteState.mode === ContentEditorPageMode.CO_WRITE) {
        setDocumentInfoPanelCoWriteButtonState(prevState => ({
          ...prevState,
          id: DocumentInfoPanelButtonStateID.OPEN_TEMPLATE,
          type: ButtonTypes.GRAY,
          content: 'Reopen agent',
        }));
      }
    } else if (
      [ContentEditorPageMode.BLOG, ContentEditorPageMode.EVENT_TAKEAWAYS, ContentEditorPageMode.CO_WRITE].includes(
        currentQueryParamMode as ContentEditorPageMode,
      )
    ) {
      setDocumentInfoPanelCoWriteButtonState(prevState => ({
        ...prevState,
        id: DocumentInfoPanelButtonStateID.OPEN_TEMPLATE,
        type: ButtonTypes.GRAY,
        content: 'Reopen agent',
      }));
    } else {
      setDocumentInfoPanelCoWriteButtonState(prevState => ({
        ...prevState,
        id: DocumentInfoPanelButtonStateID.EXPLORE_LIBRARY,
        type: ButtonTypes.GRAY,
        content: 'Explore agents',
      }));
    }
  }, [docId, documentInfoPanelCoWriteButtonState.id, orgId, query, teamId]);

  useEffect(() => {
    const nextCoWriteState = getCoWriteNextState(orgId, teamId, docId);
    const currentQueryParamMode = query.get(ContentEditorQueryParams.mode);
    const isInitialLoad = !currentQueryParamMode && docId && orgId && teamId;
    const hasDocumentNextState =
      nextCoWriteState &&
      [ContentEditorPageMode.BLOG, ContentEditorPageMode.EVENT_TAKEAWAYS, ContentEditorPageMode.CO_WRITE].includes(
        nextCoWriteState.mode as ContentEditorPageMode,
      );

    if (isInitialLoad && hasDocumentNextState) {
      setDocumentInfoPanelCoWriteButtonState({
        id: DocumentInfoPanelButtonStateID.OPEN_TEMPLATE,
        type: ButtonTypes.GRAY,
        content: 'Reopen agent',
      });
    } else {
      if (!isDocumentIsValid(docId)) {
        return;
      }

      getTeamDrafts({
        orgId,
        teamId,
        params: {
          documentId: docId,
          templateIds: [],
          search: '',
          sortField: SortFieldTypes.CREATION_TIME,
          sortOrder: SortOrders.DESC,
          offset: 0,
          limit: 1,
        },
      }).then(response => {
        const draft = head(response.result);

        if (draft && draft.template.id) {
          setDocumentInfoPanelCoWriteButtonState({
            id: DocumentInfoPanelButtonStateID.OPEN_TEMPLATE,
            type: ButtonTypes.GRAY,
            content: 'Reopen agent',
          });

          if (isRecapsDraft(draft)) {
            saveCoWriteNextState(orgId, teamId, docId, {
              mode: ContentEditorPageMode.EVENT_TAKEAWAYS,
              templateId: draft.template.id,
            });
          } else if (isBlogBuilderDraft(draft)) {
            saveCoWriteNextState(orgId, teamId, docId, {
              mode: ContentEditorPageMode.BLOG,
              templateId: draft.template.id,
            });
          } else {
            saveCoWriteNextState(orgId, teamId, docId, {
              mode: ContentEditorPageMode.CO_WRITE,
              templateId: draft.template.id,
            });
          }
        }
      });
    }

    if (!isInitialLoad && currentQueryParamMode === ContentEditorPageMode.CO_WRITE) {
      setDocumentInfoPanelCoWriteButtonState({
        id: DocumentInfoPanelButtonStateID.EXPLORE_LIBRARY,
        type: ButtonTypes.GRAY,
        content: 'Explore agents',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId, orgId, teamId]);

  const switchToMagicLinks = useCallback(() => {
    if (model.isMagicLinksMode) {
      switchToPureEditor();
    } else {
      analyticsService.track(AnalyticsActivity.magicLinksUserClickedIcon, {
        clicked_from: 'magic-links',
      });

      switchCurrentMode(ContentEditorPageMode.MAGIC_LINKS);
      updateQueryParam(ContentEditorQueryParams.currentTemplateId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.isMagicLinksMode, switchToPureEditor, analyticsService, switchCurrentMode]);

  const switchToClaimsDetection = useCallback(() => {
    if (model.isClaimsDetectionMode) {
      switchToPureEditor();
    } else {
      setClaimDetectionLoading(true);
      analyticsService.track(AnalyticsActivity.claimDetectionTriggered, {});
      switchCurrentMode(ContentEditorPageMode.CLAIMS_DETECTION);

      setTimeout(() => setClaimDetectionLoading(false), 3000);
    }
  }, [model.isClaimsDetectionMode, switchToPureEditor, analyticsService, switchCurrentMode]);

  const onCloseCoWriteLibrary = useCallback(() => {
    setIsFullSizeMode(false);

    if (orgId && teamId && docId) {
      if (
        ![ContentEditorPageMode.LIBRARY, ContentEditorPageMode.BLOG].includes(model.currentMode) &&
        currentTemplateId
      ) {
        saveCoWriteNextState(orgId, teamId, docId, {
          mode: currentTemplateId ? model.currentMode : ContentEditorPageMode.LIBRARY,
          templateId: currentTemplateId,
        });
      }

      updateQueryParam(ContentEditorQueryParams.mode);
      updateQueryParam(ContentEditorQueryParams.currentTemplateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, teamId, docId, model.currentMode, currentTemplateId]);

  const onCloseCoWrite = useCallback(() => {
    onCloseCoWriteLibrary();
    calculateInfoPanelCoWriteButton();
    switchCurrentMode(ContentEditorPageMode.PURE_EDITOR);
    updateQueryParam(ContentEditorQueryParams.mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    switchToPureEditor,
    model.currentMode,
    currentTemplateId,
    orgId,
    teamId,
    docId,
    documentInfoPanelCoWriteButtonState,
  ]);

  const onCloseSeoBlog = () => {
    onCloseCoWriteLibrary();
    calculateInfoPanelCoWriteButton();
    switchToPureEditor();
  };

  const onCloseHighlights = () => {
    switchToPureEditor();
  };

  const onCloseEventTakeaways = () => {
    onCloseCoWriteLibrary();
    switchToPureEditor();
    saveCoWriteNextState(orgId, teamId, docId, {
      mode: ContentEditorPageMode.EVENT_TAKEAWAYS,
      templateId: RECAPS_APP_ID,
    });
    setDocumentInfoPanelCoWriteButtonState({
      id: DocumentInfoPanelButtonStateID.OPEN_TEMPLATE,
      type: ButtonTypes.GRAY,
      content: 'Reopen agent',
    });
  };

  const onMagicIconClick = useCallback(
    (amount: number, text: string, doSearch?: boolean) => {
      // If user start search from input and there are some text found OR user clicked icon (doSearch)
      const isSearchAvailable = (isSearchFromInput && amount > 0) || !!doSearch;
      setMagicLinksAmount(amount);
      setMagicLinksText(text);
      setMagicLinksDoSearch(isSearchAvailable);
      // Reset to false after icon clicked
      setIsSearchFromInput(false);
    },
    [isSearchFromInput],
  );

  const onContentChange = useCallback(
    (content: string) => {
      setCurrentDocumentWordsCount(getWordsCount(content));

      if (contentEditorRef.current) {
        setCurrentDocumentHash(calculateChecksumOverDelta(contentEditorRef.current.getEditorContent()));
      }
    },
    [contentEditorRef],
  );

  const handleManageTeamPromptsClick = permissionsModel?.isTeamAdmin
    ? () => openNewTab(SETUP_ROUTES.toTeamPrompts(Number(organizationId), Number(teamId)))
    : undefined;

  useEffect(() => {
    loadSubscriptionLimits();
  }, [loadSubscriptionLimits]);

  const handleOnBack = () => {
    if (model.editorApplicationModel.applicationId) {
      analyticsService.track(AnalyticsActivity.appExited, {
        app_id: model.editorApplicationModel.applicationId ?? '',
        app_name: model.editorApplicationModel.applicationName ?? '',
        app_type: model.editorApplicationModel.applicationData.type ?? '',
      });
    } else {
      analyticsService.track(AnalyticsActivity.appExited, {
        app_id: query.get(ContentEditorQueryParams.currentTemplateId) ?? '',
        app_name: MODE_NAME_MAP[query.get(ContentEditorQueryParams.mode) as ContentEditorPageMode] ?? '',
        app_type: EApplicationType.enum.generation,
      });
    }
  };

  return (
    <DraftsContextProvider>
      {showDimmer && <div className={styles.containerDimmer} />}
      <EditorLeftSidebar
        hideChromeExtBanner={isCurrentModeParamsExists || isCurrentTemplateParamExists}
        activeDocId={+docId}
        className={cx({
          [styles.sidebarWithoutDimmer]: !showDimmer,
          [styles.sidebarWithDimmer]: showDimmer,
        })}
        onBack={handleOnBack}
      />
      {isPromptModalOpen && (
        <PromptLibraryModal
          model={model.promptLibraryModalModel}
          teamName={appModel.team?.name || ''}
          invocationContext={PromptLibraryEventSources.ASK_WRITER}
          onClose={() => setPromptModalState(false)}
          onUsePrompt={prompt => {
            const firstInput = model.editorApplicationModel?.applicationPreviewModel?.modelsMap?.toJSON()?.[0];
            const firstInputId = firstInput?.[0];

            if (firstInputId) {
              model.editorApplicationModel?.applicationPreviewModel?.onUpdateInputValue(firstInputId, 0, prompt);
            }

            setPromptModalState(false);
          }}
          showContactText={!permissionsModel?.isTeamAdmin}
          onNavigateToLearningCenter={handleManageTeamPromptsClick}
        />
      )}
      {showLeftSidebarPanel && (
        <div
          className={cx(styles.leftSidebarPanel, {
            [styles.fullScreen]: isFullSizeMode,
          })}
        >
          <div
            className={cx(
              styles.coWriteContainer,
              styles.active,
              !isFullSizeMode
                ? {
                    [styles.wide]: model.isBlogMode,
                    [styles.coWritePaneWithDimmer]: showDimmer,
                    [styles.coWriteContainerBlog]: model.isBlogMode,
                    [styles.coWritePaneShadow]: model.isBlogMode,
                    [styles.short]: model.isHighlightsMode && !model.documentHighlightsLocked,
                    [styles.long]:
                      model.isClaimsDetectionMode || (model.isHighlightsMode && model.documentHighlightsLocked),
                    [styles.narrow]: model.isCoWriteMode,
                    [styles.coWritePanelRelative]:
                      model.isCoWriteMode || model.isHighlightsMode || model.isClaimsDetectionMode,
                    [styles.eventTakeaways]: model.isEventTakeawaysMode,
                  }
                : {},
            )}
          >
            <div
              className={cx(styles.coWritePanel, styles.active, {
                [styles.short]: model.isHighlightsMode && !model.documentHighlightsLocked,
                [styles.long]: model.isHighlightsMode && model.documentHighlightsLocked,
              })}
            >
              {model.isClaimsDetectionMode && (
                <ErrorContainer onCloseClick={switchToPureEditor}>
                  <BillingLock
                    className={cx(styles.billingLock)}
                    type={BillingLockType.CLAIMS_DETECTION}
                    onActionCallback={onUpgradeSubscriptionClick}
                    banner={<img src={documentClaimsDetection} alt="lock" width={530} />}
                  />
                </ErrorContainer>
              )}
              {model.isCoWriteMode && model.editorApplicationModel && (
                <EditorApplication
                  className={styles.editorApplication}
                  isTeamAdmin={!!permissionsModel?.isTeamAdmin}
                  model={model.editorApplicationModel}
                  onBeforeDraftCreate={onBeforeAppGeneration}
                  onPromptLibraryClick={null}
                  onSupportClick={openLearningCenter}
                  onContactSales={openContactSalesPage}
                  onAllTemplatesClick={switchToLibrary}
                  onCloseClick={isEmptyDoc ? undefined : onCloseCoWrite}
                  onDraftCreated={onDraftCreated}
                />
              )}
              {model.isBlogMode && (
                <GeneratorSeoBlogContextProvider
                  organizationId={Number(orgId)}
                  teamId={Number(teamId)}
                  onBlogClosed={onCloseSeoBlog}
                  onShowTemplate={switchToLibrary}
                >
                  <GeneratorSeoBlog
                    hideCloseButton={isEmptyDoc}
                    wordsLimit={subscriptionModel.limits?.coWrite?.limit || 0}
                    onUseThisDraft={onUseThisDraft}
                    onUpdateBlogTitle={onUpdateBlogTitle}
                    allowSetupNewVoices={!!permissionsModel?.isTeamAdmin}
                    showApplyConfirmation={
                      contentEditorRef.current ? !contentEditorRef.current?.isEditorContentEmpty() : false
                    }
                    voices={appModel.voiceModel?.voiceList || []}
                  />
                </GeneratorSeoBlogContextProvider>
              )}
              {model.isEventTakeawaysMode && (
                <RecapsContextProvider
                  organizationId={orgId}
                  teamId={teamId}
                  onUseDraft={onUseRecapsDraft}
                  onClose={isEmptyDoc ? undefined : onCloseEventTakeaways}
                  onShowTemplates={switchToLibrary}
                >
                  <RecapsPanel onGenerateRecaps={onBeforeRecapsGeneration} />
                </RecapsContextProvider>
              )}
              {model.isHighlightsMode && (
                <DocumentHighlightsContextProvider
                  organizationId={Number(orgId)}
                  teamId={Number(teamId)}
                  activeDocId={+docId}
                  onHighlightsClosed={onCloseHighlights}
                  locked={model.documentHighlightsLocked}
                  onLoading={onHighlightsLoading}
                >
                  <DocumentHighlights
                    editorRef={contentEditorRef}
                    currentWordsCount={currentDocumentWordsCount}
                    currentDocumentHash={currentDocumentHash}
                    analytics={analyticsService}
                    wordsLimit={subscriptionModel.limits?.coWrite?.limit || 0}
                  />
                </DocumentHighlightsContextProvider>
              )}
            </div>
          </div>
        </div>
      )}

      {appModel.appLibraryModel && (
        <FullScreenModal open={model.isLibraryMode} onClose={onCloseAppLibraryModal}>
          <div className={styles.appLibraryContainer}>
            <AppLibrary
              model={appModel.appLibraryModel}
              showUsageStats
              onAppClick={onApplicationSelected}
              onClickLimits={() => goToBilling(navigate, appState.organizationId)}
              onClickContactSales={() => openContactSalesPage()}
              onClickGoToBilling={() => {
                if (appState.organizationId) {
                  goToBillingNewTab(appState.organizationId, BillingProduct.STARTER);
                }
              }}
              onOpenGuideClick={onOpenAppLibraryUrl}
              onOpenTutorialClick={onOpenAppLibraryUrl}
            />
          </div>
        </FullScreenModal>
      )}

      {model.isMagicLinksMode && (
        <MagicLinksContextProvider
          organizationId={Number(orgId)}
          teamId={Number(teamId)}
          onClose={onCloseMagicLinks}
          onLinkSelected={onLinkSelected}
        >
          <MagicLinksSuggestions
            analyticsService={analyticsService}
            onLoading={onMagicLinksLoading}
            onClose={onCloseMagicLinks}
            amount={magicLinksAmount}
            selectedText={magicLinksText}
            isAdmin={!!permissionsModel?.isTeamAdmin || !!permissionsModel?.isOrganizationAdmin}
            onTextUpdate={onTextUpdate}
            updateAmountOfDomains={updateAmountOfDomains}
            doSearch={magicLinksDoSearch}
            isMagicLinksMode={model.isMagicLinksMode}
            resetMagicLinksData={resetMagicLinksData}
          />
        </MagicLinksContextProvider>
      )}

      {!isFullSizeMode && (
        <ContentEditor
          ref={contentEditorRef}
          baseConfig={baseConfig}
          sidebarModel={model.sidebarModel}
          uiSidebarModel={model.uiSidebarModel}
          commandsModel={model.commandsModel}
          contentEditorModel={model.contentEditorModel}
          hideShareDocumentOption={subscriptionModel.isFree}
          onContentChange={onContentChange}
          onOpenSidebar={_onOpenSidebar}
          onRefreshClaim={onRefreshClaim}
          onRefreshScore={onRefreshScore}
          onClickClaimDetection={switchToClaimsDetection}
          onClickHighlights={switchToHighlights}
          onClickMagicLinks={switchToMagicLinks}
          onCoWriteLibraryClick={switchToCoWriteNextState}
          contentEditorPageMode={model.currentMode}
          highlightsLoading={highlightsLoading}
          claimDetectionLoading={claimDetectionLoading}
          claimsLocked={model.claimsLocked}
          focusEditorOnInit={model.isPureEditorMode}
          documentInfoPanelCoWriteButtonState={documentInfoPanelCoWriteButtonState}
          onMagicIconClick={onMagicIconClick}
          magicLinksLoading={magicLinksLoading}
          isLeftSidebarOpen={showLeftSidebarPanel}
          dataRetentionPreferences={dataRetentionModel?.retentionPreferences}
          versionHistoryModel={model.versionHistoryModel}
        />
      )}
      <Start30DayTeamTrialPopup open={isPopupOpen} onCancel={onCancelPopup} onStartTrial={onStartTrial} />
      {appState.isChromeExtensionWindowVisible && !isPopupOpen && <ChromeExtWindow sourceLocation="editor" />}
    </DraftsContextProvider>
  );
};

// TODO: move observer to component itself
// Leaving it here for now to avoid huge merge
export default observer(ContentEditorPage);
