import type React from 'react';
import { Suspense, useMemo } from 'react';

import { LoadingPage } from '@web/component-library';
import { Lock } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import config from 'utils/dynamicConfig';

import BillingContextProvider from '../../../context/billingContext';
import PeopleContextProvider from '../../../context/peopleContext';
import SsoContextProvider from '../../../context/ssoContext';
import TeamsContextProvider from '../../../context/teamsContext';
import { useNavigateToAngular } from '../../../hooks/useNavigateToAngular';
import { CreateTeamApiModel } from '../../../models/createTeam.api';
import { ExtensionConfigModel } from '../../../models/extensionConfig';
import { REACT_RELATIVE_ROUTE, ROUTE } from '../../../services/config/routes';
import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import type { MenuItem } from '../../organisms/SecondaryMenu';
import SecondaryMenuSidebar from '../../organisms/SecondaryMenu';
import { AccountPage } from '../AccountPage';
import { ApiPage } from '../ApiPage';
import { BillingGroupPage } from '../BillingGroupPage/BillingGroupPage';
import BillingPage from '../BillingPage';
import { OAuthAppsPage } from '../OAuthAppsPage';
import PeoplePage from '../PeoplePage';
import { BillingLockState } from '../ReportPage/BillingLockState/BillingLockState';
import { SecurityPage } from '../SecurityPage/SecurityPage';
import { UISecurityPageModel } from '../SecurityPage/UISecurityPageModel';
import SsoPage from '../SsoPage';
import StyleguideEditorPage from '../StyleguideEditorPage';
import TeamsPage from '../TeamsPage';

import styles from './styles.module.css';

interface AdminPanelProps {
  onAiStudioClick: VoidFunction;
}

export const AdminPanel: React.FC<AdminPanelProps> = observer(({ onAiStudioClick }) => {
  const {
    appState,
    appModel: { assistantSubscription, featureFlags, permissionsModel, organizationId, analyticsService },
  } = useAppState();
  const navigate = useNavigate();

  const { linkToStyleguide, linkToSuggestionReport } = useNavigateToAngular(appState.organizationId);

  const createTeamModel = useMemo(
    () =>
      new CreateTeamApiModel({
        organizationId: Number(appState.organizationId),
        request: requestService.api,
      }),
    [appState.organizationId],
  );

  const access = {
    settingsMenu: !!permissionsModel?.isOrganizationAdmin,
    hidePeoplePageFromMenu:
      !assistantSubscription.isEnterprise || (assistantSubscription.isEnterprise && !assistantSubscription.isMultiTeam),
    hideAppsFromMenu: assistantSubscription.isFree,
    disableUserPages: assistantSubscription.isCancelled || !!assistantSubscription?.isTrialExpired,
    hideSamlSSOFromMenu: assistantSubscription.isFree,
    adminSidebarVisible: !assistantSubscription.isFree,
    styleguideLocked: assistantSubscription.isFree,
    apiLocked: assistantSubscription.isFree,
    hasAccessToMain: permissionsModel?.isOrganizationAdmin || !!permissionsModel?.isAdminOfAnyTeam,
    disableReporting: !permissionsModel?.isEnterpriseAdmin,
  };

  // Config for the secondary menu
  const MENU_ADMIN: MenuItem = {
    section: 'Admin',
    list: access.hasAccessToMain
      ? {
          all_teams: {
            name: 'Teams',
            isDisabled: access.disableUserPages,
            navLink: ROUTE.toAllTeams(organizationId),
            trailingIcon: assistantSubscription.isStarter ? <Lock size={12} /> : undefined,
          },
          'styleguide-editor': {
            name: 'Style guide',
            link: access.styleguideLocked ? '' : linkToStyleguide,
            isDisabled: access.disableUserPages,
            isHidden:
              !featureFlags.get('styleguideAvailable', false) || config.SINGLE_TENANCY_CONFIG?.disablePortal === true,
          },
          reporting: {
            name: 'Reporting',
            navLink: featureFlags.get('aiUsageReportAvailable', false)
              ? assistantSubscription.isStarter
                ? ROUTE.toAdminReportingLocked(organizationId)
                : ROUTE.toReportAIUsage(`${organizationId}`)
              : linkToSuggestionReport,
            reload: !featureFlags.get('aiUsageReportAvailable', false),
            trailingIcon: assistantSubscription.isStarter ? <Lock size={12} /> : undefined,
          },
        }
      : {},
  };

  const MENU_SETTINGS: MenuItem = {
    section: 'Settings',
    list: access.settingsMenu
      ? {
          account: {
            name: 'Account',
            navLink: ROUTE.toAdminAccount(organizationId),
          },
          people: {
            name: 'People',
            isHidden: access.hidePeoplePageFromMenu,
            navLink: ROUTE.toPeople(organizationId),
          },
          sso: {
            name: 'Single sign-on',
            isHidden: access.hideSamlSSOFromMenu,
            navLink: ROUTE.toAdminSso(organizationId),
            trailingIcon: assistantSubscription.isStarter ? <Lock size={12} /> : undefined,
          },
          api: {
            name: 'API',
            navLink: ROUTE.toAdminApi(organizationId),
          },
          security: {
            name: 'Security',
            navLink: ROUTE.toAdminSecurity(organizationId),
            trailingIcon: assistantSubscription.isStarter ? <Lock size={12} /> : undefined,
          },
          'oauth-apps': {
            name: 'OAuth apps',
            isHidden: !assistantSubscription.access?.dataConnector,
            navLink: ROUTE.toAdminOAuthApps(organizationId),
          },
          billing: {
            name: assistantSubscription.isFree ? 'Plans and billing' : 'Billing',
            navLink: ROUTE.toAdminBilling(organizationId),
          },
          'billing-groups': {
            name: 'Billing groups',
            isHidden: !assistantSubscription.isEnterprise,
            navLink: ROUTE.toAdminBillingGroups(organizationId),
          },
        }
      : {},
  };

  const menuData: MenuItem[] = [MENU_ADMIN, MENU_SETTINGS];

  // Initialize the models
  const extensionConfigModel = useMemo(
    () =>
      new ExtensionConfigModel({
        request: requestService.api,
        organizationId: () => Number(appState.organizationId),
      }),
    [appState.organizationId],
  );

  const uiSecurityPageModel = useMemo(
    () =>
      new UISecurityPageModel({
        model: extensionConfigModel,
      }),
    [extensionConfigModel],
  );

  if (!(permissionsModel?.isOrganizationAdmin || permissionsModel?.isAdminOfAnyTeam)) {
    navigate(ROUTE.toHome(appState.organizationId));
  }

  return (
    <div className={styles.pageWrapper}>
      {access.adminSidebarVisible && (
        <>
          <SecondaryMenuSidebar data={menuData} />
        </>
      )}
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route
            path={REACT_RELATIVE_ROUTE.allTeams}
            element={
              <TeamsContextProvider createTeamModel={createTeamModel}>
                <TeamsPage />
              </TeamsContextProvider>
            }
          />
          <Route
            path={REACT_RELATIVE_ROUTE.reporting}
            element={<BillingLockState analyticsService={analyticsService} />}
          />
          <Route
            path={REACT_RELATIVE_ROUTE.people}
            element={
              <PeopleContextProvider isOrganizationAdmin={permissionsModel?.isOrganizationAdmin}>
                <PeoplePage isDisabled={MENU_SETTINGS?.list?.people?.isHidden} />
              </PeopleContextProvider>
            }
          />
          <Route path={REACT_RELATIVE_ROUTE.account} element={<AccountPage />} />
          <Route
            path={REACT_RELATIVE_ROUTE.billing}
            element={
              <BillingContextProvider>
                <BillingPage />
              </BillingContextProvider>
            }
          />
          <Route path={REACT_RELATIVE_ROUTE.security} element={<SecurityPage model={uiSecurityPageModel} />} />
          <Route
            path={REACT_RELATIVE_ROUTE.api}
            element={<ApiPage isDisabled={!permissionsModel?.isOrganizationAdmin} onAiStudioClick={onAiStudioClick} />}
          />
          <Route
            path={REACT_RELATIVE_ROUTE.styleguideEditor}
            element={<StyleguideEditorPage isDisabled={MENU_ADMIN.list?.['styleguide-editor']?.isDisabled} />}
          />
          <Route
            path={REACT_RELATIVE_ROUTE.sso}
            element={
              <SsoContextProvider>
                <SsoPage />
              </SsoContextProvider>
            }
          />
          <Route path={REACT_RELATIVE_ROUTE.billingGroups} element={<BillingGroupPage />} />
          <Route path={REACT_RELATIVE_ROUTE.oauthApps} element={<OAuthAppsPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </div>
  );
});

export default AdminPanel;
