import React, { useCallback } from 'react';

import { wordPluralize } from '@writercolab/common-utils';
import { Button, Heading, Textarea } from '@writercolab/fe.wds';

import { cn } from '@web/utils';
import { observer } from 'mobx-react-lite';

import { InviteTeammatesFormModelUi, TInviteFormErrorCode } from './InviteTeammatesFormModel.ui';

import styles from './InviteTeammatesForm.module.css';

interface IInviteTeammatesFormProps {
  id?: string;
  className?: string;
  onCancel?: () => void;
  model: InviteTeammatesFormModelUi;
  onBillingPageClick?: () => void;
  inputPlaceholder?: string;
  inputLabel?: string;
  inputMinRows?: number;
}

interface IInviteButtonTextProps {
  seatsCount: number;
}

interface IErrorMessageProps {
  errorCode: typeof TInviteFormErrorCode.type;
  onBillingPageClick?: () => void;
}

interface ISeatAvailabilityProps {
  availableSeats: number;
  maxSeats: number;
}

interface IActionButtonsProps {
  onCancel?: () => void;
  canSubmit: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  pendingInviteCount: number;
}

const InviteButtonText = ({ seatsCount }: IInviteButtonTextProps) => (
  <div className={styles.inviteButtonText}>{seatsCount > 1 ? `Invite ${seatsCount} people` : 'Invite'}</div>
);

const FormHeader = () => (
  <>
    <Heading size="h1" className={styles.heading}>
      Invite people
    </Heading>
    <Heading size="h2" className={styles.subHeading}>
      Your teammates will receive an email with an invitation link
    </Heading>
  </>
);

const ErrorMessage = ({ errorCode, onBillingPageClick }: IErrorMessageProps) => {
  if (!errorCode) {
    return null;
  }

  return TInviteFormErrorCode.match(
    errorCode,
    {
      [TInviteFormErrorCode.enum.maxSeatsReached]: () => (
        <div className={styles.errorText}>
          Too many addresses. You can purchase more seats on the{' '}
          <Button className={styles.link} variant="link" onClick={onBillingPageClick}>
            billing page
          </Button>
          .
        </div>
      ),
      [TInviteFormErrorCode.enum.emailInvalid]: () => (
        <div className={styles.errorText}>Field contains invalid email.</div>
      ),
      [TInviteFormErrorCode.enum.emailsRequired]: () => <div className={styles.errorText}>Required field.</div>,
    },
    TInviteFormErrorCode.enum.emailInvalid,
  );
};

const SeatAvailability = ({ availableSeats, maxSeats }: ISeatAvailabilityProps) => (
  <div className={styles.availabilityContainer}>
    <div className={styles.availabilityText}>
      {availableSeats}/{maxSeats} {wordPluralize(availableSeats, 'seat')} available
    </div>
  </div>
);

const ActionButtons = ({ onCancel, canSubmit, isLoading, onSubmit, pendingInviteCount }: IActionButtonsProps) => (
  <div className={styles.buttonsContainer}>
    <Button variant="outline" onClick={onCancel}>
      Cancel
    </Button>
    <Button variant="primary" disabled={!canSubmit} onClick={onSubmit} loading={isLoading}>
      <InviteButtonText seatsCount={pendingInviteCount} />
    </Button>
  </div>
);

export const InviteTeammatesForm = observer<IInviteTeammatesFormProps>(
  ({ id, onCancel, className, onBillingPageClick, inputPlaceholder, model, inputLabel }) => {
    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        model.emails.set(e.target.value, () => {
          model.validateSeats();
        });
      },
      [model],
    );

    return (
      <div id={id} className={cn(className, styles.container)}>
        <FormHeader />
        <div className={styles.inputGroup}>
          <Textarea
            label={inputLabel || 'Email addresses'}
            className={cn(styles.form, {
              [styles.hasError]: !!model.errorCode,
            })}
            onChange={handleInputChange}
            value={model.emails.value}
            placeholder={inputPlaceholder || 'teammate1@company.com, teammate2@company.com'}
          />
          {model.errorCode && (
            <div className={styles.errorContainer}>
              <ErrorMessage errorCode={model.errorCode} onBillingPageClick={onBillingPageClick} />
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <SeatAvailability availableSeats={model.availableSeats} maxSeats={model.maxSeats} />
          <ActionButtons
            onCancel={onCancel}
            canSubmit={model.canSubmit}
            isLoading={model.isLoading}
            onSubmit={model.submit}
            pendingInviteCount={model.pendingInviteCount}
          />
        </div>
      </div>
    );
  },
);
