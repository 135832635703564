import { action, computed, makeObservable, observable } from 'mobx';

import { PromisedModel, ReactiveQueue } from '@writercolab/mobx';
import { ModalsManager } from '@writercolab/models';
import { NotificationQueueItemType, TNotificationQueueItem } from '@writercolab/types';
import { Enum } from '@writercolab/utils';

import {
  TOAuthAppsConnectorResponse,
  TOAuthAppsCreateConnectorRequest,
  TOAuthAppsTypes,
  TOAuthAppsUpdateConnectorRequest,
  TPaginatedOAuthAppsExtraArgs,
  WRITER_OAUTH_APP_TYPES,
} from '@web/types';
import { IWebAppAnalyticsTrack } from 'constants/analytics';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { OAuthAppsApiModel } from 'models/oauthApps.api';

import { getLogger } from '../../../utils/logger';
import { OAuthAppsTableUIModel } from '../../organisms/OAuthAppsTable/OAuthAppsTableModel.ui';
import { OAuthAppsViewerUIModel } from '../../organisms/OAuthAppsViewer/OAuthAppsViewerModel.ui';

interface IOAuthAppsPageUIModelOpts {
  api: OAuthAppsApiModel;
  analyticsService: IWebAppAnalyticsTrack;
  isConfluenceConnectorEnabled: () => boolean;
  isAdvancedConnectorsEnabled: () => boolean;
}

const LOG = getLogger('OAuthAppsPageUIModel');
export const OAuthAppsModalType = new Enum('viewer', 'delete');
export type TOAuthModalState = {
  connectorId: string;
} | null;
export type TOAuthAppsModalManager = ModalsManager<typeof OAuthAppsModalType.type, TOAuthModalState>;

export class OAuthAppsPageUIModel {
  api: OAuthAppsApiModel;
  modalsManager: TOAuthAppsModalManager;
  notificationQueue: ReactiveQueue<TNotificationQueueItem>;
  oauthAppsViewerModel: OAuthAppsViewerUIModel;
  oauthAppsTableModel: OAuthAppsTableUIModel;

  private $appsType = observable.box<string | undefined>(undefined);
  constructor(private opts: IOAuthAppsPageUIModelOpts) {
    this.api = this.opts.api;
    this.notificationQueue = new ReactiveQueue<TNotificationQueueItem>();
    this.modalsManager = new ModalsManager<typeof OAuthAppsModalType.type, TOAuthModalState>();

    this.oauthAppsViewerModel = new OAuthAppsViewerUIModel({
      modalsManager: this.modalsManager,
      handleCreate: this.onCreate,
      handleEdit: this.handleEdit,
      getSelectedConnector: () => this.selectedConnector,
      getSelectedConnectorLoading: () => this.isSelectedConnectorLoading,
      getIsConfluenceConnectorEnabled: this.opts.isConfluenceConnectorEnabled,
      isAdvancedConnectorsEnabled: this.opts.isAdvancedConnectorsEnabled,
      getConnectorTypes: () => this.$connectorTypes.value,
      api: this.api,
      analyticsService: this.opts.analyticsService,
    });

    this.oauthAppsTableModel = new OAuthAppsTableUIModel({
      api: this.opts.api,
      modalsManager: this.modalsManager,
    });

    makeObservable(this, {
      isShowViewerModal: computed,
      isShowDeleteModal: computed,
      currentActiveEntityId: computed,
      isSelectedConnectorLoading: computed,
      isStateEmpty: computed,
      organizationSettings: computed,
      appsType: computed,
      setAppsType: action,
    });
  }

  get currentActiveEntityId() {
    const res = this.modalsManager.getArrayModalState()[0];

    return res?.connectorId;
  }

  private $selectedConnector = new PromisedModel({
    name: '$selectedConnector',
    load: async () => {
      if (!this.currentActiveEntityId) {
        return undefined;
      }

      const res = await this.api.getConnectorById(this.currentActiveEntityId);

      return res;
    },
  });

  private $organizationSettings = new PromisedModel({
    name: '$organizationSettings',
    load: async () => {
      const res = await this.api.getOrganizationSettings();

      return res;
    },
  });

  private $connectorTypes = new PromisedModel({
    name: '$connectorTypes',
    load: async () => {
      if (this._cachedConnectorTypes) {
        return this._cachedConnectorTypes;
      }
      const res = await this.api.getConnectorTypes();
      this._cachedConnectorTypes = res;

      return res;
    },
  });

  private _cachedConnectorTypes: { type: TOAuthAppsTypes; enabled: boolean }[] | undefined;

  get isSelectedConnectorLoading() {
    return this.$selectedConnector.status === 'pending';
  }

  get selectedConnector(): TOAuthAppsConnectorResponse | undefined {
    return this.$selectedConnector.value;
  }

  get organizationSettings() {
    return this.$organizationSettings.value;
  }

  onCreate = async (body: TOAuthAppsCreateConnectorRequest) => {
    try {
      await this.api.createConnector(body);
      await this.api.refresh();
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: `Your connector ${body.name} was created`,
      });
      this.modalsManager.hideModal();
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: `Your connector ${body.name} was not created`,
      });
    }
  };

  onDelete = async (connectorId: string) => {
    const connectorName = this.api.data?.find(item => item.id === connectorId)?.name || '';
    try {
      await this.api.deleteConnector(connectorId);
      await this.api.refresh();
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.delete,
        message: `Your connector ${connectorName} was deleted`,
      });
      this.modalsManager.hideModal();
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: `Your connector ${connectorName} was not deleted`,
      });
    }
  };

  handleDelete = async () => {
    if (!this.currentActiveEntityId) {
      return;
    }

    await this.onDelete(this.currentActiveEntityId);
  };

  onEdit = async (connectorId: string, body: TOAuthAppsUpdateConnectorRequest) => {
    try {
      await this.api.editConnector(connectorId, body);
      await this.api.refresh();
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: `Your connector ${body.name} was edited`,
      });
      this.modalsManager.hideModal();
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: `Your connector ${body.name} was not edited`,
      });
    }
  };

  handleEdit = async (body: TOAuthAppsUpdateConnectorRequest) => {
    if (!this.selectedConnector) {
      return;
    }

    await this.onEdit(this.selectedConnector.id, body);
  };

  get appsType() {
    // IMPORTANT we need to call this value to subscribe for promise model
    const defaultValue = String(this?.organizationSettings?.useWriterOauthApps);

    return this.$appsType.get() ?? defaultValue;
  }

  setAppsType = (appsType: string) => {
    // set value for ui
    this.$appsType.set(appsType);
    // update value for api
    this.updateAppsTypeDebounced(appsType === WRITER_OAUTH_APP_TYPES.MANAGED);
  };

  private refreshOrganizationSettings = async () => {
    this.$organizationSettings.reload();
    await this.$organizationSettings.promise;
  };

  updateAppsTypeDebounced = debounce(async (useWriterOauthApps: boolean) => {
    try {
      // need refresh data to get latest
      await this.refreshOrganizationSettings();

      const body = this.organizationSettings;
      this.api.updateOrganizationSettings({
        ...body,
        useWriterOauthApps,
      });
    } catch (err) {
      LOG.error(err);
    }
  }, 500);

  updateExtraArgsDebounced = debounce((extraArgs: TPaginatedOAuthAppsExtraArgs) => {
    this.api.pagination.setExtra(extraArgs);
  }, 500);

  get isShowViewerModal() {
    return this.modalsManager.isModalVisible(OAuthAppsModalType.enum.viewer);
  }

  get isShowDeleteModal() {
    return this.modalsManager.isModalVisible(OAuthAppsModalType.enum.delete);
  }

  closeModal = () => {
    this.oauthAppsViewerModel.setConnectorType(undefined);
    this.modalsManager.hideModal();
  };

  openViewerModal = () => {
    this.oauthAppsViewerModel.setConnectorType(undefined);
    this.modalsManager.showModal(OAuthAppsModalType.enum.viewer);
  };

  get isStateEmpty() {
    return isEmpty(this.api.data);
  }
}
