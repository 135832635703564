import type { BillingProduct } from '@writercolab/common-utils';
import { ContentEditorPageMode, SharedQueryParam } from '@writercolab/common-utils';
import { ASK_WRITER_TEMPLATE_ID } from '@writercolab/ui-chat-apps';
import { CREATE_NEW_DOC_ID, EMPTY_EDITOR_DOC_ID, SETUP_ROUTES } from '@writercolab/utils';

import {
  BLOG_BUILDER_APP_ID,
  RECAPS_APP_ID,
  TLearningCenterVideoId,
  TPromptsLibraryQueryParams,
  TPromptsLibraryTabsId,
} from '@web/types';
import isEmpty from 'lodash/isEmpty';

import config from '../../utils/dynamicConfig';
import { goToNative } from '../../utils/navigationUtils';
import type { QueryParams } from '../../utils/routesUtils';
import {
  buildDashboardParams,
  generateQueryStringFromParams,
  redirectToLearningVideo,
  substituteRoute,
} from '../../utils/routesUtils';

type TRouteParam = string | number;

const host = config.ANGULAR_REDIRECT || window.location.host;

export const ANGULAR_ROUTE = {
  host,
  toTermBank: (orgId: TRouteParam, teamId: TRouteParam) =>
    `${ANGULAR_ROUTE.host}/organization/${orgId}/team/${teamId}/terms`,
  toPortal: (orgId: TRouteParam) => `${ANGULAR_ROUTE.host}/organization/${orgId}/portal/team/home`,
  toSuggestionReport: (orgId: TRouteParam) => `${ANGULAR_ROUTE.host}/organization/${orgId}/reporting/team/suggestions`,
  toActivityReport: (orgId: TRouteParam) => `${ANGULAR_ROUTE.host}/organization/${orgId}/reporting/team/activity`,
  toActivity: (orgId: TRouteParam) => `${ANGULAR_ROUTE.host}/organization/${orgId}/reporting/team/activity`,
  toSamlSSO: (orgId: TRouteParam) => `${ANGULAR_ROUTE.host}/organization/${orgId}/sso/team`,
  toApps: (orgId: TRouteParam) => `${ANGULAR_ROUTE.host}/organization/${orgId}/plugins/team`,
  toLegacyApp: (orgId: TRouteParam) => `${ANGULAR_ROUTE.host}/organization/${orgId}/dashboard/activity`,
};

export const REACT_RELATIVE_ROUTE = {
  wildcard: '/*',
  root: '/',
  questionnaireChrome: '/organization/:orgId/chrome-questionnaire',
  logout: '/logout',
  newUserActionRedirect: '/organization/:orgId/join-request',
  loginExternal: '/sign-in',
  authRedirect: '/redirect-auth',
  appStudioSignup: '/aistudio/signup',
  appStudioSignin: '/aistudio/sign-in',
  appStudioResetPassword: '/aistudio/reset-password',
  orgBasePath: '/organization/:orgId',
  teamBasePath: '/organization/:orgId/team/:teamId',
  onboarding: '/organization/:orgId/onboarding',
  chromeOnboarding: '/organization/:orgId/chrome-onboarding',
  emailConfirmation: '/confirm/email',
  emailDeleteAccountConfirmation: '/confirm/account-delete',
  profile: '/organization/:orgId/profile',

  // google auth
  googleAuth: '/auth/social/google',

  // ai studio
  aiStudioRoot: '/aistudio/organization/:orgId',

  // ai studio builder
  aiStudioBuilder: '/app/:applicationId',
  aiStudioDeploy: '/deploy',
  aiStudioGuide: '/guide',

  // ai studio observability
  aiStudioObservability: '/observability',
  aiStudioAppEngagement: '/engagement',
  aiStudioAppPerformance: '/performance',
  aiStudioAppUsage: '/usage',
  aiStudioAppSessionLogs: '/session-logs',

  // console
  aiStudioAnalytics: '/analytics',
  aiStudioGeneralObservability: '/observability',
  aiStudioPlayground: '/playground',
  aiStudioGuiderails: '/ai-guardrails',
  aiStudioVoice: '/ai-guardrails-voice',
  aiStudioCustomRules: '/ai-guardrails-custom-rules',
  aiStudioKnowledgeGraph: '/knowledge-graph',
  aiStudioApiKeys: '/api-keys',
  aiStudioApiKeysApp: '/api-keys/:applicationId',
  aiStudioApiKeysPage: '/keys',
  aiStudioPeople: '/people',
  aiStudioUsage: '/usage',
  aiStudioBilling: '/billing',
  aiStudioAccount: '/account',
  aiStudioSessionLogs: '/session-logs',
  aiStudioTemplates: '/templates',
  aiStudioProfile: '/profile',
  aiStudioLearningCenter: '/learning/*',
  aiStudioLearningCenterWriterAcademy: '/learning/writeracademy',
  aiStudioLearningCenterDocs: '/learning/docs',

  // framework app
  aiStudioFrameworkApp: '/framework/:applicationId',
  aiStudioFrameworkAppDeploy: '/deploy',
  aiStudioFrameworkAppKeys: '/keys',
  aiStudioFrameworkObservabilityPerformance: '/performance',
  aiStudioFrameworkObservabilityUsage: '/usage',
  aiStudioFrameworkObservabilityLog: '/log',

  // agent editor
  agentEditorApp: '/agent/:applicationId',
  agentEditor: '/agent',
  aiStudioAgentEditorBuild: '/build',
  aiStudioAgentEditorDeploy: '/deploy',

  // report
  report: '/organization/:orgId/report',
  aiUsage: '/aiusage',
  userActivity: '/user-activity',
  adminAuditLog: '/admin-audit-log',

  // extensions
  apps: '/organization/:orgId/apps', // legacy route
  extensions: '/organization/:orgId/extensions',

  // home
  homeBase: '/organization/:orgId',
  home: '/organization/:orgId/team/:teamId/',

  editorPage: '/organization/:orgId/team/:teamId/document/:docId/',
  frameworkAppPage: '/organization/:orgId/team/:teamId/framework/:applicationId',
  teamUsers: '/organization/:orgId/team/:teamId/users',

  // drafts
  drafts: '/organization/:orgId/team/:teamId/drafts',
  myWork: '/organization/:orgId/team/:teamId/my-work',
  docs: '/docs',
  sessions: '/sessions',
  outputs: '/outputs',

  // styleguide
  setup: '/organization/:orgId/team/:teamId/setup',
  terms: '/terms',
  snippets: '/snippets',
  suggestions: '/suggestions',

  // suggestions
  punctuation: '/punctuation',
  writingStyle: '/writing-style',
  clarity: '/clarity',
  delivery: '/delivery',
  inclusivity: '/inclusivity',
  compliance: '/compliance',
  plagiarism: '/plagiarism',
  general: '/general',
  customRules: '/custom-rules',
  reWrite: '/reWrite',
  knowledge: '/knowledge',
  magicLinks: '/magicLinks',
  voice: '/voice',

  // admin
  admin: '/organization/:orgId/admin/*',
  allTeams: '/all_teams',
  people: '/people',
  api: '/api',
  account: '/account',
  security: '/security',
  oauthApps: '/oauth-apps',
  billing: '/billing',
  billingGroups: '/billing-groups',
  sso: '/sso',
  styleguideEditor: '/styleguide-editor',
  reporting: '/reporting',

  // templates
  templateCategories: '/organization/:orgId/team/:teamId/templates',
  templates: '/organization/:orgId/team/:teamId/templates/:templateId',

  teamPrompts: '/team-prompts',
  apiKeys: '/api-keys',
  aiStudioApiObservabilityPerformance: '/performance',
  aiStudioApiObservabilityUsage: '/usage',
  aiStudioApiObservabilityLog: '/log',

  consoleApplication: '/application/*', // root
  consoleGatewayApi: '/api-keys/:applicationId',
  consoleChat: '/chat/:applicationId/?:versionId?',
  consoleRewrite: '/rewrite',
  consoleGeneration: '/generation/:applicationId/?:versionId?',

  // apps access
  authApp: '/auth/app',

  // ask
  askBase: '/organization/:orgId/team/:teamId/ask/',
  askWithParam: '/organization/:orgId/team/:teamId/ask/:sessionId?/*',

  // chat apps
  chatApps: '/organization/:orgId/team/:teamId/chat-app/:appId',
  chatAppsWithParam: '/organization/:orgId/team/:teamId/chat-app/:appId/session/:sessionId?/*',

  // ask + chat apps
  prompts: '/prompts',

  // learning center
  learningCenter: '/organization/:orgId/team/:teamId/learning/*',
  learningCenterVideo: '/organization/:orgId/team/:teamId/learning/videos/:videoId',
  learningCenterVideos: '/organization/:orgId/team/:teamId/learning/videos/',
  learningCenterPromptLibrary: '/organization/:orgId/team/:teamId/learning/prompts',
  learningCenterWebinars: '/organization/:orgId/team/:teamId/learning/webinars',

  // KnowledgeGraph connector redirect
  knowledgeGraphConnectorRedirect: '/connector/:connectorId',

  // research assistant
  researchAssistant: '/organization/:orgId/team/:teamId/research-assistant/:appId',
};

export const ROUTE = {
  host,
  root: '/',
  questionnaireChrome: '/organization/:orgId/chrome-questionnaire',
  loginExternal: '/sign-in',
  authRedirect: '/redirect-auth',
  orgBasePath: '/organization/:orgId',
  home: '/organization/:orgId/team/:teamId',
  apps: '/organization/:orgId/apps',
  admin: '/organization/:orgId/admin',
  allTeams: '/organization/:orgId/admin/all_teams',
  people: '/organization/:orgId/admin/people',
  account: '/organization/:orgId/admin/account',
  api: '/organization/:orgId/admin/api',
  profile: '/organization/:orgId/profile',
  security: '/organization/:orgId/admin/security',
  reporting: '/organization/:orgId/admin/reporting',
  styleguideEditor: '/organization/:orgId/admin/styleguide-editor',
  teamBasePath: '/organization/:orgId/team/:teamId',
  editorPage: '/organization/:orgId/team/:teamId/document/:docId',
  frameworkAppPage: '/organization/:orgId/team/:teamId/framework/:applicationId',
  teamUsers: '/organization/:orgId/team/:teamId/users',
  onboarding: '/organization/:orgId/onboarding',
  chromeOnboarding: '/organization/:orgId/chrome-onboarding',
  emailConfirmation: '/confirm/email',

  myWork: `${REACT_RELATIVE_ROUTE.myWork}`,
  docs: `${REACT_RELATIVE_ROUTE.myWork}${REACT_RELATIVE_ROUTE.docs}`,
  sessions: `${REACT_RELATIVE_ROUTE.myWork}${REACT_RELATIVE_ROUTE.sessions}`,
  outputs: `${REACT_RELATIVE_ROUTE.myWork}${REACT_RELATIVE_ROUTE.outputs}`,

  setup: `${REACT_RELATIVE_ROUTE.setup}`,
  terms: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.terms}`,
  snippets: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.snippets}`,
  newTerms: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.terms}`,
  newSnippets: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.snippets}`,
  suggestions: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}`,

  punctuation: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.punctuation}`,
  writingStyle: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.writingStyle}`,
  clarity: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.clarity}`,
  delivery: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.delivery}`,
  inclusivity: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.inclusivity}`,
  compliance: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.compliance}`,
  plagiarism: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.plagiarism}`,
  general: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.general}`,
  customRules: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.customRules}`,
  reWrite: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.reWrite}`,
  knowledge: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.knowledge}`,
  magicLinks: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.magicLinks}`,
  voice: `${REACT_RELATIVE_ROUTE.setup}${REACT_RELATIVE_ROUTE.suggestions}${REACT_RELATIVE_ROUTE.voice}`,

  oauthApps: '/organization/:orgId/admin/oauth-apps',
  billing: '/organization/:orgId/admin/billing',
  billingGroups: '/organization/:orgId/admin/billing-groups',
  sso: '/organization/:orgId/admin/sso',
  teamDrafts: '/organization/:orgId/team/:teamId/drafts',
  teamKnowledge: '/organization/:orgId/team/:teamId/knowledge',
  ask: '/organization/:orgId/team/:teamId/ask/:sessionId?/',

  toExtensions: orgId => `/organization/${orgId}/extensions`,

  toHomeBase: (organizationId: TRouteParam, additionalParams: Record<string, string>) =>
    substituteRoute(REACT_RELATIVE_ROUTE.homeBase, { orgId: organizationId }, additionalParams),

  toHome: (
    orgId: TRouteParam = '',
    teamId: TRouteParam = 0,
    showBoarding: boolean = false,
    showTutorial = false,
    lcVideoUrl?: typeof TLearningCenterVideoId.type,
  ) => {
    const url = `/organization/${orgId}/team/${teamId}`;

    return buildDashboardParams(url, showBoarding, showTutorial, lcVideoUrl);
  },
  // Generates URL that redirects user to the editor page with the given document ID. Also accepts a query string for additional params.
  toEditorPage: (orgId, teamId, docId, query: string = '') =>
    `/organization/${orgId}/team/${teamId}/document/${docId}${query}`,

  // Generates URL that redirects user to the editor page
  // Creates new document
  toNewEditorPage: (orgId, teamId) => ROUTE.toEditorPage(orgId, teamId, CREATE_NEW_DOC_ID),

  // Generates URL that redirects user to the editor page, opens library
  // Doesn't create a new document
  toEditorWithCoWrite: (orgId, teamId) => {
    const url = ROUTE.toEditorPage(orgId, teamId, EMPTY_EDITOR_DOC_ID);

    return `${url}?mode=${ContentEditorPageMode.START_WITH_LIBRARY}`;
  },

  // Generates URL that redirects user to the editor page, opens Recaps
  // Creates new document
  toEditorWithEventTakeaways: (orgId, teamId, docId) => {
    const url = ROUTE.toEditorPage(orgId, teamId, docId);

    return `${url}?mode=${ContentEditorPageMode.EVENT_TAKEAWAYS}`;
  },

  // Generates URL that redirects user to the editor page, opens Recaps and highlights one of the drafts
  // Creates new document
  toEditorWithEventTakeawaysAndDraftHighlight: (orgId, teamId, docId, draftId) => {
    const url = ROUTE.toEditorPage(orgId, teamId, docId);

    return `${url}?mode=${ContentEditorPageMode.EVENT_TAKEAWAYS}&draftId=${draftId}`;
  },

  toEditorWithBlogBuilder: (orgId, teamId, docId, params?: Record<string, string | null>) => {
    const url = ROUTE.toEditorPage(orgId, teamId, docId);
    const _params = isEmpty(params)
      ? {}
      : Object.keys(params)
          .filter(k => params[k] !== null)
          .reduce((a, k) => ({ ...a, [k]: params[k] }), {});
    const queryString = generateQueryStringFromParams({
      mode: ContentEditorPageMode.BLOG,
      ..._params,
    });

    return `${url}${queryString}`;
  },

  toNewDocumentWithTemplates: (orgId: any, teamId: any, categoryId?: string, templateId?: string) => {
    let queryParams: any = {
      mode: ContentEditorPageMode.LIBRARY,
      coWriteCategoryId: categoryId || 'all-templates',
    };

    const navigationBuildersTemplates = [
      {
        mode: 'askWriter',
        templateId: ASK_WRITER_TEMPLATE_ID,
      },
      {
        mode: ContentEditorPageMode.BLOG,
        templateId: BLOG_BUILDER_APP_ID,
      },
      {
        mode: ContentEditorPageMode.EVENT_TAKEAWAYS,
        templateId: RECAPS_APP_ID,
      },
    ];

    if (templateId) {
      const builderTemplate = navigationBuildersTemplates.find(template =>
        Object.values(template).includes(templateId),
      );
      queryParams = {
        mode: builderTemplate ? builderTemplate.mode : ContentEditorPageMode.CO_WRITE,
        currentTemplateId: builderTemplate ? builderTemplate.templateId : templateId,
      };
    }

    const query = generateQueryStringFromParams(queryParams);

    return ROUTE.toEditorPage(orgId, teamId, CREATE_NEW_DOC_ID, query);
  },

  toNewDocumentWithMode: (orgId, teamId, mode: ContentEditorPageMode) =>
    ROUTE.toEditorWithModeAndTemplate(orgId, teamId, CREATE_NEW_DOC_ID, mode),

  toEditorWithModeAndTemplate: (
    orgId: string,
    teamId: string,
    documentId: string | number,
    mode: ContentEditorPageMode,
    preopenDraftTemplateId?: string,
  ) => {
    const queryParams: any = { mode };

    if (preopenDraftTemplateId) {
      queryParams.currentTemplateId = preopenDraftTemplateId;
    }

    const query = generateQueryStringFromParams(queryParams);

    return ROUTE.toEditorPage(orgId, teamId, documentId, query);
  },

  toTeamUsers: (orgId, teamId, additionalQueryParams?: Record<string, string>) => {
    let teamUsersPageUrl = `/organization/${orgId}/team/${teamId}/users`;

    if (additionalQueryParams) {
      teamUsersPageUrl += generateQueryStringFromParams(additionalQueryParams);
    }

    return teamUsersPageUrl;
  },

  toPeople: (orgId, additionalQueryParams?: Record<string, string>) => {
    let peoplePageUrl = `/organization/${orgId}/admin/people`;

    if (additionalQueryParams) {
      peoplePageUrl += generateQueryStringFromParams(additionalQueryParams);
    }

    return peoplePageUrl;
  },

  toAllTeams: orgId => `/organization/${orgId}/admin/all_teams`,
  toAdminOAuthApps: orgId => `/organization/${orgId}/admin/oauth-apps`,
  toAdminAccount: orgId => `/organization/${orgId}/admin/account`,
  toAdminApi: orgId => `/organization/${orgId}/admin/api`,
  toAdminBilling: orgId => `/organization/${orgId}/admin/billing`,
  toAdminBillingGroups: orgId => `/organization/${orgId}/admin/billing-groups`,
  toAdminSso: orgId => `/organization/${orgId}/admin/sso`,
  toAdminSecurity: orgId => `/organization/${orgId}/admin/security`,
  toAdminReportingLocked: orgId => `/organization/${orgId}/admin/reporting`,

  toOnboarding: orgId => ROUTE.onboarding.replace(':orgId', orgId),
  toChromeOnboarding: orgId => ROUTE.chromeOnboarding.replace(':orgId', orgId).replace(':chrome', orgId),
  toProfile: orgId => ROUTE.profile.replace(':orgId', orgId),

  toTerms: (orgId: TRouteParam, teamId: TRouteParam) => substituteRoute(ROUTE.terms, { orgId, teamId }),
  toNewTerms: (orgId: TRouteParam, teamId: TRouteParam) => substituteRoute(ROUTE.newTerms, { orgId, teamId }),
  toSnippets: (orgId: TRouteParam, teamId: TRouteParam, additionalQueryParams?: Record<string, string>): string =>
    substituteRoute(ROUTE.snippets, { orgId, teamId }, additionalQueryParams),
  toNewSnippets: (orgId: TRouteParam, teamId: TRouteParam, additionalQueryParams?: Record<string, string>): string =>
    substituteRoute(ROUTE.newSnippets, { orgId, teamId }, additionalQueryParams),
  toBilling: (orgId, triggerAction?: BillingProduct) =>
    `/organization/${orgId}/admin/billing?${triggerAction ? `triggerUpdateTo=${triggerAction}` : ''}`,

  toSuggestions: (orgId: TRouteParam, teamId: TRouteParam) => substituteRoute(ROUTE.suggestions, { orgId, teamId }),
  toSuggestionsMagicLinks: (orgId: TRouteParam, teamId: TRouteParam) =>
    substituteRoute(ROUTE.magicLinks, { orgId, teamId }),
  toKnowledgeGraph: (orgId: TRouteParam, teamId: TRouteParam) => substituteRoute(ROUTE.knowledge, { orgId, teamId }),

  toTeamDrafts: (orgId, teamId) => `/organization/${orgId}/team/${teamId}/drafts`,
  toMyWork: (orgId: TRouteParam, teamId: TRouteParam) => substituteRoute(ROUTE.myWork, { orgId, teamId }),
  toDocs: (orgId: TRouteParam, teamId: TRouteParam) => substituteRoute(ROUTE.docs, { orgId, teamId }),
  toSessions: (orgId: TRouteParam, teamId: TRouteParam) => substituteRoute(ROUTE.sessions, { orgId, teamId }),
  toOutputs: (orgId: TRouteParam, teamId: TRouteParam) => substituteRoute(ROUTE.outputs, { orgId, teamId }),

  toTeamKnowledge: (orgId, teamId) => `/organization/${orgId}/team/${teamId}/knowledge`,

  toAskWriter: (orgId, teamId, sessionId?) => `/organization/${orgId}/team/${teamId}/ask/${sessionId || ''}`,
  toAskWriterNewSession: (orgId, teamId) => `/organization/${orgId}/team/${teamId}/chat-app/ask/?new=true`,
  toAskWriterNewSessionWithOpenPL: (orgId, teamId) =>
    `/organization/${orgId}/team/${teamId}/chat-app/ask/?openPL=true&new=true&${TPromptsLibraryQueryParams.enum.plTab}=${TPromptsLibraryTabsId.enum.user}`,
  toAskWriterNewSessionWithOpenPLTeamPrompts: (orgId, teamId) =>
    `/organization/${orgId}/team/${teamId}/chat-app/ask/?openPL=true&new=true&${TPromptsLibraryQueryParams.enum.plTab}=${TPromptsLibraryTabsId.enum.team}`,
  toChatAppBase: (orgId, teamId) => `/organization/${orgId}/team/${teamId}/chat-app/`,
  toChatApp: (orgId, teamId, appId, sessionId?) =>
    `/organization/${orgId}/team/${teamId}/chat-app/${appId}/session/${sessionId || ''}`,
  toResearchApp: (orgId, teamId, appId) => `/organization/${orgId}/team/${teamId}/research-assistant/${appId}`,
  toLearningCenter: (orgId, teamId) => `/organization/${orgId}/team/${teamId}/learning/`,
  toLearningCenterVideo: (orgId, teamId, videoId) => `/organization/${orgId}/team/${teamId}/learning/videos/${videoId}`,
  toLearningCenterVideos: (orgId, teamId) => `/organization/${orgId}/team/${teamId}/learning/videos`,
  toLearningCenterPrompts: (orgId, teamId) =>
    `/organization/${orgId}/team/${teamId}/learning/prompts?${TPromptsLibraryQueryParams.enum.plTab}=${TPromptsLibraryTabsId.enum.user}`,
  toLearningCenterTeamPrompts: (orgId, teamId) =>
    `/organization/${orgId}/team/${teamId}/learning/prompts?${TPromptsLibraryQueryParams.enum.plTab}=${TPromptsLibraryTabsId.enum.team}`,
  toLearningCenterWebinars: (orgId, teamId) => `/organization/${orgId}/team/${teamId}/learning/webinars`,
  toLearningCenterWriterAcademy: (orgId, teamId) => `/organization/${orgId}/team/${teamId}/learning/writeracademy`,
  toTeamDraftsWithDraftId: (orgId, teamId, searchQuery) =>
    `/organization/${orgId}/team/${teamId}/drafts?${searchQuery}`,
  toTeamOutputsWithOutputId: (orgId, teamId, searchQuery) =>
    `/organization/${orgId}/team/${teamId}/my-work/outputs?${searchQuery}`,
  toDocumentDrafts: (orgId, teamId, documentId, templateId) => {
    const queryParams = {
      mode: 'coWrite',
      currentTemplateId: templateId,
    };

    const query = generateQueryStringFromParams(queryParams);

    return ROUTE.toEditorPage(orgId, teamId, documentId, query);
  },
  toAuthRedirect: (queryParams: QueryParams): string => {
    const query = generateQueryStringFromParams(queryParams);

    return `${ROUTE.authRedirect}${query}`;
  },

  toTeamPrompts: (orgId: TRouteParam, teamId: TRouteParam) => `/organization/${orgId}/team/${teamId}/team-prompts`,

  toAiStudioGuide: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.appGuide, { orgId, applicationId }),
  toAiStudioBuild: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.appBuild, { orgId, applicationId }),
  toAiStudioProfile: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.profile, { orgId }),
  toAiStudioDeploy: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.appDeployment, { orgId, applicationId }),
  toAiStudioAppEngagement: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.appEngagement, { orgId, applicationId }),
  toAiStudioAppPerformance: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.appPerformance, { orgId, applicationId }),
  toAiStudioAppUsage: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.appUsage, { orgId, applicationId }),
  toAiStudioAppSessionLogs: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.appSessionLogs, { orgId, applicationId }),
  toAiStudioApiPeformance: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.apiObservabilityPerformance, { orgId, applicationId }),
  toAiStudioApiUsage: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.apiObservabilityUsage, { orgId, applicationId }),
  toAiStudioApiLog: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.apiObservabilityLog, { orgId, applicationId }),
  toAiStudioFrameworkPeformance: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.frameworkAppObservabilityPerformance, { orgId, applicationId }),
  toAiStudioFrameworkUsage: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.frameworkAppObservabilityUsage, { orgId, applicationId }),
  toAiStudioFrameworkLog: (orgId: TRouteParam, applicationId: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.frameworkAppObservabilityLog, { orgId, applicationId }),
  toAiStudioHome: (orgId: TRouteParam, queryParams?: QueryParams) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.home, { orgId }, queryParams),
  toAiStudioAnalytics: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.analytics, { orgId }),
  toAiStudioGeneralObservability: (orgId: TRouteParam) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.generalObservability, { orgId }),
  toAiStudioPlayground: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.playground, { orgId }),
  toAiStudioCustomRules: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.customRules, { orgId }),
  toAiStudioVoice: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.voice, { orgId }),
  toAiStudioKnowledgeGraph: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.knowledgeGraph, { orgId }),
  toAiStudioApiApp: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.apiKeys, { orgId }),
  toAiStudioManagePeople: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.managePeople, { orgId }),
  toAiStudioManageUsage: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.manageUsage, { orgId }),
  toAiStudioSessionLogs: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.manageSessionLogs, { orgId }),
  toAiStudioManageBilling: (orgId: TRouteParam, queryParams?: QueryParams) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.manageBilling, { orgId }, queryParams),

  toGatewayApiKey: (orgId: TRouteParam, applicationId?: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.apiKeysApp, { orgId, applicationId }),
  toApiKeys: (orgId: TRouteParam, applicationId?: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.apiKeysApplication, { orgId, applicationId }),
  toApiKeysObservability: (orgId: TRouteParam, applicationId?: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.apiObservabilityPerformance, { orgId, applicationId }),

  toFrameworkAppDeploy: (orgId: TRouteParam, applicationId?: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.frameworkAppDeploy, { orgId, applicationId }),
  toFrameworkAppKeys: (orgId: TRouteParam, applicationId?: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.frameworkAppKeys, { orgId, applicationId }),
  toFrameworkObservability: (orgId: TRouteParam, applicationId?: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.frameworkAppObservabilityPerformance, { orgId, applicationId }),

  // agent editor routes
  toAgentEditorBuild: (orgId: TRouteParam, applicationId?: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.agentEditorBuild, { orgId, applicationId }),
  toAgentEditorDeploy: (orgId: TRouteParam, applicationId?: string) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.agentEditorDeploy, { orgId, applicationId }),

  toAiStudioTemplates: (orgId: TRouteParam) => substituteRoute(WRITER_CONSOLE_ROUTES.templates, { orgId }),

  toReport: (orgId: TRouteParam) => substituteRoute(WRITER_REPORT_ROUTES.report, { orgId }),
  toReportAIUsage: (orgId: TRouteParam) => substituteRoute(WRITER_REPORT_ROUTES.aiUsage, { orgId }),
  toReportUserActivity: (orgId: TRouteParam) => substituteRoute(WRITER_REPORT_ROUTES.userActivity, { orgId }),
  toAdminAuditLog: (orgId: TRouteParam) => substituteRoute(WRITER_REPORT_ROUTES.adminAuditLog, { orgId }),

  toAiStudioLearningCenterWriterAcademy: (orgId: TRouteParam) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.learningCenterWriterAcademy, { orgId }),
  toAiStudioLearningCenterDocs: (orgId: TRouteParam) =>
    substituteRoute(WRITER_CONSOLE_ROUTES.learningCenterDocs, { orgId }),

  toFrameworkAppPage: (orgId: TRouteParam, teamId: TRouteParam, applicationId: string) =>
    substituteRoute(ROUTE.frameworkAppPage, { orgId, teamId, applicationId }),
};

export const SHORTCUT_ROUTES = {
  '/home': ROUTE.toHome,
  '/editor': ROUTE.toEditorPage,
  '/editor/*': ROUTE.toEditorPage,
  '/editor/new': ROUTE.toNewEditorPage,
  '/editor/new/templates': ROUTE.toNewDocumentWithTemplates,
  '/editor/new/:categoryId': ROUTE.toNewDocumentWithTemplates,
  '/editor/new/templates/:templateId': ROUTE.toNewDocumentWithTemplates,
  '/editor/new/builders/:templateId': ROUTE.toNewDocumentWithTemplates,
  '/apps': ROUTE.toEditorWithCoWrite,
  '/editor/new/apps': ROUTE.toNewDocumentWithTemplates,
  '/editor/new/apps/:templateId': ROUTE.toNewDocumentWithTemplates,
  '/team/terms': ROUTE.toTerms,
  '/team/snippets': ROUTE.toSnippets,
  '/team/teammates': ROUTE.toTeamUsers,
  '/profile': ROUTE.toProfile,
  '/extensions': ROUTE.toExtensions,
  '/admin/people': ROUTE.toPeople,
  '/admin/teams': ROUTE.toAllTeams,
  '/admin/account': ROUTE.toAdminAccount,
  '/admin/api': ROUTE.toAdminApi,
  '/admin/billing': ROUTE.toAdminBilling,
  '/admin/security': ROUTE.toAdminSecurity,
  '/admin/sso': ROUTE.toAdminSso,
  '/admin/oauth-apps': ROUTE.toAdminOAuthApps,
  '/admin/reporting/ai-usage-report': ROUTE.toReportAIUsage,
  '/admin/reporting/admin-audit-log': ROUTE.toAdminAuditLog,
  '/admin/reporting/user-activity-report': ROUTE.toReportUserActivity,
  '/team/suggestions/punctuation': SETUP_ROUTES.toPunctuation,
  '/team/suggestions/clarity': SETUP_ROUTES.toClarity,
  '/team/suggestions/writing-style': SETUP_ROUTES.toWritingStyle,
  '/team/suggestions/delivery': SETUP_ROUTES.toDelivery,
  '/team/suggestions/inclusivity': SETUP_ROUTES.toInclusivity,
  '/team/suggestions/compliance': SETUP_ROUTES.toCompliance,
  '/team/suggestions/plagiarism': SETUP_ROUTES.toPlagiarism,
  '/team/suggestions/general': SETUP_ROUTES.toGeneral,
  '/team/suggestions/custom-rules': SETUP_ROUTES.toCustomRules,
  '/learning/videos/ask-writer': (orgId: TRouteParam, teamId: TRouteParam) =>
    redirectToLearningVideo(orgId, teamId, TLearningCenterVideoId.enum['ask-writer']),
  '/learning/videos/blog-builder': (orgId: TRouteParam, teamId: TRouteParam) =>
    redirectToLearningVideo(orgId, teamId, TLearningCenterVideoId.enum['blog-builder']),
  '/learning/videos/recaps': (orgId: TRouteParam, teamId: TRouteParam) =>
    redirectToLearningVideo(orgId, teamId, TLearningCenterVideoId.enum.recaps),
  '/learning/videos/writer-ai': (orgId: TRouteParam, teamId: TRouteParam) =>
    redirectToLearningVideo(orgId, teamId, TLearningCenterVideoId.enum['writer-ai']),
  '/learning/videos/5-things': (orgId: TRouteParam, teamId: TRouteParam) =>
    redirectToLearningVideo(orgId, teamId, TLearningCenterVideoId.enum['5-things']),
  '/learning/videos/prompt-crafting': (orgId: TRouteParam, teamId: TRouteParam) =>
    redirectToLearningVideo(orgId, teamId, TLearningCenterVideoId.enum['prompt-crafting']),
  '/learning': (orgId: TRouteParam, teamId: TRouteParam) => ROUTE.toLearningCenter(orgId, teamId),
  '/learning/webinars': (orgId: TRouteParam, teamId: TRouteParam) => ROUTE.toLearningCenterWebinars(orgId, teamId),
  '/learning/writeracademy': (orgId: TRouteParam, teamId: TRouteParam) =>
    ROUTE.toLearningCenterWriterAcademy(orgId, teamId),
  '/ask': (orgId: TRouteParam, teamId: TRouteParam) => ROUTE.toAskWriter(orgId, teamId),
  '/ask/new/prompts': (orgId: TRouteParam, teamId: TRouteParam) => ROUTE.toAskWriterNewSessionWithOpenPL(orgId, teamId),
  '/ask/new/team-prompts': (orgId: TRouteParam, teamId: TRouteParam) =>
    ROUTE.toAskWriterNewSessionWithOpenPLTeamPrompts(orgId, teamId),
  '/my-work': (orgId: TRouteParam, teamId: TRouteParam) => ROUTE.toMyWork(orgId, teamId),
  '/my-work/docs': (orgId: TRouteParam, teamId: TRouteParam) => ROUTE.toDocs(orgId, teamId),
  '/my-work/sessions': (orgId: TRouteParam, teamId: TRouteParam) => ROUTE.toSessions(orgId, teamId),
  '/my-work/outputs': (orgId: TRouteParam, teamId: TRouteParam) => ROUTE.toOutputs(orgId, teamId),
  '/askcc': (orgId: TRouteParam, teamId: TRouteParam) => ROUTE.toAskWriter(orgId, teamId),
  '/setup/voice': SETUP_ROUTES.toVoice,
  '/setup/team-prompts': SETUP_ROUTES.toTeamPrompts,
  '/setup/knowledge-graph': ROUTE.toKnowledgeGraph,
  '/learning/team-prompts': ROUTE.toLearningCenterTeamPrompts,
  '/learning/prompt-library': ROUTE.toLearningCenterPrompts,
  '/aistudio/home': ROUTE.toAiStudioHome,
  '/aistudio': ROUTE.toAiStudioHome,
  '/aistudio/templates': ROUTE.toAiStudioTemplates,
  '/aistudio/learning': ROUTE.toAiStudioLearningCenterWriterAcademy,
  '/aistudio/learning/writeracademy': ROUTE.toAiStudioLearningCenterWriterAcademy,
};

export const SHORTCUT_PORTAL_ROUTES = {
  '/admin/styleguide': ANGULAR_ROUTE.toPortal,
  '/admin/reporting/suggestions': ANGULAR_ROUTE.toSuggestionReport,
  '/admin/reporting/user': ANGULAR_ROUTE.toActivity,
};

export const AI_ASSISTANT_ROUTES = {
  home: `${REACT_RELATIVE_ROUTE.teamBasePath}`,
  docs: `${REACT_RELATIVE_ROUTE.teamBasePath}/documents`,
  myWork: `${REACT_RELATIVE_ROUTE.teamBasePath}/my-work`,
};

export const WRITER_CONSOLE_ROUTES = {
  home: `${REACT_RELATIVE_ROUTE.aiStudioRoot}`,
  analytics: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioAnalytics}`,
  generalObservability: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioGeneralObservability}`,
  playground: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioPlayground}`,
  aiGuardrails: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioGuiderails}`,
  voice: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioVoice}`,
  customRules: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioCustomRules}`,
  knowledgeGraph: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioKnowledgeGraph}`,
  profile: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioProfile}`,

  // Builder
  appBuild: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioBuilder}`,
  appGuide: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioBuilder}${REACT_RELATIVE_ROUTE.aiStudioGuide}`,
  appDeployment: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioBuilder}${REACT_RELATIVE_ROUTE.aiStudioDeploy}`,

  // Observability
  appObservability: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioBuilder}${REACT_RELATIVE_ROUTE.aiStudioObservability}`,
  appEngagement: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioBuilder}${REACT_RELATIVE_ROUTE.aiStudioAppEngagement}`,
  appPerformance: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioBuilder}${REACT_RELATIVE_ROUTE.aiStudioAppPerformance}`,
  appUsage: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioBuilder}${REACT_RELATIVE_ROUTE.aiStudioAppUsage}`,
  appSessionLogs: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioBuilder}${REACT_RELATIVE_ROUTE.aiStudioAppSessionLogs}`,
  // Billing
  managePeople: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioPeople}`,
  manageBilling: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioBilling}`,
  manageAccount: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioAccount}`,
  manageUsage: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioUsage}`,
  manageSessionLogs: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioSessionLogs}`,

  // Api Keys
  apiKeysApp: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioApiKeysApp}`,
  apiKeys: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioApiKeys}`,
  apiKeysApplication: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioApiKeysApp}${REACT_RELATIVE_ROUTE.aiStudioApiKeysPage}`,
  apiKeysObservability: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioApiKeysApp}${REACT_RELATIVE_ROUTE.aiStudioApiKeysPage}`,

  apiObservabilityPerformance: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioApiKeysApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkObservabilityPerformance}`,
  apiObservabilityUsage: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioApiKeysApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkObservabilityUsage}`,
  apiObservabilityLog: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioApiKeysApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkObservabilityLog}`,

  // Framework App
  frameworkApp: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioFrameworkApp}`,
  frameworkAppDeploy: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioFrameworkApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkAppDeploy}`,
  frameworkAppKeys: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioFrameworkApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkAppKeys}`,
  frameworkAppObservabilityPerformance: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioFrameworkApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkObservabilityPerformance}`,
  frameworkAppObservabilityUsage: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioFrameworkApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkObservabilityUsage}`,
  frameworkAppObservabilityLog: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioFrameworkApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkObservabilityLog}`,

  // Agent Editor
  agentEditorApp: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.agentEditorApp}`,
  agentEditorBuild: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.agentEditorApp}${REACT_RELATIVE_ROUTE.aiStudioAgentEditorBuild}`,
  agentEditorDeploy: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.agentEditorApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkAppDeploy}`,
  agentEditorObservabilityPerformance: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.agentEditorApp}${REACT_RELATIVE_ROUTE.aiStudioFrameworkObservabilityPerformance}`,

  // Templates
  templates: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioTemplates}`,

  // Learning Center
  learningCenterWriterAcademy: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioLearningCenterWriterAcademy}`,
  learningCenterDocs: `${REACT_RELATIVE_ROUTE.aiStudioRoot}${REACT_RELATIVE_ROUTE.aiStudioLearningCenterDocs}`,
};

export const WRITER_REPORT_ROUTES = {
  report: `${REACT_RELATIVE_ROUTE.report}`,
  aiUsage: `${REACT_RELATIVE_ROUTE.report}${REACT_RELATIVE_ROUTE.aiUsage}`,
  userActivity: `${REACT_RELATIVE_ROUTE.report}${REACT_RELATIVE_ROUTE.userActivity}`,
  adminAuditLog: `${REACT_RELATIVE_ROUTE.report}${REACT_RELATIVE_ROUTE.adminAuditLog}`,
};

export const HIDE_TRIAL_BANNER_FROM_PAGES = [ROUTE.onboarding, ROUTE.chromeOnboarding];
export const EXPIRED_USERS_ALLOWED_ROUTES = [ROUTE.billing, ROUTE.people, ROUTE.teamUsers, ROUTE.profile, ROUTE.apps];
export const HIDDEN_GLOBAL_NAV_ROUTES = [
  REACT_RELATIVE_ROUTE.editorPage,
  REACT_RELATIVE_ROUTE.askBase,
  REACT_RELATIVE_ROUTE.knowledgeGraphConnectorRedirect,
  REACT_RELATIVE_ROUTE.chatApps,
  REACT_RELATIVE_ROUTE.researchAssistant,
  REACT_RELATIVE_ROUTE.frameworkAppPage,
];

export const navigateToDeleteAccountFailureRoute = (deleteAccountToken: string | null) => {
  let route = `${REACT_RELATIVE_ROUTE.loginExternal}?${SharedQueryParam.DELETE}=false`;

  if (deleteAccountToken) {
    route += `&${SharedQueryParam.DELETE_TOKEN}=${deleteAccountToken}`;
  }

  return goToNative(route);
};
export const navigateToDeleteAccountSuccessRoute = () =>
  goToNative(`${REACT_RELATIVE_ROUTE.loginExternal}?${SharedQueryParam.DELETE}=true`);
