import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { UserTeamRole, extractEmails, validateEmail } from '@writercolab/common-utils';
import { FieldModel, ReactiveQueue } from '@writercolab/mobx';
import type { RequestServiceInitialize } from '@writercolab/network';
import { NotificationQueueItemType, type TEventQueueItem, TNotificationQueueItem } from '@writercolab/types';
import { Enum } from '@writercolab/utils';

import { getLogger } from '../../../utils/logger';

const LOG = getLogger('InviteTeammatesFormModel');

interface IInviteTeammatesFormModelUiProps {
  organizationId: () => number | undefined;
  teamId: () => number | undefined;
  maxSeatsCount: () => number | undefined;
  currentSeatsCount: () => number | undefined;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
  request: RequestServiceInitialize['api'];
}

export const TInviteFormErrorCode = new Enum('maxSeatsReached', 'emailInvalid', 'emailsRequired');
export const TInviteTeammatesEvents = new Enum(
  'invitesSentSuccessfully',
  'startSendingInvites',
  'finishSendingInvites',
  'invitesSentError',
);

export class InviteTeammatesFormModelUi {
  emails: FieldModel<string>;

  readonly notificationQueue: ReactiveQueue<TNotificationQueueItem>;
  readonly eventsQueue = new ReactiveQueue<TEventQueueItem<typeof TInviteTeammatesEvents.type, { message?: string }>>();

  private pendingInvitesBox = observable.box(0);
  private totalSeatsCount = observable.box(0);
  private loading = observable.box(false);
  private error = observable.box<typeof TInviteFormErrorCode.type | undefined>(undefined);

  constructor(private opts: IInviteTeammatesFormModelUiProps) {
    this.emails = FieldModel.build({
      init: () => '',
    });

    this.notificationQueue = opts.notificationQueue || new ReactiveQueue<TNotificationQueueItem>();

    makeObservable(this, {
      currentSeatsCount: computed,
      maxSeats: computed,
      pendingInviteCount: computed,
      totalSeats: computed,
      availableSeats: computed,
      errorCode: computed,
      isLoading: computed,
      canSubmit: computed,

      validateSeats: action,
      submit: action.bound,
    });
  }

  validateSeats() {
    const inputEmails = extractEmails(this.emails.value || '');

    if (!inputEmails?.length) {
      this.error.set(undefined);
      this.totalSeatsCount.set(this.currentSeatsCount);
      this.pendingInvitesBox.set(0);
      return;
    }

    const uniqueEmails = [...new Set(inputEmails)];
    const invalidEmails = uniqueEmails.filter(email => !validateEmail(email));

    if (invalidEmails.length > 0) {
      this.error.set(TInviteFormErrorCode.enum.emailInvalid);
      return;
    }

    const pendingCount = uniqueEmails.length;
    const totalSeats = pendingCount + this.currentSeatsCount;
    const maxSeats = this.maxSeats;

    this.totalSeatsCount.set(totalSeats);
    this.pendingInvitesBox.set(pendingCount);

    if (maxSeats > 0 && totalSeats > maxSeats) {
      this.error.set(TInviteFormErrorCode.enum.maxSeatsReached);
    } else {
      this.error.set(undefined);
    }
  }

  async submit() {
    try {
      this.loading.set(true);
      this.eventsQueue.enqueue({
        type: TInviteTeammatesEvents.enum.startSendingInvites,
        params: {},
      });

      const organizationId = this.opts.organizationId();
      const teamId = this.opts.teamId();
      const invitees =
        extractEmails(this.emails.value || '')
          ?.filter(Boolean)
          .map(email => String(email)) || [];

      if (!organizationId || !teamId || !invitees.length) {
        this.eventsQueue.enqueue({
          type: TInviteTeammatesEvents.enum.invitesSentError,
          params: { message: 'No valid emails to invite' },
        });
        return;
      }

      await this.opts.request.post('/api/user/v2/organization/{organizationId}/team/invite', {
        params: {
          path: {
            organizationId,
          },
        },
        body: {
          invitees: invitees as [string, ...string[]],
          role: UserTeamRole.MEMBER,
          teamIds: [teamId],
        },
      });

      const message =
        this.pendingInviteCount > 1
          ? `${this.pendingInviteCount} teammates were successfully invited`
          : `${this.emails.value} has been invited`;

      this.notificationQueue.enqueue({
        message,
        type: NotificationQueueItemType.enum.success,
      });

      this.eventsQueue.enqueue({
        type: TInviteTeammatesEvents.enum.invitesSentSuccessfully,
        params: { message },
      });
    } catch (e) {
      LOG.error(e);
      const errorMessage = e instanceof Error ? e.message : 'Failed to send invitations. Please try again.';
      this.error.set(TInviteFormErrorCode.enum.emailInvalid);
      this.notificationQueue.enqueue({
        message: errorMessage,
        type: NotificationQueueItemType.enum.error,
      });

      this.eventsQueue.enqueue({
        type: TInviteTeammatesEvents.enum.invitesSentError,
        params: { message: errorMessage },
      });
    } finally {
      runInAction(() => {
        this.loading.set(false);
        this.eventsQueue.enqueue({
          type: TInviteTeammatesEvents.enum.finishSendingInvites,
          params: {},
        });
      });
    }
  }

  get totalSeats() {
    return this.currentSeatsCount + this.pendingInviteCount;
  }

  get isLoading() {
    return this.loading.get();
  }

  get errorCode() {
    return this.error.get();
  }

  get availableSeats() {
    const remaining = this.maxSeats - this.totalSeats;
    return remaining > 0 ? remaining : 0;
  }

  get currentSeatsCount() {
    return this.opts.currentSeatsCount() || 1;
  }

  get pendingInviteCount() {
    return this.pendingInvitesBox.get();
  }

  get maxSeats() {
    return this.opts.maxSeatsCount() || 0;
  }

  get canSubmit() {
    return !this.isLoading && !this.errorCode && this.pendingInviteCount > 0;
  }
}
