import qs from 'qs';

import type { DateTime } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

import type { TAiUsageSimpleFeatureType, TAiUsageUserStatus } from '@web/types';
import { GroupingCategories, TUserActivityGrouping, TUserActivityTimeGrouping, TimeGroupings } from '@web/types';
import {
  endOfDay,
  format,
  intervalToDuration,
  isBefore,
  isValid,
  parseISO,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import isEmpty from 'lodash/isEmpty';
import { getLogger } from 'utils/logger';

const LOG = getLogger('ReportPage utils');
/** IMPORTANT INFO ABOUT WORKING WITH DATES WITH TIMEZONE
 *  Datepicker and Backend API already receive dates with timezone.
 *  Date with timezone is a string that looks like '2024-07-16T00:00:00.000+01:00' or '2024-07-16T00:00:00.000Z', this date should have offset at the end.
 *  we should only parse date with timezone and format it to the specified format
 *  Dates should fill full day - from: 00:00:00 / to: 23:59:59
 */

/**
 * To save latest query params and sync with localStorage
 * Add pathname to TReportPageKey, ReportConfig and appLocalStorage
 */
export const TReportPageKey = new Enum('admin-audit-log', 'aiusage', 'user-activity');
export const HISTORY_LIMIT_DATE_FROM = new Date('2024-11-02').toISOString();
export const SURFACE_DATE_LIMIT = new Date('2024-08-30').toISOString();
export const MAX_MONTHS_RANGE = 24;
export const DEFAULT_DISABLE_DATE = startOfMonth(subMonths(new Date(), MAX_MONTHS_RANGE)).toISOString();

export const NO_BILLING_GROUP = 'nobillinggroup';
export const NO_TEAM = 'noteam';
export const NO_FEATURE = 'nofeature';

export const ReportTotalSeriesNames = new Enum('Users', 'WordsGenerated', 'RecapsTranscribed', 'WordsRewritten');

export const ReportTableType = new Enum(
  'TimePeriodDay',
  'TimePeriodWeek',
  'TimePeriodMonth',
  'Team',
  'BillingGroup',
  'Feature',
  'Surface',
  'User',
);
export const CHART_KEYS = new Enum('WordsGenerated', 'WordsRewritten', 'RecapsTranscribed');

interface IGroup {
  id: string;
  name: string;
  groupType?: TAiUsageSimpleFeatureType | null;
  userStatus?: TAiUsageUserStatus | null;
}

export interface ICustomChartTooltip {
  active?: boolean;
  // TODO need to think how to avoid it
  payload?: any[];
  timeGrouping?: typeof TimeGroupings.type;
  timezone?: string;
}

/**
 * Report Table Data should to be an array of objects with `group` property
 */
type TReportTableData = Array<{ group?: IGroup | null }>;

export const hasNoBillingGroup = (data: TReportTableData) =>
  data.length === 1 && data[0].group?.id === NO_BILLING_GROUP;

export const hasNoTeam = (data: TReportTableData) => data.length === 1 && data[0].group?.id === NO_TEAM;

export const hasNoFeature = (data: TReportTableData) => data.length === 1 && data[0].group?.id === NO_FEATURE;

export const EMPTY_TABLE_MESSAGE = ({ type, data }: { data: TReportTableData; type: typeof ReportTableType.type }) => {
  const messages = ReportTableType.hash({
    [ReportTableType.enum.TimePeriodDay]: 'No data for selected period available',
    [ReportTableType.enum.TimePeriodWeek]: 'No data for selected period available',
    [ReportTableType.enum.TimePeriodMonth]: 'No data for selected period available',
    [ReportTableType.enum.Team]: 'No teams available',
    [ReportTableType.enum.BillingGroup]: 'No billing groups available',
    [ReportTableType.enum.Feature]: 'No features or agents available',
    [ReportTableType.enum.Surface]: 'No surfaces available',
    [ReportTableType.enum.User]: 'No users available',
  });

  // Check specific no-data conditions for BillingGroup, Team, and Feature
  if (type === ReportTableType.enum.BillingGroup && hasNoBillingGroup(data)) {
    return messages[ReportTableType.enum.BillingGroup];
  }

  if (type === ReportTableType.enum.Team && hasNoTeam(data)) {
    return messages[ReportTableType.enum.Team];
  }

  if (type === ReportTableType.enum.Feature && hasNoFeature(data)) {
    return messages[ReportTableType.enum.Feature];
  }

  return messages[type];
};

export const stringifyParams = (params: any): string => qs.stringify(params, { arrayFormat: 'repeat' });
export const parseParams = (params: string): any => qs.parse(params, { ignoreQueryPrefix: true });

export const SERVER_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";

export const getUTCDate = (dateWithTimeZone: Date | string | number): string => {
  return new Date(dateWithTimeZone).toISOString().split('T')[0];
};

export const getOneMonthDateRange = (timezone: string): { from: DateTime; to: DateTime } => {
  const now = new Date();

  // Calculate the "from" date (1 month ago, start of the day)
  const oneMonthAgo = subMonths(now, 1);
  const from = fromZonedTime(endOfDay(oneMonthAgo), timezone); // Start of the day in the correct timezone

  // Calculate the "to" date (today, end of the day)
  const to = fromZonedTime(endOfDay(now), timezone); // End of the day in the correct timezone

  const formatDate = (date: Date) => formatInTimeZone(date, timezone, SERVER_DATE_FORMAT);

  const res = { from: formatDate(from) as DateTime, to: formatDate(to) as DateTime };

  return res;
};

export const isISODateString = (dateString: string): boolean => {
  const date = parseISO(decodeURIComponent(dateString));

  return isValid(date);
};

export const isDateParamsValid = (params: { [key: string]: string | undefined | null }): boolean => {
  const { from, to } = params;

  const isFromValid = from ? isISODateString(from) : false;
  const isToValid = to ? isISODateString(to) : false;

  return isFromValid && isToValid;
};

export const selectedDatesRange = ({
  from,
  to,
  timezone,
}: {
  from?: string;
  to?: string;
  timezone: string;
}): string => {
  if (!from || !to || !timezone) {
    return '';
  }

  try {
    // Parse the ISO date strings
    const fromDate = parseISO(from);
    const toDate = parseISO(to);

    if (!isValid(fromDate) || !isValid(toDate)) {
      return '';
    }

    const formatString = 'MMMM d, yyyy';
    const formattedFrom = formatInTimeZone(fromDate, timezone, formatString);
    const formattedTo = formatInTimeZone(toDate, timezone, formatString);

    return `${formattedFrom} - ${formattedTo}`;
  } catch (error) {
    LOG.error('Error formatting date range:', error);
    return '';
  }
};

export const getDefaultFromDate = ({
  showFullHistory,
  timezone,
}: {
  showFullHistory: boolean;
  timezone: string;
}): string => {
  const fullHistoryFrom = getOneMonthDateRange(timezone).from;
  const limitedHistoryTzDate = fromZonedTime(startOfDay(HISTORY_LIMIT_DATE_FROM), timezone); // need to convert to the correct timezone
  const limitedHistoryFormattedFrom = formatInTimeZone(limitedHistoryTzDate, timezone, SERVER_DATE_FORMAT); // format with timezone

  if (showFullHistory) {
    return fullHistoryFrom;
  }

  return isBefore(fullHistoryFrom, limitedHistoryFormattedFrom) ? limitedHistoryFormattedFrom : fullHistoryFrom;
};

export const isUserActivityParamsMissing = (searchParams: URLSearchParams): boolean =>
  !searchParams.get('from') ||
  !searchParams.get('to') ||
  !searchParams.get('grouping') ||
  !searchParams.get('timeGrouping') ||
  isEmpty(searchParams.getAll('activeUserDefinition'));

export const isUserActivityParamsInvalid = (searchParams: URLSearchParams): boolean =>
  !isDateParamsValid({
    from: searchParams.get('from') as DateTime,
    to: searchParams.get('to') as DateTime,
  }) ||
  !TUserActivityGrouping.is(searchParams.get('grouping')) ||
  !TUserActivityTimeGrouping.is(searchParams.get('timeGrouping')) ||
  isEmpty(searchParams.getAll('activeUserDefinition'));

interface DateRangeSelectorSettings {
  disabledPastDatesTooltipText: string;
  disabledPastDates: string;
  disabledBeforeDate?: string;
  disabledWeekButton: boolean;
  disabledMonthButton: boolean;
  disabledQuarterButton: boolean;
  disabledSemesterButton: boolean;
  disabledYearButton: boolean;
}
export const getDateRangeSelectorSettings = ({
  showFullHistory,
  selectedGroupingCategory,
  timezone,
}: {
  showFullHistory: boolean;
  selectedGroupingCategory: typeof GroupingCategories.type | undefined;
  timezone: string;
}): DateRangeSelectorSettings => {
  const formatString = 'MMM d, yyyy';
  const historyLimitDateWithTimezone = formatInTimeZone(
    fromZonedTime(HISTORY_LIMIT_DATE_FROM, timezone),
    timezone,
    formatString,
  );
  const maxBeforeDateWithTimezone = formatInTimeZone(
    fromZonedTime(DEFAULT_DISABLE_DATE, timezone),
    timezone,
    formatString,
  );

  const surfaceDateWithTimezone = formatInTimeZone(fromZonedTime(SURFACE_DATE_LIMIT, timezone), timezone, formatString);

  if (selectedGroupingCategory === GroupingCategories.enum.Surface) {
    return {
      disabledPastDatesTooltipText: `Data prior to ${surfaceDateWithTimezone} is not available for group by surface views`,
      disabledPastDates: surfaceDateWithTimezone,
      disabledWeekButton: false,
      disabledMonthButton: false,
      disabledQuarterButton: false,
      disabledSemesterButton: false,
      disabledYearButton: false,
    };
  }

  if (showFullHistory) {
    return {
      disabledPastDatesTooltipText: 'This report only supports up to 2 years of data',
      disabledPastDates: maxBeforeDateWithTimezone,
      disabledWeekButton: false,
      disabledMonthButton: false,
      disabledQuarterButton: false,
      disabledSemesterButton: false,
      disabledYearButton: false,
    };
  }

  const {
    days: diffInDays,
    months: diffInMonths,
    years: diffInYears,
  } = intervalToDuration({
    start: startOfDay(historyLimitDateWithTimezone),
    end: endOfDay(new Date()),
  });

  return {
    disabledPastDatesTooltipText: 'Data is not available for these dates',
    disabledPastDates: historyLimitDateWithTimezone,
    disabledWeekButton: (diffInDays ?? 0) < 7,
    disabledMonthButton: (diffInMonths ?? 0) < 1,
    disabledQuarterButton: (diffInMonths ?? 0) < 3,
    disabledSemesterButton: (diffInMonths ?? 0) < 6,
    disabledYearButton: (diffInYears ?? 0) < 1,
  };
};

export const getFormattingByTimeGroupingForChart = (timeGrouping: typeof TimeGroupings.type) =>
  TimeGroupings.match(
    timeGrouping,
    {
      DayGrouping: () => 'MMM d',
      WeekGrouping: () => 'MMM d',
      MonthGrouping: () => 'MMM yyyy',
    },
    null,
  );

export const getFormattingByTimeGroupingForTooltip = (timeGrouping: typeof TimeGroupings.type) =>
  TimeGroupings.match(
    timeGrouping,
    {
      WeekGrouping: () => "'Week of' MMMM d, yyyy",
      MonthGrouping: () => 'MMMM yyyy',
      DayGrouping: () => 'iiii, MMMM d, yyyy',
    },
    null,
  );

const formatReportDateTime = ({
  timeGrouping,
  date,
  timezone,
  formatString,
}: {
  date: string;
  timeGrouping?: typeof TimeGroupings.type;
  timezone: string;
  formatString: string;
}): string => {
  // Format date with timezone only for DayGrouping and WeekGrouping
  if (timeGrouping === TimeGroupings.enum.DayGrouping || timeGrouping === TimeGroupings.enum.WeekGrouping) {
    return formatInTimeZone(date, timezone, formatString);
  }

  // Format date without timezone for other groupings
  return format(parseISO(date), formatString);
};

export const getChartDateTimeFormatting = ({
  timeGrouping,
  date,
  timezone,
}: {
  date: string;
  timeGrouping?: typeof TimeGroupings.type;
  timezone: string;
}): string => {
  const formatString = getFormattingByTimeGroupingForChart(timeGrouping || TimeGroupings.enum.DayGrouping);

  return formatReportDateTime({ timeGrouping, date, timezone, formatString });
};

export const getTooltipDateTimeFormatting = ({
  timeGrouping,
  date,
  timezone,
}: {
  date: string;
  timeGrouping?: typeof TimeGroupings.type;
  timezone?: string;
}): string => {
  if (!timezone) {
    return '';
  }

  const formatString = getFormattingByTimeGroupingForTooltip(timeGrouping || TimeGroupings.enum.DayGrouping);

  return formatReportDateTime({ timeGrouping, date, timezone, formatString });
};

export const getReportStatsFilename = ({
  reportType,
  timezone,
  from,
  to,
  extension,
}: {
  from: string;
  to: string;
  reportType: string;
  timezone: string;
  extension: 'csv' | 'xlsx';
}): string => {
  const _from = formatInTimeZone(from, timezone, 'd.M.yyyy');
  const _to = formatInTimeZone(to, timezone, 'd.M.yyyy');

  return `${reportType}_${_from}-${_to}.${extension}`;
};
