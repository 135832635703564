import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

import type {
  IApplicationUsageStatsValue,
  IBillingCalculation,
  IBillingPlan,
  ICancellationSurveyQuestion,
  IInvoice,
  IPaymentIntent,
  IPaymentMethod,
  TClickedFromParam,
} from '@writercolab/common-utils';
import {
  BillingAction,
  BillingInterval,
  BillingProduct,
  BillingStatus,
  CancellationPopupType,
  CancelledReason,
  CheckoutPopupType,
  DEFAULT_BILLING_CHECKOUT_SEATS,
  activateFreeSubscription,
  applyForFreeMonthDiscount,
  applyForFreeTeamWeeks,
  calculate,
  cancelSubscription,
  cancelTrial,
  capitalizeWord,
  convertCentsToDollar,
  delay,
  formatAmount,
  getAvailablePlans,
  getBillingCustomer,
  getIsEligibleForDiscount,
  getPaymentMethod,
  proSurveyQuestions,
  saveCancellationSurvey,
  startTrial,
  teamSurveyQuestions,
  updateBillingCustomer,
  updateSubscription,
} from '@writercolab/common-utils';
import { TSubscriptionLimitType } from '@writercolab/models';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import {
  SnackTheme,
  SubscribeSnack,
} from '../components/pages/BillingPage/CheckoutElements/SnackMessage/SubscribeSnack';

import type { Stripe, StripeError, PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { snackbarMessages } from '@web/component-library';
import type { ActionMap, WithChildren } from '@web/types';
import { MAX_STARTER_TEAM_SIZE, MAX_TEAM_SIZE } from '@web/types';
import { AnalyticEvents, AnalyticsActivity } from 'constants/analytics';
import groupBy from 'lodash/groupBy';
import { observer } from 'mobx-react-lite';
import { lensPath, lensProp, pipe, set } from 'ramda';
import { useNavigate } from 'react-router';
import requestService from 'services/request/requestService';

import { BackendErrorCodeKey } from '../constants/BackendErrorCodeKey';
import { ROUTE } from '../services/config/routes';
import { useAppState } from '../state';
import { getLogger } from '../utils/logger';
import { openContactSalesPage } from '../utils/navigationUtils';
import { calcMaxTeamSize } from '../utils/teamUtils';

const LOG = getLogger('ContentEditorPage');
const { VITE_STRIPE_KEY } = import.meta.env;

export interface IBillingContext {
  stripePromise: Promise<Stripe | null>;
  billingContext: TBillingState;
  onChangeBillingInterval: (interval: BillingInterval) => void;
  onUpdateSubscription: (
    plan?: IBillingPlan,
    popupType?: CheckoutPopupType,
    activity?: AnalyticEvents,
    clickFrom?: TClickedFromParam,
  ) => void;
  onActivateFreePlan: () => Promise<any>;
  onStartTrial: (type: BillingProduct) => void;
  onCancelTrial: (type: BillingProduct) => void;
  onCancelTrialConfirmed: () => void;
  onToggleTrialPopup: (popupVisible: boolean, type?: BillingProduct) => void;
  onToggleCancelTrialPopup: (popupVisible: boolean, type?: BillingProduct) => void;
  onToggleCheckoutPopup: (show: boolean, popupType: CheckoutPopupType) => void;
  onTogglePaymentInfoPopup: (show: boolean) => void;
  onSubmitBillingInformation: (paymentMethodId?: string) => void;
  onSubmitBillingInformationError: (error: StripeError) => void;
  onEditPaymentMethodClick: () => void;
  onSubmitBillingInformationComplete: (result?) => void;
  onApplyCoupon: (couponCode: string) => void;
  onRemoveCoupon: () => void;
  onEditPaymentMethod: (paymentMethod: StripePaymentMethod) => void;
  onCheckoutPlanSwitch: (interval: BillingInterval, seats: number) => void;
  onManageUsersClick: () => void;
  onManageBillingGroupsClick: () => void;
  onManagePlanClick: () => void;
  onCancelPlanClick: () => void;
  onCancellationNextStep: () => void;
  onApplyForFreeMonth: () => void;
  onApplyForWeeksFree: () => void;
  onUpdateCancellationComments: (comments: string) => void;
  onToggleCancellationPopup: (popupType: CancellationPopupType | null) => void;
  onUpdateSurveyQuestion: (question: ICancellationSurveyQuestion) => void;
  onToggleBillingContactPopup: (show: boolean) => void;
  onSaveBillingContact: (contact: string) => void;
  onContactSalesClick: (clickFrom: TClickedFromParam) => void;
}

const BillingContext = createContext<IBillingContext>({} as IBillingContext);

interface ICoupon {
  code: string;
  valid: boolean | null;
}

interface IPlanGrouped {
  free?: IBillingPlan;
  pro?: IBillingPlan;
  team?: IBillingPlan;
  starter?: IBillingPlan;
}

interface IBillingAddressState {
  isPopupVisible: boolean;
  contact: string;
  isSubmitting: boolean;
}

interface ICheckoutState {
  isPopupVisible: boolean;
  planSelected: IBillingPlan | null;
  planCalculation: IBillingCalculation | null;
  confirmationIntent: IPaymentIntent | null;
  coupon: ICoupon | null;
  isCouponLoading: boolean;
  seats: number;
  isSubmitting: boolean;
  popupType: CheckoutPopupType;
  submitButtonLabel: string;
  submitButtonDisabled: boolean;
  isPlanUnchanged: boolean;
}

interface IPaymentInfo {
  isPopupVisible: boolean;
}

interface ICancellationState {
  isLoading: boolean;
  popupType: CancellationPopupType | null;
  surveyQuestions: ICancellationSurveyQuestion[];
  comments: string;
}

interface ITrialState {
  popupVisible: boolean;
  type: BillingProduct;
}

interface ITeamSizeLimit {
  max: number;
  reached: boolean;
}

interface IUsageData {
  [TSubscriptionLimitType.enum.user]: IApplicationUsageStatsValue | undefined;
  [TSubscriptionLimitType.enum.contentCheck]: IApplicationUsageStatsValue;
  [TSubscriptionLimitType.enum.coWrite]: IApplicationUsageStatsValue;
}

interface TBillingState {
  billingInterval: BillingInterval;
  plans: IPlanGrouped | null;
  trial: ITrialState;
  cancelTrial: ITrialState;
  isLoading: boolean;
  usage: IUsageData | null;
  invoicesList: IInvoice[] | null;
  paymentMethod: IPaymentMethod | null;
  allBillingPlans: IBillingPlan[] | null;
  checkout: ICheckoutState;
  billingInfo: IPaymentInfo;
  cancellation: ICancellationState;
  paymentDate: Date;
  billingContact: IBillingAddressState;
  isSubmitting: boolean;
  teamSizeLimit: ITeamSizeLimit;
}

enum TBillingActionType {
  SetBillingInterval = 'SetBillingInterval',
  SetPlans = 'SetPlans',
  SetTrialState = 'SetTrialState',
  SetCancelTrialState = 'SetCancelTrialState',
  SetIsLoading = 'SetIsLoading',
  SetUsage = 'SetUsage',
  SetInvoicesList = 'SetInvoicesList',
  SetPaymentMethod = 'SetPaymentMethod',
  SetAllBillingPlans = 'SetAllBillingPlans',
  SetBillingInfoIsPopupVisible = 'SetBillingInfoIsPopupVisible',
  // Billing Contact
  SetBillingContactIsPopupVisible = 'SetBillingContactIsPopupVisible',
  SetBillingContact = 'SetBillingContact',
  SetBillingContactSubmitting = 'SetBillingContactSubmitting',

  // checkout
  SetCheckoutIsPopupVisible = 'SetCheckoutIsPopupVisible',
  SetCheckoutPlanSelected = 'SetCheckoutPlanSelected',
  SetCheckoutPlanCalculation = 'SetCheckoutPlanCalculation',
  SetCheckoutConfirmationIntent = 'SetCheckoutConfirmationIntent',
  SetCheckoutCoupon = 'SetCheckoutCoupon',
  SetCheckoutCouponLoading = 'SetCheckoutCouponLoading',
  SetCheckoutSeats = 'SetCheckoutSeats',
  SetCheckoutIsSubmitting = 'SetCheckoutIsSubmitting',
  SetCheckoutSubmitButton = 'SetCheckoutSubmitButton',
  SetCheckoutIsPlanUnchanged = 'SetCheckoutIsPlanUnchanged',
  ResetCheckout = 'ResetCheckout',

  // cancellation
  SetCancellationIsLoading = 'SetCancellationIsLoading',
  SetCancellationModalType = 'SetCancellationModalType',
  SetCancellationComments = 'SetCancellationComments',
  SetCancellationSurveyQuestions = 'SetCancellationSurveyQuestions',

  SetPaymentDate = 'SetPaymentDate',
  SetIsSubmitting = 'SetIsSubmitting',
  SetMaxTeamSize = 'SetMaxTeamSize',
  SetTeamSizeLimitReached = 'SetTeamSizeLimitReached',
}

type TBillingPayload = {
  [TBillingActionType.SetBillingInterval]: BillingInterval;
  [TBillingActionType.SetPlans]: IPlanGrouped;
  [TBillingActionType.SetTrialState]: ITrialState;
  [TBillingActionType.SetCancelTrialState]: ITrialState;
  [TBillingActionType.SetIsLoading]: boolean;
  [TBillingActionType.SetUsage]: IUsageData;
  [TBillingActionType.SetInvoicesList]: IInvoice[];
  [TBillingActionType.SetPaymentMethod]: IPaymentMethod | null;
  [TBillingActionType.SetAllBillingPlans]: IBillingPlan[] | null;
  [TBillingActionType.SetBillingInfoIsPopupVisible]: boolean;
  [TBillingActionType.SetCheckoutIsPopupVisible]: { show: boolean; type: CheckoutPopupType };
  [TBillingActionType.SetCheckoutPlanSelected]: IBillingPlan;
  [TBillingActionType.SetCheckoutPlanCalculation]: IBillingCalculation | null;
  [TBillingActionType.SetCheckoutConfirmationIntent]: IPaymentIntent | null;
  [TBillingActionType.SetCheckoutCoupon]: ICoupon | null;
  [TBillingActionType.SetCheckoutCouponLoading]: boolean;
  [TBillingActionType.SetCheckoutSeats]: number;
  [TBillingActionType.SetCheckoutIsSubmitting]: boolean;
  [TBillingActionType.SetCheckoutSubmitButton]: { label: string; disabled: boolean };
  [TBillingActionType.SetCheckoutIsPlanUnchanged]: boolean;
  [TBillingActionType.ResetCheckout];
  [TBillingActionType.SetCancellationIsLoading]: boolean;
  [TBillingActionType.SetCancellationModalType]: CancellationPopupType | null;
  [TBillingActionType.SetCancellationComments]: string;
  [TBillingActionType.SetBillingContactIsPopupVisible]: boolean;
  [TBillingActionType.SetBillingContactSubmitting]: boolean;
  [TBillingActionType.SetBillingContact]: string;
  [TBillingActionType.SetCancellationSurveyQuestions]: ICancellationSurveyQuestion[];
  [TBillingActionType.SetPaymentDate]: Date;
  [TBillingActionType.SetIsSubmitting]: boolean;
  [TBillingActionType.SetMaxTeamSize]: number;
  [TBillingActionType.SetTeamSizeLimitReached]: boolean;
};

type BillingActions = ActionMap<TBillingPayload>[keyof ActionMap<TBillingPayload>];

const initialBillingState: TBillingState = {
  billingInterval: BillingInterval.MONTH,
  plans: null,
  isLoading: true,
  isSubmitting: false,
  usage: null,
  invoicesList: null,
  paymentMethod: null,
  allBillingPlans: null,
  billingContact: {
    isPopupVisible: false,
    contact: '',
    isSubmitting: false,
  },
  billingInfo: {
    isPopupVisible: false,
  },
  trial: {
    popupVisible: false,
    type: BillingProduct.STARTER,
  },
  checkout: {
    isPopupVisible: false,
    planSelected: null,
    planCalculation: null,
    confirmationIntent: null,
    coupon: null,
    isCouponLoading: false,
    seats: 0,
    isSubmitting: false,
    popupType: CheckoutPopupType.CHECKOUT,
    submitButtonLabel: 'Submit',
    submitButtonDisabled: false,
    isPlanUnchanged: true,
  },
  cancelTrial: {
    popupVisible: false,
    type: BillingProduct.STARTER,
  },
  cancellation: {
    isLoading: false,
    popupType: null,
    surveyQuestions: [],
    comments: '',
  },
  paymentDate: new Date(),
  teamSizeLimit: {
    max: MAX_TEAM_SIZE,
    reached: false,
  },
};

const billingIntervalLens = lensProp<TBillingState, 'billingInterval'>('billingInterval');
const plansLens = lensProp<TBillingState, 'plans'>('plans');
const trialLens = lensProp<TBillingState, 'trial'>('trial');
const isSubmittingLens = lensProp<TBillingState, 'isSubmitting'>('isSubmitting');
const cancelTrialLens = lensProp<TBillingState, 'cancelTrial'>('cancelTrial');
const isLoadingLens = lensProp<TBillingState, 'isLoading'>('isLoading');
const usageLens = lensProp<TBillingState, 'usage'>('usage');
const invoicesListLens = lensProp<TBillingState, 'invoicesList'>('invoicesList');
const paymentMethodLens = lensProp<TBillingState, 'paymentMethod'>('paymentMethod');
const allBillingPlansLens = lensProp<TBillingState, 'allBillingPlans'>('allBillingPlans');
const billingContactIsPopupVisibleLens = lensPath<TBillingState, TBillingState['billingContact']['isPopupVisible']>([
  'billingContact',
  'isPopupVisible',
]);

const billingContactIsSubmittingLens = lensPath<TBillingState, TBillingState['billingContact']['isSubmitting']>([
  'billingContact',
  'isSubmitting',
]);

const billingContactLens = lensPath<TBillingState, TBillingState['billingContact']['contact']>([
  'billingContact',
  'contact',
]);

const billingInfoIsPopupVisibleLens = lensPath<TBillingState, TBillingState['billingInfo']['isPopupVisible']>([
  'billingInfo',
  'isPopupVisible',
]);

const checkoutIsPopupVisibleLens = lensPath<TBillingState, TBillingState['checkout']['isPopupVisible']>([
  'checkout',
  'isPopupVisible',
]);
const teamSizeMaxLens = lensPath<TBillingState, TBillingState['teamSizeLimit']['max']>(['teamSizeLimit', 'max']);
const teamSizeReachedLens = lensPath<TBillingState, TBillingState['teamSizeLimit']['reached']>([
  'teamSizeLimit',
  'reached',
]);
const checkoutPlanSelectedLens = lensPath<TBillingState, TBillingState['checkout']['planSelected']>([
  'checkout',
  'planSelected',
]);
const checkoutPlanCalculationLens = lensPath<TBillingState, TBillingState['checkout']['planCalculation']>([
  'checkout',
  'planCalculation',
]);
const checkoutConfirmationIntentLens = lensPath<TBillingState, TBillingState['checkout']['confirmationIntent']>([
  'checkout',
  'confirmationIntent',
]);
const checkoutCouponLens = lensPath<TBillingState, TBillingState['checkout']['coupon']>(['checkout', 'coupon']);
const checkoutIsCouponLoadingLens = lensPath<TBillingState, TBillingState['checkout']['isCouponLoading']>([
  'checkout',
  'isCouponLoading',
]);
const checkoutSeatsLens = lensPath<TBillingState, TBillingState['checkout']['seats']>(['checkout', 'seats']);
const checkoutIsSubmittingLens = lensPath<TBillingState, TBillingState['checkout']['isSubmitting']>([
  'checkout',
  'isSubmitting',
]);
const checkoutPopupTypeLens = lensPath<TBillingState, TBillingState['checkout']['popupType']>([
  'checkout',
  'popupType',
]);
const checkoutSubmitButtonLabelLens = lensPath<TBillingState, TBillingState['checkout']['submitButtonLabel']>([
  'checkout',
  'submitButtonLabel',
]);
const checkoutSubmitButtonDisabledLens = lensPath<TBillingState, TBillingState['checkout']['submitButtonDisabled']>([
  'checkout',
  'submitButtonDisabled',
]);
const checkoutIsPlanUnchangedLens = lensPath<TBillingState, TBillingState['checkout']['isPlanUnchanged']>([
  'checkout',
  'isPlanUnchanged',
]);
const resetCheckoutLens = lensProp<TBillingState, 'checkout'>('checkout');

const cancellationIsLoadingLens = lensPath<TBillingState, TBillingState['cancellation']['isLoading']>([
  'cancellation',
  'isLoading',
]);
const cancellationPopupTypeLens = lensPath<TBillingState, TBillingState['cancellation']['popupType']>([
  'cancellation',
  'popupType',
]);
const cancellationCommentsLens = lensPath<TBillingState, TBillingState['cancellation']['comments']>([
  'cancellation',
  'comments',
]);
const cancellationSurveyQuestionsLens = lensPath<TBillingState, TBillingState['cancellation']['surveyQuestions']>([
  'cancellation',
  'surveyQuestions',
]);

const checkoutPaymentDateLens = lensProp<TBillingState, 'paymentDate'>('paymentDate');

const billingContextReducer = (state: TBillingState, action: BillingActions) => {
  switch (action.type) {
    case TBillingActionType.SetBillingInterval:
      return set(billingIntervalLens, action.payload, state);
    case TBillingActionType.SetPlans:
      return set(plansLens, action.payload, state);
    case TBillingActionType.SetTrialState:
      return set(trialLens, action.payload, state);
    case TBillingActionType.SetCancelTrialState:
      return set(cancelTrialLens, action.payload, state);
    case TBillingActionType.SetIsLoading:
      return set(isLoadingLens, action.payload, state);
    case TBillingActionType.SetUsage:
      return set(usageLens, action.payload, state);
    case TBillingActionType.SetInvoicesList:
      return set(invoicesListLens, action.payload, state);
    case TBillingActionType.SetPaymentMethod:
      return set(paymentMethodLens, action.payload, state);
    case TBillingActionType.SetAllBillingPlans:
      return set(allBillingPlansLens, action.payload, state);
    case TBillingActionType.SetBillingInfoIsPopupVisible:
      return set(billingInfoIsPopupVisibleLens, action.payload, state);
    case TBillingActionType.SetCheckoutIsPopupVisible:
      return pipe(
        set(checkoutIsPopupVisibleLens, action.payload.show),
        set(checkoutPopupTypeLens, action.payload.type),
      )(state);
    case TBillingActionType.SetCheckoutPlanSelected:
      return set(checkoutPlanSelectedLens, action.payload, state);
    case TBillingActionType.SetCheckoutPlanCalculation:
      return set(checkoutPlanCalculationLens, action.payload, state);
    case TBillingActionType.SetCheckoutConfirmationIntent:
      return set(checkoutConfirmationIntentLens, action.payload, state);
    case TBillingActionType.SetCheckoutCoupon:
      return set(checkoutCouponLens, action.payload, state);
    case TBillingActionType.SetCheckoutCouponLoading:
      return set(checkoutIsCouponLoadingLens, action.payload, state);
    case TBillingActionType.SetCheckoutSeats:
      return set(checkoutSeatsLens, action.payload, state);
    case TBillingActionType.SetCheckoutIsSubmitting:
      return set(checkoutIsSubmittingLens, action.payload, state);
    case TBillingActionType.SetCheckoutSubmitButton:
      return pipe(
        set(checkoutSubmitButtonLabelLens, action.payload.label),
        set(checkoutSubmitButtonDisabledLens, action.payload.disabled),
      )(state);
    case TBillingActionType.SetCheckoutIsPlanUnchanged:
      return set(checkoutIsPlanUnchangedLens, action.payload, state);
    case TBillingActionType.ResetCheckout:
      return set(resetCheckoutLens, initialBillingState.checkout, state);
    case TBillingActionType.SetCancellationIsLoading:
      return set(cancellationIsLoadingLens, action.payload, state);
    case TBillingActionType.SetCancellationModalType:
      return set(cancellationPopupTypeLens, action.payload, state);
    case TBillingActionType.SetCancellationComments:
      return set(cancellationCommentsLens, action.payload, state);
    case TBillingActionType.SetCancellationSurveyQuestions:
      return set(cancellationSurveyQuestionsLens, action.payload, state);
    case TBillingActionType.SetPaymentDate:
      return set(checkoutPaymentDateLens, action.payload, state);
    case TBillingActionType.SetBillingContactIsPopupVisible:
      return set(billingContactIsPopupVisibleLens, action.payload, state);
    case TBillingActionType.SetBillingContactSubmitting:
      return set(billingContactIsSubmittingLens, action.payload, state);
    case TBillingActionType.SetBillingContact:
      return set(billingContactLens, action.payload, state);
    case TBillingActionType.SetTeamSizeLimitReached:
      return set(teamSizeReachedLens, action.payload, state);
    case TBillingActionType.SetMaxTeamSize:
      return set(teamSizeMaxLens, action.payload, state);
    case TBillingActionType.SetIsSubmitting:
      return set(isSubmittingLens, action.payload, state);
    default:
      return state;
  }
};

const stripePromise = loadStripe(VITE_STRIPE_KEY as string);

const BillingContextProvider: React.FC<WithChildren> = observer(({ children }) => {
  const { appState, loadSubscription, loadSubscriptionLimits, loadApplicationStats, appModel } = useAppState();
  const { enqueueBasicSnackbar, closeSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();
  const [billingContext, dispatchBillingContext] = useReducer(billingContextReducer, initialBillingState);
  const subscription = appModel.assistantSubscription.$subscription.value;

  const callSuccessSnack = ({ alert = 'Hooray!', topContent, bottomContent, withLogo, theme, style }) =>
    enqueueBasicSnackbar(
      snackbarMessages.billing.withComponent({
        trigger: (
          <SubscribeSnack
            bottomContent={bottomContent}
            topContent={topContent}
            alertContent={alert}
            withLogo={withLogo}
            theme={theme}
            style={style}
            onCloseIconClick={() => closeSnackbar()}
          />
        ),
      }),
    );

  /**
   * Code for Billing Plans
   * */
  const selectPlan = useCallback((plan: IBillingPlan) => {
    dispatchBillingContext({ type: TBillingActionType.SetCheckoutPlanSelected, payload: plan });
    /** Sets checkout seats for "team" and keeps it 0 for others * */
    const _seats = plan.productName === BillingProduct.TEAM ? DEFAULT_BILLING_CHECKOUT_SEATS : 0;
    dispatchBillingContext({ type: TBillingActionType.SetCheckoutSeats, payload: _seats });
  }, []);

  const _findPlanByInterval = (plans: IBillingPlan[]) =>
    plans.find(plan => plan.interval === billingContext.billingInterval);

  const onChangeBillingInterval = useCallback(
    (billingInterval: BillingInterval) =>
      dispatchBillingContext({ type: TBillingActionType.SetBillingInterval, payload: billingInterval }),
    [],
  );

  const onToggleTrialPopup = useCallback(
    (popupVisible: boolean, type: BillingProduct = BillingProduct.TEAM) =>
      dispatchBillingContext({
        type: TBillingActionType.SetTrialState,
        payload: {
          popupVisible,
          type,
        },
      }),
    [],
  );

  const onToggleCancelTrialPopup = useCallback(
    (popupVisible: boolean, type: BillingProduct = BillingProduct.TEAM) =>
      dispatchBillingContext({
        type: TBillingActionType.SetCancelTrialState,
        payload: {
          popupVisible,
          type,
        },
      }),
    [],
  );

  const onStartTrial = useCallback(
    async (type: BillingProduct) => {
      dispatchBillingContext({
        type: TBillingActionType.SetIsSubmitting,
        payload: true,
      });

      try {
        const targetTrialPlan = billingContext.allBillingPlans?.find(
          plan => plan.interval === billingContext.billingInterval && plan.productName === type,
        );
        await startTrial(`${appState.organizationId}`, targetTrialPlan?.key);
        await _loadUsageAndBillingPlans();
        await loadSubscription();
        await _reloadSubscriptionLimits();

        if (type === BillingProduct.PRO) {
          callSuccessSnack({
            alert: 'Trial liftoff',
            bottomContent: '',
            topContent: 'Pro',
            withLogo: false,
            theme: SnackTheme.BLUE,
            style: { width: 300 },
          });
        } else {
          callSuccessSnack({
            alert: 'Your trial is underway!',
            bottomContent: '',
            topContent: 'Team',
            withLogo: false,
            theme: SnackTheme.GREEN,
            style: { width: 300 },
          });
        }

        dispatchBillingContext({
          type: TBillingActionType.SetIsSubmitting,
          payload: false,
        });

        onToggleTrialPopup(false, type);
      } catch (e: any) {
        LOG.error(e);
        enqueueErrorSnackbar(e?.response?.data?.errors?.[0]?.description || 'Something went wrong.');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.organizationId, onToggleTrialPopup, billingContext.allBillingPlans, billingContext.billingInterval],
  );

  const onCancelTrial = (type: BillingProduct) => {
    onToggleCancelTrialPopup(true, type);
  };

  const onCancelTrialConfirmed = async () => {
    dispatchBillingContext({
      type: TBillingActionType.SetIsSubmitting,
      payload: true,
    });

    try {
      await cancelTrial(`${appState.organizationId}`);
      // There is a delay between cancelled Trial and setting it up back to Free
      // So this interval allow us to check is subscription actually changed
      const checkSubscription = async () => {
        const userSubscription = await loadSubscription();
        const isFree = userSubscription?.productName === BillingProduct.FREE;
        const isTeamOrStarter =
          userSubscription?.productName === BillingProduct.TEAM ||
          userSubscription?.productName === BillingProduct.STARTER;
        const isCancelled = isTeamOrStarter && userSubscription?.status === BillingStatus.CANCELED;

        dispatchBillingContext({
          type: TBillingActionType.SetIsSubmitting,
          payload: false,
        });

        if (isFree || isCancelled) {
          const snackBarMessage = isCancelled
            ? 'trial has been cancelled'
            : `trial has been cancelled and you've been downgraded to Free.`;
          clearInterval(checkSub);
          onToggleCancelTrialPopup(false);
          enqueueBasicSnackbar(`Your ${capitalizeWord(subscription?.productName || 'product')} ${snackBarMessage}`);
          navigate(0);
        }
      };

      const checkSub = setInterval(checkSubscription, 500);
    } catch (e: any) {
      LOG.error(e);
      enqueueErrorSnackbar(e?.response?.data?.errors?.[0]?.description || 'Something went wrong.');
    }
  };

  const onActivateFreePlan = async () => {
    await activateFreeSubscription(`${appState.organizationId}`);
    await _updateSubscriptionInAppState();
    await _loadUsageAndBillingPlans();
    await _reloadSubscriptionLimits();
    callSuccessSnack({
      alert: 'Hooray! You’re now on our Free plan!',
      bottomContent: '',
      topContent: 'FREE',
      withLogo: false,
      theme: SnackTheme.GREEN,
      style: { width: 435 },
    });
  };

  /** Called on clicking to Buy/Upgrade/Downgrade/Manage Plans */
  const onUpdateSubscription = useCallback(
    (plan?: IBillingPlan, popupType?: CheckoutPopupType, activity?: AnalyticEvents, clickFrom?: TClickedFromParam) => {
      if (!billingContext.plans || !subscription?.productName) return;

      const samePlan: IBillingPlan | undefined = billingContext.plans[subscription.productName];
      const _plan = plan || samePlan;
      if (!_plan) return;

      const _isTeamPlan = _plan.productName === BillingProduct.TEAM;
      const _isFreePlan = _plan.productName === BillingProduct.FREE;
      const _isStarterPlan = _plan.productName === BillingProduct.STARTER;
      const _isPlanCancelled = subscription.status === BillingStatus.CANCELED;
      const _isTrialExpired = subscription?.cancelledReason === CancelledReason.TRIAL_EXPIRED;
      let _popupType = popupType || CheckoutPopupType.CHECKOUT;

      if (_isPlanCancelled && _isFreePlan) {
        onActivateFreePlan();

        return;
      }

      /* special case to override popupType when the subscriptionStatus is cancelled (but not for expired state) */
      if ((_isTeamPlan || _isStarterPlan) && _plan.action === BillingAction.BUY) {
        _popupType = _isPlanCancelled && !_isTrialExpired ? CheckoutPopupType.MANAGE_PLAN : _popupType;
      }

      if (_plan.action === BillingAction.TRIAL) {
        selectPlan(_plan);
        onToggleTrialPopup(true, _plan.productName);
      } else {
        selectPlan(_plan);
        onToggleCheckoutPopup(true, _popupType);
      }

      if (activity && clickFrom) {
        appModel.analyticsService.track(activity, { clicked_from: clickFrom });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.organizationId, subscription, billingContext.plans],
  );

  useEffect(() => {
    const groupedPlans = groupBy(billingContext.allBillingPlans, 'productName');
    const freePlan = groupedPlans[BillingProduct.FREE];
    const proPlan = groupedPlans[BillingProduct.PRO];
    const teamPlan = groupedPlans[BillingProduct.TEAM];
    const starterPlan = groupedPlans[BillingProduct.STARTER];

    const free = freePlan && freePlan[0];
    const pro = proPlan && _findPlanByInterval(proPlan);
    const team = teamPlan && _findPlanByInterval(teamPlan);
    const starter = starterPlan && _findPlanByInterval(starterPlan);

    dispatchBillingContext({ type: TBillingActionType.SetPlans, payload: { free, pro, team, starter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingContext.allBillingPlans, billingContext.billingInterval]);

  useEffect(() => {
    if (
      !appModel.assistantSubscription.limits ||
      !subscription?.productName ||
      !appModel.assistantSubscription.limits?.user ||
      !appModel.assistantSubscription.limits?.contentCheck ||
      !appModel.assistantSubscription.limits?.coWrite
    ) {
      return;
    }

    const { user, contentCheck, coWrite } = appModel.assistantSubscription.limits;

    dispatchBillingContext({
      type: TBillingActionType.SetUsage,
      payload: {
        contentCheck,
        user: [BillingProduct.PRO, BillingProduct.FREE].includes(subscription.productName as BillingProduct)
          ? undefined
          : user,
        coWrite,
      },
    });
  }, [
    appModel.assistantSubscription.limits,
    appModel.assistantSubscription.limits?.user,
    appModel.assistantSubscription.limits?.contentCheck,
    appModel.assistantSubscription.limits?.coWrite,
    subscription?.productName,
  ]);

  /**
   * Code for Payment Info Block
   * */
  const onEditPaymentMethodClick = useCallback(() => {
    dispatchBillingContext({ type: TBillingActionType.SetBillingInfoIsPopupVisible, payload: true });
  }, []);

  const onEditPaymentMethod = useCallback(async () => {
    onTogglePaymentInfoPopup(false);
    const paymentMethod = await getPaymentMethod(`${appState.organizationId}`);
    dispatchBillingContext({ type: TBillingActionType.SetPaymentMethod, payload: paymentMethod });
    enqueueBasicSnackbar(snackbarMessages.billing.paymentMethodUpdated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Code for routing to billing page
   * */
  const onManageUsersClick = () => {
    const _route =
      subscription?.productName === BillingProduct.TEAM || appModel.teamsModel.teams?.length === 1
        ? ROUTE.toTeamUsers(appState.organizationId, appState.teamId)
        : ROUTE.toPeople(appState.organizationId);
    navigate(_route);
  };

  const onManageBillingGroupsClick = () => {
    navigate(ROUTE.toAdminBillingGroups(appState.organizationId));
  };

  /**
   * Code for billing contact
   * */
  const onToggleBillingContactPopup = useCallback((show: boolean) => {
    dispatchBillingContext({
      type: TBillingActionType.SetBillingContactIsPopupVisible,
      payload: show,
    });
  }, []);

  const onSaveBillingContact = useCallback(async (contact: string) => {
    dispatchBillingContext({
      type: TBillingActionType.SetBillingContactSubmitting,
      payload: true,
    });
    await updateBillingCustomer(Number(appState.organizationId), contact);
    dispatchBillingContext({ type: TBillingActionType.SetBillingContact, payload: contact });
    dispatchBillingContext({
      type: TBillingActionType.SetBillingContactSubmitting,
      payload: false,
    });
    onToggleBillingContactPopup(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Code for checkout
   * */
  const onToggleCheckoutPopup = useCallback((show: boolean, checkoutPopupType: CheckoutPopupType) => {
    if (!show) {
      // reset checkout variables
      dispatchBillingContext({ type: TBillingActionType.ResetCheckout });
    }

    dispatchBillingContext({
      type: TBillingActionType.SetCheckoutIsPopupVisible,
      payload: { show, type: checkoutPopupType },
    });
  }, []);

  const _calculateCheckoutTotal = async (
    billingPlan: IBillingPlan,
    coupon?: string,
    seats?: number,
    unsetCoupon = false,
  ) => {
    const _seats = billingContext.checkout.seats === 0 ? undefined : seats || billingContext.checkout.seats;
    const _coupon = unsetCoupon ? undefined : coupon || billingContext.checkout.coupon?.code;

    const data = await calculate(Number(appState.organizationId), billingPlan.key, _coupon, _seats);
    dispatchBillingContext({ type: TBillingActionType.SetCheckoutPlanCalculation, payload: data });

    return data;
  };

  const _isCheckoutSubmittingToggle = (flag: boolean) => {
    dispatchBillingContext({
      type: TBillingActionType.SetCheckoutIsSubmitting,
      payload: flag,
    });
    dispatchBillingContext({ type: TBillingActionType.SetCheckoutConfirmationIntent, payload: null });
  };

  const onApplyCoupon = useCallback(
    async (coupon: string) => {
      if (billingContext.checkout.planSelected) {
        dispatchBillingContext({ type: TBillingActionType.SetCheckoutCouponLoading, payload: true });
        try {
          const data = await _calculateCheckoutTotal(
            billingContext.checkout.planSelected,
            coupon,
            billingContext.checkout.seats,
          );

          dispatchBillingContext({
            type: TBillingActionType.SetCheckoutCoupon,
            payload: {
              code: coupon,
              valid: data ? !!data.invoice.discount : false,
            },
          });
        } catch (e: any) {
          if (e.messageKey === BackendErrorCodeKey.BILLING_INVALID_COUPON) {
            dispatchBillingContext({
              type: TBillingActionType.SetCheckoutCoupon,
              payload: {
                code: coupon,
                valid: false,
              },
            });
          }
        } finally {
          dispatchBillingContext({ type: TBillingActionType.SetCheckoutCouponLoading, payload: false });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.organizationId, billingContext.checkout],
  );

  const onRemoveCoupon = useCallback(async () => {
    if (billingContext.checkout.planSelected) {
      dispatchBillingContext({
        type: TBillingActionType.SetCheckoutCoupon,
        payload: {
          code: '',
          valid: null,
        },
      });

      await _calculateCheckoutTotal(
        billingContext.checkout.planSelected,
        undefined,
        billingContext.checkout.seats,
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.organizationId, billingContext.checkout]);

  const onCheckoutPlanSwitch = useCallback(
    (futurePlanInterval: BillingInterval, seats: number) => {
      if (billingContext.checkout.planSelected && billingContext.allBillingPlans) {
        const targetPlan = billingContext.allBillingPlans.find(
          plan =>
            plan.interval === futurePlanInterval &&
            plan.productName === billingContext.checkout.planSelected?.productName,
        );

        if (targetPlan) {
          selectPlan(targetPlan);
          dispatchBillingContext({ type: TBillingActionType.SetCheckoutPlanCalculation, payload: null });
          dispatchBillingContext({ type: TBillingActionType.SetCheckoutSeats, payload: seats });
          _calculateCheckoutTotal(targetPlan, billingContext.checkout.coupon?.code, seats);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [billingContext.checkout, billingContext.allBillingPlans],
  );

  const onSubmitBillingInformation = useCallback(async () => {
    let showSuccessSnack = true;
    const _currentSeatsNumber = billingContext?.checkout?.seats || undefined;
    const _prevNumberSeats = appModel.assistantSubscription.limits?.user?.limit || undefined;

    if (
      billingContext.checkout?.planSelected?.productName === BillingProduct.TEAM &&
      billingContext.checkout?.planSelected?.interval === subscription?.price?.interval &&
      _currentSeatsNumber !== _prevNumberSeats
    ) {
      showSuccessSnack = false;
    }

    let _coupon;
    _isCheckoutSubmittingToggle(true);

    await getPaymentMethod(Number(appState.organizationId)).then(paymentMethod => {
      dispatchBillingContext({ type: TBillingActionType.SetPaymentMethod, payload: paymentMethod });
    });

    if (billingContext.checkout.planSelected) {
      if (billingContext.checkout.coupon?.valid) {
        _coupon = billingContext.checkout.coupon.code;
      }

      const data = await updateSubscription(
        `${appState.organizationId}`,
        billingContext.checkout.planSelected.key,
        _currentSeatsNumber,
        _coupon,
      );

      loadSubscription();
      loadSubscriptionLimits();
      await loadApplicationStats(TSubscriptionLimitType.enum.user);

      if (data.paymentRequired) {
        dispatchBillingContext({
          type: TBillingActionType.SetCheckoutConfirmationIntent,
          payload: data.paymentIntent,
        });
      } else {
        if (showSuccessSnack) {
          const theme =
            billingContext.checkout.planSelected.productName === BillingProduct.PRO
              ? SnackTheme.BLUE
              : SnackTheme.GREEN;

          callSuccessSnack({
            alert: 'Hooray!',
            bottomContent: `You've been upgraded to ${billingContext.checkout.planSelected.name}`,
            topContent: billingContext.checkout.planSelected.productName,
            withLogo: true,
            style: {},
            theme,
          });
        }

        onSubmitBillingInformationComplete();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appState.organizationId,
    appModel.assistantSubscription.limits?.user?.limit,
    billingContext.checkout.seats,
    billingContext.checkout.coupon,
    billingContext.checkout.planSelected,
    onToggleCheckoutPopup,
  ]);

  const onSubmitBillingInformationError = (error: StripeError) => {
    LOG.info('BillingContextProvider', error);
    _isCheckoutSubmittingToggle(false);
  };

  const onTogglePaymentInfoPopup = useCallback(
    (show: boolean) => dispatchBillingContext({ type: TBillingActionType.SetBillingInfoIsPopupVisible, payload: show }),
    [],
  );

  const onToggleCancellationPopup = useCallback(
    (popupType: CancellationPopupType | null) =>
      dispatchBillingContext({ type: TBillingActionType.SetCancellationModalType, payload: popupType }),
    [],
  );

  const _setCancellationLoading = (isLoading: boolean) => {
    dispatchBillingContext({ type: TBillingActionType.SetCancellationIsLoading, payload: isLoading });
  };

  const _updateSubscriptionInAppState = async () => {
    loadSubscription();
    loadSubscriptionLimits();
  };

  const _loadUsageAndBillingPlans = async () => {
    if (subscription && appModel.assistantSubscription.limits) {
      onChangeBillingInterval((subscription.price?.interval as BillingInterval) || BillingInterval.MONTH);
      const allBillingplans = await getAvailablePlans(`${appState.organizationId}`);
      dispatchBillingContext({ type: TBillingActionType.SetAllBillingPlans, payload: allBillingplans });
    }
  };

  const _reloadSubscriptionLimits = async () => {
    if (appModel.assistantSubscription.limits) {
      await Promise.all([
        appModel.assistantSubscription.limits.reloadLimit(TSubscriptionLimitType.enum.user),
        appModel.assistantSubscription.limits.reloadLimit(TSubscriptionLimitType.enum.contentCheck),
        appModel.assistantSubscription.limits.reloadLimit(TSubscriptionLimitType.enum.coWrite),
      ]);
    }
  };

  const _setCancellationComments = (comments: string) => {
    dispatchBillingContext({
      type: TBillingActionType.SetCancellationComments,
      payload: comments,
    });
  };

  const _setCancellationSurveyQuestions = (questions: ICancellationSurveyQuestion[]) => {
    dispatchBillingContext({
      type: TBillingActionType.SetCancellationSurveyQuestions,
      payload: questions,
    });
  };

  const onCancellationNextStep = useCallback(async () => {
    const isTeam = subscription?.productName === BillingProduct.TEAM;

    switch (billingContext.cancellation.popupType) {
      case CancellationPopupType.CANCEL_PLAN: {
        _setCancellationSurveyQuestions(isTeam ? [...teamSurveyQuestions] : [...proSurveyQuestions]);
        _setCancellationComments('');
        _setCancellationLoading(true);
        const eligibleForTrial = await getIsEligibleForDiscount(`${appState.organizationId}`);
        _setCancellationLoading(false);
        onToggleCancellationPopup(
          eligibleForTrial ? CancellationPopupType.ONE_TIME_OFFER : CancellationPopupType.CANCELLATION_SURVEY,
        );

        break;
      }
      case CancellationPopupType.ONE_TIME_OFFER:
        onToggleCancellationPopup(CancellationPopupType.CANCELLATION_SURVEY);
        break;

      case CancellationPopupType.CANCELLATION_SURVEY: {
        const { surveyQuestions, comments } = billingContext.cancellation;

        const reasons = surveyQuestions.reduce(
          (res, question) => {
            res[question.title] = question.checked ? 'checked' : 'unchecked';

            return res;
          },
          {} as Record<string, string>,
        );

        _setCancellationLoading(true);
        await saveCancellationSurvey(`${appState.organizationId}`, reasons as any, comments);
        _setCancellationLoading(false);
        onToggleCancellationPopup(CancellationPopupType.CANCELLATION_CONFIRM);
        break;
      }
      case CancellationPopupType.CANCELLATION_CONFIRM:
        _setCancellationLoading(true);
        await cancelSubscription(`${appState.organizationId}`);
        _setCancellationLoading(false);
        onToggleCancellationPopup(CancellationPopupType.PLAN_CANCELLED);
        break;

      case CancellationPopupType.PLAN_CANCELLED:
        onToggleCancellationPopup(CancellationPopupType.DELETE_ACCOUNT);
        break;

      default:
        break;
    }
  }, [billingContext.cancellation, subscription?.productName, appState.organizationId, onToggleCancellationPopup]);

  const onApplyForFreeMonth = useCallback(async () => {
    _setCancellationLoading(true);
    await applyForFreeMonthDiscount(`${appState.organizationId}`);
    _setCancellationLoading(false);
    onToggleCancellationPopup(CancellationPopupType.ONE_TIME_OFFER_ACCEPTED);
  }, [appState.organizationId, onToggleCancellationPopup]);

  const onApplyForWeeksFree = useCallback(async () => {
    _setCancellationLoading(true);
    await applyForFreeTeamWeeks(`${appState.organizationId}`);
    delay(500).then(async () => {
      enqueueBasicSnackbar(snackbarMessages.billing.paymentExtensionSuccess);
      appModel.analyticsService.track(AnalyticsActivity.billingTeamTrialExtension, {});
      navigate(0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.organizationId, onToggleCancellationPopup]);

  const onUpdateSurveyQuestion = useCallback(
    (question: ICancellationSurveyQuestion) => {
      const updatedQuestions = billingContext.cancellation.surveyQuestions.map(q =>
        q.title === question.title
          ? {
              ...question,
              checked: !question.checked,
            }
          : q,
      );
      _setCancellationSurveyQuestions(updatedQuestions);
    },
    [billingContext.cancellation.surveyQuestions],
  );

  const onUpdateCancellationComments = useCallback((comments: string) => {
    dispatchBillingContext({ type: TBillingActionType.SetCancellationComments, payload: comments });
  }, []);

  const onSubmitBillingInformationComplete = async () => {
    _isCheckoutSubmittingToggle(false);
    onToggleCheckoutPopup(false, CheckoutPopupType.CHECKOUT);
    dispatchBillingContext({ type: TBillingActionType.SetAllBillingPlans, payload: null });
    delay(1000).then(async () => {
      /* Todo: added this delay because the cards are not updating with the new plan */
      loadSubscription();
      await _reloadSubscriptionLimits();
      await _loadUsageAndBillingPlans();
    });
  };

  /** Code for Submit button label, submit button disabled and if the plan is unchanged */
  useEffect(() => {
    const _currentKey = subscription?.price?.key || ''; // Todo: check for cases when this will be undefined
    const _futureKey = billingContext?.checkout?.planSelected?.key || '';
    const _amountDue = convertCentsToDollar(billingContext.checkout.planCalculation?.invoice.amountDue || 0);

    const _currentSeats = appModel.assistantSubscription.limits?.user?.limit || 0;
    const _futureSeats = billingContext.checkout.seats; // this will be non-zero only for teams

    const _isPlanKeyUnchanged = _currentKey === _futureKey;
    const _isSeatValueUnchanged = _futureSeats === 0 || _currentSeats === _futureSeats;
    const _isValidCouponApplied = billingContext.checkout.coupon === null || !billingContext.checkout.coupon?.valid;
    const _isValuesUnchanged = _isPlanKeyUnchanged && _isSeatValueUnchanged && _isValidCouponApplied;

    const _labelPostFix = `- pay ${_amountDue} now`;
    let _label = `Update plan ${_labelPostFix}`;
    let _isDisabled = false; // Todo: combine isDisabled and isValuesUnchanged

    if (billingContext.checkout.popupType === CheckoutPopupType.CHECKOUT) {
      /** For CHECKOUT */

      if (_currentKey.includes('yearly') && _futureKey.includes('monthly')) {
        _label = 'Downgrade plan';
      } else if ((_currentKey.includes('monthly') && _futureKey.includes('yearly')) || _currentKey === _futureKey) {
        // handle initial state when user on monthly plan
        _label = `Upgrade plan`;
      } else {
        _label = 'Upgrade plan';
      }
    } else {
      /** For MANAGE_PLAN */

      _isDisabled = _isValuesUnchanged;

      if (_isPlanKeyUnchanged && _futureSeats !== 0) {
        const _seatsDiff = Math.abs(_currentSeats - _futureSeats);

        if (_currentSeats < _futureSeats) {
          _label = `Add ${_seatsDiff} seats - pay ${_amountDue} now`;
        } else if (_currentSeats > _futureSeats) {
          _label = `Remove ${_seatsDiff} users - reduce ${_amountDue} from your next bill`;
        } else {
          _label = 'Update plan';
        }
      }

      if (_isDisabled) {
        _label = 'Update plan';
      }

      if (appModel.assistantSubscription.isCancelled) {
        _label = `Resubscribe - pay ${_amountDue} now`;
        _isDisabled = false;
      }
    }

    if (subscription?.scheduledToCancelAt) {
      _label = _isValuesUnchanged
        ? `Resubscribe to ${subscription.price?.name}`
        : `Resubscribe and update plan ${_labelPostFix}`;

      _isDisabled = false;
    }

    dispatchBillingContext({
      type: TBillingActionType.SetCheckoutSubmitButton,
      payload: { label: _label, disabled: _isDisabled },
    });
    dispatchBillingContext({ type: TBillingActionType.SetCheckoutIsPlanUnchanged, payload: _isValuesUnchanged });
  }, [
    subscription,
    billingContext.checkout.popupType,
    billingContext.checkout.seats,
    billingContext.checkout.planCalculation,
    billingContext.checkout?.planSelected,
    billingContext.checkout.coupon,
    appModel.assistantSubscription.limits?.user?.limit,
    appModel.assistantSubscription.isCancelled,
  ]);

  useEffect(() => {
    if (subscription) {
      let _paymentDate;

      switch (subscription.status) {
        case BillingStatus.TRIALING:
          _paymentDate = new Date(subscription.trialEnd || '');
          break;
        case BillingStatus.ACTIVE:
          _paymentDate = new Date(subscription.endPeriodDate || '');
          break;
        case BillingStatus.PAST_DUE:
        case BillingStatus.CANCELED:
          _paymentDate = new Date(subscription.startPeriodDate || '');
          break;
        default:
          _paymentDate = subscription.scheduledToCancelAt;
      }

      let defaultMaxTeamSize;
      switch (billingContext.checkout.planSelected?.productName) {
        case BillingProduct.TEAM:
          defaultMaxTeamSize = MAX_TEAM_SIZE;
          break;
        case BillingProduct.STARTER:
          defaultMaxTeamSize = MAX_STARTER_TEAM_SIZE;
          break;
        default:
          defaultMaxTeamSize = MAX_TEAM_SIZE;
      }

      dispatchBillingContext({
        type: TBillingActionType.SetMaxTeamSize,
        payload: calcMaxTeamSize(defaultMaxTeamSize, appModel.assistantSubscription.limits?.user?.limit),
      });
      dispatchBillingContext({ type: TBillingActionType.SetPaymentDate, payload: _paymentDate });
    }
  }, [
    subscription,
    appModel.assistantSubscription.limits?.user?.limit,
    billingContext.checkout.isPopupVisible,
    billingContext.checkout.planSelected?.productName,
  ]);

  useEffect(() => {
    dispatchBillingContext({
      type: TBillingActionType.SetTeamSizeLimitReached,
      payload:
        !appModel.assistantSubscription.isEnterprise &&
        billingContext.checkout.seats > billingContext.teamSizeLimit.max,
    });
  }, [
    subscription,
    appModel.assistantSubscription.isEnterprise,
    appModel.assistantSubscription.limits?.user?.limit,
    billingContext.checkout.seats,
    billingContext.teamSizeLimit.max,
  ]);

  /**
   * Code for ManagePlan
   * */
  const onManagePlanClick = () => {
    onUpdateSubscription(undefined, CheckoutPopupType.MANAGE_PLAN);
  };

  const onCancelPlanClick = () => {
    onToggleCancellationPopup(CancellationPopupType.CANCEL_PLAN);
  };

  /**
   * Code for InitialPage load
   * */
  useEffect(() => {
    if (!appModel.assistantSubscription.limits?.isLoaded) {
      return;
    }

    dispatchBillingContext({ type: TBillingActionType.SetIsLoading, payload: false });
    _loadUsageAndBillingPlans();

    if (subscription?.productName && subscription.productName !== BillingProduct.ENTERPRISE) {
      requestService.api
        .get('/api/billing/organizations/{organizationId}/invoice', {
          params: {
            path: {
              organizationId: Number(appState.organizationId),
            },
          },
        })
        .then(invoicesList => {
          const invoices = invoicesList.data
            .filter(item => !!item.lines[0])
            .map(item => ({
              // TODO: remove after network fix
              // @ts-ignore
              date: item.createdAt,
              activity: item.lines[0]?.description,
              charge: formatAmount(item.amountDue) || '',
              // @ts-ignore
              link: item.invoicePdf,
            }));
          dispatchBillingContext({ type: TBillingActionType.SetInvoicesList, payload: (invoices as IInvoice[]) || [] });
        });

      getPaymentMethod(`${appState.organizationId}`).then(paymentMethod => {
        dispatchBillingContext({ type: TBillingActionType.SetPaymentMethod, payload: paymentMethod });
      });
      getBillingCustomer(`${appState.organizationId}`).then(customer => {
        if (customer && customer.email) {
          dispatchBillingContext({ type: TBillingActionType.SetBillingContact, payload: customer.email });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, appModel.assistantSubscription.limits?.isLoaded]);

  const _onContactSalesClick = () => {
    appModel.analyticsService.track(AnalyticsActivity.clickedContactSales, { clicked_from: 'billing_page' });
    openContactSalesPage();
  };

  return (
    <BillingContext.Provider
      value={{
        stripePromise,
        billingContext,
        onEditPaymentMethod,
        onRemoveCoupon,
        onApplyCoupon,
        onChangeBillingInterval,
        onUpdateSubscription,
        onActivateFreePlan,
        onStartTrial,
        onCancelTrial,
        onCancelTrialConfirmed,
        onToggleTrialPopup,
        onToggleCheckoutPopup,
        onTogglePaymentInfoPopup,
        onEditPaymentMethodClick,
        onSubmitBillingInformation,
        onSubmitBillingInformationError,
        onSubmitBillingInformationComplete,
        onCheckoutPlanSwitch,
        onManageUsersClick,
        onManageBillingGroupsClick,
        onManagePlanClick,
        onCancelPlanClick,
        onToggleCancellationPopup,
        onCancellationNextStep,
        onApplyForFreeMonth,
        onApplyForWeeksFree,
        onUpdateCancellationComments,
        onUpdateSurveyQuestion,
        onToggleBillingContactPopup,
        onSaveBillingContact,
        onContactSalesClick: _onContactSalesClick,
        onToggleCancelTrialPopup,
      }}
    >
      {children}
    </BillingContext.Provider>
  );
});

export function useBillingContext() {
  const context = useContext(BillingContext);

  if (!context) {
    throw new Error('useBillingContext must be used within the BillingContextProvider');
  }

  return context;
}

export default BillingContextProvider;
