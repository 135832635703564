import React, { useCallback, useEffect } from 'react';

import cx from 'classnames';

import { BillingProduct, getWordsCount } from '@writercolab/common-utils';
import { calculateChecksumOverDelta, deltaToString } from '@writercolab/quill-delta-utils';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';

import isEmpty from 'lodash/isEmpty';

import { useDocumentHighlightsContextProvider } from '../../../context/documentHighlightsContext';
import { useAppState } from '../../../state';
import { goToBillingNewTab, openContactSalesPage } from '../../../utils/navigationUtils';
import TemplateQuotaBanner, { QUOTA_BANNER_TYPE } from '../../molecules/TemplateQuotaBanner/TemplateQuotaBanner';
import DocumentHighlight from './parts/DocumentHighlight';
import DocumentHighlightLoader from './parts/DocumentHighlightLoader';
import DocumentHighlightsHeader from './parts/DocumentHighlightsHeader';
import ErrorContainer from './parts/ErrorContainer';
import HighlightErrorBody from './parts/HighlightErrorBody';

import styles from './styles.module.css';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';

interface IDocumentHighlightsProps {
  currentDocumentHash: string | null;
  currentWordsCount: number | null;
  wordsLimit: number;
  editorRef: any;
  analytics: IWebAppAnalyticsTrack;
}

export const DocumentHighlights: React.FC<IDocumentHighlightsProps> = ({
  currentDocumentHash,
  currentWordsCount,
  wordsLimit,
  editorRef,
  analytics,
}) => {
  const { appState } = useAppState();
  const {
    documentHighlightsContext,
    onCloseClick,
    onDocumentVersionChange,
    onCopyClick,
    onRegenerateClick,
    genDocumentHighlights,
    onDocumentWordsCountChange,
  } = useDocumentHighlightsContextProvider();

  const _onGetSubscriptionClick = useCallback(
    () => goToBillingNewTab(parseInt(`${appState.organizationId}`, 10), BillingProduct.STARTER),
    [appState.organizationId],
  );

  useEffect(() => {
    if (!appState.team?.id) {
      return;
    }

    analytics.track(AnalyticsActivity.coWriteHighlightsOpened, {
      team_id: appState.team?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.team?.id]);

  useEffect(() => {
    if (!isEmpty(currentDocumentHash)) {
      onDocumentVersionChange(currentDocumentHash!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocumentHash]);

  useEffect(() => {
    if (currentWordsCount !== null) {
      onDocumentWordsCountChange(currentWordsCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWordsCount]);

  useEffect(() => {
    if (editorRef?.current?.getEditorContent) {
      const contentDelta = editorRef?.current?.getEditorContent();

      if (!isEmpty(contentDelta)) {
        const textContent = deltaToString(contentDelta);
        const activeDocumentWordsCount = getWordsCount(textContent);

        onDocumentVersionChange(calculateChecksumOverDelta(contentDelta));
        onDocumentWordsCountChange(activeDocumentWordsCount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef]);

  if (documentHighlightsContext.isLoading && !documentHighlightsContext.locked) {
    return <DocumentHighlightLoader onCloseClick={onCloseClick} />;
  }

  if (documentHighlightsContext.locked) {
    return (
      <ErrorContainer onCloseClick={onCloseClick}>
        <BillingLock
          className={cx(styles.billingLock)}
          type={BillingLockType.DOCUMENT_HIGHLIGHTS}
          onActionCallback={_onGetSubscriptionClick}
        />
      </ErrorContainer>
    );
  }

  return (
    <div className={styles.container}>
      <>
        <DocumentHighlightsHeader onHideClick={onCloseClick} />
        {documentHighlightsContext.quotaExceeded && (
          <TemplateQuotaBanner
            className={styles.containerQuotaBanner}
            wordsLimit={wordsLimit}
            type={QUOTA_BANNER_TYPE.HIGHLIGHTS}
            onContactSales={openContactSalesPage}
          />
        )}
        <div className={styles.containerList}>
          {documentHighlightsContext.isError ? (
            <HighlightErrorBody onForceReload={genDocumentHighlights} />
          ) : (
            documentHighlightsContext.highlights.map(highlight => (
              <DocumentHighlight
                documentWordsCount={documentHighlightsContext.documentWordsCount}
                key={`${highlight.id}-${highlight.type}-${highlight.version}`}
                highlight={highlight}
                onReloadClick={onRegenerateClick}
                onCopyClick={onCopyClick}
              />
            ))
          )}
        </div>
      </>
    </div>
  );
};
