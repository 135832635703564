import type { Reducer } from 'react';
import type React from 'react';
import { useEffect, useMemo, useReducer } from 'react';

import { CONTACT_SALES_LINK, wordPluralize } from '@writercolab/common-utils';
import type { MuiTooltipProps } from '@writercolab/ui-atoms';
import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  Label,
  SizeTypes,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';

import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import { useTeamsContext } from '../../../context/teamsContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppState } from '../../../state';
import { openContactSalesPage } from '../../../utils/navigationUtils';
import { teamAdminsMapper } from '../../../utils/teamUtils';
import PageTitle from '../../molecules/PageTitle';
import TeamStatsSection from '../../molecules/TeamStatsSection';
import Error404Page from '../Error404Page';
import ContactSalesInfographic from './ContactSalesInfographic';
import { CreateTeamModal, DeleteTeamModal, EditTeamModal } from './modals';
import type { ICreateTeamForm } from './modals/CreateTeamModal';
import type { IEditTeamEditForm } from './modals/EditTeamModal';

import styles from './styles.module.css';

interface ITeamsPageProps {
  isDisabled?: boolean;
}

interface IModalState {
  isOpen: boolean;
  id: number;
  formData?: IEditTeamEditForm;
  name?: string;
}

interface IModalStates {
  editForm: IModalState;
  deleteForm: IModalState;
  createForm: IModalState;
}

const initialModalStates: IModalStates = {
  createForm: {
    isOpen: false,
    formData: undefined,
    id: -1,
  },
  editForm: {
    isOpen: false,
    formData: undefined,
    id: -1,
  },
  deleteForm: {
    isOpen: false,
    id: -1,
    formData: undefined,
  },
};

const modalStatesReducer: Reducer<IModalStates, { type: string; payload: any }> = (state, action) => {
  const { payload, type } = action;

  return {
    ...state,
    [type]: {
      isOpen: !state[type].isOpen,
      id: payload.id,
      formData: payload.formData,
    },
  };
};

export const TeamsPage: React.FC<ITeamsPageProps> = observer(({ isDisabled }: ITeamsPageProps) => {
  usePageTitle('Teams');
  const {
    appModel: { assistantSubscription, permissionsModel, analyticsService },
    appState: { organizationId, userProfile },
  } = useAppState();
  const [modalState, dispatchModalState] = useReducer(modalStatesReducer, initialModalStates);
  const { createForm, editForm, deleteForm } = modalState;
  const { teams, isLoading, handleUpdateTeam, handleCreateTeam, handleDeleteTeam } = useTeamsContext();

  const teamLimit = assistantSubscription.limits?.team?.limit ?? 0;

  const access = useMemo(() => {
    const teamsLimitReached = teams.length >= teamLimit;
    const _access = {
      createTeamDisabled: !assistantSubscription.isEnterprise || teamsLimitReached,
      disableCreateTeam: {
        flag: false,
        message: <Text />,
        tooltipPlacement: 'left-start',
        tooltipWidth: 210,
      },
      disableDeleteTeamOption: !permissionsModel?.isOrganizationAdmin,
      showContactSalesInfographic: assistantSubscription.isSelfServeCustomer && !assistantSubscription.isEnterprise,
    };

    if (_access.createTeamDisabled) {
      if (teamsLimitReached) {
        _access.disableCreateTeam = {
          flag: true,
          message: (
            <div className={styles.tooltipTextContainer}>
              <Label className={styles.limitLabelContainer}>limit reached</Label>
              <Text color={TextColor.WHITE} variant={TextSize.S}>
                You’ve reached your plan’s
                <br />
                limit of {teamLimit} {wordPluralize(teamLimit, 'team')}. To get more
                <br /> teams,{' '}
                <a href={CONTACT_SALES_LINK} target="_blank" className={styles.tooltipLink}>
                  contact sales
                </a>
                .
              </Text>
            </div>
          ),
          tooltipPlacement: 'bottom',
          tooltipWidth: 140,
        };
      } else if (assistantSubscription.isEnterprise) {
        _access.disableCreateTeam = {
          flag: !permissionsModel?.isOrganizationAdmin,
          message: (
            <Text color={TextColor.WHITE} variant={TextSize.S}>
              Only org admins can create new teams
            </Text>
          ),
          tooltipPlacement: 'bottom',
          tooltipWidth: 140,
        };
      } else {
        _access.disableCreateTeam = {
          flag: true,
          message: (
            <Text color={TextColor.WHITE} variant={TextSize.S}>
              Interested in multiple teams?{' '}
              <a href={CONTACT_SALES_LINK} target="_blank" className={styles.tooltipLink}>
                Contact sales
              </a>{' '}
              if you’d like to discuss upgrading to a Team or Enterprise plan.
            </Text>
          ),
          tooltipPlacement: 'left',
          tooltipWidth: 210,
        };
      }
    }

    return _access;
  }, [
    teams.length,
    teamLimit,
    assistantSubscription.isEnterprise,
    permissionsModel?.isOrganizationAdmin,
    assistantSubscription.isSelfServeCustomer,
  ]);

  useEffect(() => {
    analyticsService.track(AnalyticsActivity.allTeamsViewed, {});
  }, [analyticsService]);

  const onChange = (type: string, id?: number, formData?: IEditTeamEditForm | ICreateTeamForm) => {
    dispatchModalState({ type, payload: { id, formData } });
  };

  const mappedOrgAdmins = useMemo(() => {
    if (teams.length) {
      return teamAdminsMapper(teams[0].admins).filter(d => d.updateDisabled);
    }

    return [];
  }, [teams]);

  const handleContactSalesClick = () => {
    analyticsService.track(AnalyticsActivity.clickedContactSales, {
      clicked_from: 'all_teams',
    });

    openContactSalesPage();
  };

  if (isDisabled) {
    return <Error404Page />;
  }

  return (
    <div className={styles.contentWrapper} style={isLoading ? { overflow: 'hidden' } : {}}>
      {!assistantSubscription.isEnterprise ? (
        <BillingLock type={BillingLockType.TEAMS} onActionCallback={handleContactSalesClick} />
      ) : (
        <div className={styles.teamsList}>
          <div className={styles.teamsHeadline}>
            <PageTitle title="Teams" teamName="" className={styles.title} />
            {!isLoading && (
              <Tooltip
                disabled={!access.disableCreateTeam.flag}
                title={access.disableCreateTeam.message}
                tooltipWidth={access.disableCreateTeam.tooltipWidth}
                placement={access.disableCreateTeam.tooltipPlacement as MuiTooltipProps['placement']}
              >
                <Button
                  type={ButtonTypes.PRIMARY}
                  className={styles.createTeamButton}
                  size={SizeTypes.MEDIUM}
                  icon={<Icon name={IconVariant.ADD} />}
                  onClick={() => onChange('createForm', 0, { name: '', admins: mappedOrgAdmins })}
                  content="Create team"
                  disabled={access.disableCreateTeam.flag}
                />
              </Tooltip>
            )}
          </div>
          {!isLoading ? (
            <>
              {teams.map(team => (
                <TeamStatsSection
                  orgId={organizationId?.toString()}
                  team={team}
                  currentUsersId={userProfile?.id}
                  onChange={onChange}
                  key={team.id}
                  disabled={!permissionsModel?.isTeamAdminOf(team.id)}
                  disableDeleteTeamOption={access.disableDeleteTeamOption}
                />
              ))}
              {!assistantSubscription.isSelfServeCustomer && <div className={styles.spacer} />}
            </>
          ) : (
            <SkeletonLoader type={SkeletonLoaderType.TEAM_STATS_SECTION} repeat={3} style={{ marginTop: 25 }} />
          )}
          {access.showContactSalesInfographic && <ContactSalesInfographic />}
        </div>
      )}

      <EditTeamModal
        isOpen={editForm.isOpen}
        changeModalState={() => onChange('editForm')}
        teamId={editForm.id}
        values={editForm.formData}
        teamsList={teams}
        onSubmit={handleUpdateTeam}
        orgId={+organizationId!}
      />
      <CreateTeamModal
        isOpen={createForm.isOpen}
        values={createForm.formData as any}
        teamsList={teams}
        changeModalState={() => onChange('createForm')}
        onSubmit={handleCreateTeam}
        orgId={+organizationId!}
      />
      <DeleteTeamModal
        isOpen={deleteForm.isOpen}
        changeModalState={() => onChange('deleteForm')}
        onSubmit={handleDeleteTeam}
        teamId={deleteForm.id}
        teamName={deleteForm?.formData?.name || ''}
      />
    </div>
  );
});

export default TeamsPage;
