import type React from 'react';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import type { ITeamDetails } from '@writercolab/common-utils';
import { BillingInterval, SUPPORT_EMAIL, extractEmails, getTeams } from '@writercolab/common-utils';
import type { DropdownOption } from '@writercolab/ui-atoms';
import {
  Button,
  ButtonTypes,
  Dropdown,
  Label,
  LinkText,
  Modal,
  Text,
  TextColor,
  TextSize,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';
import { DropdownTrigger, InputGroup, InputTypes } from '@writercolab/ui-molecules';
import { stringsUniq } from '@writercolab/utils';

import type { ISendInviteRequest } from '@web/types';
import { observer } from 'mobx-react-lite';

import { useAppState } from '../../../../state';
import { extractPlaceholder } from '../../../../utils/dropdownUtils';
import { goToBillingNewTab, launchSupportEmail } from '../../../../utils/navigationUtils';
import { BillingSeatsNotifier } from '../../../molecules/BillingSeatsNotifier/BillingSeatsNotifier';
import type { IOption } from '../../../molecules/TagSelect';
import { TagSelect } from '../../../molecules/TagSelect';

import styles from './styles.module.css';

interface ISendInviteProps {
  isOpen: boolean;
  changeModalState: () => void;
  onSubmit: (state: ISendInviteRequest) => Promise<void>;
  orgId?: number;
  onSeatsChange: (seatsNumber: number) => Promise<boolean>;
  teamSizeLimit: number;
  billingGroupOptions: IOption[];
}

interface IInviteTeam {
  id: number;
  name: string;
  color: string;
  active: false;
}

interface IInitState {
  emails: string;
  teams: IInviteTeam[];
  role: any;
}

const roleOptions: DropdownOption[] = [
  {
    name: 'Team member',
    id: 'member',
    description: 'Can create and edit docs, but cannot edit style guide',
    active: false,
  },
  {
    name: 'Team admin',
    id: 'admin',
    description: 'Can edit style guide and manage team members',
    active: false,
  },
];

const initState: IInitState = {
  emails: '',
  teams: [],
  role: null,
};

const reducer = (state: IInitState, { field, value }) => ({
  ...state,
  [field]: value,
});

const handleClickDropdown = (options: DropdownOption[], setOptions: (i: DropdownOption[]) => void, id: string) =>
   
  setOptions(options.map(option => (option.id === id ? { ...option, active: true } : { ...option, active: false })));

const modifyTeams = (teams: ITeamDetails[]) =>
  teams.map(team => ({
    id: team.id.toString(),
    name: team.name,
    active: false,
  }));

export const SendInviteModal: React.FC<ISendInviteProps> = observer(
  ({ isOpen, changeModalState, onSubmit, orgId, onSeatsChange, teamSizeLimit, billingGroupOptions, ...props }) => {
    const {
      appModel: { assistantSubscription },
    } = useAppState();

    const [state, dispatch] = useReducer(reducer, initState);
    const [teamOptions, setTeamOptions] = useState<DropdownOption[]>([]);
    const [roles, setRoles] = useState<DropdownOption[]>(roleOptions);
    const [inviteeEmailsCount, setInviteeEmailsCount] = useState(0);
    const [userCount, setUserCount] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedBillingGroup, setSelectedBillingGroup] = useState<IOption | null>(null);

    const handleChangeBillingGroup = (newValue: IOption | null) => {
      setSelectedBillingGroup(newValue);
    };

    const { enqueueErrorSnackbar } = useCustomSnackbar();
    const { emails, teams, role } = state;
    const subscription = assistantSubscription.$subscription.value;

    const access = {
      isEnterprise: assistantSubscription.isEnterprise,
      isMultiTeam: assistantSubscription.isMultiTeam,
      teamSelectionEnabled: assistantSubscription.isEnterprise && assistantSubscription.isMultiTeam,
      seatsFlowEnabled:
        (assistantSubscription.isTeam && assistantSubscription.isActive) || assistantSubscription.isEnterprise,
    };

    const isIncreaseSubscriptionNeeded = ({ availableSeatsCount, futureUsersCount }) =>
      futureUsersCount > availableSeatsCount;

    const _isTeamSizeLimitReached = useMemo<boolean>(
      () => !assistantSubscription.isEnterprise && userCount > teamSizeLimit,
      [assistantSubscription.isEnterprise, teamSizeLimit, userCount],
    );

    const inviteInputErrorMessage = useMemo<string | undefined>(
      () => (_isTeamSizeLimitReached ? `You’ve reached your max of ${teamSizeLimit} users` : undefined),
      [_isTeamSizeLimitReached, teamSizeLimit],
    );

    useEffect(() => {
      getTeams(Number(orgId)).then((teams: ITeamDetails[]) => setTeamOptions(modifyTeams(teams)));
    }, [orgId]);

    const onChangeEmails = useCallback(e => {
      let count = 0;
      const emails = extractEmails(e.target.value);

      if (emails && emails.length > 0) {
        count = stringsUniq(emails).length;
      }

      setInviteeEmailsCount(count);
      dispatch({ field: 'emails', value: e.target.value });
    }, []);

    const onChangeTeam = useCallback(
      (selectedTeamId: string) => {
        const _selectedTeamId = parseInt(selectedTeamId, 10);
        const teamSelected = teamOptions.find(t => parseInt(t.id, 10) === _selectedTeamId);

        if (teamSelected) {
          const teamsList = teamOptions.map(t => {
            const team = t;

            if (parseInt(t.id, 10) === _selectedTeamId) {
              team.active = !team.active;
            }

            return team;
          });

          dispatch({ field: 'teams', value: teamsList.filter(t => t.active) });
          setTeamOptions(teamsList);
        }
      },
      [teamOptions],
    );

    const onChangeRole = useCallback(
      (id: string) => {
        handleClickDropdown(roles, setRoles, id);
        dispatch({ field: 'role', value: roles.find(team => team.id === id) });
      },
      [roles],
    );

    const validate = () => {
      const invalidTerms: string[] = [];

      if (!extractEmails(emails)?.length) {
        invalidTerms.push('Email addresses');
      }

      if (access.teamSelectionEnabled) {
        if (teams.length === 0) {
          invalidTerms.push('Team');
        }

        if (!role?.id) {
          invalidTerms.push('User Role');
        }
      }

      if (invalidTerms.length) {
        enqueueErrorSnackbar(`Please add/select valid ${invalidTerms.join(', ')}`);

        return false;
      }

      return true;
    };

    useEffect(() => {
      if (assistantSubscription.limits?.user) {
        const currentUsersCount = assistantSubscription.limits.user.value || 1;
        const futureUsersCount = currentUsersCount + inviteeEmailsCount;

        setUserCount(futureUsersCount);
      }
    }, [assistantSubscription.limits?.user, inviteeEmailsCount]);

    const handleInvitePerson = useCallback(async () => {
      if (!validate()) {
        return;
      }

      setIsLoading(true);

      const currentUsersCount = assistantSubscription.limits?.user?.value || 1;
      const futureUsersCount = currentUsersCount + inviteeEmailsCount;

      setUserCount(futureUsersCount);

      const inviteFn = () => {
        onSubmit({
          organizationId: orgId,
          invitees: extractEmails(emails),
          role: access.teamSelectionEnabled ? role?.id : roles[0]?.id,
          teamIds: access.teamSelectionEnabled
            ? teamOptions.filter(t => t.active).map(t => Number(t.id))
            : teamOptions.map(t => Number(t.id)),
          billingGroupId: Number(selectedBillingGroup?.value),
        });
        changeModalState();
        setIsLoading(false);
      };

      if (access.seatsFlowEnabled) {
        if (assistantSubscription.isEnterprise && futureUsersCount > (assistantSubscription.limits?.user?.limit || 0)) {
          enqueueErrorSnackbar(
            <Text color={TextColor.WHITE}>
              Your account was not created because your organization does not have any remaining seats. Please contact
              your Writer admin or email{' '}
              <LinkText onClick={launchSupportEmail} className={styles.errorMessageLink}>
                {SUPPORT_EMAIL}
              </LinkText>{' '}
              for help.
            </Text>,
          );
          setIsLoading(false);

          return;
        }

        if (
          isIncreaseSubscriptionNeeded({
            futureUsersCount,
            availableSeatsCount: assistantSubscription.limits?.user?.limit,
          })
        ) {
          onSeatsChange(futureUsersCount)
            .then(successful => {
              if (successful) {
                inviteFn();
              }
            })
            .finally(() => setIsLoading(false));
        } else {
          inviteFn();
        }
      } else {
        inviteFn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      access.seatsFlowEnabled,
      access.teamSelectionEnabled,
      changeModalState,
      emails,
      enqueueErrorSnackbar,
      inviteeEmailsCount,
      onSeatsChange,
      onSubmit,
      orgId,
      role?.id,
      roles,
      selectedBillingGroup?.value,
      assistantSubscription.isEnterprise,
      assistantSubscription.limits?.user?.limit,
      assistantSubscription.limits?.user?.value,
      teamOptions,
    ]);

    const onBillingClick = useCallback(() => {
      if (!orgId) {
        return;
      }

      goToBillingNewTab(orgId);
    }, [orgId]);

    return (
      <Modal
        open={isOpen}
        handleClose={changeModalState}
        modalTitleClassName={styles.modalTitle}
        title="Invite people"
        style={{ width: '475px' }}
        {...props}
      >
        <form className={styles.styledForm}>
          <InputGroup
            value={emails}
            name="emails"
            inputType={InputTypes.TEXTAREA}
            minRows={4}
            handleChangeInput={onChangeEmails}
            id="emails"
            label="Email addresses"
            errorText={inviteInputErrorMessage}
            className={styles.emailsInput}
            placeholder="name@company.com, name@company.com"
          />
          <Text variant={TextSize.XS}>Your teammates will receive an email with an invitation link.</Text>
          {access.teamSelectionEnabled && (
            <div className={styles.inputsGroup}>
              <div>
                <Label>Team</Label>
                <Dropdown
                  trigger={
                    <DropdownTrigger
                      testId="ai-assistant-people-select-team"
                      placeholder={extractPlaceholder(teamOptions, 'Select')}
                    />
                  }
                  onPrimaryOptionClickAction={onChangeTeam}
                  options={teamOptions}
                  truncated
                  hideOnCLick={false}
                  useCheckbox
                  dropDownContainerClassName={styles.dropdownContainer}
                />
              </div>
              <div>
                <Label>Role</Label>
                <Dropdown
                  trigger={
                    <DropdownTrigger
                      testId="ai-assistant-people-select-role"
                      placeholder={extractPlaceholder(roles, 'Select')}
                    />
                  }
                  onPrimaryOptionClickAction={onChangeRole}
                  options={roles}
                  dropDownContainerClassName={styles.dropdownContainer}
                />
              </div>
            </div>
          )}
          <div className={styles.tagSelectWrapper}>
            <div className={styles.tagTitleSelect}>
              <Text variant={TextSize.S} color={TextColor.BLACK} upperCase>
                Billing Group
              </Text>
            </div>
            <TagSelect
              placeholder="Add billing group"
              noOptionsMessage="No billing groups found"
              options={billingGroupOptions}
              onChange={handleChangeBillingGroup}
              containerStyles={{
                width: '200px',
              }}
            />
          </div>

          {access.seatsFlowEnabled && assistantSubscription.limits?.user?.value && (
            <BillingSeatsNotifier
              seatsCount={assistantSubscription.limits.user.limit}
              usersCount={userCount}
              onBillingClick={onBillingClick}
              priceForAdditionalUser={assistantSubscription?.price || 0}
              futureUserCount={inviteeEmailsCount}
              teamLimitReached={_isTeamSizeLimitReached}
              monthlyBills={subscription?.price?.interval === BillingInterval.MONTH}
            />
          )}
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            content="Invite"
            onClick={() => handleInvitePerson()}
            isLoading={isLoading}
            disabled={isLoading || inviteeEmailsCount === 0 || !!inviteInputErrorMessage}
          />
        </form>
      </Modal>
    );
  },
);

export default SendInviteModal;
