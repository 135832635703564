import { BaseComponentsProps } from '@writercolab/common-utils';
import {
  Dropdown,
  DropdownOption,
  Icon,
  IconVariant,
  Logo,
  LogoVariant,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { IInstallLinks, ProfilePopupDrawer } from '@writercolab/ui-molecules';
import cx from 'classnames';
import React, { FC, ReactNode, useMemo } from 'react';
import { LinkToStyleguide } from '../LinkToStyleguide';
import { ActionWrapper } from './ActionWrapper';
import styles from './styles.module.css';

export enum GlobalNavSelection {
  HOME = 'home',
  EDITOR = 'editor',
  TEAM = 'team',
  SUGGESTIONS = 'suggestions',
  TERMS = 'terms',
  SNIPPETS = 'snippets',
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  STYLEGUIDE = 'styleguide',
  PROFILE = 'profile',
  EXTENSIONS = 'extensions',
  TEMPLATES = 'templates',
  DRAFTS = 'drafts',
  MY_WORK = 'my_work',
  AI_STUDIO = 'ai_studio',
  LEARNING = 'learning',
  SETUP = 'setup',
}

export interface INavAction {
  key: GlobalNavSelection;
  label: string;
  icon?: React.ReactNode;
  iconActive?: React.ReactNode;
  iconVariant?: IconVariant;
  iconVariantActive?: IconVariant;
  trailingIcon?: React.ReactNode;
  indicated?: boolean;
  navTo?: string;
  textMedium?: boolean;
  isSubPrimary?: boolean;
  onClick?: () => void;
}

export interface IPortalGlobalNavState {
  show: boolean;
  isPublished: boolean;
  name: string;
  url: string;
  adminPanelUrl: string;
}

export interface GlobalNavigationProps extends BaseComponentsProps {
  /** Url to open the document page with New document created */
  navToNewDocument?: string;
  /** Set logo variant */
  logoVariant?: LogoVariant;
  /** Teams available for selection */
  teamOptions?: DropdownOption[];
  /** Change current team */
  onChangeTeam: (teamId: DropdownOption['id']) => void;
  /** Current selected item */
  currentSelection?: GlobalNavSelection;
  /** List of primary actions */
  primaryActions: INavAction[];
  /** List of sub-primary actions */
  subPrimaryActions: INavAction[];
  /** List of primary actions */
  secondaryActions: INavAction[];
  /** Logo onclick callback */
  onLogoClick?: () => void;
  /** Edit button onclick callback */
  onEditClick?: () => void;
  /** Edit button onclick callback */
  onSingOutClick?: () => void;
  /** On click support callback */
  onClickSupport?: () => void;
  /** On click support callback */
  onClickWelcomeTour?: () => void;
  /** User full name */
  userFullName: string;
  /** User email name */
  userEmail: string;
  /** User avatar node */
  userAvatar: React.ReactNode;
  /** External links to install Writer */
  installLinks?: IInstallLinks[];
  /** Multiteam nav has some UI variations (blue highlight) */
  isMultiTeam?: boolean;
  /** Triggers loading state */
  isLoading?: boolean;
  /** List of select actions */
  selectActions?: DropdownOption[];
  /** Selected action */
  selectCurrent?: string;
  /** Triggers click action on select */
  onSelectActions?: (id: string) => void;
  /** Styleguide State */
  styleguide?: IPortalGlobalNavState;
  /** action on top container click */
  onProfileClick?: () => void;
  /** allow admins controls */
  isAdminMode?: boolean;
  /** Route to navigate to */
  logoNavTo?: string;
  /** User profile icon tooltip content */
  toolTipContent?: React.ReactNode;
  /** User profile icon open by default */
  toolTipOpen?: boolean;
  /** User profile icon dropdown click */
  onProfilePopupDrawerDropDownClick?: () => void;
  /** User type enterprise  */
  isEnterprise?: boolean;
  /** Learning Center  */
  learningCenter?: React.ReactNode;
  /** Styleguide visibility state  */
  hideStyleguide?: boolean;
  /** Learning Center  */
  featureFlags?: {
    waMyWorkPage?: boolean;
  };
}

const teamSwitcherDropdownTrigger = (teamName: ReactNode) => (
  <div className={styles.teamSwitcher}>
    <div className={styles.teamSwitcherTitleContainer}>
      <Text smallCaps medium variant={TextSize.XS} color={TextColor.GREY}>
        Team
      </Text>
      <Text bold variant={TextSize.XXXL} className={styles.teamSwitcherTitle}>
        {teamName}
      </Text>
    </div>
    <Icon name={IconVariant.BREADCRUMB_ARROW} />
  </div>
);

const StyleguideSection: FC<{
  styleguide: IPortalGlobalNavState;
  isAdminMode?: boolean;
}> = ({ styleguide, isAdminMode }) => {
  const visible = isAdminMode ? styleguide && styleguide.show : styleguide.isPublished;

  return (
    <>
      {visible && (
        <div id="styleguide-link-section">
          <LinkToStyleguide
            link={styleguide.url}
            adminPanelLink={styleguide.adminPanelUrl}
            isLive={styleguide.isPublished}
            isAdminMode={!!isAdminMode}
          />
        </div>
      )}
    </>
  );
};

const NewDocIcon: FC<{ onClick?: (e: React.MouseEvent) => void }> = ({ onClick }) => (
  <Icon name={IconVariant.EDITOR} className={styles.editIcon} width={24} height={24} onClick={onClick} />
);

const NewDocumentIcon: FC<{
  navToNewDocument?: string;
  onIconClick?: (e: React.MouseEvent) => void;
}> = ({ navToNewDocument, onIconClick }) =>
  navToNewDocument ? (
    <a href={navToNewDocument}>
      <NewDocIcon onClick={onIconClick} />
    </a>
  ) : (
    <NewDocIcon onClick={onIconClick} />
  );

export const GlobalNav: React.FC<GlobalNavigationProps> = ({
  className,
  logoVariant = LogoVariant.BLACK,
  teamOptions,
  primaryActions = [],
  subPrimaryActions = [],
  secondaryActions = [],
  currentSelection,
  onLogoClick,
  onEditClick,
  onChangeTeam,
  onSingOutClick,
  onClickSupport,
  onClickWelcomeTour,
  userFullName,
  userEmail,
  userAvatar,
  styleguide,
  installLinks,
  isMultiTeam,
  isLoading,
  selectActions,
  selectCurrent,
  onSelectActions,
  onProfileClick,
  isAdminMode,
  logoNavTo,
  navToNewDocument,
  toolTipOpen,
  toolTipContent,
  onProfilePopupDrawerDropDownClick,
  isEnterprise,
  learningCenter,
  hideStyleguide = false,
  featureFlags,
}) => {
  const activeTeam = useMemo(() => teamOptions?.find(team => team.active), [teamOptions]);
  const access = {
    showCreateDocButton: currentSelection !== GlobalNavSelection.DASHBOARD,
  };

  const primaryLinksBlockHighlighted = useMemo(
    () => primaryActions.some(action => action.key === currentSelection),
    [primaryActions, currentSelection],
  );

  if (isLoading) {
    return (
      <div className={cx(className, styles.container)}>
        <SkeletonLoader type={SkeletonLoaderType.GLOBAL_NAV} />
      </div>
    );
  }

  return (
    <div className={cx(className, styles.container)}>
      <div className={styles.topIcons}>
        <Logo variant={logoVariant} className={styles.containerLogoItem} onClick={onLogoClick} navTo={logoNavTo} />
        {access.showCreateDocButton && (
          <NewDocumentIcon navToNewDocument={navToNewDocument} onIconClick={onEditClick} />
        )}
      </div>
      <div className={styles.overflowWrapper}>
        <div
          className={cx(styles.primaryLinksContainer, {
            [styles.withTeam]: isMultiTeam,
            [styles.activeHighlight]: primaryLinksBlockHighlighted,
          })}
        >
          {isMultiTeam && !!teamOptions?.length && (
            <Dropdown
              triggerClassName={styles.teamSwitcherContainer}
              trigger={teamSwitcherDropdownTrigger(activeTeam?.name || teamOptions[0]?.name)}
              dropDownContainerClassName={styles.teamDropdown}
              options={teamOptions}
              onPrimaryOptionClickAction={onChangeTeam}
              highlightActiveOption
            />
          )}
          <div className={styles.primaryLinks}>
            {primaryActions.map(action => (
              <ActionWrapper
                {...action}
                key={action.key}
                actionKey={action.key}
                currentSelection={currentSelection}
                primaryLinksBlockHighlighted={primaryLinksBlockHighlighted}
              />
            ))}
          </div>
        </div>
        {subPrimaryActions.length > 0 && (
          <div
            className={cx(styles.subPrimaryLinks, {
              [styles.withTeam]: isMultiTeam,
              [styles.activeHighlight]: primaryLinksBlockHighlighted,
            })}
          >
            {subPrimaryActions.map(action => (
              <ActionWrapper
                {...action}
                key={action.key}
                actionKey={action.key}
                currentSelection={currentSelection}
                primaryLinksBlockHighlighted={primaryLinksBlockHighlighted}
              />
            ))}
          </div>
        )}
        <div className={styles.mt}>
          {learningCenter}
          <hr className={styles.separator} />
          <div className={styles.secondaryLinks}>
            {secondaryActions.map(action => (
              <ActionWrapper
                {...action}
                key={action.key}
                actionKey={action.key}
                currentSelection={currentSelection}
                primaryLinksBlockHighlighted={primaryLinksBlockHighlighted}
              />
            ))}
          </div>
          {!hideStyleguide && styleguide && (
            <>
              <hr className={styles.separator} />
              <StyleguideSection styleguide={styleguide} isAdminMode={!!isAdminMode} />
            </>
          )}
        </div>
      </div>
      <div className={styles.profileSettingsWrapper}>
        <hr className={styles.separator} />
        <div
          className={cx(styles.profileSettings, {
            [styles.profileSettingsEnterprise]: isEnterprise,
          })}
        >
          <ProfilePopupDrawer
            onClickProfile={onProfileClick}
            userFullName={userFullName}
            userEmail={userEmail}
            userAvatar={userAvatar}
            installLinks={installLinks ?? []}
            onClickSupport={onClickSupport}
            onClickWelcomeTour={onClickWelcomeTour}
            onSignOut={onSingOutClick}
            orgList={selectActions}
            currentOrgName={selectCurrent}
            toolTipContent={toolTipContent}
            toolTipOpen={toolTipOpen}
            onDropDownClick={onProfilePopupDrawerDropDownClick}
            onSelectOrganization={onSelectActions}
            featureFlags={featureFlags}
          />
        </div>
      </div>
    </div>
  );
};

export default GlobalNav;
