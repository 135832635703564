import { IAnalyticsTrack } from '@writercolab/analytics';
import { ContentEditorPageMode } from '@writercolab/common-utils';
import {
  TAnalyticsActivityTypeBuilder,
  TAppOrgTeamUserActivityParams,
  TAppOrgUserActivityParams,
  TAssetToDeleteType,
  TDeletionOptionType,
  TOrgTeamUserActivityParams,
  TOrgUserActivityParams,
  TTeamActivityParams,
} from '@writercolab/types';
import { TUIAppLibraryAnalyticsActivity, UIAppLibraryAnalyticsActivity } from '@writercolab/ui-app-library';
import { IconVariant } from '@writercolab/ui-atoms';
import { TUIChatAppsAnalyticsParams, UIChatAppsAnalyticsActivity } from '@writercolab/ui-chat-apps/analytics';
import { TUIGenerationAppsAnalyticsParams, UIGenerationAppsAnalyticsActivity } from '@writercolab/ui-generation-apps';
import { TUISidebarModelsAnalyticsParams, UISidebarModelsAnalyticsActivity } from '@writercolab/ui-sidebar-models';

import { TLearningCenterCategoryId, TLearningCenterVideoId, TVideoTag } from '@web/types';
import {
  TActivityParams as TWebQuillEditorActivityParams,
  AnalyticsActivity as WebQuillEditorAnalyticsActivity,
} from '@web/web-quill-editor';

export const AnalyticsActivity = {
  ...WebQuillEditorAnalyticsActivity,
  ...UIAppLibraryAnalyticsActivity,
  ...UIGenerationAppsAnalyticsActivity,
  ...UIChatAppsAnalyticsActivity,
  ...UISidebarModelsAnalyticsActivity,
  firstTimeGoogleSSOLogin: '[Signup] First time Google SSO login', // when new signup via Google SSO
  invitedUserSignup: '[Signup] Invited User Signup', // when invited user signup
  startedNewTrial: '[Subscription] Started New Trial', // when new self serv user signup
  userJoined: '[Subscription] Invited User Signed Up', // when the invitee accepts invitation and Joined to Writer
  viewJoinOrCreateOrgPage: '[Domain Discoverability] Viewed Join or Create Own Org Page', // when user opens Join or Create Own Org page
  footerInstallChromeExtClicked: '[Dashboard Footer] Install Chrome Ext Clicked', // when clicks install Chrome Ext button in app footer
  footerInstallWordExtClicked: '[Dashboard Footer] Install Word Clicked', //  when clicks install Word Ext button in app footer
  footerInstallOutlookExtClicked: '[Dashboard Footer] Install Outlook Clicked', //  when clicks install Outlook Ext button in app footer
  installChromeExtClicked: '[Chrome Ext Popup] Install Chrome Ext Clicked', //  when clicks install chrome ext button in Chrome Ext window
  installWordOutlookExtClicked: '[Chrome Ext Popup] Install Word/Outlook Clicked', //  when clicks install word/outlook ext button in Chrome Ext window
  chromeWindowDismissed: '[Chrome Ext Popup] Popup Dismissed', // when clicks close Chrome Ext window
  clickAdminPanel: '[Navigation] Clicked Admin Panel', // when clicks on admin panel button
  clickPurchaseProNow: '[Billing v2] Clicked Purchase Pro Now', // These events are for users who clicked the various “Purchase or Buy Now” CTAs when on Pro Trial OR on Free, to open up the Pro checkout page
  clickPurchaseTeamNow: '[Billing v2] Clicked Purchase Team Now', // These events are for users who clicked the various “Purchase or Buy Now” CTAs when on Team Trial, to open up the Team checkout page
  clickStartTeamTrial: '[Billing v2] Clicked Start Team Trial', // These events are for users who clicked the various “Start Team Trial” buttons
  clickStartProTrial: '[Billing v2] Clicked Start Pro Trial', // These events are for users who clicked the various “Start Pro Trial” buttons
  clickedContactSales: '[Billing v2] Clicked Contact Sales', // These events are for users who clicked the various “Contact sales” buttons that go to Sales page
  signedUp: '[Signup v2] Signed Up', // when user signup
  dashboardViewed: '[Dashboard] Viewed Dashboard', // when user opens Dashboard page
  suggestionsPunctuationViewed: '[Suggestions] Viewed Punctuation', // when user opens suggestions Punctuation page
  suggestionsWritingStyleViewed: '[Suggestions] Viewed Writing Style', // when user opens suggestions  Writing Style page
  suggestionsClarityViewed: '[Suggestions] Viewed Clarity', // when user opens suggestions  Writing Style page
  suggestionsInclusivityViewed: '[Suggestions] Viewed Inclusivity', // when user opens suggestions  Inclusivity page
  suggestionsDeliveryViewed: '[Suggestions] Viewed Delivery', // when user opens suggestions Delivery page
  suggestionsPlagiarismViewed: '[Suggestions] Viewed Plagiarism', // when user opens suggestions Plagiarism page
  suggestionsComplianceViewed: '[Suggestions] Viewed Compliance', // when user opens suggestions Plagiarism page
  suggestionsGeneralViewed: '[Suggestions] Viewed General', // when user opens suggestions General page
  suggestionsCustomRulesViewed: '[Suggestions] Viewed Custom Rules', // when user opens suggestions Custom Rules page
  suggestionsPunctuationUpdated: '[Suggestions] Updated Punctuation', // when user updates suggestions Custom Rules rules
  suggestionsWritingStyleUpdated: '[Suggestions] Updated Writing Style', // when user updates suggestions Writing Style rules
  suggestionsClarityUpdated: '[Suggestions] Updated Clarity', // when user updates suggestions Clarity rules
  suggestionsInclusivityUpdated: '[Suggestions] Updated Inclusivity', // when user updates suggestions Inclusivity rules
  suggestionsDeliveryUpdated: '[Suggestions] Updated Delivery', // when user updates suggestions Delivery rules
  suggestionsPlagiarismUpdated: '[Suggestions] Updated Plagiarism', // when user updates suggestions Plagiarism rules
  suggestionsComplianceUpdated: '[Suggestions] Updated Compliance', // when user updates suggestions Compliance rules
  suggestionsGeneralUpdated: '[Suggestions] Updated General', // when user updates suggestions General data
  // suggestions ReWrite rules
  suggestionsReWriteUpdated: '[Suggestions] Updated ReWrite', // when user updates suggestions
  // suggestions magic links
  magicLinksUpdated: '[Suggestions] Updated Magic Links', // when user updates suggestions General data
  termsViewed: '[Terms] Viewed Terms', // when user opens Terms page
  termAdded: '[Terms] Added a Term', // when user creates a new Term page
  termEdited: '[Terms] Edited a Term', // when user changes a Term page
  snippetsViewed: '[Snippets] Viewed Snippets', // when user opens Snippets page
  snippetAdded: '[Snippets] Added a Snippet', // when user add a new Snippet
  teammateInvited: '[Teammates] Invited a Teammate', // when user invite a new Teammate
  snippetEdited: '[Snippets] Edited a Snippet', // when user opens Snippets page
  teammatesViewed: '[Teammates] Viewed Teammates', // when user opens Teammates page
  profileViewed: '[Profile] Viewed Profile', // when user opens Profile page
  billingViewed: '[Billing] Viewed Billing', // when user opens Billing page
  appsViewed: '[Apps] Viewed Apps', // when user opens Apps page
  autoJoinCreatedOwnOrg: '[Domain Discoverability] Created Own Org', // when user creates own org
  autoJoinSelectJoinOrg: '[Domain Discoverability] Selected Join Existing Org', // when user selects join existing org
  securityViewed: '[Admin] Viewed Security', // when user opens Apps page
  peopleViewed: '[Admin] Viewed People', // when user People Page (under the Admin Panel. Not “Teammates”)
  accountViewed: '[Admin] Viewed Account', // when user opens Account page
  allTeamsViewed: '[Admin] Viewed All Teams', // when user opens All Teams page
  ssoViewed: '[Admin] Viewed Single Sign-on', // when user opens SSO configuration page
  enabledDataRetention: '[Admin] Enabled Custom Data Retention',
  disabledDataRetention: '[Admin] Disabled Custom Data Retention',
  modifiedDataRetention: '[Admin] Modified Custom Data Retention',
  canceledDataRetention: '[Admin] Canceled Custom Data Retention',
  openDocument: '[Docs] Opened a Doc', // when user opens a Document page
  createDocument: '[Docs] Created a New Doc', // when user opens a Document page
  startWithCoWrite: '[Docs] Clicked Start from CoWrite', // when user starts a new document with CoWrite
  onboardingQuestionnaire: '[Signup] Viewed Onboarding Questionnaire', // when user open Onboarding Questionnaire
  onboardingQuestionnairePage2SubmitClicked: '[Signup] Clicked “Let’s go” on Onboarding Questionnaire Page 2', // when user clicks Let’s go button on Onboarding Questionnaire Invite teammates page
  subscriptionStartedNewTeamTrial: '[Subscription] Started New Team Trial', // when user signup with Team Trial plan
  subscriptionStartedNewProTrial: '[Subscription] Started New Pro Trial', // when user signup with Pro Trial plan
  subscriptionStartedNewFreeAccount: '[Subscription] Started New Free Account', // when user signup with Free plan
  clickedChromeExtAnnouncement: '[Global Nav] Clicked Chrome Ext Announcement', // when user clicks on Chrome Announcement Button
  tutorialViewedWelcomeStep: '[Tutorial] Viewed Welcome Step',
  tutorialViewedSuggestionsStep: '[Tutorial] Viewed Suggestions Step',
  tutorialViewedTermsStep: '[Tutorial] Viewed Terms Step',
  tutorialViewedSnippetsStep: '[Tutorial] Viewed Snippets Step',
  tutorialViewedAppsStep: '[Tutorial] Viewed Apps Step',
  tutorialViewedStyleguideStep: '[Tutorial] Viewed Styleguide Step',
  tutorialViewsDemoDocStep: '[Tutorial] Viewed Demo Doc Step',
  tutorialCompleted: '[Tutorial] Completed Tutorial',
  tutorialSkipped: '[Tutorial] Skipped Tutorial',
  tutorialRestarted: '[Tutorial] Restarted Tutorial',
  tutorialExited: '[Tutorial] Exited Tutorial',
  clickedInstallChromeExt: '[Apps] Clicked Install Chrome Ext', // when user clicks on Install Chrome Ext button on Apps page
  clickedInstallWordExt: '[Apps] Clicked Install Word Ext', // when user clicks on Install Word Ext button on Apps page
  clickedInstallOutlookExt: '[Apps] Clicked Install Outlook Ext', // when user clicks on Install Outlook Ext button on Apps page
  clickedInstallEdgeExt: '[Apps] Clicked Install Edge Ext', // when user clicks on Install Edge Ext button on Apps page
  clickedInstallMacExt: '[Apps] Clicked Install Mac Ext', // when user clicks on Install Mac Ext button on Apps page
  clickedInstallWinExt: '[Apps] Clicked Install Windows Ext', // when user clicks on Install Win Ext button on Apps page
  clickedInstallContentfulExt: '[Apps] Clicked Install Contentful Ext', // when user clicks on Install Contentful Ext button on Apps page
  clickedInstallFigmaExt: '[Apps] Clicked Install Figma Ext', // when user clicks on Install Figma Ext button on Apps page
  educationalModulesTermsClickedQuickStartVideo: '[Educational Modules] Terms: Clicked Quick Start Video', // when user clicks on Quick Start Video link on Term page
  educationalModulesTermsClickedAddTerm: '[Educational Modules] Terms: Clicked Add a Term', // when user clicks Add Term link on Term page
  educationalModulesTermsClickedImportTerms: '[Educational Modules] Terms: Clicked Import Terms', // when user clicks Add Term link on Term page
  educationalModulesSnippetsClickedQuickStartVideo: '[Educational Modules] Snippets: Clicked Quick Start Video', // when user clicks Quick Start Video link on Snippets page
  educationalModulesSnippetsClickedAddTerm: '[Educational Modules] Snippets: Clicked Add a Term', // when user clicks Add Term on Snippets page

  // Apps Access page events
  signupViewedAppConsentPage: '[Signup] Viewed App Consent Page', // when user opens App Consent Page
  signupClickedAllowAccessOnAppConsent: '[Signup] Clicked Allow Access on App Consent', // when user clicks Access button on App Consent Page

  // NLG
  contentRephrased: '[NLG] Rephrased Content', // Generates a ReWrite output (either by opening the widget for first time or tabbing between ReWrite options)
  contentRegenerated: '[NLG] Rephrased Content Regenerated', // Clicked the “regenerate” button
  rephrasedContentAccepted: '[NLG] Rephrased Content Accepted', // Clicks an output to automatically accept it
  rephrasedContentCopied: '[NLG] Rephrased Content Copied', // Clicks “Copy to clipboard” button
  triggerAutoWrite: '[NLG] Triggered Keep Writing', // Clicks “Keep writing” button

  coWriteViewedCategoryAll: '[NLG] Viewed Template Library: All',
  coWriteViewedCategoryProduct: '[NLG] Viewed Template Library: Product',
  coWriteViewedCategoryMarketing: '[NLG] Viewed Template Library: Marketing',
  coWriteViewedCategorySupport: '[NLG] Viewed Template Library: Support',
  coWriteViewedCategoryHR: '[NLG] Viewed Template Library: HR',
  coWriteViewedCategoryEmployeeComms: '[NLG] Viewed Template Library: Employee Comms',
  coWriteViewedCategoryAdvertising: '[NLG] Viewed Template Library: Advertising',
  coWriteViewedCategoryRecruiting: '[NLG] Viewed Template Library: Recruiting',
  coWriteViewedCategoryExternalComms: '[NLG] Viewed Template Library: External Comms',
  coWriteViewedCategorySales: '[NLG] Viewed Template Library: Sales',
  coWriteViewedCategoryCustom: '[NLG] Viewed Template Library: Custom',
  coWriteViewedCategoryGeneral: '[NLG] Viewed Template Library: General',
  coWriteViewedCustomTemplateRequestForm: '[NLG] Viewed Custom Template Request Form',
  coWriteContactSalesCustomTemplates: '[NLG] Clicked Contact Sales on Custom Templates',
  coWriteContactSalesSEOBlogBuilder: '[NLG] Clicked Contact Sales on SEO Blog Builder',
  coWriteContactSalesTemplateLibrary: '[NLG] Clicked Contact Sales on Template Library',
  coWriteTemplateSearch: '[NLG] Searched Templates',
  coWriteTemplateOpened: '[NLG] Opened Template',
  coWriteViewedSeoBlogBuilderStep1: '[NLG] Viewed SEO Blog Builder: Step 1',
  coWriteViewedSeoBlogBuilderStep2: '[NLG] Viewed SEO Blog Builder: Step 2',
  coWriteViewedSeoBlogBuilderStep3: '[NLG] Viewed SEO Blog Builder: Step 3',

  cowriteAnnouncementDismissed: '[Cowrite Announcement] Popup Dismissed', // Announcement closed
  cowriteAnnouncementClicked: '[Cowrite Announcement] Ideate with Cowrite clicked', // "Ideate with CoWrite clicked"

  coWriteHighlightsOpened: '[NLG] Opened Highlights',
  coWriteHighlightsRegeneratedMeta: '[NLG] Regenerated Meta Description',
  coWriteHighlightsRegeneratedSummary: '[NLG] Regenerated Summary',
  coWriteHighlightsRegeneratedLinkedIn: '[NLG] Regenerated LI Post',
  coWriteHighlightsRegeneratedTwitter: '[NLG] Regenerated Twitter Post',
  coWriteHighlightsCopiedMeta: '[NLG] Copied Meta Description',
  coWriteHighlightsCopiedSummary: '[NLG] Copied Summary',
  coWriteHighlightsCopiedLinkedIn: '[NLG] Copied LI Post',
  coWriteHighlightsCopiedTwitter: '[NLG] Copied Twitter Post',
  magicLinksContactSales: '[NLG] Magic links Upgrade now Clicked',

  billingTeamTrialExtension: '[Subscriptions] Accepted Team Trial Extension',
  magciLinksViewPage: '[Magic Links] Viewed Magic Links Setup',
  magciLinksAddDomain: '[Magic Links] Added a Domain',
  magciLinksValidateDomain: '[Magic Links] Clicked Validate Domain',
  magciLinksManageCrawls: '[Magic Links] Clicked Manage Crawls',
  magciLinksCrawlAll: '[Magic Links] Clicked Crawl Now on All Domains',
  magciLinksCrawl: '[Magic Links] Clicked Crawl Now on a Domain',
  magicLinksUserClickedIcon: '[Magic Links] Opened Magic Links',
  magicLinksClickedSuggestions: '[Magic Links] Accepted Link Suggestion',

  magicLinksSearchedKeyword: '[Magic Links] Searched for Keywords',
  magicLinksAddedCustomURL: '[Magic Links] Added a Custom URL',

  termsClickedImportCSV: '[Terms] Clicked Import CSV',
  termsClickedLearnMore: '[Terms] Clicked Learn more',
  termsUploadedFile: '[Terms] Uploaded a File',
  termsClickedImport: '[Terms] Clicked Import Terms',
  termsImportSucceeded: '[Terms] Import Succeeded',

  snippetsClickedImportCSV: '[Snippets] Clicked Import CSV',
  snippetsClickedLearnMore: '[Snippets] Clicked Learn more',
  snippetsUploadedFile: '[Snippets] Uploaded a File',
  snippetsTriggered: '[Snippets] Triggered a Snippet',
  snippetsClickedImport: '[Snippets] Clicked Import Snippets',
  snippetsImportSucceeded: '[Snippets] Import Succeeded',

  billingGroupClickedLearnMore: '[BillingGroup] Clicked Learn more',
  billingGroupUploadedFile: '[BillingGroup] Uploaded a File',
  billingGroupClickedImport: '[BillingGroup] Clicked Import Snippets',
  billingGroupImportSucceeded: '[BillingGroup] Import Succeeded',

  claimDetectionTriggered: '[Claim Detection] Triggered Claim Detection',

  // Learning Center
  learningCenterWatchedVideo1: '[Learning Center] Watched Video 1',
  learningCenterWatchedVideo2: '[Learning Center] Watched Video 2',
  learningCenterWatchedVideo3: '[Learning Center] Watched Video 3',
  learningCenterWatchedVideo4: '[Learning Center] Watched Video 4',
  learningCenterWatchedVideo5: '[Learning Center] Watched Video 5',
  learningCenterWatchedVideo6: '[Learning Center] Watched Video 6',
  learningCenterClickedWebinars: '[Learning Center] Clicked Webinars',
  learningCenterClickedGuides: '[Learning Center] Clicked Guides',
  learningCenterClickedSupportCenter: '[Learning Center] Clicked Support Center',
  learningCenterClickedWhatsNew: '[Learning Center] Clicked What’s New',
  learningCenterHidTray: '[Learning Center] Hid Onboarding Video Tray',
  learningCenterOpened: '[Learning Center] Opened Learning Center',
  learningCenterOpenedAskWriterAnything: '[Learning Center] Opened AWA',
  learningCenterOpenedRecaps: '[Learning Center] Opened Recaps',
  learningCenterOpenedBlogBuilder: '[Learning Center] Opened Blog Builder',
  learningCenterViewedWebinars: '[Learning Center] Viewed Webinars page',
  learningCenterClickedWebinarCTA: '[Learning Center] Webinars: Clicked Register/Watch Now',

  // Prompt Library
  viewedPromptLibrary: '[Prompt Library] Viewed Prompt Library',
  copiedPrompt: '[Prompt Library] Copied Prompt',
  insertedPromptInAskWriter: '[Prompt Library] Inserted Prompt in Ask Writer',
  viewedSubcategory: '[Prompt Library] Viewed Sub-Category',
  searchedPromptLibrary: '[Prompt Library] Searched Prompt Library',

  // Application Console
  appConsoleAppDeployed: '[Application Console] App Deployed',
  appConsoleAppDeleted: '[Application Console] App Deleted',
  appConsoleAppUndeployed: '[Application Console] App Undeployed',
  appConsoleAppContentGenerated: '[Application Console] Generate Content',
  appConsoleSessionEndedDeployed: '[Application Console] Session ended with app undeployed',
  appConsoleDraftCreated: '[Application Console] Draft created',
  appConsoleDraftEdited: '[Application Console] Draft edited',
  appConsoleCopyAppClicked: '[Application Console] Copy app clicked',
  appConsoleShareAppClicked: '[Application Console] Share URL clicked',
  appConsoleFilterAppsUsed: '[Application Console] Filter app list used',
  appConsoleInputDeleted: '[Application Console] Input deleted',
  appConsolePromptDeleted: '[Application Console] Prompt deleted',

  // Voice
  voiceAddClicked: '[Voice] Clicked Add a Voice',
  voiceCalibrationSelected: '[Voice] Selected Calibration Method',
  voiceAddedTextForAnalysis: '[Voice] Added Text for Analysis',
  voiceCreated: '[Voice] Created a New Voice',
  voiceDeleted: '[Voice] Deleted Voice',
  voiceEdited: '[Voice] Edited Voice',
  voiceSetAsDefault: '[Voice] Set Voice as Default',

  // TeamPrompts
  createdNewTeamPrompt: '[Team Prompts] Created a new Team Prompt',
  saveTeamPromptsAw: '[Team Prompts] Clicked "Save to Team Prompts" from AW',
  saveTeamPromptsWp: '[Team Prompts] Clicked "Save to Team Prompts" from Writer Prompt Library',
  insertedTeamPrompt: '[Team Prompts] Inserted a Team Prompt',
  copiedTeamPrompt: '[Team Prompts] Copied a Team Prompts',

  // AI Studio People
  aiStudioPeopleInvitedUser: '[AI Studio Admin] Invited user',
  aiStudioPeopleChangedUserRole: '[AI Studio Admin] Changed user role',
  aiStudioPeopleDeletedUser: '[AI Studio Admin] Deleted user',
  aiStudioPeopleRemovedUser: '[AI Studio Admin] Removed user from AI Studio',
  aiStudioPeopleOrgAdminAssigned: '[AI Studio Admin] Org admin assigned',
  aiStudioPeopleOrgAdminUnassigned: '[AI Studio Admin] Org admin unassigned',
  aiStudioAccountOnly: '[AI Studio Subscription] Started New AI Studio only account',
  aiStudioOrgAdminOptedIntoAIStudio: '[AI Studio Subscription] Org admin opted into AI Studio',

  // Knowledge Graph
  knowledgeGraphViewedPage: '[Knowledge Graph] Viewed Knowledge Graph Page',
  knowledgeGraphAddedGraph: '[Knowledge Graph] Added a Graph',
  knowledgeGraphUploadedFiles: '[Knowledge Graph] Uploaded Files',
  knowledgeGraphSyncedDataConnector: '[Knowledge Graph] Synced a Data Connector',
  knowledgeGraphSelectedAssetsToSync: '[Knowledge Graph] Selected Assets to Sync',

  // OAuth apps
  oAuthAppsViewedPage: '[OAuth Apps] Viewed OAuth apps page',
  oAuthAppsAddedApp: '[OAuth Apps] Added an OAuth app',

  // Templates
  templatesViewedPage: '[AI Studio Templates] Template viewed',
  templatesCopiedPage: '[AI Studio Templates] Template copied',

  // Api apps
  apiAppsApiKeyAdded: '[AI Studio API/Framework Builder] Api key added',
  apiAppsApiKeyRevoked: '[AI Studio API/Framework Builder] Api key revoked',
  apiAppsAppDeleted: '[AI Studio API/Framework Builder] Api app deleted',
  apiAppsCapabilityChanged: '[AI Studio API/Framework Builder] Api capability changed',

  // Framework builder
  frameworkKeyRevoked: '[AI Studio API/Framework Builder] Framework key revoked',
  frameworkKeyAdded: '[AI Studio API/Framework Builder] Framework key added',
  agentKeyAdded: '[AI Studio API/Agent Editor] Agent editor key added',
  frameworkCapabilityChanged: '[AI Studio API/Framework Builder] Framework capability changed',

  // AI Studio Homepage
  aiStudioHomepageAppCreated: '[AI Studio Homepage] app created',
  aiStudioHomepageAppDeleted: '[AI Studio Homepage] app deleted',
  aiStudioHomepageApiAppCreated: '[AI Studio Homepage] api app created',
  aiStudioHomepageAppImported: '[AI Studio Homepage] app imported',

  // AI Studio App Builder
  aiStudioAppBuilderAppRenamed: '[AI Studio App Builder] App renamed',
  aiStudioAppBuilderAppUnlocked: '[AI Studio App Builder] App unlocked',
  aiStudioAppBuilderAppChangesPushed: '[AI Studio App Builder] App changes pushed',
  aiStudioAppBuilderAppChangesCancelled: '[AI Studio App Builder] App changes cancelled',

  // AI Studio Deploy
  aiStudioDeployPlaygroundDeployEnabled: '[AI Studio Deploy] Playground deploy enabled',
  aiStudioDeployPlaygroundDeployDisabled: '[AI Studio Deploy] Playground deploy disabled',
  aiStudioDeployEmbedDeployEnabled: '[AI Studio Deploy] Embed deploy enabled',
  aiStudioDeployEmbedDeployDisabled: '[AI Studio Deploy] Embed deploy disabled',
  aiStudioDeployEmbedSnippetCopied: '[AI Studio Deploy] Embed snippet copied',
  aiStudioDeployEmbedSnippetCopiedWithoutAllowlist: '[AI Studio Deploy] Embed snippet copied without allowlist',
  aiStudioDeployEmbedAllowlistAdded: '[AI Studio Deploy] Embed allowlist added',
  aiStudioDeployEmbedAllowlistRemoved: '[AI Studio Deploy] Embed allowlist removed',
  aiStudioDeployEmbedRefreshed: '[AI Studio Deploy] Embed refreshed',

  // AI Studio Billing
  aiStudioBillingAddedPaymentMethod: '[AI Studio Billing] Added payment method',
  aiStudioBillingAddedToBalance: '[AI Studio Billing] Added to balance',
  aiStudioBillingAutochargeEnabled: '[AI Studio Billing] Autocharge enabled',
  aiStudioBillingAutochargeDisabled: '[AI Studio Billing] Autocharge disabled',
  aiStudioBillingUpdateUsageLimits: '[AI Studio Billing] Usage limits updated',

  // AI Studio Voice
  aiStudioVoiceAddClicked: '[AI Studio Voice] Clicked Add a Voice',
  aiStudioVoiceCalibrationSelected: '[AI Studio Voice] Selected Calibration Method',
  aiStudioVoiceAddedTextForAnalysis: '[AI Studio Voice] Added Text for Analysis',
  aiStudioVoiceCreated: '[AI Studio Voice] Created a New Voice',
  aiStudioVoiceDeleted: '[AI Studio Voice] Deleted Voice',
  aiStudioVoiceEdited: '[AI Studio Voice] Edited Voice',
  aiStudioVoiceSetAsDefault: '[AI Studio Voice] Set Voice as Default',

  // AI Studio Knowledge Graph
  aiStudioKnowledgeGraphViewedPage: '[AI Studio Knowledge Graph] Viewed Knowledge Graph Page',
  aiStudioKnowledgeGraphAddedGraph: '[AI Studio Knowledge Graph] Added a Graph',
  aiStudioKnowledgeGraphUploadedFiles: '[AI Studio Knowledge Graph] Uploaded Files',
  aiStudioKnowledgeGraphSyncedDataConnector: '[AI Studio Knowledge Graph] Synced a Data Connector',
  aiStudioKnowledgeGraphSelectedAssetsToSync: '[AI Studio Knowledge Graph] Selected Assets to Sync',

  // Extension Launch
  extLaunchLaunchedExtension: '[Ext Launch] Launched Extension',
  extLaunchQuitExtension: '[Ext Launch] Quit Extension',

  // Extension Sing-In
  extSignInOpenedGetStarted: '[Ext Sign-In] Opened Get Started Screen',
  extSignInClickedGetStarted: '[Ext Sign-In] Clicked Get Started Button',
  extSignInClosedGetStarted: '[Ext Sign-In] Closed Get Started Screen',
  extSignInClickedSignOut: '[Ext Sign-In] Clicked Sign Out',

  // Extension Onboarding
  extOnboardingViewedSlide: '[Ext Onboarding] Viewed Onboarding Slide',
  extOnboardingClickedStartNow: '[Ext Onboarding] Clicked Start Now Button',
  extOnboardingClosed: '[Ext Onboarding] Closed Onboarding',

  // Extension Home
  extHomeCanceledEditLayout: '[Ext Home] Canceled Edit Layout',
  extHomeCapturedScreenshotFromScreen: '[Ext Home] Captured Screenshot From Screen',
  extHomeCapturedTextFromScreen: '[Ext Home] Captured Text From Screen',
  extHomeClickedTransformWidgetGenerateButton: '[Ext Home] Clicked Transform Widget Generate Button',
  extHomeCopiedCapturedText: '[Ext Home] Copied Captured Text',
  extHomeEnabledEditLayout: '[Ext Home] Enabled Edit Layout',
  extHomePinnedAnApp: '[Ext Home] Pinned an App',
  extHomeSavedEditLayout: '[Ext Home] Saved Edit Layout',
  extHomeSelectedAppsWidget: '[Ext Home] Selected Apps Widget',
  extHomeSelectedTransformWidget: '[Ext Home] Selected Transform Widget',
  extHomeSelectedTransformWidgetDropdown: '[Ext Home] Selected Transform Widget Dropdown',
  extHomeSentScreenshotToApp: '[Ext Home] Sent Screenshot to App',
  extHomeTransformedCapturedText: '[Ext Home] Transformed Captured Text',
  extHomeUnpinnedAnApp: '[Ext Home] Unpinned an App',
  extHomeViewedDashboard: '[Ext Home] Viewed Dashboard',
  extHomeViewedTransformWidgetInputLimit: '[Ext Home] Viewed Transform Widget Input Limit',

  // Extension Settings
  extSettingsViewed: '[Ext Settings] Viewed Settings',
  extSettingsUpdatedShortcut: '[Ext Settings] Updated Keyboard Shortcut',
  extSettingsCheckForUpdates: '[Ext Settings] Checked for Updates',
  extSettingsClickedGoToWhatsNewPage: "[Ext Settings] Clicked to Go to Web What's New Page",

  // Extension Profile
  extProfileViewed: '[Ext Profile] Viewed Profile',
  extProfileOpenedOrganizationDropdown: '[Ext Profile] Opened Organization Dropdown',
  extProfileSelectedNewOrganization: '[Ext Profile] Selected New Organization',
  extProfileClickedGoToWriterWeb: '[Ext Profile] Clicked to Go to Writer Web',
  extProfileClickedGoToProfilePage: '[Ext Profile] Clicked to Go to Web Profile Page',
  extProfileClickedGoToPromptLibraryPage: '[Ext Profile] Clicked to Go to Web Prompt Library Page',
  extProfileClickedGoToHelpCenterPage: '[Ext Profile] Clicked to Go to Web Help Center Page',
  extProfileClickedGoToLearningCenterPage: '[Ext Profile] Clicked to Go to Web Learning Center Page',

  reportingAdminAuditLogDownloaded: '[Reporting] Clicked Download on Admin Audit Report',
  reportingAdminAuditLogViewedPage: '[Reporting] Viewed Admin Audit Log Report',
  reportingAiUsageDownloaded: '[Reporting] Clicked Download on AI Usage Report',
  reportingAiUsageViewedPage: '[Reporting] Viewed AI Usage Report Page',
  reportingUserActivityDownloaded: '[Reporting] Clicked Download on User Activity Report',
  reportingUserActivityViewedPage: '[Reporting] Viewed User Activity Report',

  // My Work
  myWorkViewed: '[My Work] Viewed My Work', // when user views My Work page
  myWorkDocumentsViewed: '[My Work] Viewed Documents', // when user views Documents tab
  myWorkSessionsViewed: '[My Work] Viewed Sessions', // when user views Sessions tab
  myWorkOutputsViewed: '[My Work] Viewed Outputs', // when user views Outputs tab

  // Unified Knowledge Graphs
  kgAddKnowledgeGraphModalOpened: 'Add Knowledge Graph Modal Opened',
  kgAddKnowledgeGraphModalClosed: 'Add Knowledge Graph Modal Closed',
  kgAddedGraph: 'Added a Graph',
  kgWebAddUrlModalOpened: 'Add a URL Modal Opened',
  kgWebAddUrlModalClosed: 'Add a URL Modal Closed',
  kgWebAddedUrl: 'Added a URL',
  kgWebUrlStatusChanged: 'URL Status Changed',
  kgWebUrlOptionClicked: 'URL Option Clicked',
  kgWebUrlOptionSelected: 'URL Option Selected',
  kgWebUrlRemoved: 'URL Removed',
  kgWebExcludeSpecificSubpagesModalOpened: 'Exclude Specific Subpages Modal Opened',
  kgWebExcludeSpecificSubpagesModalClosed: 'Exclude Specific Subpages Modal Closed',
  kgWebExcludeSpecificSubpagesSubmitted: 'Exclude Specific Subpages Submitted',
  kgWebEditUrlModalOpened: 'Edit URL Modal Opened',
  kgWebEditUrlModalClosed: 'Edit URL Modal Closed',
  kgWebEditUrlSubmitted: 'Edit URL Submitted',
  kgWebUrlInputErrorViewed: 'URL Input Error Viewed',
  kgWebUrlErrorTooltipViewed: 'URL Error Tooltip Viewed',
  kgAskKgSelected: 'KG Selected',
  kgAskContributingSourcesViewed: 'Contributing Sources Viewed',

  // Home page
  featuredChatAppSwitcherDropdownClicked: 'Featured Chat App Switcher Dropdown Clicked',
  featuredChatAppSelected: 'Featured Chat App Selected',
  featuredChatAppSearched: 'Featured Chat App Searched',
  chatAppMessageBoxActivated: 'Chat App Message Box Activated',
  chatAppMessageSubmitted: 'Chat App Message Submitted',
  chatAppFileButtonClicked: 'Chat App File Button Clicked', // not implemented
  chatAppVoiceButtonClicked: 'Chat App Voice Button Clicked', // not implemented
  chatAppMessageError: 'Message Error', // not implemented
  recentWorkWidgetTabSelected: 'Recent Work Widget Tab Selected',
  sessionSelected: 'Session Selected',
  newSessionCreated: 'New Session Created',
  openAllSessionsClicked: 'Open All Sessions Clicked',
  docSelected: 'Doc Selected',
  newDocCreated: 'New Doc Created', // pass doc id
  openDocsPageClicked: 'Open Docs Page Clicked',
  appsWidgetTabSelected: 'Apps Widget Tab Selected',
  appsWidgetSorted: 'Apps Widget Sorted',
  openAppLibraryClicked: 'Open App Library Clicked',
  appInfoTooltipHovered: 'App Info Tooltip Hovered',
  openAIStudioPageClicked: 'Open AI Studio Page Clicked',
  buildAnAppTypeSelected: 'Build an App Type Selected',
  draftAppClicked: 'Draft App Clicked',
  openWriterAcademyPageClicked: 'Open Writer Academy Page Clicked',
  certificationClicked: 'Certification Clicked',
  tipsClicked: 'Tips Clicked',
  installExtensionClicked: 'Install Extension Clicked',
  hideExtensionClicked: 'Hide Extension Clicked',
  customizeHomePageButtonClicked: 'Customize Home Page Button Clicked',
  doneCustomizeHomePageButtonClicked: 'Done Customize Home Page Button Clicked',
  displayWidgetToggled: 'Display Widget Toggled',
  addNewShortcutButtonClicked: 'Add New Shortcut Button Clicked',
  addNewShortcutAppSelected: 'Add New Shortcut App Selected',
  appShortcutRemoved: 'App Shortcut Removed',
  extensionToggled: 'Extension Toggled',

  // My Work
  myWorkTabSelected: 'My Work Tab Selected',
  myWorkTabSorted: 'My Work Tab Sorted',
  myWorkTabFiltered: 'My Work Tab Filtered',
  myWorkTabSearched: 'My Work Tab Searched',

  newDocButtonClicked: 'New Doc Button Clicked',
  newSessionButtonClicked: 'New Session Button Clicked',
  myWorkItemMenuClicked: 'My Work Item Menu Clicked',
  docShared: 'Doc Shared',
  docDownloaded: 'Doc Downloaded',
  docDeleted: 'Doc Deleted',
  docSharedUpdateButtonClicked: 'Doc Shared Update Button Clicked',
  docSharedModalClosed: 'Doc Shared Modal Closed',
  docSharedCopyLinkButtonClicked: 'Doc Shared Copy Link Button Clicked',
  sessionRenamed: 'Session Renamed',
  sessionOpened: 'Session Opened',
  sessionDeleted: 'Session Deleted',
  sessionRenameSavedButtonClicked: 'Session Rename Saved Button Clicked',
  sessionRenamedCancelButtonClicked: 'Session Renamed Cancel Button Clicked',
  outputCopied: 'Output Copied',
  outputDeleted: 'Output Deleted',
  outputUseInNewDocButtonClicked: 'Output Use in New Doc Button Clicked',
  outputSelected: 'Output Selected',

  appShortcutReordered: 'App Shortcut Reordered',

  // Global Nav
  globalNavMenuItemClicked: 'Global Nav Menu Item Clicked',

  // Apps in Home Page
  appOpened: 'App Opened',
  appExited: 'App Exited',

  // App Generation Events
  appGenerated: 'Generate App content clicked',
} as const;

export type TActivityParams = TUIAppLibraryAnalyticsActivity &
  TUIGenerationAppsAnalyticsParams &
  TUIChatAppsAnalyticsParams &
  TUISidebarModelsAnalyticsParams &
  TWebQuillEditorActivityParams & {
    // Home
    [AnalyticsActivity.chatAppMessageBoxActivated]: TOrgTeamUserActivityParams & {
      app_id: string;
      origin: string;
    };
    [AnalyticsActivity.chatAppMessageSubmitted]: TOrgTeamUserActivityParams & {
      app_id: string;
      origin: string;
    };
    [AnalyticsActivity.chatAppFileButtonClicked]: TOrgTeamUserActivityParams & {
      app_id: string;
    };
    [AnalyticsActivity.chatAppVoiceButtonClicked]: TOrgTeamUserActivityParams & {
      app_id: string;
    };
    [AnalyticsActivity.chatAppMessageError]: TOrgTeamUserActivityParams;

    // Recent Work
    [AnalyticsActivity.recentWorkWidgetTabSelected]: TOrgTeamUserActivityParams & {
      option: string;
    };
    [AnalyticsActivity.sessionSelected]: TOrgTeamUserActivityParams & {
      session_id: string;
      origin: 'recent_work_widget' | 'other';
    };
    [AnalyticsActivity.newSessionCreated]: TOrgTeamUserActivityParams & {
      session_id: string;
      origin: 'recent_work_widget' | 'other';
    };
    [AnalyticsActivity.openAllSessionsClicked]: TOrgTeamUserActivityParams & {
      origin: 'recent_work_widget' | 'other';
    };
    [AnalyticsActivity.docSelected]: TOrgTeamUserActivityParams & {
      doc_id: string;
      origin: 'recent_work_widget' | 'other';
    };
    [AnalyticsActivity.newDocCreated]: TOrgTeamUserActivityParams & {
      origin: 'recent_work_widget' | 'other';
      doc_id: string;
    };
    [AnalyticsActivity.openDocsPageClicked]: TOrgTeamUserActivityParams & {
      origin: 'recent_work_widget' | 'other';
    };

    // Apps
    [AnalyticsActivity.appsWidgetTabSelected]: TOrgTeamUserActivityParams & {
      option: string;
    };
    [AnalyticsActivity.appsWidgetSorted]: TOrgTeamUserActivityParams & {
      origin: string;
      option: string;
    };
    [AnalyticsActivity.openAppLibraryClicked]: TOrgTeamUserActivityParams & {
      origin: 'apps_widget' | 'home_page_header' | 'recent_work_widget' | 'other';
    };
    [AnalyticsActivity.appInfoTooltipHovered]: TOrgTeamUserActivityParams & {
      app_id: string;
    };

    // AI Studio
    [AnalyticsActivity.openAIStudioPageClicked]: TOrgTeamUserActivityParams & {
      origin: 'ai_studio_widget' | 'other';
    };
    [AnalyticsActivity.buildAnAppTypeSelected]: TOrgTeamUserActivityParams & {
      origin: 'ai_studio_widget' | 'other';
      app_type: string;
    };
    [AnalyticsActivity.draftAppClicked]: TOrgTeamUserActivityParams & {
      app_id: string;
      app_type: string;
    };

    // Writer Academy
    [AnalyticsActivity.openWriterAcademyPageClicked]: TOrgTeamUserActivityParams & {
      origin: 'writer_academy_widget' | 'other';
    };
    [AnalyticsActivity.certificationClicked]: TOrgTeamUserActivityParams & {
      certification_id?: string;
      origin: 'writer_academy_widget' | 'other';
    };
    [AnalyticsActivity.tipsClicked]: TOrgTeamUserActivityParams & {
      url?: string;
      course_id?: string;
      origin: 'writer_academy_widget' | 'other';
    };

    // Extensions
    [AnalyticsActivity.installExtensionClicked]: TOrgTeamUserActivityParams & {
      extension: string;
      origin: 'extension_widget' | 'other';
    };
    [AnalyticsActivity.hideExtensionClicked]: TOrgTeamUserActivityParams & {
      extension: string;
      origin: 'extension_widget' | 'other';
    };

    // Customize Home Page
    [AnalyticsActivity.customizeHomePageButtonClicked]: TOrgTeamUserActivityParams;
    [AnalyticsActivity.doneCustomizeHomePageButtonClicked]: TOrgTeamUserActivityParams;
    [AnalyticsActivity.displayWidgetToggled]: TOrgTeamUserActivityParams & {
      option: string;
      widget: string;
    };

    // Feature Widget
    [AnalyticsActivity.featuredChatAppSwitcherDropdownClicked]: TOrgTeamUserActivityParams & {
      edit_flow: 'team admin' | 'user';
    };
    [AnalyticsActivity.featuredChatAppSelected]: TOrgTeamUserActivityParams & {
      old_app_id: string;
      new_app_id: string;
      edit_flow: 'team admin' | 'user';
    };
    [AnalyticsActivity.featuredChatAppSearched]: TOrgTeamUserActivityParams & {
      search: string;
      edit_flow: 'team admin' | 'user';
    };

    // Shortcuts
    [AnalyticsActivity.addNewShortcutButtonClicked]: TOrgTeamUserActivityParams & {
      edit_flow: 'team admin' | 'user';
    };
    [AnalyticsActivity.addNewShortcutAppSelected]: TOrgTeamUserActivityParams & {
      edit_flow: 'team admin' | 'user';
      app_id: string;
    };
    [AnalyticsActivity.appShortcutRemoved]: TOrgTeamUserActivityParams & {
      edit_flow: 'team admin' | 'user';
      app_id: string;
    };
    [AnalyticsActivity.appShortcutReordered]: TOrgTeamUserActivityParams & {
      app_id: string;
    };

    // Extensions Toggle
    [AnalyticsActivity.extensionToggled]: TOrgTeamUserActivityParams & {
      extension: string;
      option: string;
    };

    // My Work
    [AnalyticsActivity.myWorkTabSelected]: TOrgTeamUserActivityParams & {
      option: 'docs' | 'sessions' | 'outputs';
    };
    [AnalyticsActivity.myWorkTabSorted]: TOrgTeamUserActivityParams & {
      origin: 'docs' | 'sessions';
      option: 'last_opened' | 'last_updated' | 'name' | 'size';
    };
    [AnalyticsActivity.myWorkTabFiltered]: TOrgTeamUserActivityParams & {
      origin: 'docs' | 'sessions' | 'outputs';
      option: 'owned_by_anyone' | 'owned_by_me' | 'app';
      app_id?: string;
      app_name?: string;
    };
    [AnalyticsActivity.myWorkTabSearched]: TOrgTeamUserActivityParams & {
      origin: 'docs' | 'sessions' | 'outputs';
      search_term: string;
    };
    [AnalyticsActivity.newDocButtonClicked]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      doc_id: string;
    };
    [AnalyticsActivity.newSessionButtonClicked]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      session_id: string;
    };
    [AnalyticsActivity.myWorkItemMenuClicked]: TOrgTeamUserActivityParams & {
      option: 'docs' | 'sessions' | 'outputs';
    };
    [AnalyticsActivity.docShared]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      doc_id: string;
    };
    [AnalyticsActivity.docDownloaded]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      doc_id: string;
    };
    [AnalyticsActivity.docDeleted]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      doc_id: string;
    };
    [AnalyticsActivity.docSharedUpdateButtonClicked]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      doc_id: string;
      sharing_option: 'visible_only_to_me' | 'share_with_team' | 'share_with_specific_teammates';
    };
    [AnalyticsActivity.docSharedModalClosed]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      doc_id: string;
      sharing_option: 'visible_only_to_me' | 'share_with_team' | 'share_with_specific_teammates';
    };
    [AnalyticsActivity.docSharedCopyLinkButtonClicked]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      doc_id: string;
      sharing_option: 'visible_only_to_me' | 'share_with_team' | 'share_with_specific_teammates';
    };
    [AnalyticsActivity.sessionRenamed]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      session_id: string;
    };
    [AnalyticsActivity.sessionOpened]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      session_id: string;
    };
    [AnalyticsActivity.sessionDeleted]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      session_id: string;
    };
    [AnalyticsActivity.sessionRenameSavedButtonClicked]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      session_id: string;
    };
    [AnalyticsActivity.sessionRenamedCancelButtonClicked]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      session_id: string;
    };
    [AnalyticsActivity.outputCopied]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      output_id: string;
      mode: 'menu' | 'sidebar';
    };
    [AnalyticsActivity.outputDeleted]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      output_id: string;
      mode: 'menu' | 'sidebar';
    };
    [AnalyticsActivity.outputUseInNewDocButtonClicked]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      output_id: string;
      mode: 'menu' | 'sidebar';
    };
    [AnalyticsActivity.outputSelected]: TOrgTeamUserActivityParams & {
      origin: 'my_work';
      output_id: string;
    };

    // AI Studio App Builder
    [AnalyticsActivity.aiStudioAppBuilderAppRenamed]: TAppOrgTeamUserActivityParams & {
      old_application_name: string;
    };
    [AnalyticsActivity.aiStudioAppBuilderAppUnlocked]: TAppOrgTeamUserActivityParams;
    [AnalyticsActivity.aiStudioAppBuilderAppChangesPushed]: TAppOrgTeamUserActivityParams & {
      push_type: string;
    };
    [AnalyticsActivity.aiStudioAppBuilderAppChangesCancelled]: TAppOrgTeamUserActivityParams;

    [AnalyticsActivity.aiStudioDeployPlaygroundDeployEnabled]: TAppOrgTeamUserActivityParams;
    [AnalyticsActivity.aiStudioDeployPlaygroundDeployDisabled]: TAppOrgTeamUserActivityParams;
    [AnalyticsActivity.aiStudioDeployEmbedDeployEnabled]: TAppOrgTeamUserActivityParams;
    [AnalyticsActivity.aiStudioDeployEmbedDeployDisabled]: TAppOrgTeamUserActivityParams;
    [AnalyticsActivity.aiStudioDeployEmbedSnippetCopied]: TAppOrgTeamUserActivityParams & {
      embed_type: string;
      allowlist_urls: string[];
    };
    [AnalyticsActivity.aiStudioDeployEmbedSnippetCopiedWithoutAllowlist]: TAppOrgTeamUserActivityParams & {
      embed_type: string;
    };
    [AnalyticsActivity.aiStudioDeployEmbedAllowlistAdded]: TAppOrgTeamUserActivityParams & {
      embed_type: string;
      allowlist_url: string;
    };
    [AnalyticsActivity.aiStudioDeployEmbedAllowlistRemoved]: TAppOrgTeamUserActivityParams & {
      embed_type: string;
      allowlist_url: string;
    };
    [AnalyticsActivity.aiStudioDeployEmbedRefreshed]: TAppOrgTeamUserActivityParams;

    // AI Studio Billing
    [AnalyticsActivity.aiStudioBillingAddedPaymentMethod]: TOrgUserActivityParams;
    [AnalyticsActivity.aiStudioBillingAddedToBalance]: TOrgUserActivityParams & {
      balance_added: number;
    };
    [AnalyticsActivity.aiStudioBillingAutochargeEnabled]: TOrgUserActivityParams & {
      autocharge_threshold: number;
    };
    [AnalyticsActivity.aiStudioBillingAutochargeDisabled]: TOrgUserActivityParams;
    [AnalyticsActivity.aiStudioBillingUpdateUsageLimits]: TOrgUserActivityParams & {
      monthly_budget: number;
      notification_threshold: number;
    };

    // AI Studio People
    [AnalyticsActivity.aiStudioPeopleInvitedUser]: TOrgUserActivityParams & {
      invited_user_email: string;
      invited_user_role: string;
    };
    [AnalyticsActivity.aiStudioPeopleChangedUserRole]: TOrgUserActivityParams & {
      changed_user_id: number;
      changed_user_email: string;
      changed_user_role: string;
    };
    [AnalyticsActivity.aiStudioPeopleDeletedUser]: TOrgUserActivityParams & {
      deleted_user_email: string;
      deleted_user_id: number;
      deleted_user_role: string;
    };
    [AnalyticsActivity.aiStudioPeopleRemovedUser]: TOrgUserActivityParams & {
      removed_user_email: string;
      removed_user_id: number;
      removed_user_role: string;
    };
    [AnalyticsActivity.aiStudioPeopleOrgAdminAssigned]: TOrgUserActivityParams & {
      modified_user_email: string;
    };
    [AnalyticsActivity.aiStudioPeopleOrgAdminUnassigned]: TOrgUserActivityParams & {
      modified_user_email: string;
    };
    [AnalyticsActivity.aiStudioAccountOnly]: TOrgUserActivityParams & {
      plan_name: string;
    };
    [AnalyticsActivity.aiStudioOrgAdminOptedIntoAIStudio]: TOrgUserActivityParams & {
      plan_name: string;
    };

    [AnalyticsActivity.autoJoinSelectJoinOrg]: {
      access_type: 'request_to_join' | 'immediate';
      auth_method: 'Password' | 'Saml' | 'Social' | 'App' | 'Admin' | 'Firebase';
    };
    [AnalyticsActivity.termsViewed]: TTeamActivityParams;
    [AnalyticsActivity.termAdded]: {
      team_id: number;
      term_type: string;
    };
    [AnalyticsActivity.termEdited]: {
      team_id: number;
      term_type: string;
    };
    [AnalyticsActivity.termsUploadedFile]: TTeamActivityParams;
    [AnalyticsActivity.snippetAdded]: TTeamActivityParams;
    [AnalyticsActivity.snippetEdited]: TTeamActivityParams;
    [AnalyticsActivity.snippetsUploadedFile]: TTeamActivityParams;
    [AnalyticsActivity.snippetsTriggered]: TTeamActivityParams;
    [AnalyticsActivity.userJoined]: {
      team_id?: number;
      signup_method: string;
      plan_name: string;
      subscription_status: string;
    };
    [AnalyticsActivity.subscriptionStartedNewTeamTrial]: TTeamActivityParams;
    [AnalyticsActivity.billingViewed]: TTeamActivityParams;
    [AnalyticsActivity.clickPurchaseTeamNow]: {
      team_id?: number;
      clicked_from: 'team_people' | 'trial_banner';
    };
    [AnalyticsActivity.clickedContactSales]: {
      clicked_from: 'billing_page' | 'all_teams' | 'custom_rules' | 'security_page' | 'sso_page' | 'reporting_page';
      team_id?: number;
    };
    [AnalyticsActivity.allTeamsViewed]: TTeamActivityParams;
    [AnalyticsActivity.peopleViewed]: TTeamActivityParams;
    [AnalyticsActivity.contentRephrased]: {
      suggestion_ui: 'rewrite';
      team_id: number;
      prompt: string;
      selectedText: string;
    };
    [AnalyticsActivity.rephrasedContentAccepted]: {
      suggestion_ui: 'rewrite';
      team_id: number;
      prompt: string;
      text: string;
      suggestion: string;
      suggestionId: string;
      newSuggestion: string;
      newSuggestionId: string;
    };
    [AnalyticsActivity.contentRegenerated]: {
      suggestion_ui: 'rewrite';
      team_id: number;
      prompt: string;
      selectedText: string;
      suggestion: string;
      suggestionId: string;
    };
    [AnalyticsActivity.rephrasedContentCopied]: {
      suggestion_ui: 'rewrite';
      selectedText: string;
      prompt: string;
      suggestion: string;
      suggestionId: string;
      team_id: number;
    };
    [AnalyticsActivity.signedUp]: {
      team_id?: number;
      auth_method: string;
      email_consent: boolean;
      plan_name: string;
      subscription_status: string;
    };
    [AnalyticsActivity.coWriteHighlightsOpened]: TTeamActivityParams;
    [AnalyticsActivity.coWriteHighlightsRegeneratedSummary]: TTeamActivityParams;
    [AnalyticsActivity.coWriteHighlightsRegeneratedMeta]: TTeamActivityParams;
    [AnalyticsActivity.coWriteHighlightsRegeneratedLinkedIn]: TTeamActivityParams;
    [AnalyticsActivity.coWriteHighlightsRegeneratedTwitter]: TTeamActivityParams;

    [AnalyticsActivity.triggerAutoWrite]: {
      team_id: number;
      trigger_type: 'button' | 'keyboard';
    };

    [AnalyticsActivity.magciLinksAddDomain]: {
      clicked_from: string;
      team_id: number;
    };
    [AnalyticsActivity.magicLinksUserClickedIcon]: {
      clicked_from: 'magic-links';
      team_id: number;
    };
    [AnalyticsActivity.appConsoleAppDeployed]: TAppOrgUserActivityParams;
    [AnalyticsActivity.appConsoleSessionEndedDeployed]: TAppOrgUserActivityParams;
    [AnalyticsActivity.appConsoleDraftCreated]: TAppOrgUserActivityParams & {
      original_author_user_email?: string | null;
    };
    [AnalyticsActivity.appConsoleDraftEdited]: TAppOrgUserActivityParams & {
      original_author_user_email?: string | null;
    };
    [AnalyticsActivity.appConsoleAppDeleted]: TAppOrgUserActivityParams;
    [AnalyticsActivity.appConsoleAppUndeployed]: TAppOrgUserActivityParams;
    [AnalyticsActivity.appConsoleAppContentGenerated]: TAppOrgUserActivityParams & {
      most_recent_deploy_user_email?: string | null;
    };
    [AnalyticsActivity.appConsoleCopyAppClicked]: TAppOrgUserActivityParams;
    [AnalyticsActivity.appConsoleSessionEndedDeployed]: TAppOrgUserActivityParams;
    [AnalyticsActivity.appConsoleFilterAppsUsed]: TAppOrgUserActivityParams & {
      filter_applied: string | null;
    };
    [AnalyticsActivity.appConsoleInputDeleted]: TAppOrgUserActivityParams & {
      input_type: string | null;
      input_name: string | null;
      inputs_count: number | null;
    };
    [AnalyticsActivity.appConsolePromptDeleted]: TAppOrgUserActivityParams & {
      input_type: string | null;
      input_name: string | null;
      inputs_count: number | null;
    };

    [AnalyticsActivity.termsClickedLearnMore]: TTeamActivityParams & {
      clicked_from: string;
    };

    [AnalyticsActivity.snippetsClickedLearnMore]: TTeamActivityParams & {
      clicked_from: string;
    };

    [AnalyticsActivity.billingGroupClickedLearnMore]: TTeamActivityParams & {
      clicked_from: string;
    };

    [AnalyticsActivity.termsUploadedFile]: TTeamActivityParams;

    [AnalyticsActivity.snippetsUploadedFile]: TTeamActivityParams;

    [AnalyticsActivity.billingGroupUploadedFile]: TTeamActivityParams;

    [AnalyticsActivity.termsClickedImport]: TTeamActivityParams;

    [AnalyticsActivity.snippetsClickedImport]: TTeamActivityParams;

    [AnalyticsActivity.billingGroupClickedImport]: TTeamActivityParams;

    [AnalyticsActivity.termsImportSucceeded]: TTeamActivityParams;

    [AnalyticsActivity.snippetsImportSucceeded]: TTeamActivityParams;

    [AnalyticsActivity.billingGroupImportSucceeded]: TTeamActivityParams;

    [AnalyticsActivity.createdNewTeamPrompt]: Record<string, never>;

    [AnalyticsActivity.saveTeamPromptsAw]: Record<string, never>;

    [AnalyticsActivity.saveTeamPromptsWp]: Record<string, never>;

    [AnalyticsActivity.insertedTeamPrompt]: Record<string, never>;

    [AnalyticsActivity.copiedTeamPrompt]: Record<string, never>;

    [AnalyticsActivity.templatesViewedPage]: TOrgUserActivityParams & {
      template_id: string;
    };

    [AnalyticsActivity.templatesCopiedPage]: TOrgUserActivityParams & {
      template_id: string;
    };

    [AnalyticsActivity.apiAppsApiKeyAdded]: TAppOrgUserActivityParams & {
      key_name?: string;
      old_application_name: string;
    };
    [AnalyticsActivity.apiAppsApiKeyRevoked]: TAppOrgUserActivityParams & {
      key_name?: string;
      old_application_name: string;
    };
    [AnalyticsActivity.apiAppsAppDeleted]: TAppOrgUserActivityParams;
    [AnalyticsActivity.apiAppsCapabilityChanged]: TAppOrgUserActivityParams & {
      capabilities_added?: string[];
      capabilities_removed?: string[];
    };
    [AnalyticsActivity.frameworkKeyAdded]: TAppOrgUserActivityParams & {
      old_application_name?: string;
      key_name?: string;
    };
    [AnalyticsActivity.frameworkKeyRevoked]: TAppOrgUserActivityParams & {
      key_name?: string;
    };
    [AnalyticsActivity.frameworkCapabilityChanged]: TAppOrgUserActivityParams & {
      capabilities_added?: string[];
      capabilities_removed?: string[];
    };

    [AnalyticsActivity.aiStudioHomepageAppCreated]: TAppOrgUserActivityParams;

    [AnalyticsActivity.aiStudioHomepageAppDeleted]: TAppOrgUserActivityParams;

    [AnalyticsActivity.aiStudioHomepageAppImported]: TAppOrgUserActivityParams;

    [AnalyticsActivity.enabledDataRetention]: {
      selected_schedule: TDeletionOptionType;
      assets_to_delete: TAssetToDeleteType[];
    };

    [AnalyticsActivity.modifiedDataRetention]: {
      selected_schedule_new: TDeletionOptionType;
      selected_schedule_old: TDeletionOptionType;
      assets_to_delete_new: TAssetToDeleteType[];
      assets_to_delete_old: TAssetToDeleteType[];
    };

    [AnalyticsActivity.canceledDataRetention]: {
      deletion_summary: {
        assetType: TAssetToDeleteType;
        count: number;
      }[];
    };

    [AnalyticsActivity.extLaunchLaunchedExtension]: {
      triggered_by: 'manually' | 'system_startup';
    };

    [AnalyticsActivity.extOnboardingViewedSlide]: {
      slide: number;
      triggered_by: 'next_button' | 'onboarding_launch' | 'page_control';
    };

    [AnalyticsActivity.extHomeClickedTransformWidgetGenerateButton]: {
      transform_flow: string;
      dropdown_option: string;
      character_count: number;
      word_count: number;
    };
    [AnalyticsActivity.extHomePinnedAnApp]: {
      app_id: string;
      triggered_by: string;
    };
    [AnalyticsActivity.extHomeSelectedAppsWidget]: {
      category: string;
    };
    [AnalyticsActivity.extHomeSelectedTransformWidget]: {
      transform_flow: string;
    };
    [AnalyticsActivity.extHomeSelectedTransformWidgetDropdown]: {
      dropdown_option: string;
      transform_flow: string;
    };
    [AnalyticsActivity.extHomeSentScreenshotToApp]: {
      app_id: string;
      app_name: string;
    };
    [AnalyticsActivity.extHomeTransformedCapturedText]: {
      dropdown_option: string;
      transform_flow: string;
    };
    [AnalyticsActivity.extHomeUnpinnedAnApp]: {
      app_id: string;
      triggered_by: string;
    };
    [AnalyticsActivity.extHomeViewedDashboard]: {
      triggered_by: string;
    };
    [AnalyticsActivity.extHomeViewedTransformWidgetInputLimit]: {
      transform_flow: string;
      character_count: number;
      word_count: number;
      input_limit: number;
    };

    [AnalyticsActivity.extSettingsUpdatedShortcut]: {
      shortcut: 'custom' | 'default';
    };

    // Documents
    [AnalyticsActivity.createDocument]: {
      created_from: string;
    };

    // Global Nav
    [AnalyticsActivity.globalNavMenuItemClicked]: {
      menu_item: string;
    };

    // Apps in Home Page
    [AnalyticsActivity.appOpened]: TOrgTeamUserActivityParams & {
      app_id: string;
      source: 'featured_chat_app_widget' | 'shortcuts_widget' | 'apps_widget' | 'app_library';
      origin: string;
      built_by_writer: boolean;
      app_name: string;
      app_type: string;
    };

    [AnalyticsActivity.appExited]: TOrgTeamUserActivityParams & {
      app_id: string;
      app_name: string;
      app_type: string;
    };

    // Generation Apps Events
    [AnalyticsActivity.appGenerated]: TOrgTeamUserActivityParams & {
      app_id: string;
      app_name: string;
      app_type: string;
      built_by_writer: boolean;
    };
  };

export type TWebAnalyticsParams = TAnalyticsActivityTypeBuilder<typeof AnalyticsActivity, TActivityParams>;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type IWebAppAnalyticsTrack<TContext = {}> = IAnalyticsTrack<TWebAnalyticsParams, TContext>;
export type AnalyticEvents = (typeof AnalyticsActivity)[keyof typeof AnalyticsActivity];

export interface ILearningCenterVideo {
  id: number;
  route: typeof TLearningCenterVideoId.type;
  title: string;
  duration: string;
  description: string;
  url: string;
  cover: string;
  ctaButtonLabel?: string;
  icon: string;
  analyticsEvent: AnalyticEvents;
  articleTitle?: string;
  articleLink?: string;
  tags: (typeof TVideoTag.type)[];
  viewed?: boolean;
}

export interface ILearningCenterNavigationCategory {
  id: typeof TLearningCenterCategoryId.type;
  name: string;
  icon: IconVariant;
  link?: string;
  analyticsEvent?: AnalyticEvents;
  inLowerSection?: boolean;
}

export const MODE_NAME_MAP = {
  [ContentEditorPageMode.BLOG]: 'Blog builder',
  [ContentEditorPageMode.CO_WRITE]: 'Co-write',
  [ContentEditorPageMode.EVENT_TAKEAWAYS]: 'Recaps builder',
  [ContentEditorPageMode.HIGHLIGHTS]: 'Highlights',
  [ContentEditorPageMode.LIBRARY]: 'Library',
  [ContentEditorPageMode.PURE_EDITOR]: 'Editor',
};
