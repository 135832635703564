import React, { useEffect } from 'react';

import cx from 'classnames';

import { wordPluralize } from '@writercolab/common-utils';
import { PromisedError } from '@writercolab/mobx';
import {
  Button,
  ButtonTypes,
  DotLoader,
  Heading,
  HeadingColor,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  TooltipAlignment,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';
import { ThreeDotsLoader } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

import type { TKnowledgeGraphFilterSection } from '@web/types';
import { KG_SUPPORTED_FILE_EXTENSIONS, KG_SUPPORTED_MIME_TYPES } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';
import uniqWith from 'lodash/uniqWith';
import { observer } from 'mobx-react-lite';
import useDrivePicker from 'react-google-drive-picker';
import { getFolderName } from 'utils/knowledgeGraphUtils';

import { useAppState } from '../../../state';
import { SyncKnowledgeGraphModal } from '../SyncKnowledgeGraphModal';
import { SyncStatus } from '../SyncStatus';
import type { SyncStatusUIModel } from '../SyncStatus/SyncStatusModel.ui';
import type { KnowledgeGraphConnectorUIModel } from './KnowledgeGraphConnectorModel.ui';
import { KnowledgeGraphConnectorSelector } from './KnowledgeGraphConnectorSelector';

import styles from './KnowledgeGraphConnector.module.css';

export const TKnowledgeGraphConnector = new Enum('gdrive', 'sharepoint', 'notion', 'confluence');

export interface IKnowledgeGraphConnectorProps {
  model: KnowledgeGraphConnectorUIModel;
  syncStatusModel: SyncStatusUIModel;
  isConnected: boolean;
  viewOnly?: boolean;
  isBlockedByBalance?: boolean;
  isBlockedByUsageLimits?: boolean;
  isOrgAdmin?: boolean;
  onFailedFilesClick: () => void;
  onDeleteConnectionClick: () => void;
}

export const KnowledgeGraphConnector = observer<IKnowledgeGraphConnectorProps>(
  ({
    model,
    syncStatusModel,
    isConnected,
    viewOnly,
    isBlockedByBalance,
    isBlockedByUsageLimits,
    isOrgAdmin,
    onDeleteConnectionClick,
    onFailedFilesClick,
  }) => {
    const [openPicker] = useDrivePicker();
    const appState = useAppState();
    const { appModel } = appState;
    const { analyticsService } = appModel;
    const { enqueueErrorSnackbar } = useCustomSnackbar();

    useEffect(() => {
      if (
        isConnected &&
        (model.connection instanceof PromisedError || model.credentialsWithError instanceof PromisedError)
      ) {
        enqueueErrorSnackbar('Failed to connect to the data connector');
      }
    }, [isConnected, model.connection, model.credentialsWithError, enqueueErrorSnackbar]);

    useEffect(() => {
      const handleMessage = async (e: MessageEvent) => {
        if (e.data.source === 'connector' && model.connectorId) {
          const connection = await model.createConnection(e.data.code);

          const withCustomTimeout = TKnowledgeGraphConnector.matchPartial(
            model.connectorType,
            {
              notion: () => true,
              confluence: () => true,
            },
            null,
          );

          if (withCustomTimeout) {
            setTimeout(() => {
              TKnowledgeGraphConnector.matchPartial(
                model.connectorType,
                {
                  notion: () => {
                    model.updateConnectionFilters(connection.id, []);
                  },
                  confluence: () => {
                    if (model.credentials?.token) {
                      model.confluenceApiModel.setToken(model.credentials.token);
                      model.openSyncModal();
                    }
                  },
                },
                null,
              );
            }, 3000);
          }

          analyticsService.track(
            model.aiStudioMode
              ? AnalyticsActivity.aiStudioKnowledgeGraphSyncedDataConnector
              : AnalyticsActivity.knowledgeGraphSyncedDataConnector,
            {
              connector_type: connection.connector?.type,
              oauth_app_type: connection.connector?.organizationId ? 'self_managed' : 'writer_managed',
            },
          );

          model.closeOAuthPopup();
          model.closeAuthModal();
        }
      };

      window.addEventListener('message', handleMessage, false);

      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, [model.connectorId, model.connectorType, model.aiStudioMode, model, analyticsService]);

    useEffect(() => {
      let popupTimer;

      if (model.isOAuthPopupOpen && model.connectorAuthUrl) {
        const popup = window.open(
          model.connectorAuthUrl,
          '_blank',
          `addressbar=no, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${400}, height=${600}, top=${200}, left=${200}`,
        );

        popupTimer = setInterval(() => {
          if (!popup?.opener) {
            model.closeOAuthPopup();
            model.closeAuthModal();
            clearInterval(popupTimer);
          }
        }, 100);
      }

      return () => {
        if (popupTimer) {
          clearInterval(popupTimer);
        }
      };
    }, [model.isOAuthPopupOpen, model.connectorAuthUrl, model]);

    const handleOpenFilePicker = async () => {
      if (model.connection instanceof PromisedError) {
        return;
      }

      await model.refreshCredentials();

      if (model.connection && model.credentials) {
        TKnowledgeGraphConnector.match(
          model.connection.connectorType as typeof TKnowledgeGraphConnector.type,
          {
            gdrive: () => {
              if (model.credentials?.clientId && model.credentials?.developerKey) {
                openPicker({
                  clientId: model.credentials.clientId,
                  developerKey: model.credentials.developerKey,
                  token: model.credentials.token,
                  viewMimeTypes: KG_SUPPORTED_MIME_TYPES.join(','),
                  showUploadView: false,
                  showUploadFolders: false,
                  setSelectFolderEnabled: true,
                  setIncludeFolders: true,
                  supportDrives: true,
                  multiselect: true,
                  setOrigin: window.location.origin,
                  customViews: [
                    new window.google.picker.DocsView()
                      .setIncludeFolders(true)
                      .setEnableDrives(true)
                      .setSelectFolderEnabled(true),
                    new window.google.picker.DocsView()
                      .setLabel('Folders')
                      .setIncludeFolders(true)
                      .setMimeTypes('application/vnd.google-apps.folder'),
                    new window.google.picker.DocsView().setLabel('Files').setIncludeFolders(false),
                  ],
                  callbackFunction: response => {
                    if (
                      response.action === 'picked' &&
                      model.connection &&
                      !(model.connection instanceof PromisedError)
                    ) {
                      const sections = [
                        ...model.connectionSections,
                        ...response.docs.map(file => ({
                          id: file.id as TKnowledgeGraphFilterSection['id'],
                          name: file.name,
                          type: file.type === 'folder' ? 'folder' : 'document',
                        })),
                      ] as TKnowledgeGraphFilterSection[];
                      const uniqueSections = uniqWith(
                        sections,
                        (a, b) => a.id === b.id && a.type === b.type && a.driveId === b.driveId,
                      );

                      analyticsService.track(
                        model.aiStudioMode
                          ? AnalyticsActivity.aiStudioKnowledgeGraphSelectedAssetsToSync
                          : AnalyticsActivity.knowledgeGraphSelectedAssetsToSync,
                        {
                          connector_type: 'gdrive',
                          oauth_app_type: model.connection.connector?.organizationId
                            ? 'self_managed'
                            : 'writer_managed',
                        },
                      );
                      model.updateConnectionFilters(model.connection.id, uniqueSections);
                      syncStatusModel.reloadConnectorSyncStats();
                    }
                  },
                });
              }
            },
            sharepoint: () => {
              if (model.credentials?.clientId) {
                window.OneDrive.open({
                  clientId: model.credentials.clientId,
                  tenantId: model.credentials.tenantId,
                  action: 'query',
                  multiSelect: true,
                  openInNewWindow: true,
                  viewType: 'all',
                  advanced: {
                    queryParameters: 'select=*',
                    filter: KG_SUPPORTED_FILE_EXTENSIONS.join(','),
                    redirectUri: `${window.location.origin}/connector/${TKnowledgeGraphConnector.enum.sharepoint}`,
                    navigation: {
                      sourceTypes: 'Sites',
                    },
                  },
                  success: response => {
                    if (model.connection && !(model.connection instanceof PromisedError)) {
                      const sections = [
                        ...model.connectionSections,
                        ...response.value.map(file => ({
                          id: file.id,
                          driveId: file.parentReference.driveId,
                          name:
                            'folder' in file
                              ? getFolderName(file.name, file.parentReference.driveType, file.webUrl)
                              : file.name,
                          type: 'folder' in file ? 'folder' : 'document',
                        })),
                      ] as TKnowledgeGraphFilterSection[];
                      const uniqueSections = uniqWith(
                        sections,
                        (a, b) => a.id === b.id && a.type === b.type && a.driveId === b.driveId,
                      );
                      analyticsService.track(
                        model.aiStudioMode
                          ? AnalyticsActivity.aiStudioKnowledgeGraphSelectedAssetsToSync
                          : AnalyticsActivity.knowledgeGraphSelectedAssetsToSync,
                        {
                          connector_type: 'sharepoint',
                          oauth_app_type: model.connection.connector?.organizationId
                            ? 'self_managed'
                            : 'writer_managed',
                        },
                      );
                      model.updateConnectionFilters(model.connection.id, uniqueSections);
                      syncStatusModel.reloadConnectorSyncStats();
                    }
                  },
                });
              }
            },
            notion: () => {
              if (model.connection && !(model.connection instanceof PromisedError)) {
                model.updateConnectionFilters(model.connection.id, []);
              }
            },
            confluence: () => {
              if (model.connection && !(model.connection instanceof PromisedError)) {
                model.updateConnectionFilters(model.connection.id, []);
              }
            },
          },
          null,
        );
      }
    };

    const syncModalDescription = model.isConfluenceConnected ? (
      <>
        All current Confluence pages from within selected spaces will be synced.
        <br />
        Deselect any spaces you’d like excluded from the graph.
      </>
    ) : (
      <>
        These items are being synced every 24 hours.
        <br />
        Uncheck if you’d like to remove and stop syncing.
      </>
    );

    const getIsBlockedMessage = () => {
      if (isBlockedByBalance) {
        return 'Disabled due to insufficient credits, contact your org admin to resume service.';
      }

      if (isBlockedByUsageLimits) {
        return 'Disabled due to exceeding usage limits, contact your org admin to resume service.';
      }

      return 'This feature has been disabled, contact your org admin to resume service.';
    };

    if (model.isLoading) {
      return <ThreeDotsLoader className={styles.loader} />;
    }

    if (model.connection && !(model.connection instanceof PromisedError)) {
      return (
        <>
          <div className={styles.container}>
            <Text className={styles.label} variant={TextSize.XXS} color={TextColor.GREY} upperCase>
              Active connection
            </Text>
            <div className={styles.connection}>
              <div className={styles.headWrapper}>
                <div className={styles.logoWrapper}>
                  <Heading className={styles.title} variant={HeadingVariant.H2} color={HeadingColor.GREY} upperCase>
                    <Icon
                      className={styles.icon}
                      name={IconVariant[IconVariant[model.connection.connectorType.toUpperCase()]]}
                      width={32}
                      height={32}
                    />
                    {model.connectorName}
                  </Heading>
                </div>
              </div>

              <Text className={styles.status} variant={TextSize.XL} color={TextColor.GREY}>
                <span className={cx(styles.indicator, styles.connected)}></span>
                {model.connectorName} is now connected
                {model.isNotionConnected && (
                  <Tooltip
                    tooltipWrapperClassname={styles.tooltipContainer}
                    title="Notion's API doesn't allow changes to already selected synced pages. To add or change your synced pages, you'll need to delete this connection, create a new graph, and then select a different set of pages."
                    align={TooltipAlignment.LEFT}
                    tooltipWidth={310}
                  >
                    <span>
                      {' '}
                      <Icon name={IconVariant.INFO_OUTLINED} />
                    </span>
                  </Tooltip>
                )}
                {!viewOnly && model.canUserSelectFiles && (
                  <>
                    . Select folders and files to begin syncing.{' '}
                    {model.isCredentialsReady ? (
                      <Button
                        className={styles.selectFilesButton}
                        type={ButtonTypes.GRAY}
                        size={SizeTypes.SMALL}
                        onClick={handleOpenFilePicker}
                        content="Select files"
                      />
                    ) : (
                      <DotLoader className={styles.selectFilesLoader} />
                    )}
                  </>
                )}
              </Text>

              <div className={styles.files}>
                {!model.isNotionConnected && (
                  <div>
                    <Text className={styles.label} variant={TextSize.M} color={TextColor.GREY} upperCase>
                      {wordPluralize(model.connectionFolderCount, 'Folder')}
                    </Text>
                    <Text className={styles.counter} color={TextColor.GREY} upperCase>
                      {model.connectionFolderCount}
                    </Text>
                  </div>
                )}

                <div>
                  <Text className={styles.label} variant={TextSize.M} color={TextColor.GREY} upperCase>
                    {wordPluralize(syncStatusModel.previousSync?.succeeded || 0, 'File')}
                  </Text>
                  <Text className={styles.counter} color={TextColor.GREY} upperCase>
                    {syncStatusModel.previousSync?.succeeded || 0}
                  </Text>
                </div>
              </div>

              <div className={styles.syncStatusWrapper}>
                <SyncStatus
                  model={syncStatusModel}
                  isConnected={Boolean(model.credentials)}
                  connectorName={model.connectorName}
                  onFailedFilesClick={onFailedFilesClick}
                />
              </div>
              <div className={cx(styles.controls, { [styles.disabled]: viewOnly })}>
                {model.isNotionConnected ? (
                  <span
                    onClick={() => {
                      if (model.connection && !(model.connection instanceof PromisedError)) {
                        model.updateConnectionFilters(model.connection.id, []);
                      }
                    }}
                  >
                    <Icon className={styles.icon} name={IconVariant.RESYNC} width={18} height={18} />
                    Resync now
                  </span>
                ) : (
                  <Tooltip
                    placement="top"
                    disabled={!isBlockedByBalance || !isBlockedByUsageLimits}
                    tooltipWidth={270}
                    align={TooltipAlignment.LEFT}
                    title={getIsBlockedMessage()}
                  >
                    <div
                      className={cx(styles.topControls, {
                        [styles.disabled]: isBlockedByBalance || isBlockedByUsageLimits,
                      })}
                    >
                      {!model.isConfluenceConnected && (
                        <span onClick={handleOpenFilePicker}>
                          <Icon
                            className={cx(styles.icon, styles.iconPlus)}
                            name={IconVariant.PLUS_CIRCLED}
                            width={20}
                            height={20}
                          />
                          Add more items
                        </span>
                      )}

                      <span
                        onClick={() => {
                          if (model.isConfluenceConnected) {
                            model.confluenceApiModel.setToken(model.credentials?.token);
                          }

                          model.openSyncModal();
                        }}
                      >
                        <Icon
                          className={styles.icon}
                          name={IconVariant.SETTINGS_UNFILLED_GREY}
                          width={18}
                          height={18}
                        />
                        Manage synced {model.isConfluenceConnected ? 'spaces' : 'items'}
                      </span>

                      {!isEmpty(model.connectionSections) && (
                        <span
                          onClick={() => {
                            if (model.connection && !(model.connection instanceof PromisedError)) {
                              model.updateConnectionFilters(model.connection.id, model.connectionSections);
                            }
                          }}
                        >
                          <Icon className={styles.icon} name={IconVariant.RESYNC} width={18} height={18} />
                          Resync now
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}

                <span onClick={onDeleteConnectionClick}>
                  <Icon className={styles.icon} name={IconVariant.BIN} width={18} height={18} />
                  Delete connection
                </span>
              </div>
            </div>
          </div>

          {model.isSyncModalOpen && (
            <SyncKnowledgeGraphModal
              isLoading={model.isConfluenceLoading}
              title={
                model.isConfluenceConnected
                  ? 'Allow Writer to access these Confluence spaces'
                  : `Synced items from ${model.connectorName}`
              }
              description={syncModalDescription}
              documents={model.connectionFileSections}
              folders={model.connectionFolderSections}
              spaces={model.connectionSpaceSections}
              onAddItems={() => {
                model.closeSyncModal();
                handleOpenFilePicker();
              }}
              onClickClose={model.closeSyncModal}
              onClickSubmit={sections => {
                if (model.connection && !(model.connection instanceof PromisedError)) {
                  model.updateConnectionFilters(model.connection.id, sections);
                  model.closeSyncModal();
                }
              }}
            />
          )}
        </>
      );
    }

    return (
      <KnowledgeGraphConnectorSelector
        model={model}
        isBlockedByBalance={isBlockedByBalance}
        isBlockedByUsageLimits={isBlockedByUsageLimits}
        viewOnly={viewOnly}
        isOrgAdmin={isOrgAdmin}
        isAdvancedConnectorsEnabled={model.isAdvancedConnectorsEnabled}
      />
    );
  },
);
