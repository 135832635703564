import { computed, makeObservable, observable, runInAction } from 'mobx';
import qs from 'qs';

import { PaginatedModel, PromisedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize, components } from '@writercolab/network';

import type {
  TKnowledgeGraphFilesQuery,
  TKnowledgeGraphFilesResponse,
  TKnowledgeGraphFilesSortField,
  TKnowledgeGraphFilesSortOrder,
  TKnowledgeGraphFilesStatus,
  TKnowledgeGraphPaginatedArgs,
  TKnowledgeGraphPaginatedExtraArgs,
  TKnowledgeGraphPaginatedQuery,
  TKnowledgeGraphPaginatedResponse,
  TKnowledgeGraphResponse,
  TPaginatedKnowledgeGraphFilesArgs,
  TPaginatedKnowledgeGraphFilesExtraArgs,
  TPaginatedTKnowledgeGraphFilesResponse,
} from '@web/types';

import { encodeFilename, getContentType } from '../utils/mediaFilesUtils';

interface KnowledgeGraphsApiModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId?: number;
}

export const DEFAULT_PAGE_SIZE = 50;

// hardcoded for AI studio case when teamID is not defined
// CP-6151. Increased to 500 for LAUM-466
const GRAPH_LIMIT = 500;
export class KnowledgeGraphsApiModel {
  private $isLoading = observable.box(false);
  private $isGraphByIdLoading = observable.box(false);

  public pagination: PaginatedModel<
    TPaginatedTKnowledgeGraphFilesResponse,
    TKnowledgeGraphFilesResponse,
    TPaginatedKnowledgeGraphFilesArgs,
    TPaginatedKnowledgeGraphFilesExtraArgs
  >;

  constructor(private opts: KnowledgeGraphsApiModelOptions) {
    const urlParams = new URLSearchParams(document.location.search);

    this.pagination = new PaginatedModel<
      TPaginatedTKnowledgeGraphFilesResponse,
      TKnowledgeGraphFilesResponse,
      TPaginatedKnowledgeGraphFilesArgs,
      TPaginatedKnowledgeGraphFilesExtraArgs
    >({
      argsExtra: {
        search: urlParams?.get('search') ?? undefined,
        sortField: (urlParams?.get('sortField') as TKnowledgeGraphFilesSortField) ?? 'name',
        sortOrder: (urlParams?.get('sortOrder') as TKnowledgeGraphFilesSortOrder) ?? 'asc',
        graphId: urlParams?.get('graphId') ?? undefined,
        status: urlParams?.getAll('status') as TKnowledgeGraphFilesStatus[],
      },
      argsDefault: {
        offset: Number(urlParams?.get('graphId')) || 0,
        limit: Number(urlParams?.get('limit')) || DEFAULT_PAGE_SIZE,
      },
      extractMeta: obj => {
        const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

        if (offset >= obj.totalCount) {
          return this.pagination.args;
        }

        return {
          offset,
          limit: DEFAULT_PAGE_SIZE,
        };
      },
      extract: obj => obj.result ?? [],
      load: async ({ args, extra }) => {
        // remove graphId from extra args
        // we don't want to add graphId to the query params
        const { graphId, ...extraArgs } = extra;

        if (!graphId) {
          throw new Error('GraphId is not defined');
        }

        const query: TKnowledgeGraphFilesQuery = {
          ...args,
          ...extraArgs,
        };

        runInAction(() => {
          this.$isLoading.set(true);
        });

        const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/graph/{graphId}', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              graphId,
            },
            query,
          },
          querySerializer: query => qs.stringify(query, { arrayFormat: 'repeat' }),
        });

        runInAction(() => {
          this.$isLoading.set(false);
        });

        return data;
      },
    });

    makeObservable(this, {
      graphs: computed.struct,
      appStudioGraphs: computed.struct,
      isGraphsLoading: computed,
      isAppStudioGraphsLoading: computed,
      graphsFileCount: computed,
      graphsUsage: computed.struct,
      connectors: computed.struct,
      writerConnectors: computed.struct,
      isLoading: computed,
      filesData: computed,
      isGraphByIdLoading: computed,
      isGraphsLimitReached: computed,
    });
  }

  $graphs = new PaginatedModel<
    TKnowledgeGraphPaginatedResponse,
    TKnowledgeGraphResponse,
    TKnowledgeGraphPaginatedArgs,
    TKnowledgeGraphPaginatedExtraArgs
  >({
    argsExtra: {
      search: undefined,
    },
    argsDefault: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
    extractMeta: obj => {
      const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

      if (offset >= obj.totalCount) {
        return this.$graphs.args;
      }

      return {
        offset,
        limit: DEFAULT_PAGE_SIZE,
      };
    },
    extract: obj => obj.result ?? [],
    load: async ({ args, extra }) => {
      if (!this.opts.teamId) {
        throw new Error('TeamId is not defined');
      }

      const query: TKnowledgeGraphPaginatedQuery = {
        ...args,
        ...extra,
        teamId: this.opts.teamId,
      };

      const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/graph', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
          },
          query,
        },
        querySerializer: query => qs.stringify(query, { arrayFormat: 'repeat' }),
      });

      return data;
    },
  });

  $appStudioGraphs = new PaginatedModel<
    TKnowledgeGraphPaginatedResponse,
    TKnowledgeGraphResponse,
    TKnowledgeGraphPaginatedArgs,
    TKnowledgeGraphPaginatedExtraArgs
  >({
    argsExtra: {
      search: undefined,
    },
    argsDefault: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
    extractMeta: obj => {
      const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

      if (offset >= obj.totalCount) {
        return this.$appStudioGraphs.args;
      }

      return {
        offset,
        limit: DEFAULT_PAGE_SIZE,
      };
    },
    extract: obj => obj.result ?? [],
    load: async ({ args, extra }) => {
      const query: TKnowledgeGraphPaginatedQuery = {
        ...args,
        ...extra,
      };

      const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/app-studio/graph', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
          },
          query,
        },
        querySerializer: query => qs.stringify(query, { arrayFormat: 'repeat' }),
      });

      return data;
    },
  });

  $graphsUsage: PromisedModel<components['schemas']['billing_model_UsageItem'] | null> = new PromisedModel({
    name: '$graphsUsage',
    load: async () => {
      // AI Assistant KG page has a graph creditType
      if (this.opts.teamId) {
        const { data } = await this.opts.request.get(
          '/api/billing/organizations/{organizationId}/team/{teamId}/usage/{creditType}',
          {
            params: {
              path: {
                organizationId: this.opts.organizationId,
                teamId: this.opts.teamId,
                creditType: 'graph',
              },
            },
          },
        );

        return data;
      }

      // AI Studio KG page has a AIStudioGraph creditType
      const { data } = await this.opts.request.get('/api/billing/organizations/{organizationId}/usage/{creditType}', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            creditType: 'AIStudioGraph',
          },
        },
      });

      return data;
    },
  });

  $connectors: PromisedModel<components['schemas']['media_dto_BriefConnectorResponse'][]> = new PromisedModel({
    name: '$connectors',
    load: async () => {
      const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/connector', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
          },
        },
      });

      return data.result;
    },
  });

  $writerConnectors: PromisedModel<components['schemas']['media_dto_BriefConnectorResponse'][]> = new PromisedModel({
    name: '$writerConnectors',
    load: async () => {
      const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/connector/writer', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
          },
        },
      });

      return data.result;
    },
  });

  createGraph = async (type: components['schemas']['media_model_SourceType'], name: string, description?: string) => {
    const { data } = await this.opts.request.post('/api/media/organization/{organizationId}/graph', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
      },
      body: {
        teamId: this.opts.teamId,
        source: type,
        name,
        description,
      },
    });

    return data;
  };

  updateGraph = async (id: string, name: string, description?: string) => {
    const { data } = await this.opts.request.put('/api/media/organization/{organizationId}/graph/{knowledgeGraphId}', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
          knowledgeGraphId: id,
        },
      },
      body: {
        name,
        description,
      },
    });

    return data;
  };

  deleteGraph = async (id: string) => {
    const { data } = await this.opts.request.delete(
      '/api/media/organization/{organizationId}/graph/{knowledgeGraphId}',
      {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            knowledgeGraphId: id,
          },
        },
      },
    );

    return data;
  };

  deleteGraphFile = async ({ fileId }: { fileId: string }) => {
    if (!fileId) {
      throw new Error('File is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });

    const { data } = await this.opts.request.delete(
      '/api/media/organization/{organizationId}/knowledge/file/{fileId}',
      {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            fileId,
          },
        },
      },
    );

    runInAction(() => {
      this.$isLoading.set(false);
    });

    return data;
  };

  downloadGraphFile = async ({ fileId }: { fileId: string }) => {
    if (!fileId) {
      throw new Error('FileId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });

    const { data } = await this.opts.request.get(
      '/api/media/organization/{organizationId}/knowledge/file/{fileId}/knowledge/download',
      {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            fileId,
          },
        },
        parseAs: 'blob',
      },
    );

    runInAction(() => {
      this.$isLoading.set(false);
    });

    return new Blob([data]);
  };

  getGraphFileSignedUrl = async ({ fileId }: { fileId: string }) => {
    if (!fileId) {
      throw new Error('FileId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });

    const { data } = await this.opts.request.get(
      '/api/media/organization/{organizationId}/knowledge/file/{fileId}/url',
      {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            fileId,
          },
        },
      },
    );

    runInAction(() => {
      this.$isLoading.set(false);
    });

    return data.url;
  };

  getGraphById = async ({ id }: { id: string }) => {
    if (!id) {
      throw new Error('Graph Id is not defined');
    }

    this.$isGraphByIdLoading.set(true);

    const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/graph/{graphId}/info', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
          graphId: id,
        },
      },
      querySerializer: query => qs.stringify(query, { arrayFormat: 'repeat' }),
    });

    this.$isGraphByIdLoading.set(false);

    return data;
  };

  uploadGraphFile = async ({
    file,
    params,
  }: {
    file: File;
    params: {
      access?: 'private' | 'shared';
      templateId?: string;
      knowledgeGraphId?: string;
      teamId?: number;
      waitParsing?: boolean;
    };
  }) => {
    if (!file) {
      throw new Error('File is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });
    const { data } = await this.opts.request.post('/api/media/organization/{organizationId}/knowledge/file/upload', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
        header: {
          'Content-Type': getContentType(file),
          'Content-Disposition': `attachment; filename*=utf-8''${encodeFilename(file.name)}`,
          'Content-Length': file.size,
        },
        query: {
          waitParsing: false,
          ...params,
        },
      },
      body: file as any,
      bodySerializer: (body: any) => body,
    });

    runInAction(() => {
      this.$isLoading.set(false);
    });

    return data.knowledgeFile;
  };

  retryFiles = async ({ fileIds, graphId }: { fileIds?: [string, ...string[]]; graphId: string }) => {
    if (!graphId) {
      throw new Error('GraphId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });

    const { data } = await this.opts.request.post('/api/media/organization/{organizationId}/graph/{graphId}/retry', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
          graphId,
        },
      },
      body: {
        fileIds,
      },
    });

    runInAction(() => {
      this.$isLoading.set(false);
    });

    return data;
  };

  getFileStats = async (
    graphId: string,
    params?: {
      teamId?: number;
    },
  ): Promise<components['schemas']['media_model_FileStats']> => {
    if (!graphId) {
      throw new Error('GraphId is not defined');
    }

    const { data } = await this.opts.request.get(
      '/api/media/organization/{organizationId}/graph/{graphId}/file-stats',
      {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            graphId,
          },
          query: params,
        },
      },
    );

    return data;
  };

  getConnectorSyncStats = async (
    graphId: string,
  ): Promise<components['schemas']['media_dto_ConnectorSyncStatsResponse']> => {
    if (!graphId) {
      throw new Error('GraphId is not defined');
    }

    const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/graph/{graphId}/stats', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
          graphId,
        },
      },
    });

    return data;
  };

  reloadGraphs = async () => {
    this.$graphs.reload();

    return this.$graphs.promise;
  };

  reloadAppStudioGraphs = async () => {
    this.$appStudioGraphs.reload();

    return this.$appStudioGraphs.promise;
  };

  get graphs(): TKnowledgeGraphResponse[] {
    return this.$graphs.value || [];
  }

  get appStudioGraphs(): TKnowledgeGraphResponse[] {
    return this.$appStudioGraphs.value || [];
  }

  get hasNextGraphs() {
    return this.$graphs.hasNext;
  }

  loadMoreGraphs = async () => {
    await this.$graphs.next();
  };

  updateGraphsExtraArgs = (extraArgs: TKnowledgeGraphPaginatedExtraArgs) => {
    this.$graphs.setExtra(extraArgs);
  };

  get hasAppStudioGraphs() {
    return this.$appStudioGraphs.hasNext;
  }

  loadMoreAppStudioGraphs = async () => {
    await this.$appStudioGraphs.next();
  };

  updateAppStudioGraphsExtraArgs = (extraArgs: TKnowledgeGraphPaginatedExtraArgs) => {
    this.$appStudioGraphs.setExtra(extraArgs);
  };

  get isGraphsLoading() {
    return this.$graphs.status === 'pending';
  }

  get isAppStudioGraphsLoading() {
    return this.$appStudioGraphs.status === 'pending';
  }

  get graphsFileCount(): number {
    return this.pagination.rawValue?.totalCount || 0;
  }

  get graphsLimit(): number {
    return this.opts.teamId ? this.$graphs.rawValue?.totalCount || 0 : this.$appStudioGraphs.rawValue?.totalCount || 0;
  }

  get graphsUsage(): components['schemas']['billing_model_UsageItem'] {
    return (
      this.$graphsUsage.value || {
        limit: GRAPH_LIMIT,
        value: 0,
      }
    );
  }

  get isGraphsLimitReached(): boolean {
    return this.graphsLimit >= this.graphsUsage.limit;
  }

  get connectors(): components['schemas']['media_dto_BriefConnectorResponse'][] {
    return this.$connectors.value?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  }

  get writerConnectors(): components['schemas']['media_dto_BriefConnectorResponse'][] {
    return this.$writerConnectors.value?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  }

  get filesData(): TKnowledgeGraphFilesResponse[] | undefined {
    return this.pagination.value;
  }

  get isPaginationLoading() {
    return this.pagination.status === 'pending';
  }

  get isLoading() {
    return this.$isLoading.get();
  }

  get isGraphByIdLoading() {
    return this.$isGraphByIdLoading.get();
  }

  refreshPagination = async () => {
    await this.pagination.reload();
    await this.pagination.promise;
  };
}
