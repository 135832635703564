import type { ReactElement } from 'react';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

import type { BaseComponentsProps, IDocumentShareStatus, IDocumentShareStatusPayload } from '@writercolab/common-utils';
import { BillingProduct, ContentEditorPageMode, TUserStatusFilter, isMacOs } from '@writercolab/common-utils';
import {
  ButtonTypes,
  Dropdown,
  EditableTitle,
  Icon,
  IconVariant,
  ItemsTypes,
  LinkText,
  MoreButton,
  Tag,
  TagColor,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import type { IAdminsOptionsList, IDocumentInfoPanelButtonState } from '@web/types';
import { HIGHLIGHT_AVAILABLE_MAX_WORDS_COUNT, HIGHLIGHT_AVAILABLE_MIN_WORDS_COUNT } from '@web/types';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router';
import config from 'utils/dynamicConfig';
import { getLogger } from 'utils/logger';

import { useDocumentsContext } from '../../../context/documentsContext';
import { ROUTE } from '../../../services/config/routes';
import { DEFAULT_PAGINATION_LIMIT } from '../../../services/request/_types';
import { findUserInTeam } from '../../../services/request/user';
import { useAppState } from '../../../state';
import { openContactSalesPage } from '../../../utils/navigationUtils';
import { mapUserNameOption } from '../../../utils/teamUtils';
import { highlightMessage } from '../../organisms/DocumentHighlights/constants';
import ClaimDetectionButton from '../ClaimDetectionButton';
import { ShareDocument } from '../ShareDocument';
import DocumentEditInfo from './parts/DocumentEditInfo';
// TECHDEBT: WA-2647  Fix cycle dependencies within DocumentInfoPanel molecule
// eslint-disable-next-line import/no-cycle
import ToolButton from './parts/ToolButton';

import styles from './styles.module.css';

export enum DocumentInfoPanelTooltipSize {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
}

interface IDocumentInfoButtonState {
  tooltipText: string | ReactElement;
  tooltipSize?: DocumentInfoPanelTooltipSize;
  enabled: boolean;
}

const LOG = getLogger('DocumentInfoPanel');

interface IDocumentInfoPanelProps extends BaseComponentsProps {
  documentInfoPanelCoWriteButtonState: IDocumentInfoPanelButtonState;
  hideDeleteDocOption?: boolean;
  hideShareDocumentOption?: boolean;
  claimsIsOn?: boolean;
  claimDetectionLoading?: boolean;
  onDocumentDownload: () => void;
  onClickClaimDetection: () => void;
  onRefreshClaim: () => void;
  claimsCount: number;
  onClickHighlights: () => void;
  onClickAutoWrite: () => void;
  onClickMagicLinks: () => void;
  onCoWriteLibraryClick: () => void;
  contentEditorPageMode: ContentEditorPageMode;
  currentContentWords: number;
  highlightsLoading?: boolean;
  autoWriteLoading?: boolean;
  autoWriteDisabled?: boolean;
  magicLinksLoading?: boolean;
  onOpenVersionHistory: () => void;
  onCopyDocumentLink: () => void;
}

const initialDocumentShareState = {
  showPopup: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  callback: (shareStatus: IDocumentShareStatus) => {},
};

export const DocumentInfoPanel: React.FC<IDocumentInfoPanelProps> = observer(
  ({
    hideDeleteDocOption,
    hideShareDocumentOption,
    claimsIsOn,
    claimDetectionLoading,
    onDocumentDownload,
    onClickClaimDetection,
    claimsCount,
    onClickHighlights,
    onClickMagicLinks,
    onClickAutoWrite,
    onCoWriteLibraryClick,
    highlightsLoading,
    contentEditorPageMode,
    currentContentWords,
    autoWriteLoading,
    autoWriteDisabled,
    magicLinksLoading,
    documentInfoPanelCoWriteButtonState,
    onOpenVersionHistory,
    onCopyDocumentLink,
  }) => {
    const { currentDocData, updateDocumentTitle, invokeDeleteDoc, changeShareStatus, documentShareStatus } =
      useDocumentsContext();
    const { appModel } = useAppState();

    const subscriptionModel = appModel.assistantSubscription;

    const [documentShare, setDocumentShare] = useState(initialDocumentShareState);
    const [highlightsToolTipText, setHighlightsToolTipText] = useState<IDocumentInfoButtonState>({
      tooltipText: 'Highlights',
      tooltipSize: DocumentInfoPanelTooltipSize.SMALL,
      enabled: true,
    });
    const [autoWriteToolTipText, setAutoWriteToolTipText] = useState<IDocumentInfoButtonState>({
      tooltipText: 'Keep writing',
      tooltipSize: DocumentInfoPanelTooltipSize.SMALL,
      enabled: true,
    });
    const [coWriteButtonState, setCoWriterButtonState] = useState<IDocumentInfoPanelButtonState>(
      documentInfoPanelCoWriteButtonState,
    );

    const [magicLinksButtonState, setMagicLinksButtonState] = useState<IDocumentInfoPanelButtonState>({
      type: ButtonTypes.GRAY,
      content: 'Magic links',
    } as IDocumentInfoPanelButtonState);

    useEffect(() => {
      if (contentEditorPageMode === ContentEditorPageMode.MAGIC_LINKS) {
        setMagicLinksButtonState({
          ...magicLinksButtonState,
          type: ButtonTypes.GREEN,
        });
      } else {
        setMagicLinksButtonState({
          ...magicLinksButtonState,
          type: ButtonTypes.GRAY,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentEditorPageMode]);

    useEffect(() => {
      if ([ContentEditorPageMode.CO_WRITE, ContentEditorPageMode.BLOG].includes(contentEditorPageMode)) {
        setCoWriterButtonState({
          ...coWriteButtonState,
          type: ButtonTypes.PINK,
        });
      } else {
        setCoWriterButtonState({
          ...coWriteButtonState,
          type: ButtonTypes.GRAY,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentEditorPageMode]);
    useEffect(() => {
      setCoWriterButtonState(documentInfoPanelCoWriteButtonState);
    }, [documentInfoPanelCoWriteButtonState]);

    const showShareSetting = useCallback(() => {
      setDocumentShare({
        showPopup: true,
        callback: (shareStatus: IDocumentShareStatusPayload) => {
          changeShareStatus({
            access: shareStatus.access,
            userIds: shareStatus.userIds,
          });
        },
      });
    }, [changeShareStatus]);

    const userIsDocumentOwner = appModel.user?.id === currentDocData?.createdUserId;
    const isShareDocumentAvailable = userIsDocumentOwner && !hideShareDocumentOption;

    const dropdownButtonOptions: any[] = [
      isShareDocumentAvailable && {
        name: 'Share settings',
        id: 'share',
        icon: <Icon name={IconVariant.TEAM} />,
        action: showShareSetting,
      },
      {
        name: 'Download',
        id: 'download',
        icon: <Icon name={IconVariant.DOWNLOAD} />,
        action: onDocumentDownload,
      },
      !hideDeleteDocOption && {
        name: 'Delete',
        id: 'delete',
        icon: <Icon name={IconVariant.TRASH} />,
        warning: true,
        action: () =>
          currentDocData && invokeDeleteDoc(currentDocData.team.id, currentDocData.id, currentDocData.title),
      },
      appModel.featureFlags.get('documentHistoryAvailable', false) && {
        name: 'Version history',
        id: 'history',
        icon: <Icon name={IconVariant.HISTORY} />,
        action: onOpenVersionHistory,
      },
    ].filter(Boolean);

    const onDocumentTitleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
      updateDocumentTitle(e.currentTarget.textContent || '');
    };

    const onInputChangeMembers = useCallback(
      async (inputValue?: string) => {
        try {
          if (!currentDocData) {
            return [];
          }

          const { result } = await findUserInTeam(currentDocData!.organizationId, currentDocData!.team.id, {
            search: inputValue,
            offset: 0,
            limit: DEFAULT_PAGINATION_LIMIT,
            userStatus: [TUserStatusFilter.enum.active],
          });

          return result
            .filter(user => user.user.id !== currentDocData?.createdUserId)
            .map(({ user }) => {
              const { name, subText } = mapUserNameOption(user.firstName, user.lastName, user.email);

              return {
                id: user.id,
                name,
                subText,
              } as IAdminsOptionsList;
            });
        } catch (error) {
          LOG.error('Error finding user in team:', error);

          return [];
        }
      },
      [currentDocData],
    );

    useEffect(() => {
      if (subscriptionModel.isFree || !subscriptionModel.access?.autoWriteButton) {
        setAutoWriteToolTipText({
          tooltipText: (
            <div>
              <Text variant={TextSize.M} bold inline color={TextColor.WHITE}>
                Keep writing is available on Starter plan.
              </Text>{' '}
              <Link to={ROUTE.toBilling(currentDocData!.organizationId, BillingProduct.STARTER)}>
                <Text variant={TextSize.M} bold underline inline color={TextColor.WHITE}>
                  Upgrade now
                </Text>
              </Link>{' '}
              <Text variant={TextSize.M} bold inline color={TextColor.WHITE}>
                to enable.
              </Text>
            </div>
          ),
          tooltipSize: DocumentInfoPanelTooltipSize.LARGE,
          enabled: false,
        });
      } else if (subscriptionModel.limits?.coWrite?.exceeded) {
        setAutoWriteToolTipText({
          tooltipText: (
            <div>
              <Text variant={TextSize.M} color={TextColor.WHITE} bold>
                Keep writing is not available,
              </Text>
              <Text variant={TextSize.M} color={TextColor.WHITE} bold>
                because you’ve reached your limit
              </Text>
              <Text variant={TextSize.M} color={TextColor.WHITE} bold>
                of {subscriptionModel.limits?.coWrite.limit} words generated
              </Text>
              <Text variant={TextSize.M} color={TextColor.WHITE} bold className={styles.contactLink}>
                To raise your limit, <LinkText onClick={openContactSalesPage}>contact sales</LinkText>.
              </Text>
            </div>
          ),
          tooltipSize: DocumentInfoPanelTooltipSize.XLARGE,
          enabled: false,
        });
      } else if (autoWriteDisabled) {
        setAutoWriteToolTipText({
          tooltipText: 'Keep writing is only available for docs with >5 words',
          tooltipSize: DocumentInfoPanelTooltipSize.MEDIUM,
          enabled: false,
        });
      } else {
        setAutoWriteToolTipText({
          tooltipText: (
            <div>
              <Text variant={TextSize.M} bold color={TextColor.WHITE}>
                Keep writing
              </Text>
              <Text variant={TextSize.M} color={TextColor.WHITE} className={styles.autoWriteTooltip}>
                {isMacOs() ? 'cmd + enter' : 'ctrl + enter'}
              </Text>
              <Tag color={TagColor.LIGHT_GREEN} className={styles.betaTag}>
                <Text caps>Beta</Text>
              </Tag>
            </div>
          ),
          tooltipSize: DocumentInfoPanelTooltipSize.MEDIUM,
          enabled: true,
        });
      }
    }, [
      autoWriteDisabled,
      currentDocData,
      subscriptionModel.access?.autoWriteButton,
      subscriptionModel.isFree,
      subscriptionModel.limits?.coWrite,
    ]);

    useEffect(() => {
      if (currentContentWords === 0) {
        if (subscriptionModel.isEnterprise || subscriptionModel.isTeam) {
          setHighlightsToolTipText({
            tooltipText: highlightMessage.AVAILABLE_MIN_WORDS_COUNT_SIDEBAR,
            tooltipSize: DocumentInfoPanelTooltipSize.MEDIUM,
            enabled: false,
          });
        } else {
          setHighlightsToolTipText({
            tooltipText: 'Highlights',
            tooltipSize: DocumentInfoPanelTooltipSize.SMALL,
            enabled: true,
          });
        }
      } else if (subscriptionModel.isEnterprise || subscriptionModel.isTeam) {
        if (currentContentWords <= HIGHLIGHT_AVAILABLE_MIN_WORDS_COUNT) {
          setHighlightsToolTipText({
            tooltipText: highlightMessage.AVAILABLE_MIN_WORDS_COUNT_SIDEBAR,
            tooltipSize: DocumentInfoPanelTooltipSize.MEDIUM,
            enabled: false,
          });
        } else if (currentContentWords >= HIGHLIGHT_AVAILABLE_MAX_WORDS_COUNT) {
          setHighlightsToolTipText({
            tooltipText: highlightMessage.AVAILABLE_MAX_WORDS_COUNT_SIDEBAR,
            tooltipSize: DocumentInfoPanelTooltipSize.MEDIUM,
            enabled: false,
          });
        } else {
          setHighlightsToolTipText({
            tooltipText: 'Highlights',
            tooltipSize: DocumentInfoPanelTooltipSize.SMALL,
            enabled: true,
          });
        }
      }
    }, [currentDocData?.id, currentContentWords, subscriptionModel.isEnterprise, subscriptionModel.isTeam]);

    return (
      <div className={styles.container}>
        <div className={styles.containerDocInfo}>
          <div className={styles.containerTitleWrapper}>
            <div className={styles.containerTitle}>
              <div className={styles.titleEditableWrapper}>
                <EditableTitle
                  title={currentDocData?.title}
                  onBlur={onDocumentTitleBlur}
                  placeholder="Untitled document"
                />
              </div>
              <Dropdown
                containerClassName={styles.titleDropdownOptions}
                trigger={<MoreButton className={styles.moreButtonTrigger} />}
                itemsType={ItemsTypes.ACTION}
                options={dropdownButtonOptions}
                onPrimaryOptionClickAction={_ => _}
                description={currentDocData && <DocumentEditInfo documentData={currentDocData} />}
              />
            </div>
          </div>
          <div className={styles.containerTools}>
            <ToolButton
              id="writer-co-write-library-button"
              onClick={onCoWriteLibraryClick}
              iconVariant={IconVariant.WAND}
              buttonType={coWriteButtonState.type}
              buttonContent={coWriteButtonState.content}
            />
            {config.SINGLE_TENANCY_CONFIG?.disableMagicLinks ? null : (
              <ToolButton
                id="writer-magic-links-button"
                buttonRound
                isLoading={!!magicLinksLoading}
                onClick={onClickMagicLinks}
                iconVariant={IconVariant.LINK}
                buttonType={magicLinksButtonState.type}
                tooltipText={magicLinksButtonState.content}
              />
            )}
            <ToolButton
              id="writer-highlights-button"
              buttonRound
              onClick={onClickHighlights}
              buttonType={
                contentEditorPageMode === ContentEditorPageMode.HIGHLIGHTS ? ButtonTypes.GREEN : ButtonTypes.GRAY
              }
              className={cx({
                [styles.toolButtonDisabled]: !highlightsToolTipText.enabled,
              })}
              disabled={!highlightsToolTipText.enabled}
              iconVariant={IconVariant.HIGHLIGHTS}
              tooltipText={highlightsToolTipText.tooltipText}
              tooltipSize={highlightsToolTipText.tooltipSize}
              isLoading={!!highlightsLoading}
            />
            <ClaimDetectionButton
              id="writer-claim-detection-button"
              onToggleClaim={onClickClaimDetection}
              count={claimsCount}
              isExpanded={claimsIsOn}
              isLoading={claimDetectionLoading}
            />
            <ToolButton
              id="writer-auto-write-button"
              buttonRound
              buttonType={ButtonTypes.MAUVE}
              onClick={() => onClickAutoWrite()}
              iconVariant={
                autoWriteToolTipText.enabled ? IconVariant.LIGHTNING_UNION_MAUVE : IconVariant.LIGHTNING_UNION
              }
              disabled={!autoWriteToolTipText.enabled}
              tooltipText={autoWriteToolTipText.tooltipText}
              tooltipSize={autoWriteToolTipText.tooltipSize}
              isLoading={!!autoWriteLoading}
            />
          </div>
        </div>
        {documentShare.showPopup && (
          <ShareDocument
            organizationId={currentDocData!.organizationId}
            documentId={currentDocData!.id}
            team={currentDocData!.team}
            documentShareStatus={documentShareStatus}
            onInputChangeMembers={onInputChangeMembers}
            onClose={() => setDocumentShare(initialDocumentShareState)}
            onCopyLink={onCopyDocumentLink}
            isOpen={documentShare.showPopup}
            onSubmit={documentShare.callback}
          />
        )}
      </div>
    );
  },
);
