import { useMemo } from 'react';

import { Button, SimpleTooltip } from '@writercolab/fe.wds';
import { ReactiveQueue } from '@writercolab/mobx';
import { NotificationQueueItemType, TNotificationQueueItem } from '@writercolab/types';
import { ChatModes } from '@writercolab/ui-chat-apps';
import { FilterOptions, type IFilterOptionsFilter } from '@writercolab/ui-molecules';

import { ASK_WRITER_APP_ID } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { ChevronDown } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { ROUTE } from 'services/config/routes';
import requestService from 'services/request/requestService';
import { useAppState } from 'state';

import { HeaderActionsUiModel } from '../HeaderActionsModel.ui';

import styles from './HeaderActionButtons.module.css';

interface IBtnProps {
  organizationId: number | undefined;
  teamId: number | undefined;
  notificationQueue: ReactiveQueue<TNotificationQueueItem>;
}

export const NewSessionBtn = observer(({ organizationId, teamId, notificationQueue }: IBtnProps) => {
  const {
    appModel: { analyticsService },
  } = useAppState();
  const navigate = useNavigate();

  const model = useMemo(() => {
    if (!organizationId || !teamId) {
      return null;
    }

    return new HeaderActionsUiModel({
      request: requestService.api,
      organizationId,
      teamId,
    });
  }, [organizationId, teamId]);

  const handleOnChange = async (app: IFilterOptionsFilter) => {
    const session = await model?.chatSessionsApi.createSession(ChatModes.enum.chat, app.id);

    if (!session) {
      notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Could not create a new session',
      });

      return;
    }

    analyticsService.track(AnalyticsActivity.newSessionButtonClicked, {
      origin: 'my_work',
      session_id: String(session.id),
    });

    navigate(ROUTE.toChatApp(organizationId, teamId, session?.applicationId, session?.id), {
      state: {
        backTo: ROUTE.toSessions(`${organizationId}`, `${teamId}`),
      },
    });
  };

  const handleAppsSearch = (search: string) => {
    model?.updateAppsExtraArgs({ search });
  };

  const handleAppsClearSearch = () => {
    model?.updateAppsExtraArgs({ search: '' });
  };

  const renderOptionNode = (app: IFilterOptionsFilter) => <p className="text-base text-gray-500">{app.name}</p>;

  const showAskWriter = model?.assistantSubscriptionAccess?.askWriter;

  const formattedApps =
    model?.applications
      ?.map(app => ({ id: app.id, name: app.name, isSelected: false }))
      .filter(app => {
        if (!showAskWriter && app.id === ASK_WRITER_APP_ID) {
          return false;
        }

        return true;
      }) || [];

  return (
    <FilterOptions
      isSearchable
      isSingleSelect
      isAutoClose
      isEnableInfiniteScroll
      menuWidth="310px"
      placement="left"
      menuClassName={styles.sessionsDropdown}
      renderTrigger={({ onClick }) => (
        <SimpleTooltip
          trigger={
            <span>
              <Button rightIcon={<ChevronDown />} onClick={onClick} disabled={formattedApps.length === 0}>
                New session
              </Button>
            </span>
          }
          disabled={formattedApps.length !== 0}
        >
          There are no chat agents available for your team.
        </SimpleTooltip>
      )}
      renderOptionNode={renderOptionNode}
      onLoadMore={model?.loadMoreApplications}
      isLoading={model?.isLoading}
      hasNextPage={model?.hasMoreApplications}
      onChange={handleOnChange}
      options={formattedApps}
      onSearch={handleAppsSearch}
      onResetSearch={handleAppsClearSearch}
    />
  );
});
