import { useCallback, useMemo, useState } from 'react';

import {
  IconsShortcutsAskWriter,
  IconsShortcutsBlog,
  IconsShortcutsImageAnalyzer,
  IconsShortcutsRecaps,
} from '@writercolab/assets';
import { noop } from '@writercolab/common-utils';
import { SimpleTooltip } from '@writercolab/fe.wds';
import { BillingProduct, EApplicationType } from '@writercolab/types';
import { Icon, IconVariant } from '@writercolab/ui-atoms';
import type { IFilterOptionsFilter } from '@writercolab/ui-molecules';
import { FilterOptions } from '@writercolab/ui-molecules';

import type { IAppShortcutButtonProps } from 'components/molecules/AppShortcutButton';
import { AppShortcutButton } from 'components/molecules/AppShortcutButton';
import EllipsisTooltip from 'components/molecules/EllipsisTooltip/EllipsisTooltip';

import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToWindowEdges, snapCenterToCursor } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  ASK_WRITER_APP_ID,
  BLOG_BUILDER_APP_ID,
  IMAGE_ANALYZER_APP_ID,
  RECAPS_APP_ID,
  TBriefApplication,
} from '@web/types';
import { cn } from '@web/utils';
import { AnalyticsActivity } from 'constants/analytics';
import { Plus } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { getAppIcon } from 'utils/applications';
import { goToBillingNewTab } from 'utils/navigationUtils';

import { SHORTCUTS_LIMIT, type ShortcutsHomeWidgetModelUi } from './ShortcutsHomeWidgetModel.ui';

import styles from './ShortcutsHomeWidget.module.css';

interface IShortcutsHomeWidgetProps {
  model: ShortcutsHomeWidgetModelUi;
  onAddUserApp?: (appId: string) => void;
  onRemoveUserApp?: (appId: string) => void;
  onOpenApp: (app: Pick<TBriefApplication, 'id' | 'type' | 'access' | 'name'>) => void;
}

type CustomShortcut = { id: string; component?: React.ReactNode };
type Shortcut = CustomShortcut | ShortcutsHomeWidgetModelUi['apps'][number];

const renderAppOptionNode = (app: IFilterOptionsFilter) => (
  <div className={styles.appFilterOption}>
    <EllipsisTooltip text={app.name} textClassName={styles.appName} delay={500} tooltipClassName="z-10000" />
    {app.description && (
      <EllipsisTooltip
        text={app.description}
        textClassName={cn(styles.appDescription, 'text-sm')}
        delay={500}
        tooltipClassName="z-10000"
      />
    )}
  </div>
);

export const ShortcutsHomeWidget = observer<IShortcutsHomeWidgetProps>(
  ({ model, onOpenApp, onRemoveUserApp, onAddUserApp }) => {
    const [activeId, setActiveId] = useState<string | null>(null);

    const handleClickBilling = useCallback(() => {
      if (model.organizationId) {
        goToBillingNewTab(model.organizationId, BillingProduct.STARTER);
      }
    }, [model.organizationId]);

    const handleAppsFilterSearch = (value: string) => model.searchAppsPaginated(value);
    const handleAppsFilterSearchClear = () => model.searchAppsPaginated('');
    const onRemoveApp = useCallback(
      (appId: string) => {
        if (model.isAdminEdit) {
          model.removeEditableShortcutId(appId);
        } else {
          onRemoveUserApp?.(appId);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [model.isAdminEdit, onRemoveUserApp],
    );

    const onAddApp = useCallback(
      (appId: string) => {
        if (model.isAdminEdit) {
          model.addEditableShortcutId(appId);
        } else {
          onAddUserApp?.(appId);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [model.isAdminEdit, onAddUserApp],
    );

    const handleOpenApp = useCallback(
      (app: Pick<TBriefApplication, 'id' | 'type' | 'access' | 'name'>) => {
        if (model.isAdminEdit) return;

        onOpenApp(app);
      },
      [model.isAdminEdit, onOpenApp],
    );

    const customShortcuts: CustomShortcut[] = useMemo(
      () => [
        {
          id: ASK_WRITER_APP_ID,
          component: (
            <SortableItem
              key={ASK_WRITER_APP_ID}
              id={ASK_WRITER_APP_ID}
              disabled={!model.access?.askWriter}
              disableInteraction={Boolean(activeId)}
              closeIconClassName={cn({ [styles.visible]: !activeId && model.isAdminEdit })}
              text="Ask Writer"
              canClose={!model.lockedShortcutIds.includes(ASK_WRITER_APP_ID)}
              className={cn({ [styles.grab]: model.isAdminEdit })}
              contentClassName={cn({ [styles.grab]: model.isAdminEdit })}
              tooltipText={
                <p className={cn(styles.tooltip, 'font-bold text-sm text-white')}>
                  {!model.isFree ? (
                    <>
                      Ask Writer is available only on Starter plan. Upgrade to{' '}
                      <span onClick={handleClickBilling}>Writer Starter</span> to get access.
                    </>
                  ) : (
                    'Ask Writer is locked for your organization. Contact your org admin for more details.'
                  )}
                </p>
              }
              icon={<IconsShortcutsAskWriter width="40px" height="40px" />}
              onClick={() =>
                handleOpenApp({
                  id: ASK_WRITER_APP_ID,
                  type: EApplicationType.enum.chat,
                  name: 'Ask Writer',
                  access: 'public',
                })
              }
              onClose={() => onRemoveApp(ASK_WRITER_APP_ID)}
            />
          ),
        },
        {
          id: BLOG_BUILDER_APP_ID,
          component: (
            <SortableItem
              key={BLOG_BUILDER_APP_ID}
              id={BLOG_BUILDER_APP_ID}
              disabled={!model.access?.seoBlogBuilder}
              text="Blog builder"
              closeIconClassName={cn({ [styles.visible]: !activeId && model.isAdminEdit })}
              canClose={!model.lockedShortcutIds.includes(BLOG_BUILDER_APP_ID)}
              disableInteraction={Boolean(activeId)}
              className={cn({ [styles.grab]: model.isAdminEdit })}
              contentClassName={cn({ [styles.grab]: model.isAdminEdit })}
              tooltipText={
                <p className={cn(styles.tooltip, 'font-bold text-sm text-white')}>
                  {model.isFree ? (
                    <>
                      Blog builder is available only on Team plans. Upgrade to{' '}
                      <span onClick={handleClickBilling}>Writer Team</span> to get access.
                    </>
                  ) : (
                    'Blog builder is locked for your organization. Contact your org admin for more details.'
                  )}
                </p>
              }
              icon={<IconsShortcutsBlog width="40px" height="40px" />}
              onClick={() =>
                handleOpenApp({
                  id: BLOG_BUILDER_APP_ID,
                  type: EApplicationType.enum.generation,
                  name: 'Blog builder',
                  access: 'public',
                })
              }
              onClose={() => onRemoveApp(BLOG_BUILDER_APP_ID)}
            />
          ),
        },
        {
          id: RECAPS_APP_ID,
          component: (
            <SortableItem
              key={RECAPS_APP_ID}
              id={RECAPS_APP_ID}
              disabled={!model.access?.eventTakeaways}
              text="Recaps builder"
              closeIconClassName={cn({ [styles.visible]: !activeId && model.isAdminEdit })}
              className={cn({ [styles.grab]: model.isAdminEdit })}
              contentClassName={cn({ [styles.grab]: model.isAdminEdit })}
              canClose={!model.lockedShortcutIds.includes(RECAPS_APP_ID)}
              disableInteraction={Boolean(activeId)}
              tooltipText={
                <p className={cn(styles.tooltip, 'font-bold text-sm text-white')}>
                  {model.isFree ? (
                    <>
                      Recaps are available only on Team plans. Upgrade to{' '}
                      <span onClick={handleClickBilling}>Writer Team</span> to get access.
                    </>
                  ) : (
                    'Recaps are locked for your organization. Contact your org admin for more details.'
                  )}
                </p>
              }
              icon={<IconsShortcutsRecaps width="40px" height="40px" />}
              onClick={() =>
                handleOpenApp({
                  id: RECAPS_APP_ID,
                  type: EApplicationType.enum.generation,
                  name: 'Recaps builder',
                  access: 'public',
                })
              }
              onClose={() => onRemoveApp(RECAPS_APP_ID)}
            />
          ),
        },
        {
          id: IMAGE_ANALYZER_APP_ID,
          component: (
            <SortableItem
              key={IMAGE_ANALYZER_APP_ID}
              id={IMAGE_ANALYZER_APP_ID}
              disableInteraction={Boolean(activeId)}
              canClose={!model.lockedShortcutIds.includes(IMAGE_ANALYZER_APP_ID)}
              text="Image analyzer"
              closeIconClassName={cn({ [styles.visible]: !activeId && model.isAdminEdit })}
              className={cn({ [styles.grab]: model.isAdminEdit })}
              contentClassName={cn({ [styles.grab]: model.isAdminEdit })}
              icon={<IconsShortcutsImageAnalyzer width="40px" height="40px" />}
              onClick={() =>
                handleOpenApp({
                  id: IMAGE_ANALYZER_APP_ID,
                  type: EApplicationType.enum.generation,
                  name: 'Image analyzer',
                  access: 'public',
                })
              }
              onClose={() => onRemoveApp(IMAGE_ANALYZER_APP_ID)}
            />
          ),
        },
      ],
      [
        model.access,
        model.isAdminEdit,
        model.lockedShortcutIds,
        model.isFree,
        onRemoveApp,
        handleClickBilling,
        activeId,
        handleOpenApp,
      ],
    );

    const onDragEnd = ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over.id) {
        const oldIndex = model.shortcutIds.findIndex(item => item === active.id);
        const newIndex = model.shortcutIds.findIndex(item => item === over.id);

        model.replaceEditableShortcutIds(arrayMove(model.shortcutIds, oldIndex, newIndex));
        model.analyticsService.track(AnalyticsActivity.appShortcutReordered, { app_id: active.id as string });
      }

      setActiveId(null);
    };

    const onDragCancel = () => {
      setActiveId(null);
    };

    const onDragStart = ({ active }: DragStartEvent) => {
      setActiveId(active.id as string);
    };

    const draggedShortcut: Shortcut | null | undefined = useMemo(() => {
      if (!activeId) return null;

      const customShortcut = customShortcuts.find(shortcut => shortcut.id === activeId);
      const shortcut = model.apps.find(app => app.id === activeId);

      return customShortcut || shortcut;
    }, [activeId, customShortcuts, model.apps]);

    const sensors = useSensors(
      useSensor(MouseSensor, {
        activationConstraint: {
          distance: 3,
        },
      }),
    );

    return (
      <DndContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragCancel={onDragCancel}
        sensors={sensors}
        modifiers={[restrictToWindowEdges, snapCenterToCursor]}
        autoScroll={false}
      >
        <SortableContext items={model.shortcutIds} strategy={rectSortingStrategy} disabled={!model.isAdminEdit}>
          <div className={styles.container}>
            <p className={cn(styles.title, 'font-medium text-sm')}>
              {model.isAdminEdit ? 'Default shortcuts' : 'shortcuts'}
            </p>

            <div className={cn(styles.shortcuts, { [styles.dragging]: model.isAdminEdit })}>
              {model.shortcutIds.map(id => {
                const customShortcut = customShortcuts.find(shortcut => shortcut.id === id);
                const shortcut = model.apps.find(app => app.id === id);

                if (customShortcut) {
                  return customShortcut.component;
                }

                if (shortcut) {
                  return (
                    <SortableItem
                      key={shortcut.id}
                      id={shortcut.id}
                      text={shortcut.name}
                      closeIconClassName={cn({ [styles.visible]: !activeId && model.isAdminEdit })}
                      className={cn({ [styles.grab]: model.isAdminEdit })}
                      contentClassName={cn({ [styles.grab]: model.isAdminEdit })}
                      disableInteraction={Boolean(activeId)}
                      icon={
                        <Icon
                          name={getAppIcon(String(shortcut.icon)) || IconVariant.CASE_STUDY_CONTENT_TYPE}
                          width={40}
                          height={40}
                        />
                      }
                      canClose={!model.lockedShortcutIds.includes(shortcut.id)}
                      onClick={() => handleOpenApp(shortcut)}
                      onClose={() => onRemoveApp(shortcut.id)}
                    />
                  );
                }

                return null;
              })}
              <FilterOptions
                isSearchable
                isSingleSelect
                isAutoClose
                menuWidth="330px"
                placement="left"
                menuClassName={styles.filterOptionsMenu}
                renderTrigger={({ onClick, isOpen }) => (
                  <SimpleTooltip
                    placement="right"
                    trigger={
                      <div
                        className={cn(styles.addMoreTrigger, {
                          [styles.selected]: isOpen,
                          [styles.disabled]: model.shortcutIds.length >= SHORTCUTS_LIMIT,
                        })}
                        onClick={() => {
                          if (model.shortcutIds.length < SHORTCUTS_LIMIT) {
                            model.analyticsService.track(AnalyticsActivity.addNewShortcutButtonClicked, {
                              edit_flow: model.isAdminEdit ? 'team admin' : 'user',
                            });

                            onClick();
                          }
                        }}
                      >
                        <Plus size={28} />
                      </div>
                    }
                  >
                    {model.shortcutIds.length >= SHORTCUTS_LIMIT
                      ? `Can't add more than ${SHORTCUTS_LIMIT} shortcuts`
                      : `You can have up to ${SHORTCUTS_LIMIT} shortcuts`}
                  </SimpleTooltip>
                )}
                renderOptionNode={renderAppOptionNode}
                onLoadMore={model.loadMoreAppsPaginated}
                isLoading={model.isAppsPaginatedLoading}
                hasNextPage={model.isAppsPaginatedHasNext}
                isEnableInfiniteScroll
                options={model.appsPaginated}
                onChange={filter => onAddApp(filter.id)}
                onSearch={handleAppsFilterSearch}
                onResetSearch={handleAppsFilterSearchClear}
              />
            </div>
            {model.isAdminEdit && model.shortcutIds.length > 1 && (
              <p className={cn(styles.dragText, 'text-sm')}>Drag to rearrange</p>
            )}
          </div>
          <DragOverlay
            style={{
              background: 'white',
              zIndex: 1000,
              borderRadius: 12,
            }}
          >
            {draggedShortcut && 'component' in draggedShortcut && draggedShortcut.component}
            {draggedShortcut && 'name' in draggedShortcut && (
              <AppShortcutButton
                text={draggedShortcut.name}
                icon={
                  <Icon
                    name={getAppIcon(String(draggedShortcut.icon)) || IconVariant.CASE_STUDY_CONTENT_TYPE}
                    width={40}
                    height={40}
                  />
                }
                disableInteraction
                canClose={false}
                onClick={noop}
              />
            )}
          </DragOverlay>
        </SortableContext>
      </DndContext>
    );
  },
);

export const SortableItem: React.FC<{ id: string } & IAppShortcutButtonProps> = observer(({ id, ...props }) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    background: isDragging ? 'white' : 'unset',
    zIndex: isDragging ? 1000 : 'auto',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className={styles.sortableItem}>
      {isDragging && <div className={styles.dropArea} />}
      <AppShortcutButton {...props} />
    </div>
  );
});

ShortcutsHomeWidget.displayName = 'ShortcutsHomeWidget';
