import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import { ContentEditorPageMode, ContentEditorQueryParams } from '@writercolab/common-utils';
import { Modal, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { AppVoiceDropdownModel } from '@writercolab/ui-organisms';

import { DraftFeedback } from '@web/component-library';
import { EApplicationType, IOutlineSection, SeoBlogSection, SeoBlogUseDraftAction, TVoice } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import type { Params } from 'react-router';
import { useParams } from 'react-router';

import { AnalyticsActivity, MODE_NAME_MAP } from '../../../constants/analytics';
import { SeoBlogModalModalState, useGeneratorSeoBlogContext } from '../../../context/generatorSeoBlogContext';
import useQuery from '../../../hooks/useQuery';
import { useAppState } from '../../../state';
import { openContactSalesPage, openVoiceSetupShortcut } from '../../../utils/navigationUtils';
import { getCoWriteNextState, saveCoWriteNextState } from '../../../utils/templateUtils';
import TemplateQuotaBanner, { QUOTA_BANNER_TYPE } from '../../molecules/TemplateQuotaBanner/TemplateQuotaBanner';
import { CoWriteHeader } from '../Template/CoWriteHeader/CoWriteHeader';
import ActionButton from './parts/ActionButton';
import type { ITemplateDraftExtended } from './parts/BlogDrafts';
import BlogDrafts from './parts/BlogDrafts';
import BlogKeyPointsForm from './parts/BlogKeyPointsForm';
import BlogOutlineForm from './parts/BlogOutlineForm';
import BlogTitleForm from './parts/BlogTitleForm';
import { UseDraftConfirmationModal } from './parts/UseDraftConfirmationModal';
import { getSeoBlogDraftUsed, saveSeoBlogDraftUsed } from './utils/storageUtils';

import styles from './styles.module.css';

interface IGeneratorSeoBlogProps {
  wordsLimit: number;
  showApplyConfirmation: boolean;
  allowSetupNewVoices: boolean;
  onGenerateDraft?: () => Promise<void>;
  onUseThisDraft: (draft: ITemplateDraftExtended, replaceText?: boolean) => void;
  onUpdateBlogTitle: () => void;
  voices: TVoice[];
  hideCloseButton?: boolean;
}

interface GeneratorSeoBlogQueryParamTypes extends Params {
  orgId: string;
  teamId: string;
  docId: string;
}

export const GeneratorSeoBlog: React.FC<IGeneratorSeoBlogProps> = observer(
  ({
    showApplyConfirmation,
    onUseThisDraft,
    onGenerateDraft,
    onUpdateBlogTitle,
    hideCloseButton,
    wordsLimit,
    voices,
    allowSetupNewVoices,
  }) => {
    const {
      generatorSeoBlogContext,
      onCloseClick,
      onShowTemplatesClick,
      onChangeBlogTitle,
      generateTitles,
      onChangeBlogCta,
      onChangeSeoKeywords,
      generateOutline,
      setSection,
      setOutline,
      generateKeyPoints,
      fetchDocumentDrafts,
      onDraftComment,
      onDraftRemove,
      onDraftCopy,
      setKeyPoints,
      generateDraft,
      setBlogTitleValid,
      setFeedbackModalState,
      setDraftActionModalState,
      onSubmitDraftFeedback,
      setContentGenerationError,
      unsetDraftsPreview,
      setSelectedVoiceId,
    } = useGeneratorSeoBlogContext();
    const { orgId, teamId, docId } = useParams() as GeneratorSeoBlogQueryParamTypes;
    const query = useQuery();
    const [backButtonVisible, setBackButtonVisible] = useState(false);
    const [actionButtonText, setActionButtonText] = useState('');
    const [sectionTitle, setSectionTitle] = useState('');
    const [sectionNumber, setSectionNumber] = useState(1);
    const [sectionLocked, setSectionLocked] = useState(false);
    const [currentDraft, setCurrentDraft] = useState<ITemplateDraftExtended | null>(null);
    const [usedDraftId, setUsedDraftId] = useState<string | null>(null);
    const { appModel } = useAppState();
    const { assistantSubscription, analyticsService } = appModel;
    const [generationDisabledMessage, setGenerationDisabledMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
      if (generatorSeoBlogContext.blogTitle) {
        onUpdateBlogTitle();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatorSeoBlogContext.blogTitle]);

    useEffect(() => {
      const nextState = getCoWriteNextState(orgId, teamId, docId);

      if (nextState?.mode === ContentEditorPageMode.BLOG && nextState?.blogState) {
        setSection(nextState?.blogState);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, teamId, docId]);

    useEffect(() => {
      setSectionLocked(generatorSeoBlogContext.quotaExceeded);
      setGenerationDisabledMessage(
        generatorSeoBlogContext.quotaExceeded
          ? 'Your organization has reached its words generated limit.'
          : undefined,
      );
    }, [generatorSeoBlogContext.quotaExceeded]);

    useEffect(() => {
      fetchDocumentDrafts().then(() => {
        const draftUsed = getSeoBlogDraftUsed(orgId, teamId, docId);

        if (draftUsed) {
          setUsedDraftId(draftUsed);
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, teamId, docId]);

    const _onShowTemplatesClick = () => {
      saveCoWriteNextState(orgId, teamId, docId, {
        mode: ContentEditorPageMode.BLOG,
        blogState: generatorSeoBlogContext.section,
      });
      onShowTemplatesClick();
    };

    const _handleUseDraftClick = (draft: ITemplateDraftExtended) => {
      setCurrentDraft(draft);

      if (!showApplyConfirmation) {
        saveSeoBlogDraftUsed(orgId, teamId, docId, `${draft.id}`);
        onUseThisDraft(draft);
        setUsedDraftId(`${draft.id}`);
        onCloseClick();
      } else {
        setDraftActionModalState(SeoBlogModalModalState.OPEN);
      }
    };

    const _handleGenerateDraft = async () => {
      analyticsService.track(AnalyticsActivity.appGenerated, {
        app_id: query.get(ContentEditorQueryParams.currentTemplateId) ?? '',
        app_name: MODE_NAME_MAP[query.get(ContentEditorQueryParams.mode) as ContentEditorPageMode] ?? '',
        app_type: EApplicationType.enum.generation,
        built_by_writer: true,
      });
      await onGenerateDraft?.();
      await generateDraft();
    };

    const _onClickViewDrafts = () => setSection(SeoBlogSection.DRAFTS);
    const _handleSwitchSection = () => {
      setContentGenerationError(null);
      unsetDraftsPreview();

      if (generatorSeoBlogContext.section === SeoBlogSection.TITLE) {
        if (isEmpty(generatorSeoBlogContext.blogTitle)) {
          setBlogTitleValid(false);
        } else {
          setSection(SeoBlogSection.OUTLINE);
        }
      } else if (generatorSeoBlogContext.section === SeoBlogSection.OUTLINE) {
        const sections: IOutlineSection[] = generatorSeoBlogContext.outlineGenerated.map(sections => ({
          ...sections,
          valid: !isEmpty(sections.name),
        }));
        setOutline(sections);
        const hasErrors = sections.some(item => !item.valid);

        if (hasErrors) {
          return;
        }

        setSection(SeoBlogSection.KEYPOINTS);
      } else if (generatorSeoBlogContext.section === SeoBlogSection.KEYPOINTS) {
        setSection(SeoBlogSection.DRAFTS);
      }
    };

    const _handleSwitchBackSection = () => {
      if (generatorSeoBlogContext.section === SeoBlogSection.OUTLINE) {
        setSection(SeoBlogSection.TITLE);
      } else if (generatorSeoBlogContext.section === SeoBlogSection.KEYPOINTS) {
        setSection(SeoBlogSection.OUTLINE);
      } else if (generatorSeoBlogContext.section === SeoBlogSection.DRAFTS) {
        setSection(SeoBlogSection.KEYPOINTS);
      }
    };

    const _handleCloseFeedbackModal = () => {
      setFeedbackModalState(SeoBlogModalModalState.HIDDEN);
    };

    const _handleCloseDraftActionModal = () => {
      setDraftActionModalState(SeoBlogModalModalState.HIDDEN);
    };

    useEffect(() => {
      if (generatorSeoBlogContext.section === SeoBlogSection.TITLE) {
        setActionButtonText('Next: The outline');
        setSectionTitle('The basics');
        setSectionNumber(1);
      } else if (generatorSeoBlogContext.section === SeoBlogSection.OUTLINE) {
        setSectionTitle('The outline');
        setActionButtonText('Next: Key points');
        setSectionNumber(2);
      } else if (generatorSeoBlogContext.section === SeoBlogSection.KEYPOINTS) {
        setSectionTitle('Key points');
        setActionButtonText('Generate new output');
        setSectionNumber(3);
      } else if (generatorSeoBlogContext.section === SeoBlogSection.DRAFTS) {
        setSectionTitle('Output preview');
      }

      setBackButtonVisible(generatorSeoBlogContext.section !== SeoBlogSection.TITLE);
    }, [generatorSeoBlogContext.section]);

    const _onUseDraftModalAction = useCallback(
      async (draft: ITemplateDraftExtended, actionType: SeoBlogUseDraftAction) => {
        saveSeoBlogDraftUsed(orgId, teamId, docId, `${draft.id}`);
        setUsedDraftId(`${draft.id}`);
        onUseThisDraft(draft, actionType === SeoBlogUseDraftAction.REPLACE);
        setDraftActionModalState(SeoBlogModalModalState.HIDDEN);
        onCloseClick();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [orgId, teamId, docId],
    );

    const voiceDropdownModel = useMemo(
      () =>
        new AppVoiceDropdownModel({
          settings: { selection: 'auto' },
          teamVoices: voices,
          orgVoices: [],
        }),
      [voices],
    );

    useEffect(() => {
      setSelectedVoiceId(voiceDropdownModel.value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voiceDropdownModel.value]);

    return (
      <div className={cx(styles.container, styles.seoBlog)}>
        {currentDraft && generatorSeoBlogContext.draftActionModalState === SeoBlogModalModalState.OPEN && (
          <UseDraftConfirmationModal
            draft={currentDraft}
            onActionClick={_onUseDraftModalAction}
            onCloseModal={_handleCloseDraftActionModal}
          />
        )}

        <Modal
          open={generatorSeoBlogContext.draftFeedbackModalState === SeoBlogModalModalState.OPEN}
          className={styles.modalContainer}
          handleClose={_handleCloseFeedbackModal}
          style={{ padding: 0 }}
        >
          <DraftFeedback onClose={_handleCloseFeedbackModal} onSubmit={onSubmitDraftFeedback} />
        </Modal>

        <div className={styles.headerContainer}>
          <CoWriteHeader
            onCloseClick={hideCloseButton ? undefined : onCloseClick}
            onAllTemplatesClick={_onShowTemplatesClick}
            title="Blog post builder"
            isSeoBlog
          />
        </div>
        <div className={styles.headerDescription}>
          <Text bold className={styles.headerDescriptionTextLarge}>
            {sectionTitle}
          </Text>
          {!generatorSeoBlogContext.section.includes(SeoBlogSection.DRAFTS) && (
            <Text className={styles.headerDescriptionTextSmall}>{sectionNumber} of 3</Text>
          )}
        </div>
        {!isEmpty(generatorSeoBlogContext.contentGenerationError) && (
          <div className={styles.errorContainerWrapper}>
            <div className={styles.errorContainer}>
              <Text color={TextColor.ORANGE}>{generatorSeoBlogContext.contentGenerationError}</Text>
            </div>
          </div>
        )}
        <div className={styles.headerStepActions}>
          <div>
            {backButtonVisible && (
              <ActionButton
                className={styles.headerStepActionButton}
                iconSymbol="&lsaquo;"
                onClick={_handleSwitchBackSection}
                tooltipContent={
                  <Text variant={TextSize.M} bold color={TextColor.WHITE}>
                    Back to previous step
                  </Text>
                }
                arrow
              />
            )}
          </div>
          <div className={styles.headerStepActionsFlex}>
            {!generatorSeoBlogContext.section.includes(SeoBlogSection.DRAFTS) && (
              <>
                {!isEmpty(generatorSeoBlogContext.documentDrafts) && (
                  <ActionButton
                    className={cx(styles.headerStepActionButton, {
                      [styles.headerStepActionNextButtonQuotaQuotaExceeded]: sectionLocked,
                    })}
                    content="View drafts"
                    iconSymbol="&raquo;"
                    onClick={_onClickViewDrafts}
                    disabled={sectionLocked}
                  />
                )}
                {generatorSeoBlogContext.section === SeoBlogSection.KEYPOINTS ? (
                  <ActionButton
                    content={actionButtonText}
                    className={cx(styles.headerStepActionNextButton, {
                      [styles.headerStepActionNextButtonQuotaQuotaExceeded]: sectionLocked,
                      [styles.headerStepActionGenerating]:
                        generatorSeoBlogContext.loadingDocumentDrafts || generatorSeoBlogContext.contentGenerating,
                    })}
                    iconSymbol="&rsaquo;"
                    onClick={() => _handleGenerateDraft()}
                    isLoading={generatorSeoBlogContext.contentGenerating}
                    tooltipContent={generationDisabledMessage}
                    disabled={
                      sectionLocked ||
                      generatorSeoBlogContext.keyPointsGenerating ||
                      generatorSeoBlogContext.loadingDocumentDrafts ||
                      generatorSeoBlogContext.contentGenerating
                    }
                  />
                ) : (
                  <ActionButton
                    content={actionButtonText}
                    className={cx(styles.headerStepActionNextButton, {
                      [styles.headerStepActionNextButtonQuotaQuotaExceeded]: sectionLocked,
                      [styles.headerStepActionGenerating]: generatorSeoBlogContext.outlineGenerating,
                    })}
                    iconSymbol="&rsaquo;"
                    isLoading={generatorSeoBlogContext.contentGenerating}
                    tooltipContent={generationDisabledMessage}
                    disabled={
                      sectionLocked ||
                      generatorSeoBlogContext.keyPointsGenerating ||
                      generatorSeoBlogContext.outlineGenerating
                    }
                    onClick={_handleSwitchSection}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {generatorSeoBlogContext.quotaExceeded && (
          <div className={styles.containerForm}>
            <TemplateQuotaBanner
              wordsLimit={wordsLimit}
              type={QUOTA_BANNER_TYPE.BLOG}
              onContactSales={openContactSalesPage}
            />
          </div>
        )}
        {generatorSeoBlogContext.section === SeoBlogSection.TITLE && (
          <BlogTitleForm
            allowSetupNewVoices={allowSetupNewVoices}
            onSetupNewVoice={openVoiceSetupShortcut}
            title={generatorSeoBlogContext.blogTitle}
            cta={generatorSeoBlogContext.blogCta}
            titleGenerating={generatorSeoBlogContext.titlesGenerating}
            keywords={generatorSeoBlogContext.blogSeoKeywords}
            titlesGenerated={generatorSeoBlogContext.titlesGenerated}
            onChangeBlogTitle={onChangeBlogTitle}
            onChangeBlogCta={onChangeBlogCta}
            onChangeSeoKeywords={onChangeSeoKeywords}
            onGenerateTitlesClick={generateTitles}
            titleValid={generatorSeoBlogContext.blogTitleValid}
            voiceDropdownModel={voiceDropdownModel}
            isVoiceEnabled={assistantSubscription.isModelReady && !!assistantSubscription.access?.voice}
          />
        )}
        {generatorSeoBlogContext.section === SeoBlogSection.OUTLINE && (
          <BlogOutlineForm
            outlineGenerated={generatorSeoBlogContext.outlineGenerated}
            outlineGenerating={generatorSeoBlogContext.outlineGenerating}
            headline={generatorSeoBlogContext.blogTitle}
            onOutlineGenerate={generateOutline}
            setOutline={setOutline}
          />
        )}
        {generatorSeoBlogContext.section === SeoBlogSection.KEYPOINTS && (
          <BlogKeyPointsForm
            headline={generatorSeoBlogContext.blogTitle}
            keyPointsGenerated={generatorSeoBlogContext.keyPointsGenerated}
            keyPointsGenerating={generatorSeoBlogContext.keyPointsGenerating}
            outline={generatorSeoBlogContext.outlineGenerated}
            onKeyPointsGenerate={generateKeyPoints}
            setKeyPoints={setKeyPoints}
          />
        )}
        {generatorSeoBlogContext.section === SeoBlogSection.DRAFTS && (
          <BlogDrafts
            setSection={setSection}
            activeDocumentDraftId={usedDraftId}
            documentDrafts={generatorSeoBlogContext.documentDrafts}
            onDraftRemove={onDraftRemove}
            onDraftCopy={onDraftCopy}
            onDraftUse={_handleUseDraftClick}
            onDraftComment={onDraftComment}
            fetchDocumentDrafts={fetchDocumentDrafts}
            loadingDocumentDrafts={generatorSeoBlogContext.loadingDocumentDrafts}
          />
        )}
      </div>
    );
  },
);
