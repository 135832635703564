import { type ITeamDetails } from '@writercolab/common-utils';
import { type components } from '@writercolab/network';
import { type DropdownOption } from '@writercolab/ui-atoms';

export const MAX_TEAM_SIZE = 5;
export const MAX_STARTER_TEAM_SIZE = 20;
export const TEAM_NAME_DUPLICATE_ERROR_MESSAGE = 'TEAM NAME ALREADY IN USE';

export type CreateTeamRequestParams = components['schemas']['organization_dto_CreateTeamRequest'];
export type TTeam = components['schemas']['organization_model_Team'];
export type TTeamWithAdmins = components['schemas']['organization_dto_TeamWithAdmins'];
export type TPaginatedTeamUsers = components['schemas']['model_PaginatedResult_com_qordoba_user_dto_TeamUserDto'];
export type TTeamUser = components['schemas']['com_qordoba_user_dto_TeamUserDto'];

interface ManagedByTeam extends DropdownOption {}

export interface IAdminsOptionsList {
  name: string;
  subText: string;
  id: number;
}

export interface ITeamsManagedByProps {
  suggestionsManagedBy?: ManagedByTeam;
  termsManagedBy?: ManagedByTeam;
  snippetsManagedBy?: ManagedByTeam;
}

export interface IMappedTeamDetails extends ITeamDetails, ITeamsManagedByProps {
  color: string;
}

export type ITeamQueryParams = {
  search?: string;
  limit?: number;
  offset?: number;
  userStatus?: components['schemas']['user_model_UserStatus'][];
};
