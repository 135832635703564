import { Enum } from '@writercolab/utils';

export const TConsoleUnknownOrgId = 0;

export const TConsoleRouteId = new Enum(
  'dashboard',
  'templates',
  'apiKeys',
  'analytics',
  'aiGuardrails',
  'knowledgeGraph',
  'docs',
  'admin',
  'app',
  'voice',
  'customRules',
  'playground',
  'manage',
  'managePeople',
  'manageBilling',
  'manageUsage',
  'manageAccount',
  'generalObservability',
  'sessionLogs'
);
