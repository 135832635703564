import { openNewTab } from '@writercolab/common-utils';
import cn from 'classnames';

import { IconsCommonArrowTopRight } from '@writercolab/assets';
import { IconLink } from '../IconLink';
import styles from './LinkWithLabel.module.css';

export interface ILinkWithLabelProps extends BaseComponentsProps {
  label: React.ReactNode;
  link: string;
  linkText: string;
}

const LinkWithLabel: React.FC<ILinkWithLabelProps> = ({ className, label, link, linkText }) => {
  const handleLinkClick = () => {
    openNewTab(link);
  };

  return (
    <div className={cn(styles.linkWithLabelMainContainer, className)}>
      <p className={cn(styles.linkWithLabelTitle, 'relative inline font-normal tracking-wider uppercase m-0')}>
        {label}
      </p>
      <div className={styles.linkWithLabelLinkContainer}>
        <IconLink
          text={<span className={styles.linkWithLabelText}>{linkText}</span>}
          onClick={handleLinkClick}
          className={styles.linkWithLink}
          icon={<IconsCommonArrowTopRight className={styles.linkWithLabelIcon} />}
          iconPosition="right"
          ariaLabel={linkText}
        />
      </div>
    </div>
  );
};

export default LinkWithLabel;
