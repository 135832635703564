import { useCallback, useEffect, useState } from 'react';

import { SETUP_ROUTES } from '@writercolab/utils';

import { GlobalNavSelection } from '../components/organisms/GlobalNav';

import { useLocation } from 'react-router';

import { HIDDEN_GLOBAL_NAV_ROUTES, REACT_RELATIVE_ROUTE, ROUTE } from '../services/config/routes';
import { getLogger } from '../utils/logger';
import { useRouteMatch } from './useRouteMatch';

const LOG = getLogger('useComputeRoutes');

export const useComputeRoutes = () => {
  const location = useLocation();
  const [isGlobalNavHidden, setIsGlobalNavHidden] = useState(false);
  const [activeRoute, setActiveRoute] = useState(GlobalNavSelection.DASHBOARD);

  const isRouteMatch = useRouteMatch();

  const getCurrentRoute = useCallback(
    (routes: string[]) => {
      const _activeRoute = routes.find(r => isRouteMatch(r, true));

      switch (_activeRoute) {
        case ROUTE.terms:
          return GlobalNavSelection.TERMS;
        case ROUTE.snippets:
          return GlobalNavSelection.SNIPPETS;
        case ROUTE.editorPage:
          return GlobalNavSelection.EDITOR;
        case ROUTE.myWork:
        case ROUTE.docs:
        case ROUTE.outputs:
        case ROUTE.sessions:
          return GlobalNavSelection.MY_WORK;
        case ROUTE.teamUsers:
          return GlobalNavSelection.TEAM;
        case ROUTE.people:
        case ROUTE.allTeams:
        case ROUTE.account:
        case ROUTE.security:
        case ROUTE.billing:
        case ROUTE.billingGroups:
        case ROUTE.oauthApps:
        case ROUTE.api:
        case ROUTE.sso:
        case ROUTE.reporting:
          return GlobalNavSelection.SETTINGS;
        case ROUTE.apps:
          return GlobalNavSelection.EXTENSIONS;
        case ROUTE.profile:
          return GlobalNavSelection.PROFILE;
        case ROUTE.suggestions:
        case ROUTE.newTerms:
        case ROUTE.newSnippets:
        case ROUTE.voice:
        case SETUP_ROUTES.clarity:
        case SETUP_ROUTES.writingStyle:
        case SETUP_ROUTES.compliance:
        case SETUP_ROUTES.customRules:
        case SETUP_ROUTES.delivery:
        case SETUP_ROUTES.general:
        case SETUP_ROUTES.inclusivity:
        case SETUP_ROUTES.plagiarism:
        case SETUP_ROUTES.punctuation:
        case SETUP_ROUTES.reWrite:
        case SETUP_ROUTES.magicLinks:
        case SETUP_ROUTES.teamPrompts:
        case SETUP_ROUTES.knowledgeGraph:
          return GlobalNavSelection.SETUP;
        case ROUTE.teamDrafts:
          return GlobalNavSelection.DRAFTS;
        case REACT_RELATIVE_ROUTE.learningCenter:
          return GlobalNavSelection.LEARNING;
        case REACT_RELATIVE_ROUTE.extensions:
          return GlobalNavSelection.EXTENSIONS;
        default:
          return GlobalNavSelection.DASHBOARD;
      }
    },
    [isRouteMatch],
  );

  useEffect(() => {
    const currentRoute = getCurrentRoute([
      ROUTE.editorPage,
      ROUTE.docs,
      ROUTE.teamUsers,
      ROUTE.people,
      ROUTE.api,
      ROUTE.profile,
      ROUTE.allTeams,
      ROUTE.terms,
      ROUTE.newTerms,
      ROUTE.suggestions,
      ROUTE.apps,
      ROUTE.security,
      ROUTE.account,
      ROUTE.billing,
      ROUTE.billingGroups,
      ROUTE.oauthApps,
      ROUTE.sso,
      ROUTE.reporting,
      ROUTE.teamDrafts,
      ROUTE.myWork,
      ROUTE.docs,
      ROUTE.outputs,
      ROUTE.sessions,
      ROUTE.snippets,
      ROUTE.newSnippets,
      ROUTE.voice,
      ROUTE.teamKnowledge,
      REACT_RELATIVE_ROUTE.learningCenter,
      REACT_RELATIVE_ROUTE.extensions,
      // TODO: quick fix for subroutes, requires more thoughful solution
      SETUP_ROUTES.clarity,
      SETUP_ROUTES.writingStyle,
      SETUP_ROUTES.compliance,
      SETUP_ROUTES.customRules,
      SETUP_ROUTES.delivery,
      SETUP_ROUTES.general,
      SETUP_ROUTES.inclusivity,
      SETUP_ROUTES.plagiarism,
      SETUP_ROUTES.punctuation,
      SETUP_ROUTES.reWrite,
      SETUP_ROUTES.magicLinks,
      SETUP_ROUTES.teamPrompts,
      SETUP_ROUTES.knowledgeGraph,
    ]);

    const globalNavHidden = !!HIDDEN_GLOBAL_NAV_ROUTES.find(r => isRouteMatch(r));

    setActiveRoute(currentRoute);
    setIsGlobalNavHidden(globalNavHidden);
  }, [getCurrentRoute, isRouteMatch]);

  useEffect(() => {
    LOG.info('Going to route: ', location.pathname);
  }, [location.pathname]);

  return { activeRoute, isGlobalNavHidden };
};
