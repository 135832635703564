import React, { useMemo } from 'react';

import cx from 'classnames';

import { wordPluralize } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Dropdown,
  DropdownPlacement,
  Heading,
  HeadingColor,
  HeadingVariant,
  Icon,
  IconVariant,
  Input,
  Modal,
  Score,
  SizeTypes,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { TabList } from '@writercolab/ui-molecules';

import { DropdownTrigger } from 'components/molecules/DropdownTrigger';

import { TVoicePreviewMode, TVoiceTraitType } from '@web/types';
import { observer } from 'mobx-react-lite';

import {
  VOICE_CONSTRUCTOR_DESCRIPTION_LIMIT,
  VOICE_CONSTRUCTOR_NAME_LIMIT,
  VOICE_PREFERENCES,
  VOICE_TRAITS,
  VOICE_TRAITS_LIMIT,
  VoiceConstructorModalUIModel,
} from './VoiceConstructorModalModel.ui';

import styles from './VoiceConstructorModal.module.css';

export interface IVoiceConstructorModalProps {
  open: boolean;
  model: VoiceConstructorModalUIModel;
  onClickClose: () => void;
}

export const VoiceConstructorModal = observer<IVoiceConstructorModalProps>(({ open, model, onClickClose }) => {
  const tabs = useMemo(() => {
    const tabs = [
      {
        id: TVoicePreviewMode.enum.Original as string,
        label: 'Original text',
        content: (
          <>
            <Text className={styles.exampleHeading} variant={TextSize.XXL} color={TextColor.GREY}>
              {model.api.voiceExamplesList[model.selectedExampleId]?.title}
            </Text>
            <Text
              className={cx(styles.example, {
                [styles.exampleDisabled]: model.isPreviewLoading,
              })}
              variant={TextSize.M}
              color={TextColor.GREY}
            >
              {model.api.voiceExamplesList[model.selectedExampleId]?.text}
            </Text>
          </>
        ),
      },
    ];

    if (model.previews[model.selectedExampleId]) {
      tabs.push({
        id: TVoicePreviewMode.enum.Updated,
        label: 'With voice applied',
        content: (
          <>
            <Text className={styles.exampleHeading} variant={TextSize.XXL} color={TextColor.GREY}>
              {model.previews[model.selectedExampleId]?.title}
            </Text>
            <Text
              className={cx(styles.example, {
                [styles.exampleDisabled]: model.isPreviewLoading,
              })}
              variant={TextSize.M}
              color={TextColor.GREY}
            >
              {model.previews[model.selectedExampleId]?.text}
            </Text>
          </>
        ),
      });
    }

    return tabs;
  }, [model.api.voiceExamplesList, model.isPreviewLoading, model.previews, model.selectedExampleId]);

  return (
    <Modal
      open={open}
      hideCloseButton
      className={styles.container}
      multiModalContainerClassName={styles.outerContainer}
      disableBackdropClick
      handleClose={onClickClose}
    >
      <>
        <div className={styles.closeButton} onClick={onClickClose}>
          <Icon name={IconVariant.CLOSE_WHITE} height={18} width={18} />
        </div>
        <div className={styles.header}>
          {model.isEditingEnabled ? (
            <>
              <Tooltip title={model.saveDisabledMessage} disabled={!model.saveDisabledMessage}>
                <Button
                  type={ButtonTypes.PRIMARY}
                  className={styles.headerButton}
                  disabled={!!model.saveDisabledMessage}
                  isLoading={model.isSaving}
                  content="Save voice"
                  onClick={() => {
                    if (model.selectedVoice) {
                      model.updateVoice();
                    } else {
                      model.addVoice();
                    }
                  }}
                />
              </Tooltip>
              <Button
                type={ButtonTypes.DEFAULT}
                className={styles.headerButton}
                disabled={false}
                content="Go back"
                onClick={() => model.setIsEditingEnabled(false)}
              />
            </>
          ) : (
            <Tooltip
              title={`Add at least ${VOICE_TRAITS_LIMIT.MIN} traits and select your perspective preferences to finalize this voice`}
              disabled={model.traits.length >= VOICE_TRAITS_LIMIT.MIN && model.isPreferencesSelected}
              tooltipWidth={180}
            >
              <Button
                type={ButtonTypes.PRIMARY}
                className={styles.headerButton}
                disabled={model.traits.length < VOICE_TRAITS_LIMIT.MIN || !model.isPreferencesSelected}
                content="Next"
                onClick={() => model.setIsEditingEnabled(true)}
              />
            </Tooltip>
          )}

          <Icon className={styles.headerIcon} name={IconVariant.VOICE_TUNE} width={40} height={40} />
          <Heading variant={HeadingVariant.H2} color={HeadingColor.BLACK} upperCase>
            Tune a new voice
          </Heading>
          <Text className={styles.headerSubtitle} variant={TextSize.XL} color={TextColor.GREY}>
            Customize your voice by selecting traits and perspective
          </Text>
        </div>

        <div className={styles.contentContainer}>
          <div
            className={cx(styles.content, {
              [styles.contentDisabled]: model.isEditingEnabled,
            })}
          >
            <div className={styles.traitsSelected}>
              {model.traits.length}/{VOICE_TRAITS_LIMIT.MAX} traits selected
              <div className={styles.score}>
                <span className={styles.scoreCounter}>{model.traits.length}</span>
                <Score value={model.traits.length / (VOICE_TRAITS_LIMIT.MAX / 100)} hideScoreValue={false} />
              </div>
            </div>

            <Heading className={styles.traitsHeading} variant={HeadingVariant.H3} color={HeadingColor.BLACK}>
              Select traits *
            </Heading>
            <Text variant={TextSize.XL} color={TextColor.GREY}>
              Select between {VOICE_TRAITS_LIMIT.MIN}-{VOICE_TRAITS_LIMIT.MAX} traits that best represent your brand
              voice
            </Text>

            <div className={styles.traits}>
              <div className={styles.traitsSection}>
                <Heading className={styles.traitsTitle} variant={HeadingVariant.H5} color={HeadingColor.BLACK}>
                  Casual vs Formal
                </Heading>
                {VOICE_TRAITS.filter(trait => trait.type === TVoiceTraitType.enum.casualFormal).map(trait => (
                  <Button
                    key={`trait-${trait.id}`}
                    disabled={model.isTraitNegated(trait.id) || model.isTraitDisabled(trait.id)}
                    className={cx(styles.optionButton, {
                      [styles.optionButtonDisabled]: model.isTraitNegated(trait.id) || model.isTraitDisabled(trait.id),
                    })}
                    type={model.isTraitSelected(trait.id) ? ButtonTypes.BLACK : ButtonTypes.DEFAULT}
                    size={SizeTypes.MEDIUM}
                    content={trait.name}
                    onClick={() => model.toggleTrait(trait.id)}
                  />
                ))}
              </div>

              <div className={styles.traitsSection}>
                <Heading className={styles.traitsTitle} variant={HeadingVariant.H5} color={HeadingColor.BLACK}>
                  Tone
                </Heading>
                {VOICE_TRAITS.filter(trait => trait.type === TVoiceTraitType.enum.tone).map(trait => (
                  <Button
                    key={`trait-${trait.id}`}
                    disabled={model.isTraitDisabled(trait.id)}
                    className={cx(styles.optionButton, {
                      [styles.optionButtonDisabled]: model.isTraitDisabled(trait.id),
                    })}
                    type={model.isTraitSelected(trait.id) ? ButtonTypes.BLACK : ButtonTypes.DEFAULT}
                    size={SizeTypes.MEDIUM}
                    content={trait.name}
                    onClick={() => model.toggleTrait(trait.id)}
                  />
                ))}
              </div>

              <div className={styles.traitsSectionNarrow}>
                <Heading className={styles.traitsTitle} variant={HeadingVariant.H5} color={HeadingColor.BLACK}>
                  Personality
                </Heading>
                {VOICE_TRAITS.filter(trait => trait.type === TVoiceTraitType.enum.personality).map(trait => (
                  <Button
                    key={`trait-${trait.id}`}
                    disabled={model.isTraitDisabled(trait.id)}
                    className={cx(styles.optionButton, {
                      [styles.optionButtonDisabled]: model.isTraitDisabled(trait.id),
                    })}
                    type={model.isTraitSelected(trait.id) ? ButtonTypes.BLACK : ButtonTypes.DEFAULT}
                    size={SizeTypes.MEDIUM}
                    content={trait.name}
                    onClick={() => model.toggleTrait(trait.id)}
                  />
                ))}
              </div>
            </div>

            <div className={styles.preferencesSelected}>
              {model.selectedPreferences.length}/{model.preferences.length} selected
              <div className={styles.score}>
                <span className={styles.scoreCounter}>{model.preferences.length}</span>
                <Score
                  value={model.selectedPreferences.length / (model.preferences.length / 100)}
                  hideScoreValue={false}
                />
              </div>
            </div>
            <Heading className={styles.preferencesHeading} variant={HeadingVariant.H3} color={HeadingColor.BLACK}>
              Perspective *
            </Heading>
            <Text variant={TextSize.XL} color={TextColor.GREY}>
              Customize your point-of-view preferences
            </Text>

            <div className={styles.preferences}>
              {model.preferences.map((preference, i) => (
                <div className={styles.preference} key={`preference-${i}`}>
                  <div className={styles.preferenceDefinition}>
                    <Heading
                      className={styles.preferenceHeading}
                      variant={HeadingVariant.H4}
                      color={HeadingColor.BLACK}
                    >
                      {VOICE_PREFERENCES[i]?.title}
                    </Heading>
                    <Text className={styles.preferenceDescription} variant={TextSize.M} color={TextColor.GREY}>
                      {VOICE_PREFERENCES[i]?.description}
                    </Text>
                  </div>
                  <Button
                    className={styles.optionButton}
                    type={preference === true ? ButtonTypes.BLACK : ButtonTypes.DEFAULT}
                    size={SizeTypes.MEDIUM}
                    content="Yes"
                    onClick={() => model.togglePreference(i, true)}
                  />
                  <Button
                    className={styles.optionButton}
                    type={preference === false ? ButtonTypes.BLACK : ButtonTypes.DEFAULT}
                    size={SizeTypes.MEDIUM}
                    content="No"
                    onClick={() => model.togglePreference(i, false)}
                  />
                  <Button
                    className={styles.optionButton}
                    type={preference === null ? ButtonTypes.BLACK : ButtonTypes.DEFAULT}
                    size={SizeTypes.MEDIUM}
                    content="Don’t care"
                    onClick={() => model.togglePreference(i, null)}
                  />
                </div>
              ))}
            </div>
          </div>

          {model.isEditingEnabled ? (
            <div className={styles.editContainer}>
              <Heading className={styles.heading} variant={HeadingVariant.H3} color={HeadingColor.BLACK}>
                Final touches
              </Heading>
              <Text className={styles.subtitle} variant={TextSize.XL} color={TextColor.GREY}>
                To save, add a few last details
              </Text>
              <Text className={styles.nameLabel} variant={TextSize.XL} color={TextColor.GREY} bold>
                Voice name*
              </Text>
              <Input
                className={styles.name}
                placeholder="e.g., External communications"
                value={model.name}
                inputProps={{ maxLength: VOICE_CONSTRUCTOR_NAME_LIMIT.MAX }}
                onChange={e => model.setName(e.target.value)}
              />
              <Text className={styles.counter} variant={TextSize.XS} color={TextColor.GREY3} medium upperCase>
                {model.name.length}/{VOICE_CONSTRUCTOR_NAME_LIMIT.MAX} {wordPluralize(model.name.length, 'char')}
              </Text>
              <Text className={styles.descriptionLabel} variant={TextSize.XL} color={TextColor.GREY} bold>
                When to use
              </Text>
              <textarea
                data-qordoba-ignore
                className={styles.description}
                placeholder="Where should writers use this voice? We'll show this description in the voice selection dropdown.&#10;&#10;e.g., customer newsletters, sales emails"
                value={model.description}
                maxLength={VOICE_CONSTRUCTOR_DESCRIPTION_LIMIT.MAX}
                onChange={e => model.setDescription(e.target.value)}
              />
              <Text className={styles.tuningCounter} variant={TextSize.XS} color={TextColor.GREY3} medium upperCase>
                {model.description.length}/{VOICE_CONSTRUCTOR_DESCRIPTION_LIMIT.MAX}{' '}
                {wordPluralize(model.description.length, 'char')}
              </Text>
            </div>
          ) : (
            <div className={styles.previewContainer}>
              <Heading className={styles.previewHeading} variant={HeadingVariant.H3} color={HeadingColor.BLACK}>
                Try out your voice
              </Heading>
              <Text className={styles.previewDescription} variant={TextSize.M} color={TextColor.GREY}>
                When you’re done tuning your voice, apply the voice to the sample content below to see how it sounds.
              </Text>

              <Text className={styles.previewLabel} variant={TextSize.XS} color={TextColor.GREY} caps>
                Select a sample text
              </Text>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  dropDownContainerClassName={styles.dropdown}
                  trigger={
                    <DropdownTrigger
                      className={styles.dropdownTrigger}
                      placeholder={model.api.voiceExamplesList[model.selectedExampleId]?.title}
                    />
                  }
                  options={model.api.voiceExamplesList.map((example, index) => ({
                    id: String(index),
                    name: example.title,
                    active: index === model.selectedExampleId,
                    className: index === model.selectedExampleId ? styles.dropdownOptionActive : undefined,
                  }))}
                  onPrimaryOptionClickAction={id => model.setSelectedExampleId(Number(id))}
                  placement={DropdownPlacement.BOTTOM_RIGHT}
                />
              </div>

              {model.api.voiceExamplesList[model.selectedExampleId] && (
                <div className={styles.exampleContainer}>
                  {model.isPreviewLoading && (
                    <div className={styles.loadingContainer}>
                      <div className={styles.loadingBarWrapper}>
                        <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.loadingBar} />
                      </div>
                      Applying voice to text
                    </div>
                  )}
                  <TabList
                    className={cx(styles.tabs, {
                      [styles.tabsHidden]: !model.previews[model.selectedExampleId],
                      [styles.tabsOriginal]: model.previewMode === TVoicePreviewMode.enum.Original,
                      [styles.tabsUpdated]: model.previewMode === TVoicePreviewMode.enum.Updated,
                    })}
                    activeTab={model.previewMode}
                    onChange={mode => {
                      model.setPreviewMode(TVoicePreviewMode.get(mode, TVoicePreviewMode.enum.Original));
                    }}
                    tabs={tabs}
                  />
                  <div className={styles.applyButtonContainer}>
                    <Button
                      type={ButtonTypes.PURPLE_2}
                      size={SizeTypes.MEDIUM}
                      className={cx(styles.applyButton, {
                        [styles.applyButtonDisabled]: model.isPreviewDisabled,
                      })}
                      disabled={model.isPreviewDisabled}
                      content="Apply voice to this text"
                      icon={<Icon name={IconVariant.REFRESH_WHITE} width={12} height={12} />}
                      onClick={model.getVoicePreviews}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    </Modal>
  );
});
