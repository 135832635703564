import React, { useMemo } from 'react';

import cx from 'classnames';

import { BillingProduct } from '@writercolab/common-utils';
import { Button, ButtonTypes, Heading, HeadingVariant, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface ITrialPopup {
  open: boolean;
  type: BillingProduct;
  hasLessThanTwoUsers: boolean;
  isSubmitting: boolean;
  onCancelTrial?: () => void;
  onCancel?: () => void;
  isDowngrade: boolean;
}

export const CancelTrialPopup: React.FC<ITrialPopup> = ({
  open,
  type,
  hasLessThanTwoUsers,
  onCancelTrial,
  onCancel,
  isSubmitting,
  isDowngrade,
}) => {
  const onCancelMyTrialClick = () => {
    onCancelTrial && onCancelTrial();
  };

  const footerText = useMemo(() => {
    if (isDowngrade) {
      return hasLessThanTwoUsers && <span>If yes, we’ll immediately downgrade you to our Free plan.</span>;
    }

    return hasLessThanTwoUsers && <span>If you cancel, you'll lose access to all your work</span>;
  }, [isDowngrade, hasLessThanTwoUsers]);

  return (
    <Modal open={open} className={styles.modalContainer} handleClose={onCancel}>
      <div>
        <Heading variant={HeadingVariant.H4} className={styles.deleteHeader}>
          Cancel my <span className={styles.capitalized}>{type}</span> trial
        </Heading>

        <Text className={styles.hyped} variant={TextSize.XL}>
          Are you sure you want to cancel your <span className={styles.capitalized}>{type}</span> trial early?{' '}
          {footerText}
        </Text>

        <div className={cx(styles.actionButtonsHolder)}>
          <Button className={styles.action} type={ButtonTypes.BORDERED} onClick={onCancel}>
            Never mind
          </Button>
          <Button
            danger
            className={styles.action}
            type={ButtonTypes.PRIMARY}
            onClick={onCancelMyTrialClick}
            isLoading={isSubmitting}
            content="Cancel trial"
          />
        </div>
      </div>
    </Modal>
  );
};

export default CancelTrialPopup;
