import type React from 'react';
import { useEffect, useMemo } from 'react';

import cx from 'classnames';

import { openNewTab } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Dropdown,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  LinkText,
  Logo,
  RadioGroup,
  Text,
  TextColor,
  TextSize,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';

import { snackbarMessages } from '@web/component-library';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { useAppState } from 'state';
import { openContactSalesPage } from 'utils/navigationUtils';

import WriterForChromeImg from '../../../assets/icons/chromeLogo.svg?react';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { extractPlaceholder } from '../../../utils/dropdownUtils';
import { getLogger } from '../../../utils/logger';
import DropdownTrigger, { DropdownTriggerVariant } from '../../molecules/DropdownTrigger';
import InfoIconText from '../../molecules/InfoIconText/InfoIconText';
import { PageDescription } from '../../molecules/PageDescription/PageDescription';
import {
  RoundedContainer,
  TRoundedContainerBackgroundColor,
  TRoundedContainerSize,
} from '../../molecules/RoundedContainer/RoundedContainer';
import { WildcardDomainsTable } from '../../molecules/WildcardDomainsTable/WildcardDomainsTable';
import type { UISecurityPageModel } from './UISecurityPageModel';
import { TChromeExtensionRestrictionType, contentRestrictionOptions } from './UISecurityPageModel';

import styles from './SecurityPage.module.css';

interface ISecurityPageNextProps {
  model: UISecurityPageModel;
}

const LOG = getLogger('SecurityPageNext');

const openSupportPage = () =>
  openNewTab('https://support.writer.com/article/68-managing-allowed-domains-for-writer-for-chrome');

const TooltipDefaultPreference: React.FC = () => (
  <div className={styles.infoTooltipContent}>
    <Text variant={TextSize.S} color={TextColor.WHITE} className={styles.paragraph}>
      Always check content: Writer suggestions will be automatically enabled in active text areas on supported websites.
    </Text>
    <Text variant={TextSize.S} color={TextColor.WHITE} className={styles.paragraph}>
      Don't check content: Writer suggestions will be disabled across all websites.
    </Text>
    <Text variant={TextSize.S} color={TextColor.WHITE} className={styles.paragraph}>
      Check content only if user opts in: Writer suggestions will enabled only if the user opts in for that URL at the
      time it's viewed.
    </Text>
    <LinkText className={cx(styles.underline, styles.tooltipLink)} onClick={openSupportPage}>
      Learn more
    </LinkText>
  </div>
);

const TooltipWildcard: React.FC = () => (
  <div className={styles.infoTooltipContent}>
    <Text variant={TextSize.S} color={TextColor.WHITE} className={styles.paragraph} bold>
      Wildcard operations
    </Text>
    <Text variant={TextSize.S} color={TextColor.WHITE} className={styles.paragraph}>
      Individual pages: www.acme.com/faq.html
      <br />
      Entire site: www.acme.com/*
      <br />
      Parts of site: www.acme.com/faq/*
      <br />
      Entire domain: *.acme.com
      <br />
    </Text>
  </div>
);

export const SecurityPage = observer<ISecurityPageNextProps>(({ model }) => {
  usePageTitle('Security');

  const { appModel } = useAppState();

  useEffect(() => {
    appModel.analyticsService.track(AnalyticsActivity.securityViewed, {});
  }, [appModel.analyticsService]);

  const { enqueueSuccessSnackbar } = useCustomSnackbar();
  const restrictionsOptions = useMemo(
    () => [
      {
        value: TChromeExtensionRestrictionType.enum.all,
        text: (
          <Text bold className={styles.radioGroupLabel} variant={TextSize.XXXL}>
            Always enable Writer on all websites
          </Text>
        ),
        additions: model.allWebsitesRestrictionVisible ? (
          <div className={styles.rowContainer}>
            <RoundedContainer className={styles.roundedContainer}>
              <RoundedContainer
                color={TRoundedContainerBackgroundColor.enum.green2}
                hasShadow={false}
                variant={TRoundedContainerSize.enum.M}
              >
                <div className={styles.rowIconTextWrapper}>
                  <Icon name={IconVariant.CHECK} className={styles.rowIcon} width={14} height={10} />
                  <Text variant={TextSize.XL} medium>
                    Writer is actively checking content on all supported websites.{' '}
                    <LinkText onClick={openSupportPage}>Learn more here</LinkText>.
                  </Text>
                </div>
              </RoundedContainer>
              <div className={styles.rowTextWrapper}>
                <Text variant={TextSize.XL}>
                  If you’d like to have Writer only check specific URLs or not check every web page automatically,
                  select “use rules” for advanced settings.
                </Text>
              </div>
            </RoundedContainer>
          </div>
        ) : undefined,
      },
      {
        value: TChromeExtensionRestrictionType.enum.approved,
        text: (
          <Text bold className={styles.radioGroupLabel} variant={TextSize.XXXL}>
            Use rules to specify where and how Writer should check content
          </Text>
        ),
        additions: (
          <div className={styles.rowContainer}>
            <RoundedContainer className={styles.roundedContainer}>
              <Text variant={TextSize.XXL} bold className={styles.rowHeaderText}>
                Select a default preference
              </Text>
              <Text variant={TextSize.XL} color={TextColor.GREY}>
                Select how you’d like Writer to check content by default. This default preference will apply for any
                website not listed under in the table below under “website-specific rules”.
              </Text>
              <div className={styles.rowFormWrapper}>
                <InfoIconText
                  title="Default preference"
                  titleSize={TextSize.XL}
                  titleBold
                  tooltipWidth={250}
                  description={<TooltipDefaultPreference />}
                />
                <Dropdown
                  containerClassName={cx(styles.dropdownContainer, {
                    [styles.disabled]: model.allWebsitesRestrictionVisible,
                  })}
                  options={model.contentCheckRestrictions}
                  trigger={
                    <DropdownTrigger
                      disabled={model.allWebsitesRestrictionVisible}
                      placeholder={extractPlaceholder(model.contentCheckRestrictions)}
                      variant={DropdownTriggerVariant.ROUNDED}
                    />
                  }
                  onPrimaryOptionClickAction={type => model.onChangeDefaultContentCheckRestriction(type)}
                />
              </div>
            </RoundedContainer>
            <RoundedContainer className={styles.roundedContainer}>
              <Text variant={TextSize.XXL} bold className={styles.rowHeaderText}>
                Create website-specific rules
              </Text>
              <Text variant={TextSize.XL} color={TextColor.GREY}>
                Select your preference for how Writer should check specific URLs. We’ll prioritize these rules in the
                order they are listed. You can add specific domains, subdomains, or pages. You can also use wildcard
                operations to add a broader set of URLs.
              </Text>
              <div className={styles.rowFormTableWrapper}>
                <InfoIconText
                  title={
                    <Text variant={TextSize.XL} color={TextColor.GREY}>
                      <LinkText onClick={openSupportPage}>Learn more</LinkText> about wildcards
                    </Text>
                  }
                  titleSize={TextSize.XL}
                  titleBold
                  tooltipWidth={250}
                  description={<TooltipWildcard />}
                />
                <WildcardDomainsTable
                  className={styles.formContainer}
                  options={model.wildcardOptions}
                  onAddNewOption={model.onAddNewWildcardOption}
                  onRemoveOption={model.onRemoveWildcardOption}
                  onOptionChange={model.onChangeWildcardOption}
                  restrictionOptions={contentRestrictionOptions}
                  checkDescriptionTooltipContent={<TooltipDefaultPreference />}
                  addNewOptionDisabled={model.allWebsitesRestrictionVisible}
                />
              </div>
            </RoundedContainer>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model.contentCheckRestrictions, model.allWebsitesRestrictionVisible],
  );

  const handleSaveConfig = async () => {
    try {
      await model.save();
      enqueueSuccessSnackbar(snackbarMessages.appSecurity.settingsUpdated);
    } catch (e) {
      LOG.error('Error while saving security config', e);
    }
  };

  const handleContactSalesClick = () => {
    appModel.analyticsService.track(AnalyticsActivity.clickedContactSales, {
      clicked_from: 'security_page',
    });

    openContactSalesPage();
  };

  if (appModel.assistantSubscription.isStarter) {
    return (
      <div className={styles.lockWrapper}>
        <BillingLock type={BillingLockType.SECURITY} onActionCallback={handleContactSalesClick} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerWrapper}>
        <Heading variant={HeadingVariant.H2} className={styles.containerHeader} upperCase>
          Security
        </Heading>
        <PageDescription
          title="Domain management"
          headingVariant={HeadingVariant.H3}
          description={
            <span className={styles.sectionText}>Configure Writer to check content only on specific websites.</span>
          }
        />
        <div className={styles.sectionContainer}>
          <PageDescription
            headingVariant={HeadingVariant.H3}
            icon={
              <div className={styles.chromeIconContainer}>
                <WriterForChromeImg className={styles.chromeIcon} />
                <Logo className={styles.chromeIconWriterLogo} />
              </div>
            }
            title="Writer for Chrome"
            descriptionSize={TextSize.XL}
            description={
              <span className={cx(styles.sectionText, styles.sectionTextSecondary)}>
                Select which websites you’d like the Writer Chrome extension to actively check.
              </span>
            }
          >
            <div className={styles.sectionActionWrapper}>
              <Button
                type={ButtonTypes.PRIMARY}
                content="Save"
                onClick={handleSaveConfig}
                className={styles.saveButton}
                isLoading={model.loading}
                disabled={!model.canSave}
              />
            </div>
          </PageDescription>
        </div>
        <div className={cx(styles.sectionContainerForm, styles.sectionContainer)}>
          <RadioGroup
            name="restrictionType"
            currentValue={model.restrictionType}
            className={styles.radioGroup}
            options={restrictionsOptions}
            onChange={e => model.onChangeRestrictionType(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
});
