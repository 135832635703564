import React from 'react';

import cx from 'classnames';

import { SUPPORT_EMAIL } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  LinkText,
  Modal,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import { openContactSalesPage } from '../../../../../utils/navigationUtils';

import generalStyles from '../styles.module.css';
import styles from './styles.module.css';

interface IDeleteMyAccount {
  open: boolean;
  toBillingHref: string;
  extendedWarning?: boolean;
  handleClose?: () => void;
}

export const DeleteMyAccount: React.FC<IDeleteMyAccount> = ({ open, toBillingHref, extendedWarning, handleClose }) => (
  <Modal
    open={open}
    title="Delete my account"
    className={generalStyles.modalContainer}
    handleClose={handleClose}
    warning
  >
    <div>
      <div className={generalStyles.content}>
        <Text variant={TextSize.M} className={styles.text}>
          To delete your account and all of your data, email
          <LinkText onClick={openContactSalesPage} className={styles.link}>
            {SUPPORT_EMAIL}
          </LinkText>
          , with the subject line "Delete my account.”
        </Text>
        <Heading variant={HeadingVariant.H4} className={styles.warningHeader}>
          But, are you sure you want to do this?
        </Heading>
        <div className={styles.redBackground}>
          <Text variant={TextSize.S} className={styles.text} bold>
            You’ll permanently lose:
          </Text>

          <ul className={styles.options}>
            <li>
              <Text variant={TextSize.S}>Access to Writer’s web agent and extensions</Text>
            </li>
            <li>
              <Text variant={TextSize.S}>All of your docs</Text>
            </li>
          </ul>

          {extendedWarning && (
            <Text variant={TextSize.S} className={styles.text} bold>
              If you’re the only user in your org, your org’s account will also be deleted. This means you’ll
              permanently lose:
            </Text>
          )}

          {extendedWarning && (
            <ul className={styles.options}>
              <li>
                <Text variant={TextSize.S}>All terms and snippets</Text>
              </li>
              <li>
                <Text variant={TextSize.S}>Portal pages, including your drafts and live pages</Text>
              </li>
              <li>
                <Text variant={TextSize.S}>Suggestion settings</Text>
              </li>
              <li>
                <Text variant={TextSize.S}>Any other docs in your org</Text>
              </li>
            </ul>
          )}

          <Text variant={TextSize.M} className={cx(styles.text, styles.privacyPolicy)} italic>
            <Icon name={IconVariant.LOCK_LINE} className={styles.lockIcon}></Icon>
            Learn more about our data privacy policy
            <a href="https://writer.com/privacy/" target="_blank" className={styles.link}>
              here
            </a>
            .
          </Text>
        </div>
      </div>
      <div className={generalStyles.actionButtonsHolder}>
        <Button
          type={ButtonTypes.BLACK}
          className={generalStyles.mainAction}
          navTo={toBillingHref}
          content="Got it"
        ></Button>
      </div>
    </div>
  </Modal>
);

export default DeleteMyAccount;
