import type { Reducer } from 'react';
import React, { useCallback, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import type Delta from 'quill-delta';
import type { Sources } from 'quill';
import Quill from 'quill';
import cx from 'classnames';
import noop from 'lodash/noop';
import throttle from 'lodash/throttle';
import QuillCursors from 'quill-cursors';
import {
  ALLOWED_QUILL_FORMATS,
  QA_COMMANDS_FORMAT_NAME,
  QA_COMMANDS_LOADING_FORMAT_NAME,
  QA_MAGIC_FORMAT_NAME,
  QA_REWRITE_FORMAT_NAME,
  QA_TEXTHIGHLIGHT_FORMAT_NAME,
  QL_SNIPPET_CLASS_PREFIX,
  QL_SNIPPET_HIGHLIGHT_FORMAT_NAME,
  QL_TEXTHIGHLIGHT_CLASS_PREFIX,
  QUILL_FORMAT,
  TRIGGER_SNIPPET_EVENT,
} from '@writercolab/quill-delta-utils';

import { DataRetentionDeletionBanner } from '@writercolab/ui-atoms';
import type { IBaseSidebarConfig, IIssue, IssueFlag } from '@writercolab/common-utils';
import { IssueCardType, IssueCategory, IssueType, TAssetToDelete } from '@writercolab/common-utils';
import { abbreviateNumber } from '@writercolab/utils';
import { ReactQuill } from '@writercolab/react-quill';
import type { SidebarModel } from '@writercolab/ui-sidebar-models';
import type { CommandsModel } from '@writercolab/ui-commands';
import type { components, RequestServiceInitialize } from '@writercolab/network';

import last from 'lodash/last';
import first from 'lodash/first';

import styles from './styles.module.css';

import FloatingCard, { FloatingCardType } from '../FloatingCard';
import 'quill/build/quill.snow.css';
import './_editor.css';

import {
  AutoWriteModule,
  CommandsModule,
  CustomQuillClipboard,
  CustomQuillHistory,
  FontsModule,
  Formatter,
  SnippetsDetector,
  SuggestionsModule,
  ToolbarModule,
  useMagicLinksModule,
} from '../../modules';
import {
  CommandsFormat,
  CommandsLoadingFormat,
  CustomEmojiFormat,
  ImageFormat,
  MagicHighlightFormat,
  RephraseHighlightFormat,
  SnippetFormat,
  TextHighlightFormat,
} from '../../formats';
import { AnalyticsActivity, type IWebAppAnalyticsTrack } from '../../analytics';
import { StrikeFormat } from '../../formats/strike';

Quill.register(
  {
    'modules/history': CustomQuillHistory,
    'modules/clipboard': CustomQuillClipboard,
    'modules/snippets': SnippetsDetector,
    'modules/suggestions': SuggestionsModule,
    'modules/cursors': QuillCursors,
    'modules/autowrite': AutoWriteModule,
    'modules/writerToolbar': ToolbarModule,
    'modules/commands': CommandsModule,
    'modules/formatter': Formatter,
    'modules/fonts': FontsModule,
  },
  true,
);

Quill.register(
  {
    'formats/emoji': CustomEmojiFormat,
    // 'formats/video': VideoFormat,
    'formats/image': ImageFormat,
    'formats/strike': StrikeFormat,
    [`formats/${QL_SNIPPET_HIGHLIGHT_FORMAT_NAME}`]: SnippetFormat,
    [`formats/${QA_TEXTHIGHLIGHT_FORMAT_NAME}`]: TextHighlightFormat,
    [`formats/${QA_REWRITE_FORMAT_NAME}`]: RephraseHighlightFormat,
    [`formats/${QA_MAGIC_FORMAT_NAME}`]: MagicHighlightFormat,
    [`formats/${QA_COMMANDS_FORMAT_NAME}`]: CommandsFormat,
    [`formats/${QA_COMMANDS_LOADING_FORMAT_NAME}`]: CommandsLoadingFormat,
  },
  true,
);

interface IEditorPure {
  baseConfig: IBaseSidebarConfig;
  analyticsService: IWebAppAnalyticsTrack;
  commandsModel: CommandsModel;
  requestService: RequestServiceInitialize['api'];
  currentIssueId: string;
  readOnly: boolean;
  visibleIssues: IIssue[];
  canWriteToTerms: boolean;
  isMagicLinksMode: boolean;
  magicLinkUrl: string;
  magicLinksSearchText: string;
  amountOfDomains: number;
  isRewriteEnabled: boolean;
  isLimitReached: boolean;
  limit: number;
  onLimitReached: () => void;
  onMagicIconClick: (amount: number, text: string, doSearch?: boolean) => void;

  onClaimResolve: (issue: IIssue) => Promise<any>;

  onClaimDecline: (issue: IIssue) => Promise<any>;

  onApplySnippet: (snippetId: string) => Promise<any>;

  onApplySuggestion: (replacement: string, issue: IIssue) => Promise<any> | undefined;
  // onFlagSuggestion: (issue: IIssue, flagType: IssueFlag) => Promise<any>;

  onFlagSuggestion: (issue: IIssue, flagType: IssueFlag, comment?: string | null) => any;
  onTextChange: (content: string, delta: Delta, source: Sources) => void;
  // onSuggestionClick: (issue: IIssue) => void;
  onAfterContentPaste?: (delta: Delta) => void;
  onSearchSnippet: (
    shortcuts: string[],
  ) => Promise<components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2'][]>;
  onCmdEnterClick: (triggeredFromShortcut: boolean, callback?: () => void) => void;
  onShareVideoClick: () => void;
  onCommandsError: (error?: string) => void;
  onCommandsSuccess: (message: string) => void;
  onContactSales: () => void;
  isCommandsEnabled: boolean;
  onUpgradeSubscription: () => void;
  isAskKnowledgeGraphEnabled: boolean;
  isKeepWritingEnabled: boolean;
  sidebarModel: SidebarModel;
  isFree: boolean;
  isVoiceEnabled: boolean;
  shouldShowDataDeletionBanner?: boolean;
  readableDeletionDate?: string;
  handleDataRetentionLearnMoreClick?: () => void;
  isToolbarHidden: boolean;
}

export interface IEditorForwardRef {
  quillRef: React.RefObject<Quill>;
  focusSuggestionHighlight: (issue: IIssue, skipScroll?: boolean) => void;
  replaceSuggestion: (issue: IIssue, replacement: string) => void;
  refreshCurrentHighlights: () => void;
}

type FloatingCardState = {
  triggeredOnClick: boolean;
  currentIssue?: IIssue;
  cardType: FloatingCardType;
  element?: HTMLElement;
  snippet?: components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2'];
};

enum FloatingCardAction {
  SET_ISSUE_AND_ELEMENT = 'SET_ISSUE_AND_ELEMENT',
  SHOW_CARD = 'SHOW_CARD',
  RESET = 'RESET',
  GENERAL = 'GENERAL',
}

type FloatingCardStateAction = {
  type: FloatingCardAction;
  payload?: Partial<FloatingCardState> & { triggeredOnClick?: boolean };
};

const initialFloatingCardState: FloatingCardState = {
  triggeredOnClick: false,
  cardType: FloatingCardType.NONE,
};

const floatingCardReducer = (state: FloatingCardState, action: FloatingCardStateAction) => {
  let newState = { ...state };

  switch (action.type) {
    case FloatingCardAction.RESET:
      newState = { ...initialFloatingCardState };
      break;
    case FloatingCardAction.SET_ISSUE_AND_ELEMENT:
      newState = {
        ...state,
        element: action.payload?.element,
        currentIssue: action.payload?.currentIssue,
      };
      break;
    case FloatingCardAction.SHOW_CARD:
      newState = {
        ...state,
        snippet: action.payload?.snippet,
        element: action.payload?.element || newState.element,
        cardType: action.payload?.cardType || FloatingCardType.NONE,
        triggeredOnClick: !!action.payload?.triggeredOnClick,
      };
      break;

    default:
      newState = { ...initialFloatingCardState, ...action.payload };
  }

  return newState;
};

export const EditorPure = React.forwardRef<IEditorForwardRef, IEditorPure>(
  (
    {
      baseConfig,
      requestService,
      commandsModel,
      currentIssueId,
      readOnly,
      onTextChange,
      visibleIssues,
      canWriteToTerms,
      analyticsService,
      onApplySnippet,
      // onSuggestionClick,
      onApplySuggestion,
      onClaimResolve,
      onClaimDecline,
      onFlagSuggestion,
      onAfterContentPaste,
      onSearchSnippet,
      onCmdEnterClick,
      onShareVideoClick,
      onCommandsError,
      onCommandsSuccess,
      onContactSales,
      isMagicLinksMode,
      onMagicIconClick,
      magicLinkUrl,
      magicLinksSearchText,
      amountOfDomains,
      onLimitReached,
      isLimitReached,
      limit,
      isCommandsEnabled,
      onUpgradeSubscription,
      isAskKnowledgeGraphEnabled,
      isKeepWritingEnabled,
      isRewriteEnabled,
      sidebarModel,
      isFree,
      shouldShowDataDeletionBanner,
      readableDeletionDate,
      handleDataRetentionLearnMoreClick,
      isToolbarHidden,
      isVoiceEnabled,
    },
    editorRef,
  ) => {
    const inlineIssueWaitingTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
    const quillRef = useRef<React.RefObject<Quill>>(null);
    const editorWrapperRef = useRef(null);
    const keyboardCallbacks = useRef({
      onCmdEnterClick,
      onAfterContentPaste,
      onShareVideoClick,
    });
    const { MagicLinksUI, onApplyLinkFormat, onChangeMagicSelection } = useMagicLinksModule(
      onMagicIconClick,
      magicLinkUrl,
      magicLinksSearchText,
      isMagicLinksMode,
      amountOfDomains,
      analyticsService,
      quillRef.current?.current ?? undefined,
    );

    const [floatingCardState, setFloatingCardState] = useReducer<Reducer<FloatingCardState, FloatingCardStateAction>>(
      floatingCardReducer,
      initialFloatingCardState,
    );

    const modules = {
      formatter: {
        onAfterShareVideoClick: keyboardCallbacks.current.onShareVideoClick,
      },
      toolbar: false,
      writerToolbar: {
        container: 'writer-editor-toolbar',
        formats: Object.values(QUILL_FORMAT),
        onApplyLinkFormatCb: onApplyLinkFormat,
      },
      clipboard: {
        hooks: {
          // onAfterPaste: (delta: Delta) => keyboardCallbacks.current?.onAfterContentPaste(delta), // TODO: check this
        },
      },
      uploader: {
        handler: () => {},
      },
      fonts: {},
      cursors: true,
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true,
      },
      commands: {
        config: baseConfig,
        editorWrapperRef,
        requestService,
        trigger: '/ask',
        model: commandsModel,
        onError: onCommandsError,
        onSuccess: onCommandsSuccess,
        isLockedByLimit: isLimitReached,
        limit: abbreviateNumber(limit, true),
        onLimitReached,
        onContactSales,
        onClickAutoWrite: (callback?: () => void) => keyboardCallbacks.current?.onCmdEnterClick(true, callback),
        isLocked: !isCommandsEnabled,
        isAskKnowledgeGraphEnabled,
        isKeepWritingEnabled,
        isRewriteEnabled,
        isVoiceEnabled,
        onUpgradeSubscription,
        isFree,
      },
      autowrite: {
        config: baseConfig,
        requestService,
      },
      keyboard: {
        bindings: {
          cmdEnter: {
            key: 'Enter',
            metaKey: true,
            handler: () => keyboardCallbacks.current?.onCmdEnterClick(true),
          },
          ctrlEnter: {
            key: 'Enter',
            ctrlKey: true,
            handler: () => keyboardCallbacks.current?.onCmdEnterClick(true),
          },
        },
      },
      suggestions: {},
      snippets: {
        onSearchSnippet,
      },
    };

    // in order to handle fn change and call correct fn in quill context
    // we need to handle fn updates for keyboard module
    useEffect(() => {
      keyboardCallbacks.current = {
        onCmdEnterClick,
        onAfterContentPaste,
        onShareVideoClick,
      };
    }, [onCmdEnterClick, onAfterContentPaste, onShareVideoClick]);

    const onReplaceSuggestion = (issue: IIssue, replacement: string) => {
      const editor = getEditor();

      if (!editor) {
        return;
      }

      const suggestions = editor.getModule('suggestions') as SuggestionsModule;
      suggestions.replaceSuggestion(issue, replacement);
      resetFloatingCard();
    };

    const onSnippetClick = () => {
      const editor = getEditor();

      if (!editor) {
        return;
      }

      if (floatingCardState.element) {
        (editor.getModule('snippets') as SnippetsDetector).insertSnippet(floatingCardState.element);
        resetFloatingCard();

        if (floatingCardState.snippet?.snippetId) {
          onApplySnippet(floatingCardState.snippet.snippetId);
        }
      }
    };

    const onIssuesChange = useCallback(
      (visibleIssues: IIssue[]) => {
        const editor = getEditor();

        if (!editor) {
          return;
        }

        const suggestions = editor.getModule('suggestions') as SuggestionsModule;
        suggestions.setCurrentIssuesList(visibleIssues);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currentIssueId],
    );

    const refreshCurrentHighlights = useCallback(() => {
      onIssuesChange([]);
      onIssuesChange(visibleIssues);
    }, [onIssuesChange, visibleIssues]);

    const onFocusSuggestionHighlight = useCallback((issue: IIssue, skipScroll?: boolean) => {
      const editor = getEditor();

      if (!editor) {
        return;
      }

      const suggestions = editor.getModule('suggestions') as SuggestionsModule;

      suggestions.onFocusSuggestionHighlight(issue, skipScroll);
    }, []);

    useImperativeHandle(editorRef, () => ({
      quillRef: quillRef.current!,
      focusSuggestionHighlight: onFocusSuggestionHighlight,
      replaceSuggestion: onReplaceSuggestion,
      refreshCurrentHighlights,
    }));

    useEffect(() => {
      onIssuesChange(visibleIssues);

      if (floatingCardState.currentIssue) {
        const currentIssue = visibleIssues.find(issue => issue.issueId === floatingCardState.currentIssue?.issueId);

        if (!currentIssue) {
          resetFloatingCard();
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleIssues, floatingCardState.currentIssue?.issueId, onIssuesChange]);

    useEffect(() => {
      const editor = getEditor();

      document.addEventListener('click', onClickOutsideIssue);

      const event = TRIGGER_SNIPPET_EVENT as any;

      editor?.root.addEventListener('scroll', onEditorScroll);
      editor?.root.addEventListener(event, onTriggerSnippetCard);

      return () => {
        editor?.root.removeEventListener('scroll', onEditorScroll);
        editor?.root.removeEventListener(event, onTriggerSnippetCard);
        document.removeEventListener('click', onClickOutsideIssue);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floatingCardState]);

    useEffect(() => {
      if (floatingCardState.currentIssue && floatingCardState.cardType === FloatingCardType.INLINE_CARD) {
        analyticsService.track(AnalyticsActivity.suggestionViewed, {
          suggestion_category: floatingCardState.currentIssue.category,
          suggestion_issue_type: floatingCardState.currentIssue.issueType,
          card_type: IssueCardType.INLINE,
          team_id: +baseConfig.workspaceId,
          organization_id: +baseConfig.organizationId,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analyticsService, baseConfig.workspaceId, floatingCardState]);

    const getEditor = () => quillRef.current?.current;

    const getIssueFromElement = useCallback(
      (element: HTMLElement) => {
        if (element.classList.contains(QL_TEXTHIGHLIGHT_CLASS_PREFIX)) {
          const issueId = element.getAttribute('data-id');
          const issue = visibleIssues.find(issue => issue.issueId === issueId);

          return issue;
        }

        return undefined;
      },
      [visibleIssues],
    );

    const getCardTypeFromElement = useCallback((element: HTMLElement): FloatingCardType => {
      if (element.classList.contains(QL_TEXTHIGHLIGHT_CLASS_PREFIX)) {
        return FloatingCardType.INLINE_CARD;
      }

      if (element.classList.contains(QL_SNIPPET_CLASS_PREFIX)) {
        return FloatingCardType.SNIPPET;
      }

      return FloatingCardType.NONE;
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onEditorScroll = useCallback(
      throttle(() => {
        const editor = getEditor();

        if (editor && floatingCardState.element && floatingCardState.cardType !== FloatingCardType.NONE) {
          const scrolledTop = editor.root.scrollTop;
          const elementTopPosition = floatingCardState.element.offsetTop + floatingCardState.element.offsetHeight;

          if (scrolledTop >= elementTopPosition) {
            resetFloatingCard();
          }
        }
      }, 100),
      [floatingCardState],
    );

    const onClickOutsideIssue = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const issue = getIssueFromElement(target);

      if (!issue) {
        resetFloatingCard();
      }
    };

    const calculateElementForIssue = (issue: IIssue): HTMLElement | undefined => {
      const editor = getEditor();

      const elements = editor?.root.querySelectorAll(TextHighlightFormat.getIssueClass(issue));

      if (issue.issueType === IssueType.PASSIVE_VOICE || issue.issueType === IssueType.READABILITY) {
        return last(elements) as HTMLElement;
      } else {
        return first(elements) as HTMLElement;
      }
    };

    const onEditorClick = (event: React.MouseEvent) => {
      const target = event.target as HTMLElement;
      const issue = getIssueFromElement(target);

      resetFloatingCard();

      if (issue && issue.issueType !== IssueType.DICTIONARY) {
        const element = event.target as HTMLElement;

        sidebarModel.issues?.setSelectedIssue(issue, 'editor');

        if (canShowInlineSuggestion(issue)) {
          setFloatingCardState({
            type: FloatingCardAction.SET_ISSUE_AND_ELEMENT,
            payload: {
              currentIssue: issue,
              element: calculateElementForIssue(issue) || element,
            },
          });
          showInlineSuggestion(true);
        }
      }
    };

    const onMouseMove = (event: React.MouseEvent) => {
      if (floatingCardState.triggeredOnClick) {
        return;
      }

      const target = event.target as HTMLElement;
      const elementType = getCardTypeFromElement(target);

      if (elementType === FloatingCardType.NONE) {
        resetFloatingCard();
      }

      switch (elementType) {
        case FloatingCardType.INLINE_CARD:
          mouseMoveOverInlineSuggestion(target);
          break;

        case FloatingCardType.SNIPPET:
          showSnippet(target);
          break;

        default:
          break;
      }
    };

    const onKeyDownCapture = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (floatingCardState.snippet) {
          onSnippetClick();
        }
      }
    };

    const mouseMoveOverInlineSuggestion = (element: HTMLElement) => {
      const issue = getIssueFromElement(element);

      if (issue && canShowInlineSuggestion(issue)) {
        startWaitingInlineSuggestion(issue, element);
      } else {
        clearTimeout(inlineIssueWaitingTimeout.current);
      }
    };

    const startWaitingInlineSuggestion = (issue: IIssue, element: HTMLElement) => {
      if (floatingCardState.currentIssue?.issueId === issue.issueId) {
        return;
      } else {
        resetFloatingCard();
      }

      setFloatingCardState({
        type: FloatingCardAction.SET_ISSUE_AND_ELEMENT,
        payload: {
          currentIssue: issue,
          element: calculateElementForIssue(issue) || element,
        },
      });

      clearTimeout(inlineIssueWaitingTimeout.current);
      inlineIssueWaitingTimeout.current = setTimeout(showInlineSuggestion, 500);
    };

    const showSnippet = useCallback(
      (element: HTMLElement, sticky = false) => {
        const editor = getEditor();

        if (!editor) {
          return;
        }

        const snippetBlotData = (editor.getModule('snippets') as SnippetsDetector).getSnippetMeta(element);

        if (snippetBlotData && floatingCardState.snippet?.snippetId !== snippetBlotData.snippet.snippetId) {
          setFloatingCardState({
            type: FloatingCardAction.SHOW_CARD,
            payload: {
              element,
              snippet: snippetBlotData.snippet,
              cardType: FloatingCardType.SNIPPET,
              triggeredOnClick: sticky,
            },
          });

          if (sticky) {
            analyticsService.track(AnalyticsActivity.snippetTriggered, {
              id: snippetBlotData.snippet.snippetId,
              snippet: snippetBlotData.snippet.snippet,
              team_id: +baseConfig.workspaceId,
            });
          }
        }
      },
      [analyticsService, baseConfig.workspaceId, floatingCardState.snippet?.snippetId],
    );

    const onTriggerSnippetCard = useCallback(
      ({
        detail,
      }: {
        detail: {
          element: HTMLElement;
        };
      }) => {
        showSnippet(detail.element, true);
      },
      [showSnippet],
    );

    const showInlineSuggestion = (triggeredOnClick?: boolean) => {
      clearTimeout(inlineIssueWaitingTimeout.current);
      setFloatingCardState({
        type: FloatingCardAction.SHOW_CARD,
        payload: {
          triggeredOnClick,
          cardType: FloatingCardType.INLINE_CARD,
        },
      });
    };

    const _onApplySuggestion = (replacement: string, issue: IIssue) => {
      resetFloatingCard();

      return onApplySuggestion(replacement, issue);
    };

    const _onClaimResolveClick = (issue: IIssue) => {
      resetFloatingCard();

      return onClaimResolve(issue);
    };

    const _onClaimDeclineClick = (issue: IIssue) => {
      resetFloatingCard();

      return onClaimDecline(issue);
    };

    const _onFlagSuggestion = (issue: IIssue, flagType: IssueFlag, comment?: string | null) => {
      resetFloatingCard();

      return onFlagSuggestion(issue, flagType, comment);
    };

    const canShowInlineSuggestion = (currentIssue: IIssue) => {
      // all banned words can be displayed inline
      // all dictionary terms can be shown inline
      // all claims\quotes can be show inline
      if (
        IssueType.DICTIONARY === currentIssue.issueType ||
        [IssueCategory.Claim, IssueCategory.Quote, IssueCategory.BannedWords, IssueCategory.UseCarefully].includes(
          currentIssue.category,
        )
      ) {
        return true;
      }

      // other inline cards are allowed only if there are suggestions available
      return currentIssue.suggestions.length;
    };

    const onQuillTextChange = useCallback(
      (content: string, delta: Delta, source: Sources) => {
        if (source === 'user') {
          resetFloatingCard();
        }

        onTextChange(content, delta, source);
      },
      [onTextChange],
    );

    const resetFloatingCard = () => setFloatingCardState({ type: FloatingCardAction.RESET });

    return (
      <div
        className={cx(styles.container, 'writer-editor')}
        spellCheck="false"
        data-enable-grammarly="false"
        data-gramm="false"
        data-gramm_editor="false"
        ref={editorWrapperRef}
      >
        <div
          className={cx(styles.quillWrapper, { [styles.isToolbarHidden]: isToolbarHidden })}
          onClick={onEditorClick}
          onMouseMove={onMouseMove}
          onKeyDownCapture={onKeyDownCapture}
        >
          <ReactQuill
            className={styles.editor}
            style={{ height: '100%' }}
            readOnly={readOnly}
            placeholder={
              isCommandsEnabled
                ? "Type '/ask' to generate content with commands. Or, try an agent."
                : "Let's get started! Type here to begin scoring your content"
            }
            formats={ALLOWED_QUILL_FORMATS}
            theme="snow"
            defaultValue=""
            onChange={onQuillTextChange}
            onChangeSelection={isMagicLinksMode ? onChangeMagicSelection : noop}
            modules={modules}
            ref={quillRef}
          />
        </div>
        {shouldShowDataDeletionBanner && readableDeletionDate && (
          <DataRetentionDeletionBanner
            className={styles.dataDeletionBanner}
            asset={TAssetToDelete.enum.Doc}
            readableDate={readableDeletionDate}
            onLearnMoreClick={handleDataRetentionLearnMoreClick}
          />
        )}
        <div
          id="writer-editor-toolbar"
          className={cx(styles.quillToolbar, { [styles.isHidden]: isToolbarHidden })}
        ></div>
        <FloatingCard
          issue={floatingCardState.currentIssue}
          element={floatingCardState.element}
          snippet={floatingCardState.snippet}
          cardType={floatingCardState.cardType}
          canWriteToTerms={canWriteToTerms}
          onSnippetClick={onSnippetClick}
          onClaimResolveClick={_onClaimResolveClick}
          onClaimDeclineClick={_onClaimDeclineClick}
          onApplySuggestionCallback={_onApplySuggestion}
          onFlagSuggestionCallback={_onFlagSuggestion}
        />

        {MagicLinksUI}
      </div>
    );
  },
);
EditorPure.displayName = 'EditorPure';
