import { isEmpty } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';

import { wordPluralize } from '@writercolab/common-utils';
import { ReactiveQueue } from '@writercolab/mobx';
import { ApiError } from '@writercolab/network';
import type { components } from '@writercolab/network';
import { NotificationQueueItemType, TNotificationQueueItem } from '@writercolab/types';

import { TVoicePreviewMode, TVoiceTraitType, TVoiceType } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { VoiceApiModel } from 'models/voice.api';

export const VOICE_CONSTRUCTOR_NAME_LIMIT = {
  MAX: 40,
};
export const VOICE_CONSTRUCTOR_DESCRIPTION_LIMIT = {
  MAX: 90,
};
export const VOICE_TRAITS_LIMIT = {
  MIN: 3,
  MAX: 5,
};
export const VOICE_TRAITS = [
  {
    id: 'casual',
    name: 'Casual',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['formal', 'professional', 'distanced', 'dry', 'conservative'],
  },
  {
    id: 'formal',
    name: 'Formal',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['casual', 'familiar', 'approachable', 'conversational', 'friendly'],
  },
  {
    id: 'familiar',
    name: 'Familiar',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['formal', 'professional', 'distanced', 'dry', 'conservative'],
  },
  {
    id: 'professional',
    name: 'Professional',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['casual', 'familiar', 'approachable', 'conversational', 'friendly'],
  },
  {
    id: 'approachable',
    name: 'Approachable',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['formal', 'professional', 'distanced', 'dry', 'conservative'],
  },
  {
    id: 'distanced',
    name: 'Distanced',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['casual', 'familiar', 'approachable', 'conversational', 'friendly'],
  },
  {
    id: 'conversational',
    name: 'Conversational',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['formal', 'professional', 'distanced', 'dry', 'conservative'],
  },
  {
    id: 'dry',
    name: 'Dry',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['casual', 'familiar', 'approachable', 'conversational', 'friendly'],
  },
  {
    id: 'friendly',
    name: 'Friendly',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['formal', 'professional', 'distanced', 'dry', 'conservative'],
  },
  {
    id: 'conservative',
    name: 'Conservative',
    type: TVoiceTraitType.enum.casualFormal,
    negates: ['casual', 'familiar', 'approachable', 'conversational', 'friendly'],
  },
  {
    id: 'authoritative',
    name: 'Authoritative',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'trustworthy',
    name: 'Trustworthy',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'confident',
    name: 'Confident',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'motivating',
    name: 'Motivating',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'supportive',
    name: 'Supportive',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'aspirational',
    name: 'Aspirational',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'informative',
    name: 'Informative',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'analytical',
    name: 'Analytical',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'empowering',
    name: 'Empowering',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'journalistic',
    name: 'Journalistic',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'serious',
    name: 'Serious',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'objective',
    name: 'Objective',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'persuasive',
    name: 'Persuasive',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'concise',
    name: 'Concise',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'straightforward',
    name: 'Straightforward',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'dynamic',
    name: 'Dynamic',
    type: TVoiceTraitType.enum.tone,
  },
  {
    id: 'funny',
    name: 'Funny',
    type: TVoiceTraitType.enum.personality,
  },
  {
    id: 'witty',
    name: 'Witty',
    type: TVoiceTraitType.enum.personality,
  },
  {
    id: 'snarky',
    name: 'Snarky',
    type: TVoiceTraitType.enum.personality,
  },
  {
    id: 'sarcastic',
    name: 'Sarcastic',
    type: TVoiceTraitType.enum.personality,
  },
  {
    id: 'playful',
    name: 'Playful',
    type: TVoiceTraitType.enum.personality,
  },
  {
    id: 'clever',
    name: 'Clever',
    type: TVoiceTraitType.enum.personality,
  },
  {
    id: 'irreverent',
    name: 'Irreverent',
    type: TVoiceTraitType.enum.personality,
  },
  {
    id: 'edgy',
    name: 'Edgy',
    type: TVoiceTraitType.enum.personality,
  },
];
export const VOICE_PREFERENCES = [
  {
    title: 'Use we, our, us',
    description: 'e.g., We’re excited to announce the release of Writer for Mac, our desktop app for MacOS.',
  },
  {
    title: 'Use you to refer to the audience',
    description: 'e.g., If you use MacOS, you can install our Writer for Mac desktop app.',
  },
  {
    title: 'Use I and first-person perspective',
    description: 'e.g., I’m excited to announce the release of Writer for Mac, our desktop app for MacOS.',
  },
];

export interface IVoiceConstructorModalUIModel {
  api: VoiceApiModel;
  analyticsService: IWebAppAnalyticsTrack;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
  aiStudioMode: boolean;
  voiceLimits: () => { limit: number; exceeded: boolean };
  onClose: (id: string) => void;
}

export class VoiceConstructorModalUIModel {
  api: VoiceApiModel;
  traits: string[] = [];
  preferences: (boolean | null | undefined)[] = Array.from({ length: VOICE_PREFERENCES.length });
  previews: components['schemas']['content_generation_model_VoiceExample'][] = [];
  selectedExampleId = 0;
  previewMode: typeof TVoicePreviewMode.type = TVoicePreviewMode.enum.Original;
  isInputsEnabled = true;
  isPreviewLoading = false;
  isEditingEnabled = false;
  name = '';
  description = '';
  selectedVoice: components['schemas']['content_generation_dto_VoiceResponse'] | null = null;
  isSaving = false;
  notificationQueue: ReactiveQueue<TNotificationQueueItem>;

  constructor(private opts: IVoiceConstructorModalUIModel) {
    this.notificationQueue = opts.notificationQueue || new ReactiveQueue();

    makeObservable(this, {
      traits: observable.struct,
      preferences: observable.struct,
      previews: observable.struct,
      selectedExampleId: observable,
      previewMode: observable,
      isInputsEnabled: observable,
      isPreviewLoading: observable,
      isEditingEnabled: observable,
      name: observable,
      description: observable,
      selectedVoice: observable,
      isSaving: observable,
      selectedPreferences: computed.struct,
      isPreviewDisabled: computed,
      saveDisabledMessage: computed,
      reset: action,
      setIsEditingEnabled: action,
      setName: action,
      setDescription: action,
      setSelectedVoice: action,
      setTraits: action,
      setPreferences: action,
      toggleTrait: action,
      togglePreference: action,
      setSelectedExampleId: action,
      setPreviewMode: action,
    });

    this.api = opts.api;
  }

  get selectedPreferences() {
    return this.preferences.filter(preference => preference !== undefined);
  }

  get isPreferencesSelected() {
    return !this.preferences.includes(undefined);
  }

  get isPreviewDisabled() {
    return this.preferences.includes(undefined) || this.traits.length < VOICE_TRAITS_LIMIT.MIN || !this.isInputsEnabled;
  }

  get saveDisabledMessage() {
    if (isEmpty(this.name)) {
      return 'Name the voice before saving';
    }

    if (this.selectedVoice === null) {
      const voiceLimits = this.opts.voiceLimits();
      if (voiceLimits.exceeded) {
        return `Your org has reached the ${voiceLimits.limit} ${wordPluralize(voiceLimits.limit, 'voice')} limit on your plan.`;
      }
    }

    return undefined;
  }

  reset = () => {
    this.traits = [];
    this.preferences = Array.from({ length: VOICE_PREFERENCES.length });
    this.previews = [];
    this.selectedExampleId = 0;
    this.previewMode = TVoicePreviewMode.enum.Original;
    this.isInputsEnabled = true;
    this.isPreviewLoading = false;
    this.isEditingEnabled = false;
    this.name = '';
    this.description = '';
  };

  setIsEditingEnabled = (isEnabled: boolean) => {
    this.isEditingEnabled = isEnabled;
  };

  setName = (name: string) => {
    this.name = name;
  };

  setDescription = (description: string) => {
    this.description = description;
  };

  setSelectedVoice = (voice: components['schemas']['content_generation_dto_VoiceResponse'] | null) => {
    this.selectedVoice = voice;
  };

  setTraits = (traits: string[]) => {
    this.traits = traits;
  };

  isTraitSelected = (id: string) => this.traits.includes(id);

  isTraitDisabled = (id: string) => !this.isTraitSelected(id) && this.traits.length >= VOICE_TRAITS_LIMIT.MAX;

  isTraitNegated = (id: string) =>
    this.traits.some(trait => VOICE_TRAITS.find(t => t.id === trait)?.negates?.includes(id));

  setPreferences = (preferences: (boolean | null | undefined)[]) => {
    this.preferences = preferences;
  };

  toggleTrait = (id: string) => {
    this.traits = this.traits.includes(id) ? this.traits.filter(t => t !== id) : [...this.traits, id];

    if (!this.isPreviewLoading) {
      this.isInputsEnabled = true;
    }
  };

  togglePreference = (index: number, value: boolean | null) => {
    this.preferences = this.preferences.map((preference, i) => {
      if (index === i && value !== preference) {
        if (!this.isPreviewLoading) {
          this.isInputsEnabled = true;
        }

        return value;
      } else {
        return preference;
      }
    });
  };

  setSelectedExampleId = (id: number) => {
    this.selectedExampleId = id;
  };

  setPreviewMode = (mode: typeof TVoicePreviewMode.type) => {
    this.previewMode = mode;
  };

  getVoicePreviews = async () => {
    this.previewMode = TVoicePreviewMode.enum.Original;
    this.isInputsEnabled = false;
    this.isPreviewLoading = true;
    this.previews = [];

    try {
      const data = await this.api.getVoicePreviews(
        this.traits.map(trait => ({ name: trait })),
        this.preferences[0],
        this.preferences[1],
        this.preferences[2],
      );
      this.previews = data.result;
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }

    this.isPreviewLoading = false;
    this.previewMode = TVoicePreviewMode.enum.Updated;
  };

  addVoice = async () => {
    try {
      this.isSaving = true;
      const voice = await this.api.addVoice({
        name: this.name,
        description: this.description,
        type: TVoiceType.enum.Manual,
        sampleText: undefined,
        profile: undefined,
        overview: undefined,
        settings: {
          traits: this.traits.map(t => ({ name: t })),
          pronouns: this.preferences[0],
          secondPerson: this.preferences[1],
          firstPerson: this.preferences[2],
        },
        rewrite: false,
      });
      this.opts.analyticsService.track(
        this.opts.aiStudioMode ? AnalyticsActivity.aiStudioVoiceCreated : AnalyticsActivity.voiceCreated,
        {
          method: 'manual',
          voice_name: this.name,
        },
      );
      this.isSaving = false;
      this.opts.onClose(voice.id);
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }
  };

  updateVoice = async () => {
    if (this.selectedVoice) {
      try {
        this.isSaving = true;
        const voice = await this.api.updateVoice(this.selectedVoice.id, {
          name: this.name,
          description: this.description,
          type: TVoiceType.enum.Manual,
          sampleText: undefined,
          profile: undefined,
          overview: undefined,
          settings: {
            traits: this.traits.map(t => ({ name: t })),
            pronouns: this.preferences[0],
            secondPerson: this.preferences[1],
            firstPerson: this.preferences[2],
          },
          rewrite: this.selectedVoice.rewrite,
        });
        this.opts.analyticsService.track(
          this.opts.aiStudioMode ? AnalyticsActivity.aiStudioVoiceEdited : AnalyticsActivity.voiceEdited,
          {
            voice_name: this.name,
          },
        );
        this.isSaving = false;
        this.opts.onClose(voice.id);
      } catch (error) {
        if (error instanceof ApiError) {
          this.notificationQueue.enqueue({
            type: NotificationQueueItemType.enum.error,
            message: 'Something went wrong',
          });
        }
      }
    }
  };
}
