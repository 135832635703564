import React from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingColor,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  UserNotificationBanner,
} from '@writercolab/ui-atoms';
import { ThreeDotsLoader } from '@writercolab/ui-molecules';

import type { TOAuthAppsTypes } from '@web/types';
import { OAuthAppsTypes } from '@web/types';
import { Lock } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { openContactSalesPage } from 'utils/navigationUtils';

import { InputLabel } from '../../molecules/InputLabel/InputLabel';
import { InputField } from '../InputField/InputField';
import type {
  ConfluenceFormModel,
  GDriveFormModel,
  NotionFormModel,
  OAuthAppsViewerUIModel,
  SharepointFormModel,
  TConnectorReturnModel,
} from './OAuthAppsViewerModel.ui';

import styles from './OAuthAppsViewer.module.css';

interface IOAuthAppsViewerProps {
  model: OAuthAppsViewerUIModel;
}

interface IConnectorFormProps {
  type: TOAuthAppsTypes;
  formModel: TConnectorReturnModel;
}

export const OAuthAppsViewer = observer(({ model }: IOAuthAppsViewerProps) => {
  const handleSelectConnector = (connectorType: TOAuthAppsTypes) => () => {
    model.setConnectorType(connectorType);
  };

  const handleSubmit = () => {
    model.onSubmit();
  };

  return (
    <>
      {model.isShowCreateStep && (
        <div className={styles.stepWrapper}>
          <div className={styles.stepHeadWrapper}>
            <div className={styles.stepTitleWrapper}>
              <Heading variant={HeadingVariant.H4} color={HeadingColor.BLACK}>
                Set up an OAuth application
              </Heading>
            </div>
            <div>
              <Text variant={TextSize.XL} color={TextColor.BLACK} as="span">
                To begin, select a data source
                <br />
                Not seeing a data source you need? Reach out to
              </Text>{' '}
              <a href="mailto:support@writer.com" className={styles.link}>
                support@writer.com
              </a>
            </div>

            {!model.isAdvancedConnectorsEnabled && (
              <UserNotificationBanner
                type="info"
                className={styles.limitedConnectorsBanner}
                notification={<>To access advanced data connectors, upgrade your plan.</>}
                action={{
                  title: 'Contact sales',
                  onClick: () => {
                    openContactSalesPage();
                  },
                }}
              />
            )}
          </div>
          <div className={styles.cardsContainer}>
            {model.connectors.map(connector => {
              const icon = IconVariant[connector.type.toUpperCase()];
              const isConnectorBlocked = model.isConnectorBlocked(connector.type);

              return (
                <Tooltip
                  key={connector.type}
                  placement="top"
                  title={'This is an enterprise feature'}
                  disabled={!isConnectorBlocked}
                >
                  <Button
                    className={cx(styles.card, {
                      [styles.disabled]: isConnectorBlocked,
                    })}
                    key={connector.type}
                    content={
                      <>
                        {connector.name}
                        {isConnectorBlocked && <Lock width={12} height={12} className={styles.lockIcon} />}
                      </>
                    }
                    type={ButtonTypes.TRANSPARENT_HOVER}
                    size={SizeTypes.XXXL}
                    icon={<Icon name={icon ? IconVariant[icon] : IconVariant.ALERT} width={32} height={32} />}
                    onClick={handleSelectConnector(connector.type)}
                    disabled={isConnectorBlocked}
                  />
                </Tooltip>
              );
            })}
          </div>
        </div>
      )}
      {model.getSelectedConnectorLoading() && (
        <div className={styles.loaderWrapper}>
          <ThreeDotsLoader />
        </div>
      )}
      {model.connectorData && model.connectorForm && (
        <div className={styles.editStepWrapper}>
          <>
            <div className={styles.stepHeadWrapper}>
              <div className={styles.stepTitleWrapper}>
                <Icon name={model.connectorData.icon} width={32} height={32} />
                <Heading variant={HeadingVariant.H2} color={HeadingColor.GREY} upperCase>
                  {model.connectorData.name}
                </Heading>
              </div>
              <div className={styles.stepDescriptionWrapper}>
                <Text
                  variant={TextSize.XL}
                  color={TextColor.BLACK}
                  dangerouslySetInnerHTML={{ __html: model.connectorData.description }}
                />
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.linkWrapper}>
                  <Icon name={IconVariant.SETUP} />
                  <a href={model.connectorData.setupGuideUrl} target="_blank" className={styles.link}>
                    OAuth app setup guide
                  </a>
                </div>
                <div className={styles.linkWrapper}>
                  <Icon name={IconVariant.LIFEBUOY} />
                  <a href={model.connectorData.settingUpUrl} target="_blank" className={styles.link}>
                    Setting up Knowledge Graph
                  </a>
                </div>
              </div>
            </div>
            <form className={styles.form}>
              <div className={styles.formWrapper}>
                <ConnectorForm formModel={model.connectorForm} type={model.connectorData.type} />
              </div>

              <div className={styles.formActionsWrapper}>
                <Button type={ButtonTypes.BORDERED} onClick={model.onCancel}>
                  Never mind
                </Button>
                <Button
                  type={ButtonTypes.PRIMARY}
                  onClick={handleSubmit}
                  isLoading={model.api.isLoading}
                  disabled={!!model.connectorForm.error || !model.connectorForm.touchedForm}
                >
                  Save application
                </Button>
              </div>
            </form>
          </>
        </div>
      )}
    </>
  );
});

export const ConnectorForm = observer(({ type, formModel }: IConnectorFormProps) => {
  return OAuthAppsTypes.match(
    type,
    {
      gdrive: () => {
        const form = formModel as ReturnType<typeof GDriveFormModel>;

        return (
          <>
            <div className={styles.formRow}>
              <InputField field={form.form.name}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="APPLICATION NAME" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.developerKey}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="DEVELOPER KEY" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.clientId}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="CLIENT ID" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.clientSecret}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="CLIENT SECRET" required />
              </InputField>
            </div>
          </>
        );
      },
      sharepoint: () => {
        const form = formModel as ReturnType<typeof SharepointFormModel>;

        return (
          <>
            <div className={styles.formRow}>
              <InputField field={form.form?.name}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="APPLICATION NAME" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.clientId}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="CLIENT ID" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.clientSecret}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="CLIENT SECRET" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.tenantId}>
                <InputLabel
                  className={styles.formLabel}
                  textSize={TextSize.XS}
                  label="TENANT ID"
                  description="Enter your tenant ID if your Azure application is single tenant"
                />
              </InputField>
            </div>
          </>
        );
      },
      notion: () => {
        const form = formModel as ReturnType<typeof NotionFormModel>;

        return (
          <>
            <div className={styles.formRow}>
              <InputField field={form.form?.name}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="APPLICATION NAME" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.clientId}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="CLIENT ID" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.clientSecret}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="CLIENT SECRET" required />
              </InputField>
            </div>
          </>
        );
      },
      confluence: () => {
        const form = formModel as ReturnType<typeof ConfluenceFormModel>;

        return (
          <>
            <div className={styles.formRow}>
              <InputField field={form.form?.name}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="APPLICATION NAME" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.clientId}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="CLIENT ID" required />
              </InputField>
            </div>
            <div className={styles.formRow}>
              <InputField field={form.form?.clientSecret}>
                <InputLabel className={styles.formLabel} textSize={TextSize.XS} label="CLIENT SECRET" required />
              </InputField>
            </div>
          </>
        );
      },
    },
    null,
  );
});
