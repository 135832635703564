import type { ChangeEvent } from 'react';
import type React from 'react';
import { useCallback, useMemo } from 'react';

import cx from 'classnames';

import type { IBillingPlan, TClickedFromParam } from '@writercolab/common-utils';
import {
  BillingAction,
  BillingInterval,
  BillingProduct,
  BillingStatus,
  formatAmount,
  PlanStatusLabelColor,
  PlanStatusReadable,
} from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  LabelledTags,
  RadioGroup,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import { AnalyticEvents, AnalyticsActivity } from 'constants/analytics';

import contactSales from '../../../../assets/backgrounds/contactSales.png';
import analytics from '../../../../services/analytics/analyticsService';

import styles from './styles.module.css';

interface IBillingPlansProps {
  billingInterval: BillingInterval;
  onChangeBillingInterval: (interval: BillingInterval) => void;
  onChangeBillingPlan: (plan: IBillingPlan, action: BillingAction) => void;
  currentPlan?: IBillingPlan;
  status?: BillingStatus;
  free?: IBillingPlan;
  pro?: IBillingPlan;
  team?: IBillingPlan;
  starter?: IBillingPlan;
  isTrialExpired?: boolean;
  onContactSalesClick?: (clickFrom: TClickedFromParam) => void;
  isTeamDisabled: boolean;
}

interface IBillingPlanProps {
  className: string;
  heading: string;
  description: string;
  price?: number;
  isFree?: boolean;
  isEnterprise?: boolean;
  isTeam?: boolean;
  isStarter?: boolean;
  interval: BillingInterval;
  onButtonClick?: () => void;
  action?: BillingAction;
  features: string[];
  featuresHeading: string;
  featuresListClassName: string;
  actionDisabled?: boolean;
  currentLabel: React.ReactNode | null;
  onContactSalesClick?: (clickFrom: TClickedFromParam) => void;
}

const _computeCurrentStatusLabel = (
  productName: BillingProduct,
  isCurrent: boolean,
  status?: BillingStatus,
  isTrialExpired?: boolean,
) => {
  if (!isCurrent || !status) {
    return null;
  }

  if (isTrialExpired && productName === BillingProduct.FREE) {
    return null;
  }

  const _planLabel = isTrialExpired ? PlanStatusReadable._FE_TRIAL_EXPIRED : PlanStatusReadable[status];

  return <LabelledTags bgColor={PlanStatusLabelColor[status]}>{_planLabel}</LabelledTags>;
};

const _trackButtonClick = (
  activity: AnalyticEvents,
  activityProperties: Record<string, any> = {
    clicked_from: 'all_plans_widget',
  },
) => analytics.track(activity, activityProperties);

const BillingPlan: React.FC<IBillingPlanProps> = ({
  className,
  onButtonClick,
  heading,
  price,
  action,
  description,
  featuresHeading,
  features,
  featuresListClassName,
  interval,
  isEnterprise,
  isFree,
  isTeam,
  isStarter,
  actionDisabled,
  currentLabel,
  onContactSalesClick,
}) => {
  const handleContactSalesClick = useCallback(
    () => onContactSalesClick && onContactSalesClick('all_plans_widget'),
    [onContactSalesClick],
  );

  const priceConverted = useMemo(() => {
    const amount = formatAmount(price ?? 0);
    return interval === BillingInterval.YEAR && isStarter ? amount / 12 : amount;
  }, [interval, price, isStarter]);

  const priceText = useMemo(() => {
    if (isStarter) {
      return (
        <div className={styles.priceAttributes}>
          <Text variant={TextSize.M}>{'per user / month'}</Text>
        </div>
      );
    }

    if (interval === BillingInterval.MONTH) {
      return (
        <div className={styles.priceAttributes}>
          <Text variant={TextSize.M}>{isTeam ? 'per user/month' : 'per month'}</Text>
        </div>
      );
    } else {
      return (
        <div className={styles.priceAttributes}>
          <Text variant={TextSize.M}>{isTeam ? 'per user/year' : 'per year'}</Text>
        </div>
      );
    }
  }, [isTeam, isStarter, interval]);

  const actionButton = useMemo(() => {
    let content = 'Buy now';
    let type = ButtonTypes.PRIMARY;

    if (action === BillingAction.CONTACT) {
      return (
        <Button
          type={ButtonTypes.BLACK}
          content="Contact sales"
          className={styles.planSubscribe}
          onClick={handleContactSalesClick}
        />
      );
    }

    if (action === BillingAction.NONE) {
      return <Button type={ButtonTypes.BORDERED} content="You're on this plan" className={styles.planSubscribe} />;
    }

    switch (action) {
      case BillingAction.ACTIVATE_FREE:
        content = 'Activate Free plan';
        break;
      case BillingAction.BUY:
        content = 'Purchase now';
        type = ButtonTypes.BLACK;
        break;
      case BillingAction.TRIAL:
        content = 'Start a 14-day free trial';
        type = ButtonTypes.BORDERED;
        break;
      case BillingAction.TRIAL_HIGHLIGHTED:
        content = 'Start a 14-day free trial';
        break;
      case BillingAction.UPGRADE:
        content = 'Upgrade now';
        break;
      case BillingAction.RESUBSCRIBE:
        content = 'Resubscribe';
        break;
      case BillingAction.SWITCH:
        content = `Switch to ${interval === BillingInterval.MONTH ? 'monthly' : 'yearly'}`;
        break;
      case BillingAction.MANAGE_PLAN:
        content = "You're on this plan";
        type = ButtonTypes.BORDERED;
        break;
      default:
        break;
    }

    return (
      <Button
        type={type}
        content={content}
        className={cx(styles.planSubscribe, {
          [styles.planSubscribeDisabled]: actionDisabled,
        })}
        onClick={onButtonClick}
        disabled={actionDisabled}
      />
    );
  }, [action, interval, onButtonClick, actionDisabled, handleContactSalesClick]);

  return (
    <div className={cx(styles.plan, className)}>
      <div className={styles.planHeader}>
        <div>
          <Heading variant={HeadingVariant.H3}>{heading}</Heading>
        </div>
        {currentLabel}
      </div>
      <Text variant={TextSize.XL} className={styles.planDescription} bold>
        {description}
      </Text>
      {isEnterprise && (
        <div className={styles.enterpriseLogo}>
          <img src={contactSales} alt="contact sales images" width={104} />
        </div>
      )}
      {isFree && (
        <Text className={styles.freeText} bold>
          Free
        </Text>
      )}
      {!isEnterprise && !isFree && (
        <div className={styles.planPrice}>
          <Text className={styles.planPriceText} bold>
            ${priceConverted}
          </Text>
          {priceText}
        </div>
      )}

      {actionButton}

      <div className={styles.planFeatures}>
        <Text variant={TextSize.M} bold className={styles.featuresDescription}>
          {featuresHeading}
        </Text>

        <ul className={featuresListClassName}>
          {features.map(feature => (
            <li key={feature}>
              <Icon name={IconVariant.CHECK} />
              <Text variant={TextSize.M}>{feature}</Text>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const BillingPlans: React.FC<IBillingPlansProps> = ({
  billingInterval,
  onChangeBillingInterval,
  onChangeBillingPlan,
  status,
  currentPlan,
  free,
  pro,
  team,
  starter,
  isTrialExpired,
  onContactSalesClick,
  isTeamDisabled,
}) => {
  const _onChangeBillingPlan = useCallback(
    (plan: IBillingPlan, action: BillingAction) => {
      if (action === BillingAction.TRIAL) {
        _trackButtonClick(
          plan.productName === BillingProduct.TEAM
            ? AnalyticsActivity.clickStartTeamTrial
            : AnalyticsActivity.clickStartProTrial,
        );
      } else if (action === BillingAction.BUY && plan.productName === BillingProduct.PRO) {
        _trackButtonClick(AnalyticsActivity.clickPurchaseProNow);
      }

      onChangeBillingPlan(plan, action);
    },
    [onChangeBillingPlan],
  );

  const _onChangeBillingInterval = (event: ChangeEvent<HTMLInputElement>) =>
    onChangeBillingInterval(event.target.value as BillingInterval);

  const updatesDisabled = status === BillingStatus.PAST_DUE;

  const freePlan = useMemo(() => {
    if (!free) {
      return null;
    }

    const isCurrent = currentPlan?.productName === free.productName;
    let { action } = free;
    const currentStatusLabel = _computeCurrentStatusLabel(free.productName, isCurrent, status, isTrialExpired);

    if (isCurrent) {
      if (isTrialExpired) {
        action = BillingAction.ACTIVATE_FREE;
      } else if (status === BillingStatus.CANCELED) {
        action = BillingAction.RESUBSCRIBE;
      } else {
        action = BillingAction.NONE;
      }
    }

    return (
      <BillingPlan
        className={styles.planFree}
        isFree
        heading="Free"
        interval={billingInterval}
        action={action}
        onButtonClick={() => _onChangeBillingPlan(free, action)}
        description="Basic suggestions, anywhere you write"
        featuresHeading="Includes"
        features={['Grammar', 'Spelling', 'Punctuation', 'Terms', 'Snippets', 'Data security']}
        featuresListClassName={styles.featuresList}
        currentLabel={currentStatusLabel}
      />
    );
  }, [free, billingInterval, currentPlan, status, isTrialExpired, _onChangeBillingPlan]);

  const proPlan = useMemo(() => {
    if (!pro) {
      return null;
    }

    let { action } = pro;
    const { productName, amount, interval } = pro;
    const isCurrent = currentPlan?.productName === productName;
    const isCurrentPlanFree = currentPlan?.productName === BillingProduct.FREE;
    const currentStatusLabel = _computeCurrentStatusLabel(productName, isCurrent, status, isTrialExpired);
    const featuresHeading = isCurrentPlanFree ? 'Everything in Free, plus:' : 'Includes:';

    if (isCurrent) {
      if (status === BillingStatus.CANCELED) {
        action = BillingAction.RESUBSCRIBE;
      } else if (isTrialExpired) {
        action = BillingAction.UPGRADE;
      } else if (status === BillingStatus.TRIALING) {
        action = BillingAction.BUY;
      } else if (currentPlan?.interval !== interval) {
        action = BillingAction.SWITCH;
      } else if (status === BillingStatus.ACTIVE || status === BillingStatus.PAST_DUE) {
        action = BillingAction.MANAGE_PLAN;
      } else {
        action = BillingAction.NONE;
      }
    }

    return (
      <BillingPlan
        className={styles.planPro}
        heading="Pro"
        interval={billingInterval}
        onButtonClick={() => _onChangeBillingPlan(pro, action)}
        description="Advanced suggestions for individuals."
        action={action}
        price={amount}
        featuresHeading={featuresHeading}
        features={[
          'Autocorrect',
          'Spelling & Grammar',
          'Punctuation',
          'Clarity',
          'Delivery',
          'Inclusivity',
          'Snippets',
          'Writing style configuration',
          'Plagiarism',
          'Data security',
        ]}
        featuresListClassName={styles.featuresList}
        currentLabel={currentStatusLabel}
      />
    );
  }, [pro, billingInterval, currentPlan, status, isTrialExpired, _onChangeBillingPlan]);

  const teamPlan = useMemo(() => {
    if (!team) {
      return null;
    }

    let { action } = team;
    const { productName, amount, interval } = team;
    const isCurrent = currentPlan?.productName === productName;
    const currentStatusLabel = _computeCurrentStatusLabel(productName, isCurrent, status, isTrialExpired);

    if (isCurrent) {
      if (isTrialExpired || status === BillingStatus.TRIALING) {
        action = BillingAction.BUY;
      } else if (status === BillingStatus.CANCELED) {
        action = BillingAction.RESUBSCRIBE;
      } else if (currentPlan?.interval !== interval) {
        action = BillingAction.SWITCH;
      } else if (status === BillingStatus.ACTIVE) {
        action = BillingAction.MANAGE_PLAN;
      } else {
        action = BillingAction.NONE;
      }
    } else if (currentPlan?.productName === BillingProduct.PRO && status === BillingStatus.ACTIVE) {
      action = BillingAction.TRIAL_HIGHLIGHTED;
    }

    return (
      <BillingPlan
        className={styles.planTeam}
        heading="Team"
        isTeam
        interval={billingInterval}
        onButtonClick={() => _onChangeBillingPlan(team, action)}
        description="Content acceleration and brand consistency for teams"
        action={action}
        price={amount}
        featuresHeading="Everything in Free, plus:"
        features={[
          'Automated content generation (Limit 15k words/user/month)',
          'Templates',
          'Rewrite',
          'Highlights',
          'Magic links',
          'Style guide documentation site',
          'Terminology management',
          'Snippets',
          'Writing style configuration',
          'Data security and privacy',
          'Team roles and permissions',
          'Quality report',
          'Google and Okta single sign-on',
        ]}
        featuresListClassName={styles.featuresList}
        actionDisabled={updatesDisabled || isTeamDisabled}
        currentLabel={currentStatusLabel}
      />
    );
  }, [
    team,
    billingInterval,
    currentPlan,
    status,
    isTrialExpired,
    _onChangeBillingPlan,
    updatesDisabled,
    isTeamDisabled,
  ]);

  const enterprisePlanFromTeam = useMemo(
    () => (
      <BillingPlan
        className={styles.planEnterprise}
        heading="Enterprise"
        isEnterprise
        interval={billingInterval}
        action={BillingAction.CONTACT}
        description="Enterprise-wide team and user management"
        featuresHeading="Everything in Team, plus:"
        features={[
          'More words / user / month for content generation',
          'Custom language model training',
          'Multi-domain support for magic links',
          'Multiple teams and style guides under single organization',
          'Style guide implementation and customization',
          'Custom writing style rules',
          'License utilization report',
          'SAML-based single sign-on',
          'Enterprise-wide admin panel',
          'API',
          'Dedicated customer success rep',
        ]}
        featuresListClassName={styles.featuresList}
        currentLabel={null}
        onContactSalesClick={onContactSalesClick}
      />
    ),
    [billingInterval, onContactSalesClick],
  );

  const starterPlan = useMemo(() => {
    if (!starter) {
      return null;
    }

    let { action } = starter;
    const { productName, amount, interval } = starter;
    const isCurrent = currentPlan?.productName === productName;
    const currentStatusLabel = _computeCurrentStatusLabel(productName, isCurrent, status, isTrialExpired);

    if (isCurrent) {
      if (isTrialExpired || status === BillingStatus.TRIALING) {
        action = BillingAction.BUY;
      } else if (status === BillingStatus.CANCELED) {
        action = BillingAction.RESUBSCRIBE;
      } else if (currentPlan?.interval !== interval) {
        action = BillingAction.SWITCH;
      } else if (status === BillingStatus.ACTIVE) {
        action = BillingAction.MANAGE_PLAN;
      } else {
        action = BillingAction.NONE;
      }
    } else if (currentPlan?.productName === BillingProduct.PRO && status === BillingStatus.ACTIVE) {
      action = BillingAction.TRIAL_HIGHLIGHTED;
    }

    return (
      <BillingPlan
        className={styles.planStarter}
        heading="Starter"
        isStarter
        interval={billingInterval}
        onButtonClick={() => _onChangeBillingPlan(starter, action)}
        description="Content acceleration and brand consistency for teams"
        action={action}
        price={amount}
        featuresHeading="Features:"
        features={[
          'Enterprise grade security',
          'Up to 20 seats',
          'Create Knowledge Graph for company-specific data from files, Google Drive',
          'Deploy up to 5 no-code custom agents in AI studio',
          "Use Writer's pre-built AI agents, including general purpose Ask Writer knowledge agent",
        ]}
        featuresListClassName={styles.starterFeaturesList}
        actionDisabled={updatesDisabled}
        currentLabel={currentStatusLabel}
      />
    );
  }, [starter, billingInterval, currentPlan, status, isTrialExpired, _onChangeBillingPlan, updatesDisabled]);

  const enterprisePlanFromStarter = useMemo(
    () => (
      <BillingPlan
        className={styles.planEnterprise}
        heading="Enterprise"
        isEnterprise
        interval={billingInterval}
        action={BillingAction.CONTACT}
        description="Enterprise-wide team and user management"
        featuresHeading="Everything in Starter, plus:"
        features={[
          'Unlimited Knowledge Graph, AI studio usage, and Voice usage',
          'Unlimited seats and teams',
          'Low-code agentic builder',
          'Newest Writer models',
          'Access to AI Program management and custom app builds',
          'Enterprise security features - including teams, reporting, and automatic provisioning',
        ]}
        featuresListClassName={styles.starterFeaturesList}
        currentLabel={null}
        onContactSalesClick={onContactSalesClick}
      />
    ),
    [billingInterval, onContactSalesClick],
  );

  const shareOptions = [
    {
      value: BillingInterval.MONTH,
      text: (
        <Text variant={TextSize.S} smallCaps>
          Billed monthly
        </Text>
      ),
    },
    {
      value: BillingInterval.YEAR,
      text: (
        <Text variant={TextSize.S} smallCaps>
          Billed yearly (save 25%)
        </Text>
      ),
    },
  ];

  return (
    <div>
      <Heading variant={HeadingVariant.H4}>All plans</Heading>
      <div className={styles.billingIntervals}>
        <RadioGroup
          options={shareOptions}
          name="shareOption"
          onChange={_onChangeBillingInterval}
          currentValue={billingInterval}
          disabled={updatesDisabled}
          tooltip={{ title: 'Billing period is disabled until your open invoice is resolved', tooltipWidth: 211 }}
        />
      </div>
      <div className={styles.plans}>
        {(freePlan || proPlan) && (
          <div className={styles.planGroup}>
            <div className={styles.plansGroup}>
              {freePlan}
              {proPlan}
            </div>
          </div>
        )}
        {teamPlan && currentPlan?.productName === BillingProduct.TEAM && (
          <div className={styles.planGroup}>
            <div className={styles.plansGroup}>
              {teamPlan}
              {enterprisePlanFromTeam}
            </div>
          </div>
        )}
        {starterPlan && currentPlan?.productName !== BillingProduct.TEAM && (
          <div className={styles.planGroup}>
            <div className={styles.plansGroup}>
              {starterPlan}
              {enterprisePlanFromStarter}
            </div>
          </div>
        )}
      </div>

      <Text className={styles.fullPlanDetails}>
        View{' '}
        <a target="_blank" href="https://writer.com/plans">
          full plan details
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          href="https://support.writer.com/hc/en-us/articles/360051738391-Limits-when-checking-content-with-Writer"
        >
          usage limits
        </a>
      </Text>
    </div>
  );
};

export default BillingPlans;
