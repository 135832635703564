import React, { Suspense, useCallback, useMemo } from 'react';

import { BillingProduct } from '@writercolab/common-utils';
import { ReactiveQueue } from '@writercolab/mobx';
import type { TNotificationQueueItem } from '@writercolab/types';
import { TSubscriptionLimitType } from '@writercolab/models';
import { DotLoader, useCustomSnackbar, useQueueWorkerNotifications } from '@writercolab/ui-atoms';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';
import { SETUP_ROUTES } from '@writercolab/utils';

import { LoadingPage, snackbarMessages } from '@web/component-library';
import { AnalyticsActivity } from 'constants/analytics';
import SnippetsContextProvider from 'context/snippetsContext';
import TermsContextProvider from 'context/termsContext';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import requestService from 'services/request/requestService';

import MagicLinksSetupContext from '../../../context/magicLinksSetupContext';
import { useSuggestionsContext } from '../../../context/suggestionsContext';
import { TeamPromptsApiModel } from '../../../models/teamPrompts.api';
import { REACT_RELATIVE_ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import { config } from '../../../utils/dynamicConfig';
import { goToBilling, goToSuggestions } from '../../../utils/navigationUtils';
import type { MenuItem } from '../../organisms/SecondaryMenu';
import SecondaryMenuSidebar from '../../organisms/SecondaryMenu';
import { KnowledgeGraphPage, KnowledgeGraphPageModel } from '../KnowledgeGraphPage';
import { SnippetsPage } from '../SnippetsPage';
import { TeamPromptsPage, TeamPromptsPageUiModel } from '../TeamPromptsPage';
import { TermsPage } from '../TermsPage';
import { VoicePage, VoicePageModel } from '../VoicePage';
import ClarityPage from './subpages/ClarityPage';
import CompliancePage from './subpages/CompliancePage';
import { CustomRulesPage } from './subpages/CustomRulesPage';
import DeliveryPage from './subpages/DeliveryPage';
import GeneralPage from './subpages/GeneralPage';
import InclusivityPage from './subpages/InclusivityPage';
import MagicLinksPage from './subpages/MagicLinksPage';
import PlagiarismPage from './subpages/PlagiarismPage';
import PunctuationPage from './subpages/PunctuationPage';
import ReWritePage from './subpages/ReWritePage';
import WritingStylePage from './subpages/WritingStylePage';

import styles from './styles.module.css';

export const SuggestionPage = observer(() => {
  const { appModel, appState } = useAppState();
  const { isLoading } = useSuggestionsContext();
  const { isEnterprise, isFree, isTeam, access } = appModel.assistantSubscription;
  const { enqueueSuccessSnackbar, enqueueDeleteSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();

  const { analyticsService } = appModel;

  const teamPromptsApiModel = useMemo(
    () =>
      new TeamPromptsApiModel({
        notificationQueue: new ReactiveQueue<TNotificationQueueItem>(),
        analyticsService,
        request: requestService.api,
        organizationId: Number(appState.organizationId),
        teamId: Number(appState.teamId),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.organizationId, appState.teamId],
  );

  const teamPromptsPageUiModel = useMemo(
    () =>
      new TeamPromptsPageUiModel({
        teamPromptsApiModel,
        notificationQueue: new ReactiveQueue<TNotificationQueueItem>(),
      }),
    [teamPromptsApiModel],
  );

  useQueueWorkerNotifications(teamPromptsApiModel.notificationQueue);
  useQueueWorkerNotifications(teamPromptsPageUiModel.notificationQueue);

  const navigate = useNavigate();

  const _access = useMemo(
    () => ({
      complianceAvailable: isEnterprise || isTeam,
      inclusivityAvailable: true,
      deliveryAvailable: true,
      plagiarismAvailable: true,
      knowledgeAvailable: true,
      promptsAvailable: true,
      reWriteAvailable: !!access?.reWrite,
      magicLinksAvailable: !!access?.magicLinks,
    }),
    [access?.magicLinks, access?.reWrite, isEnterprise, isTeam],
  );

  const voicePageModel = useMemo(
    () =>
      appModel.voiceModel &&
      new VoicePageModel({
        api: appModel.voiceModel,
        analyticsService,
        aiStudioMode: false,
        voicesLimit: appModel.assistantSubscription.limits?.voice?.limit,
      }),
    [appModel.voiceModel, appModel.assistantSubscription.limits?.voice, analyticsService],
  );

  const knowledgeGraphPageModel = useMemo(
    () =>
      new KnowledgeGraphPageModel({
        request: requestService.api,
        organizationId: Number(appState.organizationId),
        teamId: Number(appState.teamId),
        analyticsService: analyticsService.withContext({
          category: 'Setup',
          subcategory: 'Knowledge Graph',
          product: 'Writer App',
        }),
        aiStudioMode: false,
        onRenameGraphSuccess: () => enqueueSuccessSnackbar(snackbarMessages.knowledgeGraph.updateSuccess),
        onDeleteGraphSuccess: (name: string) =>
          enqueueDeleteSnackbar(snackbarMessages.knowledgeGraph.deleteSuccess(name)),
        onError: () => enqueueErrorSnackbar(snackbarMessages.knowledgeGraph.genericError),
        isAssistantSubscriptionActive: () => appModel.assistantSubscription.isActive,
        isConfluenceConnectorEnabled: () => appModel.featureFlags.get('confluenceConnectorEnabled', false),
        isAdvancedConnectorsEnabled: () => appModel.assistantSubscription.access?.advancedDataConnector ?? false,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.organizationId, appState.teamId],
  );

  // @ts-expect-error TODO need re-check all routes because some routes are not supported
  const existingMenuData: MenuItem[] = useMemo(
    () => [
      {
        section: 'suggestions',
        list: {
          punctuation: {
            name: 'Punctuation',
            navLink: SETUP_ROUTES.toPunctuation(`${appState.organizationId}`, `${appState.teamId}`),
          },
          writingStyle: {
            name: 'Writing style',
            navLink: SETUP_ROUTES.toWritingStyle(`${appState.organizationId}`, `${appState.teamId}`),
          },
          clarity: {
            name: 'Clarity',
            navLink: SETUP_ROUTES.toClarity(`${appState.organizationId}`, `${appState.teamId}`),
          },
          delivery: {
            name: 'Delivery',
            navLink: SETUP_ROUTES.toDelivery(`${appState.organizationId}`, `${appState.teamId}`),
            locked: !_access.deliveryAvailable,
            lockConfig: {
              title: 'Enterprise Feature',
              description:
                'How content is delivered -- the voice and tone in which it comes across -- can make a difference in building rapport with an audience. Interested?',
              cta: true,
            },
          },
          inclusivity: {
            name: 'Inclusivity',
            navLink: SETUP_ROUTES.toInclusivity(`${appState.organizationId}`, `${appState.teamId}`),
            locked: !_access.inclusivityAvailable,
            lockConfig: {
              title: 'Enterprise Feature',
              description:
                'Inclusive language is respectful and relevant to all groups of people, and reflects diversity instead of perpetuating stereotypes. Interested?',
              cta: true,
            },
          },
          compliance: {
            name: 'Compliance',
            navLink: SETUP_ROUTES.toCompliance(`${appState.organizationId}`, `${appState.teamId}`),
            locked: !_access.complianceAvailable,
            lockConfig: {
              title: 'Enterprise Feature',
              description: 'Compliance lets you redact sensitive user information. Interested?',
              cta: true,
            },
          },
          plagiarism: {
            name: 'Plagiarism',
            locked: !_access.plagiarismAvailable,
            navLink: SETUP_ROUTES.toPlagiarism(`${appState.organizationId}`, `${appState.teamId}`),
            lockConfig: {
              title: 'Enterprise Feature',
              description: 'Plagiarism lets you ensure all submitted content is original work. Interested?',
              cta: true,
            },
          },
          customRules: {
            name: 'Custom rules',
            navLink: SETUP_ROUTES.toCustomRules(`${appState.organizationId}`, `${appState.teamId}`),
          },
          general: {
            name: 'General',
            navLink: SETUP_ROUTES.toGeneral(`${appState.organizationId}`, `${appState.teamId}`),
          },
        },
      },
      {
        section: 'content generation',
        list: {
          knowledge: {
            name: 'Knowledge Graph',
            isHidden: !_access.knowledgeAvailable,
            navLink: SETUP_ROUTES.toKnowledge(`${appState.organizationId}`, `${appState.teamId}`),
          },
          voice: {
            name: 'Voice',
            isHidden: false,
            navLink: SETUP_ROUTES.toVoice(`${appState.organizationId}`, `${appState.teamId}`),
          },
          prompts: {
            name: 'Team prompts',
            isHidden: !_access.promptsAvailable,
            navLink: SETUP_ROUTES.toTeamPrompts(`${appState.organizationId}`, `${appState.teamId}`),
          },
          reWrite: {
            name: 'Rewrite',
            isHidden: !_access.reWriteAvailable,
            navLink: SETUP_ROUTES.toReWrite(`${appState.organizationId}`, `${appState.teamId}`),
          },
          ...(config.SINGLE_TENANCY_CONFIG?.disableMagicLinks
            ? {}
            : {
                magicLinks: {
                  name: 'Magic links',
                  isHidden: !_access.magicLinksAvailable,
                  navLink: SETUP_ROUTES.toMagicLinks(`${appState.organizationId}`, `${appState.teamId}`),
                },
              }),
        },
      },
    ],
    [
      _access.complianceAvailable,
      _access.deliveryAvailable,
      _access.inclusivityAvailable,
      _access.knowledgeAvailable,
      _access.magicLinksAvailable,
      _access.plagiarismAvailable,
      _access.reWriteAvailable,
      _access.promptsAvailable,
      appState.organizationId,
      appState.teamId,
    ],
  );

  // @ts-expect-error TODO need re-check all routes because some routes are not supported
  const menuData: MenuItem[] = useMemo(
    () => [
      {
        section: 'Platform',
        list: {
          knowledge: {
            name: 'Knowledge Graph',
            isHidden: !_access.knowledgeAvailable,
            navLink: SETUP_ROUTES.toKnowledge(`${appState.organizationId}`, `${appState.teamId}`),
          },
          prompts: {
            name: 'Team prompts',
            isHidden: !_access.promptsAvailable,
            navLink: SETUP_ROUTES.toTeamPrompts(`${appState.organizationId}`, `${appState.teamId}`),
          },
          voice: {
            name: 'Voice',
            isHidden: false,
            navLink: SETUP_ROUTES.toVoice(`${appState.organizationId}`, `${appState.teamId}`),
          },
        },
      },
      {
        section: 'Suggestions',
        list: {
          terms: {
            name: 'Terms',
            navLink: SETUP_ROUTES.toTerms(`${appState.organizationId}`, `${appState.teamId}`),
          },
          punctuation: {
            name: 'Punctuation',
            navLink: SETUP_ROUTES.toPunctuation(`${appState.organizationId}`, `${appState.teamId}`),
          },
          writingStyle: {
            name: 'Writing style',
            navLink: SETUP_ROUTES.toWritingStyle(`${appState.organizationId}`, `${appState.teamId}`),
          },
          clarity: {
            name: 'Clarity',
            navLink: SETUP_ROUTES.toClarity(`${appState.organizationId}`, `${appState.teamId}`),
          },
          delivery: {
            name: 'Delivery',
            navLink: SETUP_ROUTES.toDelivery(`${appState.organizationId}`, `${appState.teamId}`),
            locked: !_access.deliveryAvailable,
            lockConfig: {
              title: 'Enterprise Feature',
              description:
                'How content is delivered -- the voice and tone in which it comes across -- can make a difference in building rapport with an audience. Interested?',
              cta: true,
            },
          },
          inclusivity: {
            name: 'Inclusivity',
            navLink: SETUP_ROUTES.toInclusivity(`${appState.organizationId}`, `${appState.teamId}`),
            locked: !_access.inclusivityAvailable,
            lockConfig: {
              title: 'Enterprise Feature',
              description:
                'Inclusive language is respectful and relevant to all groups of people, and reflects diversity instead of perpetuating stereotypes. Interested?',
              cta: true,
            },
          },
          compliance: {
            name: 'Compliance',
            navLink: SETUP_ROUTES.toCompliance(`${appState.organizationId}`, `${appState.teamId}`),
            locked: !_access.complianceAvailable,
            lockConfig: {
              title: 'Enterprise Feature',
              description: 'Compliance lets you redact sensitive user information. Interested?',
              cta: true,
            },
          },
          plagiarism: {
            name: 'Plagiarism',
            locked: !_access.plagiarismAvailable,
            navLink: SETUP_ROUTES.toPlagiarism(`${appState.organizationId}`, `${appState.teamId}`),
            lockConfig: {
              title: 'Enterprise Feature',
              description: 'Plagiarism lets you ensure all submitted content is original work. Interested?',
              cta: true,
            },
          },
          general: {
            name: 'General',
            navLink: SETUP_ROUTES.toGeneral(`${appState.organizationId}`, `${appState.teamId}`),
          },
          customRules: {
            name: 'Custom rules',
            navLink: SETUP_ROUTES.toCustomRules(`${appState.organizationId}`, `${appState.teamId}`),
          },
        },
      },
      {
        section: 'Tools',
        list: {
          snippets: {
            name: 'Snippets',
            navLink: SETUP_ROUTES.toSnippets(`${appState.organizationId}`, `${appState.teamId}`),
          },
          reWrite: {
            name: 'Rewrite',
            isHidden: !_access.reWriteAvailable,
            navLink: SETUP_ROUTES.toReWrite(`${appState.organizationId}`, `${appState.teamId}`),
          },
          ...(config.SINGLE_TENANCY_CONFIG?.disableMagicLinks
            ? {}
            : {
                magicLinks: {
                  name: 'Magic links',
                  isHidden: !_access.magicLinksAvailable,
                  navLink: SETUP_ROUTES.toMagicLinks(`${appState.organizationId}`, `${appState.teamId}`),
                },
              }),
        },
      },
    ],
    [
      _access.complianceAvailable,
      _access.deliveryAvailable,
      _access.inclusivityAvailable,
      _access.knowledgeAvailable,
      _access.magicLinksAvailable,
      _access.plagiarismAvailable,
      _access.reWriteAvailable,
      _access.promptsAvailable,
      appState.organizationId,
      appState.teamId,
    ],
  );

  const navigateToSuggestionsRoot = useCallback(() => {
    goToSuggestions(navigate, appState.organizationId!, appState.teamId!);
  }, [navigate, appState.organizationId, appState.teamId]);

  const handleLockActionClick = () => {
    analyticsService.track(AnalyticsActivity.clickPurchaseProNow, {
      clicked_from: 'suggestions',
    });

    goToBilling(navigate, appState.organizationId!, BillingProduct.STARTER);
  };

  if (isLoading) {
    return (
      <div className={styles.dotLoader}>
        <DotLoader />
      </div>
    );
  }

  if (isFree) {
    return (
      <div className={styles.billingLock}>
        <BillingLock type={BillingLockType.SUGGESTIONS} onActionCallback={handleLockActionClick} />
      </div>
    );
  }

  return (
    <div className={styles.pageWrapper}>
      <SecondaryMenuSidebar data={appModel.featureFlags.get('waMyWorkPage', false) ? menuData : existingMenuData} />
      <div className={styles.suggestionSectionWrapper}>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route path={REACT_RELATIVE_ROUTE.punctuation} element={<PunctuationPage />} />
            <Route path={REACT_RELATIVE_ROUTE.writingStyle} element={<WritingStylePage />} />
            <Route path={REACT_RELATIVE_ROUTE.clarity} element={<ClarityPage />} />
            <Route path={REACT_RELATIVE_ROUTE.delivery} element={<DeliveryPage />} />
            <Route path={REACT_RELATIVE_ROUTE.inclusivity} element={<InclusivityPage />} />
            <Route
              path={REACT_RELATIVE_ROUTE.compliance}
              element={
                <CompliancePage isLocked={!_access.complianceAvailable} onAccessDenied={navigateToSuggestionsRoot} />
              }
            />
            <Route path={REACT_RELATIVE_ROUTE.plagiarism} element={<PlagiarismPage />} />
            <Route path={REACT_RELATIVE_ROUTE.general} element={<GeneralPage />} />
            <Route path={REACT_RELATIVE_ROUTE.customRules} element={<CustomRulesPage />} />
            <Route
              path={REACT_RELATIVE_ROUTE.terms}
              element={
                <TermsContextProvider
                  organizationId={appState.organization?.id}
                  rootTeamId={appState.team?.id}
                  teamId={appState.team?.termBank.teamId}
                >
                  <TermsPage />
                </TermsContextProvider>
              }
            />
            <Route
              path={REACT_RELATIVE_ROUTE.snippets}
              element={
                <SnippetsContextProvider
                  organizationId={appState.organization?.id}
                  teamId={appState.team?.snippet.teamId}
                >
                  <SnippetsPage />
                </SnippetsContextProvider>
              }
            />
            <Route
              path={REACT_RELATIVE_ROUTE.voice}
              element={
                voicePageModel && (
                  <VoicePage
                    model={voicePageModel}
                    isAdmin={!!appModel.permissionsModel?.isTeamAdmin}
                    teamName={appState.team?.name}
                    isAssistantSubscriptionActive
                    isFeatureLocked={!appModel.assistantSubscription.access?.voice}
                    onBillingLockClick={() => goToBilling(navigate, appState.organizationId, BillingProduct.STARTER)}
                    checkIsVoiceEditable={() => true}
                  />
                )
              }
            />
            <Route path={REACT_RELATIVE_ROUTE.reWrite} element={<ReWritePage />} />
            <Route
              path={REACT_RELATIVE_ROUTE.teamPrompts}
              element={<TeamPromptsPage model={teamPromptsPageUiModel} />}
            />
            <Route
              path={REACT_RELATIVE_ROUTE.knowledge}
              element={
                <KnowledgeGraphPage
                  model={knowledgeGraphPageModel}
                  isConnectorsEnabled={appModel.assistantSubscription.access?.dataConnector ?? false}
                  isWebConnectorsEnabled={appModel.featureFlags.get('kgWebConnectorEnabled', false)}
                  isOrgAdmin={!!appModel.permissionsModel?.isOrganizationAdmin}
                  isTeamAdmin={!!appModel.permissionsModel?.isTeamAdmin}
                  isFeatureLocked={!appModel.assistantSubscription.access?.knowledgeGraph}
                  // We're not verifying access to specific graph in AI Assistant
                  // so, we're passing true here, this is used in AI Studio
                  hasAdminAccess={() => true}
                  isBlockedByBalance={false}
                  isBlockedByUsageLimits={false}
                  isBlockedByStorageLimit={appModel.assistantSubscription.limits?.graphStorage?.exceeded}
                  refreshLimits={() => {
                    appModel.assistantSubscription?.limits?.reloadSubscriptionLimits();
                    appModel.assistantSubscription.limits?.reloadLimit(TSubscriptionLimitType.enum.GraphStorage);
                  }}
                />
              }
            />
            <Route
              path={REACT_RELATIVE_ROUTE.magicLinks}
              element={
                <MagicLinksSetupContext>
                  <MagicLinksPage />
                </MagicLinksSetupContext>
              }
            />
            <Route path="*" element={<Navigate to={`.${REACT_RELATIVE_ROUTE.knowledge}`} />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
});
