import React, { ChangeEvent, useEffect, useMemo, useReducer } from 'react';

import cx from 'classnames';

import {
  BillingCostDetail,
  BillingInterval,
  CheckoutPopupType,
  IBillingCalculation,
  convertCentsToDollar,
  getDateFormatMMMDDYYYY,
} from '@writercolab/common-utils';
import {
  ITooltipProps,
  RadioGroup,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { InputNumber } from '@writercolab/ui-molecules';

import { TeamSizeLimitReachedNotification } from '../../../molecules/TeamSizeLimitRechedNotification';

import styles from './styles.module.css';

interface IBillingTotalWidget {
  planDuration: BillingInterval;
  type: CheckoutPopupType;
  seats: number;
  billingDate: Date;
  rows: BillingCostDetail[];
  userCount?: number;
  disableSeatsSelector?: boolean;
  isLoading?: boolean;
  onChange?: (state: IBillingTotalWidgetState) => void;
  isCancelled?: boolean;
  disabled?: boolean;
  billingDatePrefix?: string;
  teamSizeLimit: number;
  isTeamSizeLimitReached: boolean;
}

/**
 * Maps billing cost rows from lines to rows for BillingTotalWidget
 * @param data
 * @return {BillingCostDetail[] | any[]}
 */
export const billingCalculationMapper = (
  data: IBillingCalculation | null,
  isCheckoutDisabled?: boolean, // to show `Total due on ...` vs `Total due now`
  isCancelled?: boolean, // to show warning text "Cancelled"
  isValuesUnchanged?: boolean, // to show warning text "Cancelled"
  isPastDue?: boolean, // to show `Total due on NEXT_PAYMENT_DATE`
  planDuration?: BillingInterval,
  displayYearlyPerMonth?: boolean,
) => {
  if (!data || !data.invoice) return [];

  const { invoice, paymentAt } = data;
  const { lines, discount, amountDue, startingBalance, endingBalance } = invoice;

  const rows = [] as BillingCostDetail[];

  lines.sort((a, b) => b.amount - a.amount);

  /* rows of charges */
  lines
    .filter(line => !line.proration)
    .forEach(({ amount, description }) => {
      const cost =
        planDuration === BillingInterval.YEAR && displayYearlyPerMonth
          ? `${convertCentsToDollar(amount / 12)} x 12`
          : convertCentsToDollar(amount);

      rows.push({
        cost,
        description,
      });
    });

  /* rows of prorated credits/charges */
  lines
    .filter(line => line.proration)
    .forEach(({ amount, description }) => {
      rows.push({
        cost: convertCentsToDollar(amount),
        description: _updatedProratedDescriptionText(description, amount > 0),
        discount: amount < 0,
      });
    });

  /* rows to show discount */
  if (discount) {
    let cost = '';

    if (discount.coupon.amountOff) {
      cost = `-${convertCentsToDollar(discount.coupon.amountOff!)}`;
    } else {
      cost = `-${discount.coupon.percentOff}%`;
    }

    rows.push({
      cost,
      description: discount.coupon.name,
      discount: true,
    });
  }

  /* row to show "Credits applied" */
  if (startingBalance < 0) {
    const _credits = -Math.abs(startingBalance - endingBalance);
    rows.push({
      cost: convertCentsToDollar(_credits),
      description: 'Credits applied',
      discount: true,
    });
  }

  /* row to show "Past due balance" */
  if (startingBalance > 0) {
    const _debit = Math.abs(startingBalance - endingBalance);
    rows.push({
      cost: convertCentsToDollar(_debit),
      description: 'Past due balance',
      debit: true,
    });
  }

  /* row to show Total/ Cancelled */
  const _totalDuePostfix = isCheckoutDisabled ? `on ${getDateFormatMMMDDYYYY(paymentAt)}` : 'now';

  if (isValuesUnchanged && isCancelled) {
    rows.push({
      description: '',
      warningText: 'Cancelled',
      cost: '',
      isTotal: true,
    });
  } else if (isPastDue) {
    rows.push({
      cost: convertCentsToDollar(amountDue),
      description: 'Total due on ',
      warningText: getDateFormatMMMDDYYYY(paymentAt),
      isTotal: true,
    });
  } else {
    rows.push({
      cost: convertCentsToDollar(amountDue),
      description: `Total due ${_totalDuePostfix}`,
      isTotal: true,
    });
  }

  /* row to show "Remaining Balance" */
  if (endingBalance) {
    rows.push({
      cost: convertCentsToDollar(-endingBalance),
      description: 'Remaining credits',
      discount: true,
    });
  }

  return rows;
};

/**
 * function to generate text starting with "Prorated credit..." or "Prorated charge..."
 * @param text
 * @private
 */
export const _updatedProratedDescriptionText = (text, isCharge = false) => {
  if (!text || !text.length) return text;

  let _result: string = text;

  if (_result.includes('on ')) {
    const _prefix = isCharge ? 'Prorated charge on ' : 'Prorated credit on ';
    _result = _prefix + _result.split('on ')[1];
  }

  _result = _result.split(' after')[0] || _result;
  _result = _result.split(' before')[0] || _result;

  return _result;
};

export type IBillingTotalWidgetState = typeof initialState;

type IAction = {
  field?: keyof IBillingTotalWidgetState;
  value?: any;
};

const initialState = {
  planDuration: BillingInterval.MONTH,
  seats: 10,
};

const reducer = (state: IBillingTotalWidgetState, { field, value }: IAction) => ({
  ...state,
  [field as string]: value,
});

const radioGroupOptions = [
  {
    value: BillingInterval.MONTH,
    text: (
      <Text variant={TextSize.S} smallCaps>
        Billed monthly
      </Text>
    ),
  },
  {
    value: BillingInterval.YEAR,
    text: (
      <Text variant={TextSize.S} smallCaps>
        Billed yearly (save 25%)
      </Text>
    ),
  },
];

const loader = width => (
  <SkeletonLoader className={styles.loader} type={SkeletonLoaderType.DEFAULT} width={width} height={15} />
);

const differenceBox = value => (
  <div className={cx('flexContainerCentered', styles.differenceBox)}>
    {value > 0 && '+'}
    {value}
  </div>
);

const _tooltipText = val =>
  `Your team has ${val} team members. To further lower your seat count, remove existing team members`;

const TeamSizeWidget = ({ seats, onChange, updatedSeats, currentUserCount, isDisabled, hasWarning, tooltip }) => (
  <div className="flexVerticalCenter">
    <InputNumber
      defaultValue={seats}
      max={150}
      min={currentUserCount}
      onChange={onChange}
      disabled={isDisabled}
      tooltip={tooltip}
      warning={!!hasWarning}
      disabledDownArrowTooltip={{
        title: _tooltipText(currentUserCount),
      }}
    />
    {updatedSeats !== seats && (
      <>
        <Text extraSmallCaps variant={TextSize.XS} className={styles.totalSeatsText}>
          Total Seats
        </Text>
        {differenceBox(updatedSeats - seats)}
      </>
    )}
  </div>
);

const elements = {
  [CheckoutPopupType.CHECKOUT]: {
    seatsComponent: (seats, onChange, updatedSeats, currentUserCount, isDisabled, hasWarning, tooltip) => (
      <TeamSizeWidget
        seats={seats}
        onChange={onChange}
        updatedSeats={updatedSeats}
        currentUserCount={currentUserCount}
        isDisabled={isDisabled}
        hasWarning={hasWarning}
        tooltip={tooltip}
      />
    ),
    billingDatePrefix: 'Start date',
  },
  [CheckoutPopupType.MANAGE_PLAN]: {
    seatsComponent: (seats, onChange, updatedSeats, currentUserCount, isDisabled, hasWarning, tooltip) => (
      <div className="flexVerticalCenter">
        <TeamSizeWidget
          seats={seats}
          onChange={onChange}
          updatedSeats={updatedSeats}
          currentUserCount={currentUserCount}
          isDisabled={isDisabled}
          hasWarning={hasWarning}
          tooltip={tooltip}
        />
      </div>
    ),
    billingDatePrefix: 'Next bill',
  },
};

const Loading = () => (
  <>
    <div className={styles.costRow}>
      {loader(150)}
      {loader(20)}
    </div>
    <div className={cx(styles.costRow, styles.totalRow)}>
      {loader(78)}
      {loader(20)}
    </div>
  </>
);

export const BillingTotalWidget: React.FC<IBillingTotalWidget> = ({
  planDuration,
  type,
  seats,
  billingDate,
  rows,
  userCount,
  disableSeatsSelector,
  isLoading,
  onChange,
  isCancelled,
  disabled,
  billingDatePrefix,
  teamSizeLimit,
  isTeamSizeLimitReached,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState, initialState => {
    const _state = initialState;

    return {
      ..._state,
      planDuration,
      seats,
    };
  });

  const _seats = userCount && userCount > seats ? userCount : seats;
  const _formattedBillingDate = getDateFormatMMMDDYYYY(billingDate);
  const _formattedPlanDuration = planDuration === BillingInterval.MONTH ? 'monthly' : 'yearly';
  const _managePlanTooltipContent = useMemo<Partial<ITooltipProps>>(
    () => ({
      title: isTeamSizeLimitReached ? (
        <TeamSizeLimitReachedNotification teamSizeLimit={teamSizeLimit} />
      ) : (
        <Text color={TextColor.WHITE}>Seat selection is disabled until your open invoice is resolved</Text>
      ),
    }),
    [isTeamSizeLimitReached, teamSizeLimit],
  );

  const onBillingPlanDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ field: 'planDuration', value: e.target.value as BillingInterval });
  };

  const onSeatsChange = val => {
    dispatch({ field: 'seats', value: Number(val) });
  };

  let _element = elements[type];
  const _billingDateLabel = isCancelled ? 'Last Payment' : billingDatePrefix || _element?.billingDatePrefix;

  if (type === CheckoutPopupType.CHECKOUT && userCount && userCount > 1) {
    _element = elements[CheckoutPopupType.MANAGE_PLAN];
  }

  const _seatsComponent = _element.seatsComponent(
    _seats,
    onSeatsChange,
    state.seats,
    userCount,
    disabled,
    isTeamSizeLimitReached,
    _managePlanTooltipContent,
  );

  useEffect(() => {
    onChange && onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className={styles.container}>
      <RadioGroup
        name="planDuration"
        className={styles.radioGroup}
        options={radioGroupOptions}
        currentValue={state.planDuration}
        onChange={onBillingPlanDurationChange}
        disabled={disabled}
        tooltip={{
          title: 'Billing period is disabled until your open invoice is resolved',
          tooltipWidth: 211,
        }}
      />
      {!disableSeatsSelector && <div className={styles.seatsHolder}>{_seatsComponent}</div>}
      <div className={styles.priceDetailsMainContainer}>
        <div
          className={cx(styles.costHolder, {
            [styles.costHolderLoading]: isLoading,
            [styles.costHolderLoaded]: !isLoading,
          })}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {rows.map(item => (
                <div
                  className={cx(styles.costRow, {
                    [styles.discount]: item.discount,
                    [styles.debit]: item.debit,
                    [styles.totalRow]: item.isTotal,
                  })}
                  key={`${item.description}-${item.cost}`}
                >
                  <Text variant={TextSize.S}>
                    {item.description}
                    <span className={styles.warningText}>{item.warningText}</span>
                  </Text>
                  <Text variant={TextSize.S}>{item.cost}</Text>
                </div>
              ))}
            </>
          )}
        </div>
        <div className={styles.planDetailsHolder}>
          <Text variant={TextSize.XS} bold>
            Plan details
          </Text>
          {isLoading ? (
            <>
              <Text variant={TextSize.XXS} className={styles.planDetailsHolderLoader}>
                {_billingDateLabel}: {loader(50)}
              </Text>
              <Text variant={TextSize.XXS} className={styles.planDetailsHolderLoader}>
                Billed {loader(50)}
              </Text>
            </>
          ) : (
            <>
              <Text variant={TextSize.XXS}>
                {_billingDateLabel}: {_formattedBillingDate} <br />
                Billed {_formattedPlanDuration}
              </Text>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingTotalWidget;
