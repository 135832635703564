import type React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Heading, HeadingVariant, Modal, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import monthOfferPro from '../../../../../assets/backgrounds/monthOfferPro.png';
import monthOfferTeam from '../../../../../assets/backgrounds/monthOfferTeam.png';
import quickGuide from '../../../../../assets/backgrounds/quickGuide.png';
import LogoGreen from '../../../../../assets/logo/logoGreen.svg?react';

import generalStyles from '../styles.module.css';
import styles from './styles.module.css';

interface IOneTimeOffer {
  open: boolean;
  type: OneTimeOfferType;
  isLoading?: boolean;
  isDowngrade: boolean;
  onContinueDowngradeCb?: () => void;
  onMonthFreeCb?: () => void;
  handleClose?: () => void;
}

const OneMonthFreeTeam = () => (
  <div className={generalStyles.content}>
    <Text variant={TextSize.M} className={styles.textSpaced}>
      Sometimes you just need more time to get the hang of things. Have another month of Team Plan, on us! That way,
      you'll have a chance to get more value from Writer.
    </Text>
    <div className={styles.image}>
      <img src={monthOfferTeam} alt="month offer team" />
    </div>
  </div>
);

const OneMonthFreeTeamAccepted = () => (
  <div className={styles.contentCentered}>
    <div className={styles.altHeader}>
      <LogoGreen className={styles.logo} width={36} height={36} />
      <Heading variant={HeadingVariant.H2}>+1 MONTH</Heading>
    </div>
    <Heading variant={HeadingVariant.H5} className={styles.textCenter} bold>
      You've got an extra month on Team Plan
    </Heading>
    <Text variant={TextSize.M} className={cx(styles.textCenter, styles.textSpaced)}>
      You got it! We've given you an extra month at no cost.
      <br />
      Your next bill has been pushed back to June 30, 2021.
    </Text>
    <div className={styles.acceptImageContainer}>
      <div className={styles.imageText}>
        <Text variant={TextSize.L} bold>
          Looking for help with setting up Writer?
        </Text>
        <Text variant={TextSize.M}>
          Check out our{' '}
          <a
            href="https://support.writer.com/article/26-getting-started-5-steps"
            target="_blank"
            className={styles.acceptImageContainerLink}
          >
            Getting Started
          </a>{' '}
          guide ,
          <br /> where you'll find short and sweet video
          <br /> tutorials to get you going.{' '}
        </Text>
      </div>
      <img src={quickGuide} alt="month offer team" className={styles.acceptImage} />
    </div>
  </div>
);

const OneMonthFreeProAccepted = () => (
  <div className={styles.contentCentered}>
    <div className={styles.altHeader}>
      <LogoGreen className={styles.logo} />
      <Heading variant={HeadingVariant.H2}>+1 month</Heading>
    </div>
    <Heading variant={HeadingVariant.H5} className={styles.textCenter}>
      You've got an extra month of Pro
    </Heading>
    <Text variant={TextSize.M} className={cx(styles.textCenter, styles.textSpaced)}>
      Awesome, thank you 🙏🏼 . We've given you an extra month at no cost. We will check back in on June 30.
    </Text>
    <div className={styles.image}>
      <img src={monthOfferPro} alt="month offer pro" width={356} />
    </div>
  </div>
);

const OneMonthFreePro = () => (
  <div className={generalStyles.content}>
    <Text variant={TextSize.M} className={styles.textSpaced}>
      We think the advanced suggestions you get with Pro make your writing a lot stronger. We’d love a bit more time to
      show that to you. Another month of Pro, on us?
    </Text>
    <div className={styles.image}>
      <img src={monthOfferPro} alt="month offer pro" width={356} />
    </div>
  </div>
);

const OneMonthFreeStarter = () => (
  <div className={styles.contentCentered}>
    <Text variant={TextSize.M} className={styles.textSpaced}>
      Sometimes you just need more time to get the hang of things. Have another month of Starter Plan, on us! That way,
      you'll have a chance to get more value from Writer.
    </Text>
  </div>
);

const OneMonthFreeStarterAccepted = () => (
  <div className={styles.contentCentered}>
    <div className={styles.altHeader}>
      <LogoGreen className={styles.logo} width={36} height={36} />
      <Heading variant={HeadingVariant.H2}>+1 MONTH</Heading>
    </div>
    <Heading variant={HeadingVariant.H5} className={styles.textCenter} bold>
      You've got an extra month on Starter Plan
    </Heading>
    <Text variant={TextSize.M} className={cx(styles.textCenter, styles.textSpaced)}>
      You got it! We've given you an extra month at no cost.
      <br />
    </Text>
    <div className={styles.acceptImageContainer}>
      <div className={styles.imageText}>
        <Text variant={TextSize.L} bold>
          Looking for help with setting up Writer?
        </Text>
        <Text variant={TextSize.M}>
          Check out our{' '}
          <a
            href="https://support.writer.com/article/26-getting-started-5-steps"
            target="_blank"
            className={styles.acceptImageContainerLink}
          >
            Getting Started
          </a>{' '}
          guide ,
          <br /> where you'll find short and sweet video
          <br /> tutorials to get you going.{' '}
        </Text>
      </div>
      <img src={quickGuide} alt="month offer team" className={styles.acceptImage} />
    </div>
  </div>
);

export enum OneTimeOfferType {
  ONE_MONTH_OFFER_TEAM = 'ONE_MONTH_OFFER_TEAM',
  ONE_MONTH_OFFER_PRO = 'ONE_MONTH_OFFER_PRO',
  ONE_MONTH_OFFER_STARTER = 'ONE_MONTH_OFFER_STARTER',
  ONE_MONTH_OFFER_TEAM_ACCEPTED = 'ONE_MONTH_OFFER_TEAM_ACCEPTED',
  ONE_MONTH_OFFER_PRO_ACCEPTED = 'ONE_MONTH_OFFER_PRO_ACCEPTED',
  ONE_MONTH_OFFER_STARTER_ACCEPTED = 'ONE_MONTH_OFFER_STARTER_ACCEPTED',
}

const Popup = {
  [OneTimeOfferType.ONE_MONTH_OFFER_TEAM]: {
    // eslint-disable-next-line
    title: "We'd like to give you another month of Team, on us!",
    content: <OneMonthFreeTeam />,
  },
  [OneTimeOfferType.ONE_MONTH_OFFER_PRO]: {
    // eslint-disable-next-line
    title: "We'd like to give you another month of Pro",
    content: <OneMonthFreePro />,
  },
  [OneTimeOfferType.ONE_MONTH_OFFER_STARTER]: {
    // eslint-disable-next-line
    title: "We'd like to give you another month of Starter",
    content: <OneMonthFreeStarter />,
  },
  [OneTimeOfferType.ONE_MONTH_OFFER_TEAM_ACCEPTED]: {
    title: undefined,
    content: <OneMonthFreeTeamAccepted />,
  },
  [OneTimeOfferType.ONE_MONTH_OFFER_PRO_ACCEPTED]: {
    title: undefined,
    content: <OneMonthFreeProAccepted />,
  },
  [OneTimeOfferType.ONE_MONTH_OFFER_STARTER_ACCEPTED]: {
    title: undefined,
    content: <OneMonthFreeStarterAccepted />,
  },
};

export const OneTimeOffer: React.FC<IOneTimeOffer> = ({
  open,
  type,
  isLoading,
  onContinueDowngradeCb,
  onMonthFreeCb,
  handleClose,
  isDowngrade,
}) => {
  const regularButtons = (
    <div className={cx(generalStyles.actionButtonsHolder, styles.actionButtonsHolderAlt)}>
      <Button
        type={ButtonTypes.TRANSPARENT}
        size={SizeTypes.XS}
        className={generalStyles.secondaryAction}
        onClick={onContinueDowngradeCb}
        content={isDowngrade ? 'Continue with downgrade' : 'Cancel'}
      ></Button>
      <Button
        type={ButtonTypes.BLACK}
        className={generalStyles.mainAction}
        onClick={onMonthFreeCb}
        content="Yes, give me 1 month free"
        isLoading={isLoading}
      ></Button>
    </div>
  );

  const gotItButton = (
    <div className={styles.gotItButton}>
      <Button
        type={ButtonTypes.BLACK}
        className={generalStyles.mainAction}
        onClick={handleClose}
        content="Got it"
      ></Button>
    </div>
  );

  const isOnlyCloseActionAvailable =
    type === OneTimeOfferType.ONE_MONTH_OFFER_TEAM_ACCEPTED ||
    type === OneTimeOfferType.ONE_MONTH_OFFER_PRO_ACCEPTED ||
    type === OneTimeOfferType.ONE_MONTH_OFFER_STARTER_ACCEPTED;

  return (
    <Modal
      open={open}
      title={Popup[type].title}
      titleClassName={styles.modalTitle}
      modalTitleClassName={styles.modalTitle}
      className={cx(generalStyles.modalContainer, styles.modalContainer)}
      handleClose={handleClose}
      hideCloseButton={isOnlyCloseActionAvailable}
    >
      <div>
        {Popup[type].content}
        {isOnlyCloseActionAvailable ? gotItButton : regularButtons}
      </div>
    </Modal>
  );
};

export default OneTimeOffer;
