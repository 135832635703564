import React, { useMemo } from 'react';

import cx from 'classnames';

import {
  BillingProduct,
  BillingStatus,
  CheckoutPopupType,
  NUMBER_OF_DAYS_BEFORE_FREE_PLAN_WARNING,
  getDateFormatMMMDDYYYY,
  getNumberOfDays,
} from '@writercolab/common-utils';
import { Button, ButtonTypes, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import InfoIcon from './InfoIcon';
import { IYourBillingPlanWidget } from './index';

import styles from './styles.module.css';

interface ICancelledDuePlanHolder extends BaseComponentsProps {
  cancelAt?: string | Date | null;
  renewByDate?: string | null;
  billingProduct?: BillingProduct;
  planStatus?: IYourBillingPlanWidget['billingStatus'];
  onUpgradeNowCallback?: IYourBillingPlanWidget['onUpgradeNowCallback'];
  onInfoIconClick?: () => void;
  isTrialExpired?: boolean;
}

const computeAdditionalInfoText = (
  isTeam: boolean,
  isStarter: boolean,
  cancelAt: ICancelledDuePlanHolder['cancelAt'],
  renewByDate?: string | null,
  onInfoIconClick?,
) => {
  const _numOfDays = getNumberOfDays(Date.now() - new Date(cancelAt || '').getTime());
  const _warningSubText =
    isTeam || isStarter ? 'your account will be locked' : 'we will automatically move you to the Free plan';
  const _formattedDate = getDateFormatMMMDDYYYY(renewByDate);

  if (_numOfDays > NUMBER_OF_DAYS_BEFORE_FREE_PLAN_WARNING) {
    return (
      <span>
        <br />
        If you don't update your payment info by {_formattedDate}, {_warningSubText}{' '}
        {onInfoIconClick && <InfoIcon onClick={onInfoIconClick} />}
      </span>
    );
  }

  return '';
};

export const CancelledDuePlanHolder: React.FC<ICancelledDuePlanHolder> = ({
  cancelAt,
  renewByDate,
  planStatus,
  onInfoIconClick,
  billingProduct,
  onUpgradeNowCallback,
  className,
  isTrialExpired,
}) => {
  let _shortHolder = false;
  let _heading;
  let _details;
  let _renewPlanButtonText;
  let _checkoutPopupType;
  const _formattedDateOfPayment = getDateFormatMMMDDYYYY(cancelAt);

  const _isTeam = billingProduct === BillingProduct.TEAM;
  const _isStarter = billingProduct === BillingProduct.STARTER;
  const _planLabel = useMemo(() => {
    if (_isStarter) return 'Starter ';
    if (_isTeam) return 'Team ';
    return '';
  }, [_isStarter, _isTeam]);

  if (planStatus === BillingStatus.ACTIVE) {
    _heading = <>You’ve cancelled your plan {onInfoIconClick && <InfoIcon onClick={onInfoIconClick} />}</>;
    _details =
      _isTeam || _isStarter ? (
        <>
          Your access to {_planLabel}features will end on {_formattedDateOfPayment}.
        </>
      ) : (
        <>You'll be downgraded to Free on {_formattedDateOfPayment}.</>
      );
    _shortHolder = _isTeam;
    _renewPlanButtonText = 'Resubscribe';
  }

  if (planStatus === BillingStatus.PAST_DUE) {
    const _additionalInfo = computeAdditionalInfoText(_isTeam, _isStarter, cancelAt, renewByDate, onInfoIconClick);

    _heading = <span>There was a problem with your card</span>;
    _details = <>Please check your payment info. {_additionalInfo}</>;
    _renewPlanButtonText = 'Update card';
    _checkoutPopupType = CheckoutPopupType.MANAGE_PLAN;
  }

  if (planStatus === BillingStatus.CANCELED) {
    if (isTrialExpired) {
      _heading = <>Your {_planLabel}trial has expired</>;
      _details = <>Upgrade to reactivate Writer.</>;
      _renewPlanButtonText = 'Upgrade now';
    } else {
      _heading = <>Your {_planLabel}plan has ended</>;
      _details = <>Resubscribe to a new {_planLabel}plan to reactivate Writer.</>;
      _renewPlanButtonText = 'Resubscribe';
    }
  }

  return (
    <div
      className={cx(styles.cancelledPlanHolder, className, {
        [styles.cancelledPlanHolderShort]: _shortHolder,
      })}
    >
      <div className={styles.cancelledTextHolder}>
        <Text variant={TextSize.L} bold>
          {_heading}
        </Text>
        <Text variant={TextSize.S}>{_details}</Text>
      </div>
      {onUpgradeNowCallback && (
        <Button
          size={SizeTypes.SMALL}
          type={ButtonTypes.BLACK}
          content={_renewPlanButtonText}
          className={cx(styles.upgradeNowButton, {
            [styles.canceledButton]: planStatus === BillingStatus.CANCELED,
          })}
          onClick={() => onUpgradeNowCallback(undefined, _checkoutPopupType)}
        />
      )}
    </div>
  );
};
