import type React from 'react';
import { useCallback, useMemo } from 'react';

import cx from 'classnames';

import { BillingProduct } from '@writercolab/common-utils';
import { Dropdown, DropdownPlacement, ItemsTypes, SearchBar, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { ListDropdown, ListDropdownType } from '@writercolab/ui-molecules';

import { TermsLimitReached } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';

import { TermsAccessEnum, TermsFilterKey, useTermsContext } from '../../../../context/termsContext';
import { goToBilling } from '../../../../utils/navigationUtils';
import PageTitle from '../../../molecules/PageTitle';
import { ImportItemType } from '../../../organisms/ImporterPopup/types';
import { headerTooltipText } from '../../termsAndSnippetsShared/common';
import {
  CreateTermContainer,
  DeleteButton,
  DownloadButton,
  FreeSubscriptionTermsLimitReached,
  ImportButton,
  TagsFiltersContainer,
} from '../../termsAndSnippetsShared/commonComponents';
import { useTermsHeaderActions } from '../../termsAndSnippetsShared/useTermsHeaderActions';

import styles from '../../termsAndSnippetsShared/TermsHeader.module.css';

interface ITermHeaderProps {
  isTeamNameShown: boolean;
  addNewItemsDisabled: boolean;
  orgId: number | undefined;
}

const TermsLimitReachedTooltipText = ({ navigate, orgId }) => (
  <FreeSubscriptionTermsLimitReached onCtaClick={() => goToBilling(navigate, orgId)} variant={TermsLimitReached.TERM} />
);

export const TermsHeader: React.FC<ITermHeaderProps> = ({ isTeamNameShown = false, addNewItemsDisabled, orgId }) => {
  const {
    termsContext,
    handleSearch,
    handleClipMode,
    handleDownloadTerms,
    handleOpenNewTermPopup,
    handleToggleModal,
    handleOpenImportTermsPopup,
    handleFilterChange,
  } = useTermsContext();
  const navigate = useNavigate();
  const {
    tooltipContent,
    onChangeSearchEvent,
    handleClearInput,
    handleDeleteButtonClick,
    clipModeDropdownOptions,
    downloadDropdownOptions,
  } = useTermsHeaderActions(termsContext.clipMode, handleSearch, handleToggleModal);

  const access = {
    isTermsLoaded: termsContext.isTermsLoaded,
    showViewOnlyTag: termsContext.termsAccess !== TermsAccessEnum.ADMIN,
    viewOnlyTagTooltip:
      termsContext.termsAccess === TermsAccessEnum.TEAM_MEMBER
        ? headerTooltipText.viewOnlyTeamMember(ImportItemType.TERMS)
        : headerTooltipText.viewOnlyAnotherTeam(termsContext.manageTeam?.name),
  };

  const teamName = (isTeamNameShown && termsContext.currentTeam?.name) || '';
  const isFilterTagsApplied = termsContext.filters[TermsFilterKey.TAGS].filter(filter => filter.isSelected);

  const handleTagsFilterClose = () => {
    const updatedTags = termsContext.filters[TermsFilterKey.TAGS].map(filter => ({ ...filter, isSelected: false }));
    handleFilterChange(TermsFilterKey.TAGS, updatedTags);
  };

  const handleLimitTooltipClick = useCallback(() => {
    orgId && goToBilling(navigate, orgId, BillingProduct.STARTER);
  }, [navigate, orgId]);

  const importButtonTooltipText = useMemo(() => {
    if (addNewItemsDisabled) {
      return <TermsLimitReachedTooltipText navigate={navigate} orgId={orgId} />;
    } else {
      return (
        <Text variant={TextSize.L} color={TextColor.WHITE}>
          {headerTooltipText.importBtn}
        </Text>
      );
    }
  }, [addNewItemsDisabled, navigate, orgId]);

  return (
    <div className={styles.header}>
      <div className={styles.headerInlineItem}>
        <PageTitle title="Terms" teamName={teamName} className={styles.pageTitle} />
      </div>

      <div className={styles.headerInlineItem}>
        {!!termsContext.selectedTerms.length && (
          <DeleteButton
            className={styles.staticWidthButton}
            testId="ai-assistant-terms-delete"
            numOfSelectedTerms={termsContext.selectedTerms.length}
            onClick={handleDeleteButtonClick}
          />
        )}
        {!isEmpty(isFilterTagsApplied) && (
          <TagsFiltersContainer filtersAmount={isFilterTagsApplied.length} onClose={handleTagsFilterClose} />
        )}

        {access.isTermsLoaded && !access.showViewOnlyTag && (
          <ImportButton
            className={cx(styles.importButton, styles.staticWidthButton, {
              [styles.importButtonDisabled]: addNewItemsDisabled,
            })}
            testId="ai-assistant-terms-import"
            onClick={handleOpenImportTermsPopup}
            disabled={addNewItemsDisabled}
            buttonTooltipText={importButtonTooltipText}
          />
        )}

        <ListDropdown
          className={styles.clipModeDropdown}
          options={clipModeDropdownOptions}
          type={ListDropdownType.SHOW_SELECTED_TRIGGER_ICON}
          tooltip={tooltipContent}
          onSelect={handleClipMode}
          dropDownContainerClassName={styles.clipModeDropdownContainer}
        />
        <SearchBar
          id="search"
          value={termsContext.searchTerm}
          placeholder="Search"
          onChange={onChangeSearchEvent}
          className={styles.searchBar}
          handleClearInput={handleClearInput}
        />

        <div className={styles.newButtonContainer}>
          {access.isTermsLoaded && (
            <CreateTermContainer
              showViewOnlyTag={access.showViewOnlyTag}
              viewOnlyTagTooltip={access.viewOnlyTagTooltip}
              handleOpenNewTermPopup={handleOpenNewTermPopup}
              disabled={addNewItemsDisabled}
              buttonTooltipEnabled={!addNewItemsDisabled}
              buttonTooltipText={
                <FreeSubscriptionTermsLimitReached
                  onCtaClick={handleLimitTooltipClick}
                  variant={TermsLimitReached.TERM}
                />
              }
            />
          )}
        </div>
        <Dropdown
          trigger={
            <DownloadButton
              testId="ai-assistant-terms-download"
              className={cx(styles.controlButton, styles.downloadBtn)}
              isLoading={termsContext.downloadInProgress}
            />
          }
          options={downloadDropdownOptions}
          onPrimaryOptionClickAction={handleDownloadTerms}
          placement={DropdownPlacement.BOTTOM_RIGHT}
          itemsType={ItemsTypes.ACTION}
        />
      </div>
    </div>
  );
};

export default TermsHeader;
